import React from 'react';

import Framework from "@beardeddevops/react.framework";
import {Collections} from "components";


const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Net Profits Report',
		url: 'net_profits',
		icon: 'file',
	},
];

export default class NetProfitsReport extends Framework.Components.View {


	constructor(props) {
		super(props);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
	}

	render() {

		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
				</Framework.Components.PageBar>
				<Collections.NetProfitsReport
					history={this.props.history}
					reloadParent={this.props.reloadParent}
				/>
			</div>
		);
	}
}
