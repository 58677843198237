import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

export default class Locations extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Locations._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	};

	render() {
		const {data, pages, loading,limit} = this.state;
		let columns =[
			{
				Header: "Name",
				accessor: "pkey",
				Cell: props => <Link
					to={"/locations/" + props.original.pkey}>{props.original.name}</Link>
			},
			{
				Header:"Store Number",
				accessor: "store_number"
			},
			{
				Header:"Address",
				id:"street_1",
				accessor: (object) => object.street_2 ? object.street_1 + " " + object.street_2 : object.street_1,
			},
			{
				Header: "State",
				id:"state",
				accessor: 'state'
			},
			{
				Header:"Zipcode",
				accessor: "zip"
			},
			{
				Header:"Phone Number",
				accessor: "phone"
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`/locations/`+ props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		]
		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{/*{this.renderSearch("Locations")}*/}
							{this.renderHeaderRight("Locations",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
				</CardContent>
				{APPCONFIG.settings.isDev() ? null :
					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				}
			</Card>
		)
	}
}
