import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';

/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
export default {
	typography: {
		useNextVariants: true,
	},
	fontFamily: 'Roboto, sans-serif',
	borderRadius: 2,
	palette: {
		primary1Color: cyan[500],
		primary2Color: cyan[700],
		primary3Color: grey[400],
		accent1Color: '#158f76',
		accent2Color: grey[100],
		accent3Color: grey[500],
		textColor: '#000000',
		secondaryTextColor: grey[500],
		alternateTextColor: '#ffffff',
		canvasColor: '#ffffff',
		borderColor: grey[300],
		disabledColor: grey[300],
		pickerHeaderColor: cyan[500],
		clockCircleColor: grey[700],
		shadowColor: '#000000',
	},
	overrides: {
		MuiCard: {
			root: {
				marginBottom: '20px',
				position: 'relative',
				overflow: 'visible'
			}
		},
		MuiCardContent: { // Name of the component ⚛️ / style sheet
			root: { // Name of the rule
				padding: '14px', // Some CSS
			},
		},
		MuiCardActions: {
			root: {
				padding: '14px', // Some CSS
				justifyContent: 'flex-end',
				'&:last-of-type': {
					marginRight: '0px',
				},
			}
		},
		MuiOutlinedInput: {
			inputMarginDense: {
				padding: '8px',
				'&$inputMultiline': {
					padding: '2px 2px 1px 2px !important'
				}
			}
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '-1px',
				marginBottom: '0px'
			}
		},
		MuiInputLabel: {
			outlined: {
				'&$marginDense': {
					transform: 'translate(14px, 10px) scale(1)'
				}
			},
		},
		MuiTabs: {
			root: {
				height: '36px',
				minHeight: 'none',
				borderBottom: '1px solid #e3e3e3'
			},
			indicator: {
				backgroundColor: '#ffffff'
			}
		},
		MuiTab: {
			root: {
				height: '36px',
				minHeight: 'none',
				borderRadius: '8px 8px 0 0 !important',
				backgroundColor: '#f5f5f5 !important',
				zIndex: '1',
				border: '1px solid #a8a8a8 !important',
				borderBottom: 'none !important',
				"&$selected": {
					"backgroundColor": "#ffffff !important"
				}
			},
		},
	},
};
