import React from 'react';

import {Button} from 'reactstrap';
import CardHeader from "@material-ui/core/CardHeader"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import {Tab,Tabs} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import Account from "components/Objects/Account";
import BillingObject from "components/Objects/Billing";
import SubscriptionObject from "components/Objects/Subscription";
import CustomReceipt from "../../../../../components/Objects/AccountCustomizations";
import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework"
import API from "@beardeddevops/react.api";
import Types from "../../../../../constants/Types";
import {confirmAlert} from "react-confirm-alert";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/',
		icon: 'dashboard',
	},
	{
		name: 'Account',
		url: '/settings',
		icon: 'address-card-o',
	},
];

class Settings extends Framework.Components.View {

	constructor(props) {
		super(props,'details');
	}

	componentDidMount() {
		this.state.showSubscribe = this.props.rootState.no_subscription;
		this.state.card_error = true;
	}

	handleSubscribe = async (ev) => {
		ev.preventDefault();

		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<Card>
						<CardContent style={{display: 'grid'}}>
							<b style={{fontSize: '16px'}}>Please select the plan you wish to subscribe to. <br/><br/><i>You cannot use terminals with Cash Only</i></b>
						</CardContent>
						<CardActions>
							<Button color="primary"
							        onClick={async () => {
								        let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.READ);
								        await billing.submit(null, 'GET_CREATE_STRIPE_CASH');
										onClose();
								        window.location.reload()
							        }}>Cash Only</Button>
							<Button color="primary"
							        onClick={async () => {
								        let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.READ);
								        await billing.submit(null, 'GET_CREATE_STRIPE_TERMINAL');
										onClose();
								        window.location.reload()
							        }}>Terminals</Button>
							<Button color="primary"
							        onClick={() => {onClose();}}>Cancel</Button>
						</CardActions>
					</Card>
				)
			}
		});
	}

	render() {

		// Create the Stripe object yourself...
		const stripePromise = loadStripe(APPCONFIG.settings.isDev()?"pk_test_tRSfBrW6ZVEDUfzR5Z9LS0Dc":"pk_live_5xbn5wfaZ994xK2auzKck9dp");

		const appearance = {
			theme: 'stripe'
		};

		//Pass to BillingObject
		let subscriptionFailed = this.props.rootState.failedSubscription;

		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>

				<Tabs value={this.state.tab} onChange={this.handleTabChange} component="div">
					<Tab label="Account Settings" value="details"/>
					<Tab label="Billing" value="billing"/>
					<Tab label="Customizations" value="customizations"/>
				</Tabs>

				<div style={{display: this.state.tab === "details" ? "block" : "none"}}>
					<Account
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</div>

				<div id="billingContainer" style={{display: this.state.tab === "billing" ? "" : "none"}}>
					<div id="billingObject">
						<Card>
							<CardContent>
								<Elements stripe={stripePromise}>
									<BillingObject
										subFailed={subscriptionFailed}
										baseUrl={'/accounts#billing'}
										reloadPage={this.refreshPage}
										reloadSelf={this.state.refresh}
										history={this.props.history}
									/>
								</Elements>
							</CardContent>
						</Card>

				</div>
					<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>

							<div id="subscriptionObject">
								<Card>
									<CardContent style={{paddingBottom: 0}}>
										<SubscriptionObject
											reloadPage={this.refreshPage}
											reloadSelf={this.state.refresh}
											baseUrl={'/settings#billing'}
											history={this.props.history}
											setCardError={(card_error) => {this.setState({card_error})}}
										/>
										{this.props.rootState.no_subscription ? <hr style={{marginBottom: 0}}/> : null}
									</CardContent>
									{this.props.rootState.no_subscription ?
										<CardActions style={{justifyContent: 'flex-end', padding: '16px'}}>
											<Button color="primary"
											        onClick={this.handleSubscribe}
											        disabled={this.state.card_error}
											>Subscribe</Button>
										</CardActions>
										: null}
								</Card>
							</div>
					</Framework.Errors.General>
				</div>

				<div style={{display: this.state.tab === "customizations" ? "block" : "none"}}>
					<CustomReceipt history={this.props.history}
					               reloadPage={this.refreshPage}
					               reloadSelf={this.state.refresh}
					               id={this.props.rootState.receiptCustomizationId}
					               account={this.props.rootState.account}
					/>
				</div>
			</div>
		);
	}
}

export default Framework.Components.withRootState(Settings);
