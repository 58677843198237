import React from 'react';
import TextField from './TextField';
import PropTypes from "prop-types";

class NumberField extends React.Component {
	static propTypes = {
		...TextField.propTypes,
		allowNegative: PropTypes.bool
	};

	static defaultProps = {
		...TextField.defaultProps,
		allowNegative: false
	}

	handleChange = (name, value) => {
		let cachedValue = value;
		let {update, property,allowNegative} = this.props;
		let max = property?.range?.max || 12;
		let type = property?.type || 'string';
		let isInt = type === 'int';
		let isPhone = type === 'phone' || name.includes('phone') || (name.includes('number') && max < 16);

		if (isInt) value = value.toString();
		if (isPhone || name.includes('zip')) {
			value = value.replace(/(?!-)\D/g, '');
		} else {
			if(allowNegative)value = value.replace(/[^-0-9\.]/g, '');
			else value = value.replace(/\D/g, '');


		}
		if (value.length && isInt) {
			value = parseInt(value);

			if (value > max) value = Math.min(max, parseInt(cachedValue));
		} else {
			value = value.substr(0, max);
		}
		update(name, value);
	}

	render() {
		let {type, update, property, ...rest} = this.props;

		type = type || property.type_js;

		return (
			<TextField
				type={this.props.type || property.type_js}
				property={property}
				update={this.handleChange}
				{...rest}
			/>
		);
	}
}

export default NumberField;
