import React from 'react';
import APPCONFIG from 'constants/Config';

export default class Footer extends React.Component {
  render() {
    return (
      <section className="app-footer no-print">
        <div className="container-fluid">
          <span className="float-left">
            <span>Version {process.env.version} | {APPCONFIG.copyright}-{APPCONFIG.year} © <a className="brand" target="_blank" href={APPCONFIG.productLink}>{APPCONFIG.brand}</a></span>
          </span>
          <span className="float-right">
	          <span>Built by <a href={process.env.author.url} target="_blank">{process.env.author.name}</a></span>
          </span>
        </div>
      </section>
    );
  }
}
