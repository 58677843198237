import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";

import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";


export default class Certificates extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Certificates._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		let columns = [

			{
				Header: "Name",
				accessor: "pkey",
				Cell: props => <Link
					to={"/certificates/" + props.value}>{props.original.name}</Link>
			},
			{
				Header: "Code",
				id: "code",
				accessor: r => r.code,
			},
			{
				Header: "Amount",
				id: "value",
				accessor: r => "$ " + (parseFloat(r.value)).toFixed(2),
				Cell: props => <div style={{textAlign: 'right'}}>{props.value}</div>
			},
			{
				Header: "Phone",
				id: "phone",
				accessor: r => r.phone,
				Cell: props => <div>{props.value.substr(0,3) + "-" + props.value.substr(3,3) + "-" +  props.value.substr(6,4)}</div>

			},
			{
				Header: "Email",
				id: "email",
				accessor: r => r.email,
			},
			{
				Header: "Status",
				id: "used",
				accessor: r => r.used,
				Cell: props => <div>
					{props.value === true ? "Used" : null}
				</div>,
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell : props => {
					return(
						<span>
							<Link to={`/certificates/` + props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (

			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Gift Certificates')}
							{this.renderHeaderRight("Certificates",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						onPageSizeChange={this.onPageSizeChange}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
