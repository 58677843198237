import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import {Button, Alert, ModalHeader, ModalBody, ModalFooter, Modal} from 'reactstrap';

import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import Typography from "@material-ui/core/Typography";

export default class Layaway extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @typedef {Transactions_Object} this.state
		 */
		super(props, Types.Layaways._name);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (typeof(this.props.setRemaining) === 'function' && this.state.remaining !== prevState.remaining) {
			this.props.setRemaining(this.state.remaining)
		}
	}

	render() {
		/**
		 * @type {Layaways_Properties} properties
		 */
		let properties = this.model.properties;
		/**
		 *  @type {Layaways_Object} object
		 */
		let object = this.state;

		return <Card>
			<CardContent>
				<h4 className="section-title">Layaway Details</h4>
				<div>
					<div className={'form-group'}>
						<Framework.Elements.TextField
							name={"customer"}
							label={"Customer Name"}
							property={properties.customer}
							value={object.customer}
							error={object.customer_error}
							update={this.handleTextFieldPropertyUpdate}
						/>

					</div>
					<div className={'form-group'}>
						<Framework.Elements.TextField
							name={"customer"}
							label={"Layaway Notes"}
							property={properties.customer_notes}
							value={object.customer_notes}
							error={object.customer_notes_error}
							update={this.handleTextFieldPropertyUpdate}
							multiline={4}
						/>
					</div>
					<hr/>
				</div>
				<div className={"row"}>

					{object.completed_date !== "" ?
						<div className={"col"}>
							<div className={"form-group"}>
								<Framework.Elements.Date
									label={"Completed Date"}
									value={object.completed_date}
									error={object.completed_date_error}
									property={properties.completed_date}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div> : null}
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.Date
								label={"Estimated Date"}
								value={object.estimated_date}
								error={object.estimated_date_error}
								property={properties.estimated_date}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
					</div>
				</div>
				<div className={"row"}>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								error={object.sub_total_amount_error}
								value={object.sub_total_amount + object.amount - object.tax_amount}
								property={properties.sub_total_amount}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								error={object.tax_amount_error}
								value={object.tax_amount}
								property={properties.tax_amount}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								label={"Total"}
								error={object.amount_error}
								value={object.amount}
								property={properties.amount}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
				</div>
				<div className={"row"}>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								error={object.cc_fees_error}
								value={object.cc_fees}
								property={properties.cc_fees}
								label={"CC Fees Collected"}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								error={object.amount_error}
								value={parseFloat(Math.abs((object.remaining - (object.amount - object.deposit)))).toFixed(2)}
								property={properties.amount}
								label={"Additional Payments"}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
					<div className={"col"}>
						<div className={"form-group"}>
							<Framework.Elements.DollarAmount
								error={object.remaining_error}
								value={object.remaining}
								property={properties.remaining}
								update={this.handleTextFieldPropertyUpdate}
								disabled={true}
							/>
						</div>
					</div>
				</div>
				<div style={{display: 'grid'}}>
					<Button color="primary" onClick={this.handleSubmit} style={{justifySelf: 'end'}}>Save</Button>
				</div>
			</CardContent>
		</Card>
	}
}
