import React from 'react';
import Framework from "@beardeddevops/react.framework";
import {Objects} from "components";

export default class Checkout extends Framework.Components.View {
	render() {
		return (
			<div className="container-fluid">
				<Objects.Checkout
					id={this.props.account}
					history={this.props.history}
					reloadShell={this.props.reloadShell}
					reloadPage={this.refreshPage}
					reloadSelf={this.state.refresh}
					shouldSave={this.state["saveModelUser"]}
					shouldReloadCart={this.props.shouldReloadCart}
					baseUrl={this.props.baseUrl}
				/>
			</div>
		)
	}
};
