import React from 'react';
import Framework from '@beardeddevops/react.framework';
import SidenavContent from './SidenavContent';
import onClickOutside from 'react-onclickoutside';
import PropTypes from "prop-types";

const FrameworkSideBar = Framework.Components.withRootState(Framework.Components.SideBar);

export default class Sidebar extends React.Component {
	static propTypes = {
		locations: PropTypes.number,
		sessions: PropTypes.number,
		items: PropTypes.number,
		ffl_boss_linked: PropTypes.bool
	};

	componentDidMount() {

	}

	render() {
		let props = this.props;
		return (
			<FrameworkSideBar
				footerLink={"https://fflsoftwarepro.freshdesk.com/support/tickets/new"}
				clickOutside={false}
				{...props}
			>
					<SidenavContent {...props} />
			</FrameworkSideBar>
		);
	}
}

