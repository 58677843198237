import React from "react";
import ReactTable from "react-table";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import {Card, CardContent} from "@material-ui/core";

import {Objects} from "components";
const {UniversalModal} = Framework.Elements;

class TransactionEntries extends Framework.Components.Collection{
	constructor(props) {
		let model = new API.BuildClass(Types.Transactions_Entries._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 5;
		this.limit = 5;
		this.state.refundObject={};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if(prevState.data !== this.state.data){
			this.props.refundItems(this.state.data)
		}
	}

	// handleToggleRefund = (id, total, quantity, quantity_refunded, refund_amount, transaction, product = false) => (e) => {
	// 	this.state.refundObject = {
	// 		id: id,
	// 		total: total,
	// 		quantity: quantity,
	// 		quantity_refunded: quantity_refunded,
	// 		refund_amount: refund_amount,
	// 		transaction: transaction,
	// 		product: product,
	// 	}
	// 	this.toggle('modalRefund')();
	// }

	handleReload() {
		this.reloadTable();
		// this.props.refund();
	}

	render() {
		const {data, pages, limit, loading} = this.state;


		const columns = [
			//Todo:Flag consignment if it exists
			{
				Header: "Name",
				id: "name",
				accessor: r => (
					<a
						href={r.transaction_type === "transaction_product"
							? "https://app.fflboss.com/products/" + r.pkey
							: r.transaction_type === "transaction_item"
								? "https://" + window.location.host + "/inventory/" + r.inventory
								: "https://" + window.location.host + "/consignments/" + r.inventory}
						target={"_blank"}
					>
						{r.name}
					</a>
				)
			},
			{
				Header: "Serial",
				id: "serial_number",
				accessor: r => (
					<span>
						{r.serial_number}
						<span className={"no-print"}>
							<Framework.Printable.Label
								ref={el => (this['printable' + r.pkey] = el)}
								prefix="P" code={r.pkey} label={r.serial_number}
							/>
						</span>
					</span>
				)
			},
			{
				Header: "SKU",
				id: "sku",
				accessor: r => r.sku
			},
			{
				Header: "Unit Price",
				id: "price",
				accessor: r => r.price
			},
			{
				Header: "Quantity",
				id: "quantity",
				accessor: r => r.transaction_type === "transaction_product"  ? 1 : r.quantity
			},

			{
				//Todo: Can't sort this column.. If we could get the subtotal calculated on the backend, this could be fixed.
				Header: "Subtotal",
				id: "subtotal",
				accessor: r => r.subtotal =  r.transaction_type === "transaction_product" ? ( r.price) : r.transaction_type === "transaction_trade" ? <span style={{color: "red"}}>{parseFloat( r.price * r.quantity).toFixed(2)}</span> : parseFloat( r.price * r.quantity).toFixed(2)
			},
			{
				//Todo: Test this once FFL Products are retrievable
				Header: "Discount",
				id: "discount_amount",
				 accessor: r =>  (r.discount_amount)
			},

			{
				Header: "Refunded",
				id: "refunded",
				accessor: r => r.refunded === 0 ? '' : 'Refunded'
			}
		];
		return (
			<>
				<Card>

					<CardContent>
						<div className="row no-screen" >
							<div className={"col-8"}>
								<h1>Transaction {this.props.parent}</h1></div>
							<div className={"col-4 pull-right"} >
								{data[0] ?
									<div className={"no-screen"}
									     style={{textAlign: "right", width: "100%", fontSize: "1rem"}}>
										<strong>{data[0].transactions.locations.name} </strong><br/>
										{data[0].transactions.locations.store_number ? <div>{data[0].transactions.locations.store_number}</div> : null}
										{data[0].transactions.locations.phone ? <div>{data[0].transactions.locations.phone}</div> : null}
										{data[0].transactions.locations.email ? <div>{data[0].transactions.locations.email}</div> : null}
										{data[0].transactions.locations.street_1 ? <div>{data[0].transactions.locations.street_1}</div> : null}
										{data[0].transactions.locations.street_1 ?
											<div>{data[0].transactions.locations.city} {data[0].transactions.locations.state} {data[0].transactions.locations.zip}</div> : null}
									</div>
									: null}
							</div>
						</div>
						<div className="box-body">
							<div className="table-bar">
								<div className="d-flex no-print">
									{this.renderHeaderRight("Transaction Items", columns)}
								</div>
							</div>
							<ReactTable
								key={this.state.key}
								columns={columns}
								manual // Forces table not to paginate or sort automatically, so we can handle it server-side
								data={data}
								pages={pages} // Display the total number of pages
								loading={loading} // Display the loading overlay when we need it
								loadingText="Requesting Data"
								onFetchData={this.fetchData} // Request new data when things change
								onPageSizeChange={this.onPageSizeChange}
								onSortedChange={this.onSortedChange}
								page={this.state.page}
								onPageChange={this.onPageChange}
								expanderDefaults={{
									sortable: true,
									resizable: true,
									filterable: false,
									width: undefined
								}}
								onExpandedChange={this.onExpandedChange}
								defaultSorted={[
									{
										id: this.model.sort,
										desc: this.model.order === 'desc'
									}
								]}
								showPagination={true}
								showPaginationTop={false}
								showPaginationBottom={true}
								showPageSizeOptions={true}
								defaultPageSize={limit}
								pageSize={limit}
								pageSizeOptions={[5, 20, 50, 100, 500, 1000]}
								className="-striped -highlight"
							/>
						</div>

						{APPCONFIG.settings.isDev() ? null :
							<Framework.Elements.LoadingSpinner isLoading={loading}/>
						}

					</CardContent>
					{/*<UniversalModal*/}
					{/*	isDev={APPCONFIG.settings.isDev()}*/}
					{/*	// location={this.props.locations}*/}
					{/*	title={"Refund"}*/}
					{/*	name={"Refunds"}*/}
					{/*	submitLabel={"Refund"}*/}
					{/*	parent={this.props.parent}*/}
					{/*	object={Objects.TransactionRefund}*/}
					{/*	objectProps={{*/}
					{/*		entry: this.state.refundObject.id,*/}
					{/*		total: this.state.refundObject.total,*/}
					{/*		quantity: this.state.refundObject.quantity,*/}
					{/*		quantity_refunded: this.state.refundObject.quantity_refunded,*/}
					{/*		refund_amount: this.state.refundObject.refund_amount,*/}
					{/*		transaction: this.state.refundObject.transaction,*/}
					{/*		product: this.state.refundObject.product,*/}
					{/*		items: this.state.data*/}
					{/*	}}*/}
					{/*	history={this.props.history}*/}
					{/*	toggle={this.state.modalRefund}*/}
					{/*	reportClose={this.reportClose('modalRefund')}*/}
					{/*	afterSave={() => this.handleReload()}*/}
					{/*	size={'md'}*/}
					{/*	hideGoTo={true}*/}
					{/*/>*/}
				</Card>
			</>

		)
	}
}
export default Framework.Components.withRootState(TransactionEntries);
