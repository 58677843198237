import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";


export default class Reports extends Framework.Components.Collection {
	constructor(props) {

		//Todo: Hook up correct model once backend is finshed
		let model = new API.BuildClass(Types.Certificates._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
	}

	//todo: Fix the columns
	render() {
		const {data, pages, limit, loading} = this.state;
		let columns = [

			{
				Header: "Report Name",
				id:"name",
				accessor: "name",
			},
			{
				Header: "Total",
				id: "Total",
				accessor: r => r.code,
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 60,
				Cell: props => {
					return (
						<span>
							{/*Todo: Link will have to be unique on the backend*/}
							<Link to={`/layaways/` + props.original.layaway}>
								<Button color="primary" size={"sm"} block={true}>View</Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (

			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Reports')}
							{this.renderHeaderRight("Reports",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						onPageSizeChange={this.onPageSizeChange}
						className="-striped -highlight"
					/>

					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				</CardContent>
			</Card>
		)
	}
}
