import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class Account extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Accounts_Subscriptions_Object} this.state    Skus
		 */
		super(props, Types.Accounts._name);
		this.noDelete = false;
		this.state.warningShown = false;
	}

	async handleSubmit(event) {
		if (typeof event !== 'undefined') event.preventDefault();
		this.setState({loading: true});
		if (this.handleProcess()) {
			this.model.submit(this, API.RequestType.UPDATE, false).then((data) => {
				window.location.reload();
			});
		} else {
			this.setState({loading: false});
			this.props.attemptedSave(false);
		}
		return false;
	}

	redirect = "/settings";
	// noinspection JSUnusedGlobalSymbols
	account_level = true;

	loadSelfActual(data) {
		super.loadSelfActual(data);
		this.setState({password: ''});
	}

	form = () => {
		let {properties} = this.model;
		let {
			name_first,
			name_first_error,
			name_last,
			name_last_error,
			email,
			email_error,
			password_temp,
			password_temp_error,
			password_re,
			password_re_error,
			password,
			password_error,
			password_new,
			password_new_error,
			reference_id,
			reference_id_error
		} = this.state;

		return (

			<div>
				{(name_first || name_last) && <div>
					<Typography variant="h5" component="h2">
						{name_first} {name_last}
					</Typography>
					<hr/>
				</div>}
				<div className={'row'}>

					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"name_first"}
							                              label={"First Name"}
							                              property={properties.name_first}
							                              value={name_first}
							                              error={name_first_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
					</div>
					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"name_last"}
							                              label={"Last Name"}
							                              property={properties.name_last}
							                              value={name_last}
							                              error={name_last_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
					</div>
					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"email"}
							                              label={"Email"}
							                              property={properties.email}
							                              value={email}
							                              error={email_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
					</div>
					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"password_new"}
							                              label={"New Password"}
							                              property={properties.password_new}
							                              value={password_new}
							                              error={password_new_error}
							                              update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
					</div>
					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"password_re"}
							                              label={"Retype Password"}
							                              property={properties.password_re}
							                              value={password_re}
							                              error={password_re_error}
							                              update={this.handleTextFieldPropertyUpdate}
							                              onKeyPress={e => {
								                              if (e.key === 'Enter' && this.state.pkey) {
									                              this.handleSubmit()
								                              }
							                              }}
							/>
						</div>
					</div>
					<div className={'col-4'}>
						<div className="form-group">
							<Framework.Elements.TextField name={"password"}
							                              label={"Current or Temporary Password"}
							                              property={properties.password}
							                              value={password}
							                              error={password_error}
							                              update={this.handleTextFieldPropertyUpdate}
							                              onKeyPress={e => {
								                              if (e.key === 'Enter') {
									                              this.handleSubmit()
								                              }
							                              }}
							/>
						</div>
					</div>

					<div className={'col-4'} onClick={() => {
						if (!this.state.warningShown) {
							alert("Adding an API key will upgrade your account to the Terminal plan if you have active terminals. This will result in a price increase.")
							this.state.warningShown = true;
						}
					}}>
						<Framework.Elements.TextField name={"reference_id"}
						                              label={"Current API Key (last 4) - Contact Support to Remove"}
						                              property={properties.reference_id}
						                              value={reference_id}
						                              error={reference_id_error}
						                              update={this.handleTextFieldPropertyUpdate}
						/>
					</div>


				</div>


				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</div>
		)
	}
}

export default withRouter(Account);
