import React from 'react';

import {Collections, Objects} from "components";
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "constants/Config";

import {Button} from "reactstrap";
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Locations',
		url: '/locations',
		icon: 'building',
	}
];

export default class Locations extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>
				{/*<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>*/}
				{/*		<Button data-id="add-location" onClick={this.toggle('modalLocation')} size={"sm"} color="primary">*/}
				{/*			<i className="fa fa-building"/>&nbsp;&nbsp;Add Location*/}
				{/*		</Button>*/}
				{/*</Framework.Components.PageBar>*/}


				<Collections.Locations
					history={this.props.history}
					reloadParent={this.props.reloadParent}
					reloadPage={this.refreshPage}
					reloadSelf={this.state.refresh}
				/>

				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Location"}
					object={Objects.Location}
					history={this.props.history}
					toggle={this.state['modalLocation']}
					reportClose={this.reportClose('modalLocation')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		);
	}
}

