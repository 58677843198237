import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import View from '../../../components/View';
import {Objects} from "components";
import InventoryLedgersCollection from 'components/Collections/InventoryLedgers';

import {Button} from 'reactstrap';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

export default class Inventory extends View {

	componentDidMount() {
		this.setModel("Inventory");
	}

	render() {
		let props = this.props;
		return (
			<div className="container-fluid">
				 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					 <Objects.Inventory
						 parent={this.props.match.params.id}
						 history={this.props.history}
						 baseUrl={this.props.baseUrl}
						 id={this.props.match.params.id}
						 // noDelete = {false}
						 shouldSave={this.state["saveModelInventory"]}
						 attemptedSave={this.resetModal("Inventory")}
					 />
				</Framework.Errors.General>
				<InventoryLedgersCollection parent={this.props.id} {...props} />
			</div>
		)
	}
};
