import React from "react";
import APPCONFIG from "../../constants/Config";
import moment from "moment";
// Wrapper around ReactToPrint that works around its pattern of passing anonymous functions in the render method

export default (data) => {

	//data parse
	let layawayInfo = data.data;
	let layawayItems = data.items;
	let layawayPayments = data.pData?.filter(item => item.amount !== "0.00");
	let customReceipt = data.rData.items[0];
	let location = layawayInfo.locations;

	let sub_total = 0;
	let singularItemDiscounts = 0;

	let items = layawayItems.entries.map((item, i) => {
		console.log("item logging", item)
		//Fix for Firearms quantity
		let costs  = item.cost ?? item.price
		let quantity = item.refund_quantity != null ? item.refund_quantity : (item.quantity !== null ? item.quantity : 1);

		let price = parseFloat(costs);
		sub_total += price * quantity;

		//Getting singular item discounts from entries
		singularItemDiscounts += (parseFloat(item.discount_amount * quantity));

		return (
			<div>
				<br></br>
				<div key={'i' + i} style={{ borderBottom: "1px dashed #d6d6d6"}} id={"printItems"}>
					<div className={"row"}>
					     <span className={"col-6"}>
						     <span><strong>{item.name}</strong></span>
					     </span>
					<span className={"col-6 text-right"}>
							<span id={"printPrice"}>$ {price.toFixed(2)}</span>
						</span>
				</div>

					{item?.discount_amount != "0.00" ?
						<>
							<div className={"row"}>
								<span className={"col-6"}>
									<span><strong > Discount: </strong></span>
								</span>
								<span className={"col-6 text-right"}>
									<span style={{color: "red"}} >$ {item.discount_amount}</span>
								</span>
							</div>
						</> : null
					}

					<div className={"row"}>
						<span className={"col-6"}>SKU: {item.sku}</span>
						<span className={"col-6 text-right"}>

								<span id={"printRefunded"}>x{quantity}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<strong>{"$ " + (parseFloat((price * quantity) - (item.discount_amount * quantity)).toFixed(2))}</strong>
								</span>
						</span>
					</div>
				</div>
			</div>
		)
	})

	let payments = layawayPayments.map((payment, i) => {
		let lastItem = i + 1 === layawayPayments.length;
		return (
			<div>
				<br></br>
				<div key={'p' + i} style={{borderBottom:  lastItem ? null : "1px dashed #d6d6d6"}} id={"printItems"}>
					<div className={"row"}>
					     <span className={"col-6"}>
						     <span><strong>{moment(payment.created).format('MM-DD-YYYY')}</strong></span>
					     </span>
						<span className={"col-6 text-right"}>
							<span id={"printPrice"}>$ {payment.amount}</span>
						</span>
					</div>
				</div>
			</div>
		)
	})

	let layawayCreatedDate = moment(layawayInfo.created).format('MM-DD-YYYY');

	//custom for stone inc (they're in central so its not that bad rn...)
	let layawayCreatedTime = moment(layawayInfo.created).format('HH:mm:ss');

	return (
		<>
				<div>
				{location ?
					<div className={"receiptHeader"} style={{marginTop: "1rem"}}>
						<span style={{textAlign: "center", fontSize: '1rem'}}>
							{customReceipt.logo &&
								<div>
									<img
										id="logo"
										src={APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/accounts/customizations/image'}
										alt={""}
										style={{marginBottom: "0.8rem", height:"115px"}}
									/>
								</div>
							}
							<div>
								{location.name? <div><b>{location.name}</b></div> : null}
								{location.store_number ? <div>{location.store_number}</div> : null}
								{location.phone ? <div>{location.phone}</div> : null}
								{location.email ? <div>{location.email}</div> : null}
								{location.street_1 ? <div>{location.street_1}</div> : null}
								{location.street_1 ?
									<div>{location.city} {location.state} {location.zip}</div> : null}
								{layawayCreatedDate} {(data.account === 183 || data.account === 10) ? layawayCreatedTime : ""}
								<br/>
								<br/>
							</div>
						</span>

					</div>
					: null}
				<div style={{fontSize: '20px', borderBottom: "1px solid #d6d6d6", marginTop:"1rem", paddingBottom: "10px"}}>
					<b>Layaway #{data.data.pkey}</b>
				</div>
				<div>
					{items}
				</div>
			<div className={"row"}  style={{color: "rgba(0, 0, 0, 0.5)", paddingTop: "10px"}}>
				<div className={"col"} id={"poInfo"}>
					{/*Conditionally show po order information*/}
					{layawayInfo.po_number ?
					<div>
						<span>PO Number</span><span style={{float: "right"}}>{layawayInfo.po_number}</span><br/>
					</div> : null
					}
				</div>
			</div>

			<div className="invoice-sum text-right" >
				<ul className="list-unstyled">
					<li>Sub Total: $ {sub_total.toFixed(2)}</li>
					<li>{singularItemDiscounts ? "Singular Discounts: $" + parseFloat(singularItemDiscounts).toFixed(2): null}</li>
					<li>Tax : $ {layawayInfo.tax_amount}</li>
					<li>{"Total: $ " + layawayInfo.amount}</li>
					<li>{"Remaining: $ " + layawayInfo.remaining}</li>
				</ul>
			</div>

			{/*payments*/}
			<div>
				{payments && <hr />}
				<div>
					<div style={{textAlign: "center"}}>
						<div style={{fontSize: "1.2rem"}}><b>Payments Made</b></div>
						<br/>
					</div>
					{payments}
					{/*TODO: remaining balance and total of all payments made here*/}
				</div>
			</div>


				{/*Don't show line if there is no transaction info*/}
				{!customReceipt.message && !customReceipt.warranty && !customReceipt.return_policy ? null : <hr></hr>}
			<div id="customMessageContainer">
				<div style={{textAlign: "center"}}>
					<h4 id="customMessage">
						{customReceipt.message ?? ""}
					</h4>
					{customReceipt.warranty &&
						<div style={{textAlign: "center"}}>
							<span><b>Warranty Information</b></span>
							<div>
								{customReceipt.warranty}
							</div>
							<br/>
							<br/>
						</div>
					}
					{customReceipt.return_policy &&
						<div style={{textAlign: "center"}}>
							<span><b>Returns Information</b></span>
							<div>
								{customReceipt.return_policy}
							</div>
							<br/>
							<br/>
						</div>
					}
				</div>
			</div>

					{/*Section for Other Transaction information regarding the client*/}
					{layawayInfo.customer != null && layawayInfo.receipt_email != null && layawayInfo.description != null &&
					<div>
						<hr />
					<div style={{textAlign: "center"}}>
						<div style={{fontSize: "1.2rem"}}><b>Additional Information</b></div>
						<br/>
					</div>
							{layawayInfo.customer ?
								<div style={{textAlign: "center"}}>
									<span><b>Customer Name</b></span>
									<div>
										{layawayInfo.customer}
									</div>
									<br/>
								</div>
								: null}

					{layawayInfo.receipt_email ?
						<div style={{textAlign: "center"}}>
							<span><b>Customer Email</b></span>
							<div>
								{layawayInfo.receipt_email}
							</div>
							<br/>
						</div>
						: null}
					{layawayInfo.description ?
						<div style={{textAlign: "center"}}>
							<span><b>Description</b></span>
							<div>
								{layawayInfo.description}
							</div>
							<br/>

						</div>

						: null}

					{layawayInfo.notes ?
						<div style={{textAlign: "center"}}>
							<span><b>Notes</b></span>
							<div>
								{layawayInfo.notes}
							</div>
							<br/>
						</div>
						: null }
					</div>
						}
				</div>
		</>
	)
}
