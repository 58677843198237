import React from "react";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import _Base from "./_Base";

export default class _Checkbox extends _Base {

	render() {
		let property = this.state;
		let passThroughProps = this.passThroughProps;
		if( property.value == null ) {
			property.value = false;
		}
		return (
			<FormControl error={property.error !== ""} component="fieldset">
				<FormControlLabel control={
					<Checkbox
						checked={property.value}
						onChange={this.handleCheckedChangeFor}
						inputProps={{name: this.props.name}}
						disabled={this.props.disabled}
						style={{
							maxWidth: 250,
							marginBottom: 0,
							marginTop: 0
						}}
						{...passThroughProps}
					/>
				} label={this.props.boldLabel ?
					<span style={{ fontWeight: 'bold' }}>{(this.props.label||property.label)}</span> :
					(this.props.label||property.label)}
				/>
				{property.error !== "" ? <FormHelperText>{property.error}</FormHelperText> : null}
			</FormControl>
		)
	}
}
