import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
import {Button, ButtonGroup} from "reactstrap";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/index";
// import Currency from "../../../../../BeardedDevOps/react.framework/src/Masks/Currency";


export default class RegisterLedgerObject extends Framework.Components.Object {
	static propTypes = {
		current_amount: PropTypes.string,
		method: PropTypes.string
	};

	constructor(props) {
		/**
		 *  @type {Registers_Ledgers_Object} this.state
		 */
		super(props, Types.Registers_Ledgers._name);
		this.state.type = true;
		this.state.leave = props.currentAmount;
	}

	handleTypeChange = (event) => {
		let type = false;
		if (event.currentTarget.id === 'true') type = true
		this.setState({type: type})
	}
	handleEODAmount = (property, value) => {
		if (parseFloat(value) > 0) {
			this.setState({leave:this.props.currentAmount-value})
		}

		this.handleTextFieldPropertyUpdate(property, value);
		this.setState({leave: this.state.currentAmount})
		this.setState({currentAmount:(parseFloat(this.props.currentAmount-value).toFixed(2))})
	}


	render() {
		/**
		 *  @type {Registers_Ledgers_Object} object
		 */
		let object = this.state;
		/**
		 *  @type {Registers_Ledgers_Properties} properties
		 */
		let properties = this.model.properties
		// let exists = object.pkey && object.pkey.value;

		if (this.props.method === "sod") object.note = "Start of Day";
		if (this.props.method === "eod") object.note = 'End of Day';
		// object.register = this.props.parent;

		let method = this.props.method;
		return (
			<div>
				<form>
					<fieldset disabled={false}>
						<div className={"row"}>
							<div className="col-md">
								{method === "eod" ?
									<div className={"row"}>
										<div className={"col"}>
											<div className="form-group">
												<TextField
													placeholder={"0.00"}
													label={'Current Amount in Drawer'}
													margin={"normal"}
													variant="outlined"
													value={this.props.currentAmount}
													fullWidth={true}
													disabled={true}
													InputProps={{
														startAdornment: (
															<i className="fa fa-usd" style={{marginRight: '5px'}}/>
														),

													}}
												/>
											</div>
										</div>
										<div className={"col"}>
											<div className="form-group">
												<Framework.Elements.DollarAmount
													name={"amount"}
													label={"Amount to Leave in Drawer"}
													value={object.amount}
													error={object.amount_error}
													property={properties.amount}
													update={this.handleEODAmount}
													margin={"normal"}
												/>
											</div>
										</div>
										<div className={"col"}>
											<div className="form-group">
												<TextField
													placeholder={"0.00"}
													label={'Amount to remove from drawer'}
													margin={"normal"}
													variant="outlined"
													value={this.state.leave}
													fullWidth={true}
													disabled={true}
													InputProps={{
														startAdornment: (
															<i className="fa fa-usd" style={{marginRight: '5px'}}/>
														),

													}}
												/>
											</div>
										</div>
									</div> : null}
								{method === "sod" ?
									<div className={"row"}>
										<div className={"col"}>
											<div className="form-group">
												<TextField
													placeholder={"0.00"}
													label={'Current Amount in Drawer'}
													margin={"normal"}
													variant="outlined"
													value={this.props.currentAmount}
													fullWidth={true}
													disabled={true}
													InputProps={{
														startAdornment: (
															<i className="fa fa-usd" style={{marginRight: '5px'}}/>
														),

													}}
												/>
											</div>
										</div>
										<div className={"col"}>
											<div className="form-group">
												<Framework.Elements.DollarAmount
													name={"currentAmount"}
													label={"Amount to Add into Drawer"}
													value={object.amount === "" ? object.amount = "0.00" : object.amount}
													error={object.amount_error}
													property={properties.amount}
													update={this.handleTextFieldPropertyUpdate}
													margin={"normal"}
												/>
											</div>

										</div>

									</div> : null}

								{method === "adj" ?
									<>
										<div className={"row"}>
											<div className={"col-6"}>
												<div className="form-group">
													<ButtonGroup>
														<Button
															id={'true'}
															color="primary"
															active={this.state.type}
															outline={!this.state.type}
															onClick={this.handleTypeChange}
														>
															Add
														</Button>
														<Button
															id={'false'}
															color="primary"
															active={!this.state.type}
															outline={this.state.type}
															onClick={this.handleTypeChange}
														>
															Remove
														</Button>
													</ButtonGroup>
												</div>
											</div>
											<div className={"col-6"}>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"amount"}
														label={"Amount to Change"}
														value={object.amount}
														property={properties.amount}
														error={object.amount_error}
														update={this.handleTextFieldPropertyUpdate}
													/>

												</div>
											</div>
										</div>
										<div className="form-group">
											<Framework.Elements.TextField
												name={"note"}
												label={"Reason for adjustment"}
												property={properties.note}
												value={object.note}
												error={object.note_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>

									</>
									: null}

							</div>

						</div>
					</fieldset>
				</form>

				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</div>
		)
	}
}

