//Employees and Supervisors can't Read/Write Users, Registers, Certificates and locations
const UserRoles = {
	Admin: 0,
	Manager: 10,
	Supervisor: 20,
	Employee: 30,

	0: 'Admin',
	10: 'Manager',
	20: 'Supervisor',
	30: 'Employee',
};

export default UserRoles;
