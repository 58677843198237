import React from 'react';
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from 'constants/Types';
import Framework from '@beardeddevops/react.framework';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Card, CardContent } from "@material-ui/core";
import { Button } from 'reactstrap';

class TradeIns extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Trades._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	addToCart = (tradein, taxable = false) => () => {
		let item = new API.BuildClass(Types.Checkouts_Trades._name, API.RequestType.CREATE);
		item.object= {
			trade: tradein,
			quantity:'1',
			taxable: taxable
		}
		// noinspection JSIgnoredPromiseFromCall
		item.submit(null, API.RequestType.CREATE) //.then(() =>
		// this.props.history.push('/checkout'));
		this.handleCheckoutIncrement();
	};

	handleCheckoutIncrement = () => {
		this.props.rootState.set({
			session_items_count: this.props.rootState.session_items_count === null ? 1 : 1 + this.props.rootState.session_items_count
		});
	}

	render() {
		const { data, pages, limit, loading } = this.state;
		console.log('TRADE_IN COLLECTION PROPS', this.props);
		let columns = [
			{
				Header: "Name",
				accessor: "pkey",
				Cell: props => <Link to={"/trade-ins/" + props.value}>{props.original.name}</Link>
			},
			{
				Header: "Price",
				id: "price",
				accessor: r => r.price,
			},
			{
				Header: "Notes",
				id: 'notes',
				accessor: r => r.notes,
			},
			{
				Header: "Quantity",
				id: "quantity",
				accessor: r => r.quantity,
			},
			{
				Header: "Checkout",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 120,
				Cell: props => {
					return (
						<Button color="primary pull-right"
						        onClick={this.addToCart(props.original.pkey, props.original.taxable)}
						        size={"sm"} block={true}><i
							className={"fa fa-shopping-cart"}/>&nbsp;&nbsp;Add</Button>
					);
				}
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell : props => {
					return(
						<span>
							<Link to={`/trade-ins/` + props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];

		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Trade-Ins')}
							{this.renderHeaderRight("Trade-Ins",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						onPageSizeChange={this.onPageSizeChange}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		);
	}
}
export default Framework.Components.withRootState(TradeIns);
