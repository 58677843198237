'use strict';

import val from 'validator';
import {API} from "./index";

export default class Validator {

	/**
	 * @param component
	 * @param component.model
	 * @param component.state
	 * @param requestType
	 * @param submitted
	 * @param focused
	 * @param set
	 */
	process(component, requestType, submitted = false, focused = null, set = null) {
		let shouldContinue = true;

		//Clear out all previous notices
		component.model.properties.forEach((item, key) => {
			if (item !== null && item !== false) {
				// noinspection JSIgnoredPromiseFromCall
				this.constructor.process_error(component, key, '', requestType);
			}
		});

		if (!submitted) return true;

		let failedKeys = [];
		let self = this;
		let scrollTo = false;
		component.model.properties.forEach(function (item, key) {
			let itemValid = item !== null && item !== false;
			if (itemValid) {
				let isValidatable = (typeof component.model.properties[key].validate === 'undefined') || component.model.properties[key].validate !== false;
				// noinspection JSUnresolvedVariable
				let isSettable = (typeof component.model.properties[key].settable === 'undefined') || component.model.properties[key].settable !== false;
				// noinspection JSUnresolvedVariable
				let isQueryable = component.model.properties[key].queryable && [API.RequestType.READ, API.RequestType.READ_ALL].indexOf(requestType) !== -1;
				let isRequired = component.model.properties[key].required && API.RequestType.CREATE === requestType;
				let isUpdateable = component.model.properties[key].required && component.model.properties[key].update && API.RequestType.UPDATE === requestType;
				let isNotEmpty = typeof component.state[key] !== 'undefined' && component.state[key] !== '' && component.state[key] !== null;
				let shouldValidate = isQueryable || isRequired || isUpdateable || isNotEmpty;
				let isFocused = focused === null || focused === key;
				let processItem = isValidatable && isSettable && shouldValidate && isFocused;
				if ( processItem ) {
					//If only evaluating a set of items verify that this item is part of that set
					// noinspection JSObjectNullOrUndefined
					if (set !== null && typeof item.set !== "undefined" && Array.isArray(item.set) && !item.set.includes(set) ) {
						return;
					}
					let itemResult = self.processItem(component, key, requestType);
					if (itemResult === false) {
						failedKeys.push(key);
						if(scrollTo === false){
							scrollTo = key;
						}
						shouldContinue = false;
					}
				}
			}
		});

		if(scrollTo !== false){
			let elements = document.getElementsByName(scrollTo);
			if(elements.length > 0){
				if(elements[0].type ==="hidden") document.getElementById(scrollTo).scrollIntoView()
				else elements[0].scrollIntoView();
				window.scrollBy(0, -100);
			}
		}
		console.log(failedKeys);
		return shouldContinue;
	};

	processItem(component, propertyName, requestType) {
		let shouldContinue = true;

		//Don't ever try and validate pkey
		if( propertyName === 'pkey' || propertyName === '_id' ) {
			return true;
		}

		let isPropertiesCollection = typeof component.model !== 'undefined' && typeof component.state[propertyName] !== 'undefined';
		let prop = isPropertiesCollection ? component.model.properties[propertyName] : component.state;
		let value = isPropertiesCollection ? component.state[propertyName] : component.state.value;
		let {type, validation_type, range, required} = prop;
		if( validation_type ) {
			type = validation_type;
		}

		if (value == null || value === "") {
			if (required) {
				this.constructor.process_error(component, propertyName, 'Required', requestType);
				return false;
			}

			return true;
		}

		switch (type) {
			case "string":
			case "binary_id":
				if (range && value.length < range.min) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, `Min length ${range.min}`, requestType);
				} else if (range && value.length > range.max) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, `Max length ${range.max}`, requestType);
				}
				break;
			case "float":
			case "int": {
				let method = type === 'int' ? 'isInt' : 'isFloat';

				if (required || value != null) {
					if (!val[method](String(value), range)) {
						shouldContinue = false;
						this.constructor.process_error(component, propertyName, `Value must be a number and between ${range.min} and ${range.max}`, requestType);
					}
				}
				break;
			}
			case "bool":
				if ( ![true, false, 'true', 'false', 'TRUE', 'FALSE', 'True', 'False'].includes(value) ) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, `Must be true or false`, requestType);
				}
				break;
			case "date":
			case "datetime":
				if (required || value != null) {
					if (!val.isISO8601(String(value))) {
						shouldContinue = false;
						this.constructor.process_error(component, propertyName, `Value must be a valid date`, requestType);
					}
				}
				break;

			case "phone":
				if (value.length < 10 || value.length > 14 /* extension */ || !val.isInt(value, 'en-US')) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, 'Phone number is invalid.', requestType);
				}
				break;
			case "email":
				if (!val.isEmail(value)) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, 'Email address is required and must contain an @ and .', requestType);
				}
				break;
			case "zip":
				if (!val.isPostalCode(value, 'US')) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, 'Zip code is invalid.', requestType);
				}
				break;
			case "price":
				if (!val.isCurrency(String(value), {
					digits_after_decimal: [1, 2]
				})) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, `Value must be a currency amount`, requestType);
				}
				break;
			case "url":
				break;
			case "password":
				if (!val.isLength(value, range)) {
					shouldContinue = false;
					this.constructor.process_error(component, propertyName, `Password must be between ${range.min} and ${range.max}`, requestType);
				}
				break;
			case "fkey":
				if (required || value != null) {
					//TODO: Not the best solution but we have fkeys that are actually strings instead of ints.
					//if (!val.isInt(String(value))) {
					//	shouldContinue = false;
					//	this.constructor.process_error(component, propertyName, `Value must be selected from the dropdown`, requestType);
					//}
				}
				break;
			case "video":
			case "image":
			case "array":
			case "blob":
				break;
		}

		return shouldContinue;
	}

	static process_error(component, propertyName, value, requestType) {
		try {
			let isPropertiesCollection = typeof component.model !== 'undefined' && typeof component.state[propertyName] !== 'undefined';

			let propertyValue = isPropertiesCollection ? component.state[propertyName] : component.state.value;

			if (value.trim() !== '') {
				console.log((isPropertiesCollection ? 'Collection' : 'Element') + ' Error - ' + propertyName + ' | ' + propertyValue + ' | ' + value + ' | ' + ' For Transaction ' + (requestType || 'null'));
			}

			/**
			 * @object prop
			 * @property helper
			 */
			if (isPropertiesCollection) {
				const prop = component.model.properties[propertyName];
				let error = '';
				if (typeof prop !== 'object') return;
				if (prop.helper !== '') {
					if (value !== "") {
						error = prop.helper;
					} else {
						error = value; //Allow error value to be cleared out
					}
				} else {
					error = value;
				}
				component.setState({[propertyName + '_error']: error});
			} else {
				if (component.state.helper !== '') {
					if (value !== "") {
						component.setState({error: component.state.helper});
						component.props.update(propertyName + '_error', component.state.helper);
					} else {
						component.setState({error: value});
						component.props.update(propertyName + '_error', value);
					}
				} else {
					//component.error = value;
					component.setState({error: value});
					component.props.update(propertyName + '_error', value);
				}
			}
		}
		catch (e) {
			console.log('Failed to process error for ' + propertyName);
		}
	};

};
