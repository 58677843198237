import React from 'react';
import {withRouter} from "react-router";
import APPCONFIG from 'constants/Config';
import TextField from '@material-ui/core/TextField';
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import API from "@beardeddevops/react.api";
import {toast} from "react-toastify";
import {Card, CardContent} from "@material-ui/core";

class Information extends Framework.Components.Object {

	constructor(props) {
		super(props, Types.Accounts._name);
		this.model.requestType = 'GET';
		this.brand = APPCONFIG.brand;
		this.shouldAutoLoad = false;
	}

	render() {
		return (
			<div className="row justify-content-center" style={{maxWidth: '100%'}}>
				<div className="row col-lg-8">
					<div className="row" style={{width: '100%'}}>
						<div className="col-md-4">
							<span className="text-center"
							      style={{marginTop: '20px', display: 'block', paddingBottom: '25px'}}>
								<Link to={"/login"}>
									<Button color="secondary" size={"md"} block={false} style={{boxSizing: 'border-box', borderRadius: '.5rem', textDecoration: 'none #d1D5DB solid', textDecorationThickness: 'auto', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'}}>Back to Homepage</Button>
								</Link>
							</span>
						</div>
						<div className="col-md-8">
							<span className={'justify-content-center'}>
								<h4>Find out about the new Point of Sale Today</h4>
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<span className="text-center"
							      style={{marginTop: '20px', display: 'block', paddingBottom: '25px'}}>
								<img src="/assets/images/ffl-integration.png" style={{width: '400px'}}
								     alt={'FFL Boss Integration'}/>
							</span>
						</div>
						<div className="col-md-8">
							<h3>FFL Boss Integration</h3>
							<p>Point of Sale is able to directly integrate with FFL Boss allowing you to
								maintain a
								single firearms list between the two products.</p>
						</div>
					</div>
					<hr/>
					<div className="row">
						<div className="col-md-8">
							<h3>Z11 With Chip Integration</h3>
							<p>Compatible with Dejavoo Z11 counter top touch screen card terminal. The Z11
								supports
								WiFI connectivity and has EMV and NFC Contactless built in.</p>
						</div>
						<div className="col-md-4">
							<span className="text-center"
							      style={{display: 'block', paddingTop: '10px', paddingBottom: '10px'}}>
								<img src="/assets/images/dejavoo-z11.jpg" style={{width: '200px'}}
								     alt={'Z11 Support'}/>
							</span>
						</div>
					</div>
					<hr/>
					<div className="row">
						<div className="col-md-4">
							<span style={{display: 'block', paddingTop: '10px', paddingBottom: '10px'}}>
								<img src="/assets/images/blue-dog-logo.svg" style={{width: '200px'}}
								     alt={'Blue Dog'}/>
							</span>
							<span style={{display: 'block', paddingTop: '10px', paddingBottom: '10px'}}>
								<img src="/assets/images/trans-national.png" style={{width: '200px'}}
								     alt={'Trans National'}/>
							</span>
							<span style={{display: 'block', paddingTop: '10px', paddingBottom: '10px'}}>
								<img src="/assets/images/fluid-pay.svg" style={{width: '200px'}}
								     alt={'Fluid Pay'}/>
							</span>
						</div>
						<div className="col-md-8">
							<h3>BlueDog and TransNational</h3>
							<p>Support for BlueDog, TransNational and Fluidpay.com credit card processing
								networks.</p>
						</div>
					</div>
					<hr/>
					<div className="row">
						<div className="col-md-8">
							<h3>Fast and Easy Checkout</h3>
							<p>With our fast checkout process it is a breeze to run automatic, manual, check,
								and
								cash transactions.</p>
						</div>
						<div className="col-md-4">
							<span className="text-center"
							      style={{display: 'block', paddingBottom: '30px', marginTop: '20px'}}>
								<img src="/assets/images/checkout.PNG" style={{width: '400px'}}
								     alt={'Checkout Image'}/>
							</span>
						</div>
					</div>

				</div>
			</div>
		)
	}
}

const Informational = withRouter(Information);
const Page = () => (
	<div className="page-informational">
		<div className="main-body">
			<div key="1">
				<Informational/>
			</div>
		</div>
	</div>
);

export default Page;
