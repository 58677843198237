import React, {Component} from "react";
// noinspection ES6CheckImport
import PropTypes from "prop-types";
import API from "@beardeddevops/react.api";
import ErrorStackParser from "error-stack-parser";
import StackTraceGPS from 'stacktrace-gps';

export default class ErrorBoundary extends Component {
	static propTypes = {
		isDev: PropTypes.bool.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			stack: null,
			pathname: props.location?.pathname || '',
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.location && props.location.pathname !== state.pathname) {
			return {
				hasError: false,
				pathname: props.pathname,
			}
		}

		return null;
	}

	// noinspection JSUnusedGlobalSymbols
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return {
			hasError: true
		};
	}

	componentDidCatch(error, errorInfo) {
		if (!this.props.isDev) {
			console.log(error);
			console.log(errorInfo.componentStack);
		} else {
			console.log(error);
			console.log(errorInfo.componentStack);
		}
		this.setState({
			hasError: true,
			error: error,
			stack: errorInfo && errorInfo.componentStack ? errorInfo.componentStack : null
		});
	}

	render() {
		// Whatever rendering of error messages or children components you would like
		if (this.state.hasError) {
			// You can render any custom fallback UI
			let isDev = this.props.isDev;
			let error = this.state.error;
			let stack = this.state.stack;

			// let errorMessage = "Please use the {<strong onClick={async () => {await API.clearCache();}>Clear API Cache</strong>} function and refresh this page";

			//let's face it, we never throw a custom msg, commenting this out for now - Mason
			// if( this.state.error && this.state.error.hasOwnProperty('ui_message') ) {
			// 	errorMessage = this.state.error.ui_message;
			// }

			if(error){
				//log error.message, error.stack, this.state.pathname, log user on the API side
				let errorLoggingModel = new API.BuildClass("Unexpected_Errors", 'PUT');
				errorLoggingModel.object.path = this.state.pathname;
				errorLoggingModel.object.message = error.message;
				const stack = ErrorStackParser.parse(error);
				const gps = new StackTraceGPS();
				if (stack[0]) {
					gps.pinpoint(stack[0]).then((data) => {
						if (data.fileName && data.lineNumber) {
							const parsed = data.fileName.split('components/')[1];
							errorLoggingModel.object.stack = (parsed ?? data.fileName) + "|" + data.lineNumber;
							errorLoggingModel.submit(null, 'PUT', true);
						}
					});
				} else {
					errorLoggingModel.object.stack = error.stack;
					errorLoggingModel.submit(null, 'PUT', true);
				}
			}

			let errorMessage = '';
			if( error && isDev ) {
				errorMessage = error.message;
			}

			//TODO: we should probably log this stuff
			return <div style={{margin: '20px'}}>
				<h3>Unexpected error has occurred.<br/>
					Please use the <strong onClick={async () => {
						await API.clearCache();
					}}>Clear API Cache (click here)</strong> function and refresh this page. <br/>
					If the unexpected error still occurs take a screenshot and notify support.
				</h3>
				{isDev && error ?
					<div>
						{errorMessage}
						{stack ?
							<div dangerouslySetInnerHTML={{__html: stack.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
							: null}
					</div>
					: null}
			</div>;
		}

		return this.props.children;
	}
}
