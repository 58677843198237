import React from 'react';

import {Collections, Objects} from "components";
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "constants/Config";

import {Button} from "reactstrap";
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Layaways',
		url: '/layaways',
		icon: 'hourglass',
	}
];

export default class Layaways extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
						<Button data-id="add-layaway" onClick={this.toggle('modalLayaway')} size={"sm"} color="primary">
							<i className="fa fa-hourglass"/>&nbsp;&nbsp;Start Layaway
						</Button>
				</Framework.Components.PageBar>


				<Collections.Layaways
					history={this.props.history}
					reloadParent={this.props.reloadParent}
					reloadPage={this.refreshPage}
					reloadSelf={this.state.refresh}
				/>

				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway"}
					title={'Start Layaway'}
					submitLabel={'Start Layaway'}
					object={Objects.AddLayaway}
					history={this.props.history}
					toggle={this.state['modalLayaway']}
					reportClose={this.reportClose('modalLayaway')}
					afterSave={this.handleAfterSave}
					size={'xl'}
				/>

			</div>
		);
	}
}

