import React from 'react';

import Framework from "@beardeddevops/react.framework";
import {Objects} from "components";


const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
];

export default class Dashboard extends Framework.Components.View {
	constructor(props) {
		super(props);

	}

	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>

						<Objects.Dashboard
							location={location}
							history={this.props.history}
						/>
			</div>
		);
	}
}


