import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections} from "components";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Firearms',
		url: '/products',
		icon: 'barcode',
	}
];

export default class Products extends Framework.Components.View {
	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Products
						history={this.props.history}
						reloadParent={this.props.reloadParent}
					/>
				</Framework.Errors.General>
			</div>
		);
	}
}
