import React from 'react';

import {Collections} from "components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "constants/Config";
import {Button} from "reactstrap";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Paused Sales',
		url: '/sessions',
		icon: 'pause-circle',
	}
];
export default class Sessions extends Framework.Components.View {
	constructor(props) {
		super(props);
		//Create a reference variable
		this.child = React.createRef();
	};
	//Referencing newCart form Session\Collections
	newCart = () => {
		this.child.current.newCart()
	};
	render() {
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id={"new-sale"} color={"primary"} onClick={this.newCart} size={"sm"}>
						<i className="fa fa-calculator"/>&nbsp;&nbsp;New Sale
					</Button>
				</Framework.Components.PageBar>
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Sessions
						history={this.props.history}
						reloadParent={this.props.reloadParent}
						ref={this.child}
					/>
				</Framework.Errors.General>
			</div>
		);
	}
}
