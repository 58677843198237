import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
import API from "@beardeddevops/react.api";


/**
 *  @property {Inventories_Object} state
 *  @property {object} model
 *  @property {Inventories_Properties} model.properties
 *  @property {Inventories_Is} model.is
 *  @property {Inventories_Has} model.has
 */

export default class InventoryObject extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Inventories._name);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevProps.sku !== this.state.sku)
		{
			this.state.pkey = this.state.sku;
		}
		// /** @type {Certificates_Object} object */
		// let object = this.state;
		// if (typeof this.props.updateName === 'function') {
		// 	this.props.updateName(object.name);
		// }
	}

	handleSubmit = async (event) => {
		if (typeof event !== 'undefined') event.preventDefault();
		if (!this.props.id)
		{
			this.read = 'POST';
			super.handleSubmit(event, false);
		}
		else
		{
			super.handleSubmit(event, false);
		}
	}
	form = () => {
		let {properties} = this.model;
		/**
		 *  @type {Inventories_Object} object
		 */
		let object = this.state;
		let {loading} = this.state;
		object.account = this.props.account
		object.location = this.props.locations
		return (
			<div>

				<fieldset>
					<h4 className="section-title">Inventory Restocking</h4>
					<div className="form-group">
						<Framework.Elements.Select
							text ={"name(sku"}
							shouldLoad={true}
							update={this.handleSelectPropertyUpdate}
							property={properties.sku}
							value={object.sku}
							error={object.sku_error}
							object={object.skus}
							model={Types.Skus._name}
							nullable={false}
							limit={null}
							sort={'name'}
						/>
					</div>

				<div className={"form-group"}>
					<Framework.Elements.NumberField
						name={"number"}
						label={"Quantity"}
						property={ properties.quantity}
						value={object.quantity}
						error={object.quantity_error}
						update={this.handleTextFieldPropertyUpdate}
						allowNegative = {true}

					/>
				</div>
					<p style={{
						color: "gray",
						padding: "0 4px",
						marginBottom: "19px",
						fontSize: "11px",
						lineHeight: '12px'
					}}>
						Use Negative Numbers to Remove Stock.
					</p>
				</fieldset>


				<div className={classNames("loader", {'active': loading})}>
					<ClipLoader color={"#4A4A4A"} loading={loading}/>
				</div>
			</div>
		)
	}
}
