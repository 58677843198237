import React from "react";
import TextField from "@material-ui/core/TextField";
import _Base from "./_Base";
import {v4 as uuid} from 'uuid';
import Phone from "../Masks/Phone";
import PhoneExtension from "../Masks/PhoneExtension";
import PropTypes from "prop-types";

export default class _PhoneNumber extends _Base {
	static getDerivedStateFromProps = (props, state) => {
		const nextState = {};

		if (props.value !== state.value) {
			nextState.value = props.value;
			if( nextState.value !== null && nextState.value !== nextState.value.replace(/\D/g,'') ) {
				nextState.value.replace(/\D/g,'');
				props.update(state.name, nextState.value.replace(/\D/g,''));
			}
		}

		if (props.error !== state.error) {
			nextState.error = props.error;
		}

		return nextState;
	};

	static propTypes = {
		..._Base.propTypes,
		hasExtension: PropTypes.bool,
	}

	static defaultProps = {
		margin: 'dense',
		variant: 'outlined',
	}

	constructor(props) {
		super(props);
		// Generate a new autoComplete ID for every TextField instance to prevent browsers from autofilling form fields
		this.autoCompleteToken = uuid();
		this.state.validation_type = this.props.type || 'phone';

		if( this.state.value !== null && this.state.value !== this.state.value.replace(/\D/g,'') ) {
			this.props.update(this.state.name, this.state.value.replace(/\D/g,''));
		}
	}

	handleDataChange = (event) => {
		let value = event.target.value;
		value = value
			.replace(/\D/g, '')
			.trim();

		this.props.update(this.state.name, value);
	};

	render() {
		let {type} = this.props;
		let property = this.state;
		let multiline = this.props.multiline;
		let passThroughProps = this.passThroughProps;
		let additionalProps = {};

		if (multiline) {
			additionalProps.rows = multiline;
			additionalProps.style = {marginTop: '20px'};
		}

		if (property.value == null) {
			property.value = "";
		}

		type = type || 'tel';
		let Mask = this.props.hasExtension ? PhoneExtension : Phone;

		return (
			<TextField
				{...additionalProps}
				{...passThroughProps}
				autoComplete={this.autoCompleteToken}
				type={type}
				value={property.value}
				error={property.error !== ""}
				helperText={property.error}
				onChange={this.handleDataChange}
				onBlur={this.handleProcess}
				inputProps={{name: property.name}}
				label={this.props.label||property.label}
				fullWidth={true}
				margin={this.props.margin}
				variant={this.props.variant}
				multiline={!!multiline}
				InputProps={{
					inputComponent: Mask
				}}
				InputLabelProps={{shrink: property.value.length > 0}}
			/>
		)
	}
}