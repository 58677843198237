import React from "react";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";

import Typography from '@material-ui/core/Typography';
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";

import API from "@beardeddevops/react.api";
import {confirmAlert} from "react-confirm-alert";


/**
 *  @property {Accounts_Billing_Object} state
 *  @property {object} model
 *  @property {Accounts_Billing_Properties} model.properties
 *  @property {Accounts_Billing_Is} model.is
 *  @property {Accounts_Billing_Has} model.has
 */

const BillingObject = (props) => {

	const stripe = useStripe();
	const elements = useElements();

	const subscriptionFailed = props.subFailed;
	const no_subscription = props.rootState.no_subscription;

	const handleSubmit = async (ev) => {
		ev.preventDefault();

		const cardElement = elements.getElement("card");

		let token = null;
		await stripe.createToken(cardElement)
			.then((payload) => token = payload.token);

		let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.CREATE);
		billing.object.token = token['id'];
		await billing.submit(null, API.RequestType.CREATE);
		window.location.reload()
	};


	const handleClear = async (ev) => {
		ev.preventDefault();

		confirmAlert({
			title: 'Cancel Subscription',
			message: 'Are you sure you want to cancel the subscription for this account?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.DELETE);
						await billing.submit(null, 'DELETE_CANCEL')
						window.location.reload()
					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});
	}

	return (

		<div style={{height: "219px"}}>
			<Typography variant="h5" component="h3">
				Billing
			</Typography>
			<hr/>
			<div style={{
				padding: "10px",
				marginTop: "2.5rem",
			}}>
				<CardElement onReady={(element) => this._element = element}
				             options={{
					             iconStyle: 'solid',
					             style: {
						             base: {
							             width: "100px",
							             iconColor: '#3f51b5',
							             color: 'black',
							             fontSize: '16px',
							             letterSpacing: "1px",
							             '::placeholder': {
								             color: '#aab7c4',
							             },
						             },
						             invalid: {
							             iconColor: '#ff0033',
							             color: '#ff0033',
						             },
					             },
				             }}/>
				<hr></hr>
				<div className={""} style={{textAlign: "right", paddingTop: "3rem"}}>

					<button onClick={handleSubmit} className={"btn btn-primary mb-2 mr-2"}>
						Update Card Number
					</button>

					{!subscriptionFailed && !no_subscription &&
						<button onClick={handleClear} className={"btn btn-danger mb-2"}>
							Cancel Subscription
						</button>
					}
				</div>
			</div>
		</div>
	)
}

export default Framework.Components.withRootState(BillingObject);

// export default class BillingObject extends Framework.Components.Object {
//
// 	constructor(props) {
// 		super(props, Types.Accounts_Billing._name);
// 	}
//
// 	_element = null;
// 	redirect = "/settings";
// 	// noinspection JSUnusedGlobalSymbols
// 	account_level = true;
//
// 	handleSubmit = async event => {
// 		if (typeof event !== 'undefined') event.preventDefault();
// 		this.setState({loading: true});
//
// 		console.log("stripe props",this.props);
// 		//
// 		// const stripe = useStripe();
// 		// const elements = useElements();
//
// 		const cardElement = elements.getElement("card");
//
// 		this.props.stripe.createToken(cardElement)
// 			.then((payload) => console.log('[token]', payload));
//
// 		// let {token} = await this.props.stripe.createToken({name: "Name"});
// 		// if( token && typeof token.id !== "undefined" ) {
// 		// 	this.state.token = token.id;
// 		// }
// 		//
// 		// if (this.handleProcess()) {
// 		//
// 		// 	this.model.submit(this, this.state.pkey && this.state.pkey ? API.RequestType.UPDATE : API.RequestType.CREATE, () => {
// 		// 		// noinspection JSUnresolvedFunction
// 		// 		this._element.clear();
// 		// 		this.setState({loading: false});
// 		// 		this.props.attemptedSave(true);
// 		// 		this.loadSelf();
// 		// 	}).then();
// 		// } else {
// 		// 	if (typeof this.props.attemptedSave === "function") {
// 		// 		this.props.attemptedSave(false);
// 		// 	}
// 		// 	this.setState({loading: false});
// 		// }
// 		//
// 		// return false;
// 	};
//
// 	form = () => {
// 		/**
// 		 * @object this.state
// 		 * @property last4
// 		 * @property exp_month
// 		 * @property exp_year
// 		 */
// 		let exists = this.state.last4;
// 		let brand = this.state.brand;
// 		let last4 = this.state.last4;
// 		let exp_month = this.state.exp_month;
// 		let exp_year = this.state.exp_year;
//
// 		return (
// 			<div>
// 				<Typography variant="h5" component="h3">
// 					Billing
// 				</Typography>
// 				<hr/>
//
// 				<div style={{
// 					margin: "20px 50px 0",
// 					backgroundColor: "#f9f9f9",
// 					padding: "10px"
// 				}}>
// 					{sForm()}
// 				</div>
// 				{exists ? <div style={{
// 					margin: "0 50px 0",
// 					padding: "10px"
// 				}}>
// 					<Typography component="h5">
// 						Currently a {brand} ending in {last4}
// 					</Typography>
// 					<Typography color="textSecondary" gutterBottom>
// 						Expires {exp_month}/{exp_year}
// 					</Typography>
// 				</div> : null}
//
// 				<div className={classNames("loader", {'active': this.state.loading})}>
// 					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
// 				</div>
// 			</div>
// 		)
// 	}
// }
