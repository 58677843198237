import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Objects} from "components";
import {Button} from 'reactstrap';

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Inventory',
		url: '/skus',
		icon: 'list-alt',
	},
	{
		name: 'Inventory Groups',
		url: '/skus/classes',
		icon: 'folder',
	}
];

export default class SkuClasses extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-sku" onClick={this.toggle('modalAdd')} size={"sm"} color="primary">
						<i className="fa fa-list-alt"/>&nbsp;&nbsp;Add Inventory
					</Button>
				</Framework.Components.PageBar>

				 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.SkuClasses
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Inventory Group"}
					object={Objects.SkuClass}
					history={this.props.history}
					toggle={this.state['modalAdd']}
					reportClose={this.reportClose('modalAdd')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		);
	}
}
