import React from "react";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class Inventories extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Inventories._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	addToCart = (inventory, serial, taxable = true) => async () => {
		let item = new API.BuildClass(Types.Checkouts_Items._name, API.RequestType.CREATE);
		item.properties.inventory.value = inventory;
		item.object = {
			inventory: inventory,
			serial: serial,
			quantity: '1',
			taxable: taxable
		}
		item.submit(null, API.RequestType.CREATE).then(() => {
			this.handleCheckoutIncrement();
		});

	};

	handleCheckoutIncrement = () => {
		this.props.rootState.set({
			session_items_count: this.props.rootState.session_items_count === null ? 1 : 1 + this.props.rootState.session_items_count
		});
	}

	render() {
		const {data, pages, loading, limit} = this.state;
		let columns = [
			{
				Header: "Name",
				id: "skus.name",
				accessor: r => r.skus.name,
				Cell: props => <Link to={{
					pathname: "/inventory/" + props.original.pkey,
					inventoryProps: {parent: props.original.pkey}
				}}>{props.value}</Link>
			},
			{
				Header: "SKU",
				id: "skus.sku",
				accessor: r => r.skus.sku,
				Cell: props => <Link to={"/skus/" + props.original.skus.pkey}>{props.value}</Link>
			},
			{
				Header: "Inventory Group",
				id: "skus.sku_classes.name",
				accessor: r => r.skus.sku_classes.name,
				Cell: props => <Link
					to={"/skus/classes/" + props.original.skus.class}>{props.value}</Link>
			},
			{
				Header: "Price",
				id: "skus.price_retail",
				width: 120,
				accessor: r => r.skus.price_retail,
				Cell: props => <span>${props.value}</span>
			},
			// {
			// 	Header: "Location",
			// 	id: "location",
			// 	accessor: r => r.locations.name,
			// 	Cell: props => <Link to={"/locations/"+props.original.pkey}>{props.value}</Link>
			// },
			{
				Header: "Quantity",
				id: "quantity",
				width: 120,
				filterable: false,
				accessor: r => r.skus.serialized === false ? r.quantity : r.inventory_serializeds.length
			},
			{
				Header: "Checkout",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 120,
				Cell: props => {
					return (
						<span>
							{
								//Check if serialized or out of stock
								props.original.skus.serialized !== false || props.original.quantity <= 0 ?
									null :
									<Button color="primary pull-right"
									        onClick={this.addToCart(props.original.pkey, null, props.original.skus?.taxable ?? true)}
									        size={"sm"} block={true}><i
										className={"fa fa-shopping-cart"}/>&nbsp;&nbsp;Add</Button>
							}
						</span>
					);
				}
			},

			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={{
								pathname: "/inventory/" + props.original.pkey,
								inventoryProps: {parent: props.original.pkey}
							}}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		]
		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Products')}
							{this.renderHeaderRight("Products", columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						onPageSizeChange={this.onPageSizeChange}
						onSortedChange={this.onSortedChange}
						page={this.state.page}
						onPageChange={this.onPageChange}

						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						showPagination={true}
						showPaginationTop={false}
						showPaginationBottom={true}
						showPageSizeOptions={true}
						defaultPageSize={limit}
						pageSizeOptions={[5, 10, 20, 25, 50, 100, 500, 1000, 'All']}
						className="-striped -highlight"
						renderPageSizeOptions={this.renderPageSizes}
					/>
				</CardContent>
				{APPCONFIG.settings.isDev() ? null :
					<Framework.Elements.LoadingSpinner isLoading={loading}/>
				}
			</Card>
		)
	}
}
export default Framework.Components.withRootState(Inventories);
