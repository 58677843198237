import React from 'react';
const body = document.getElementsByTagName('body')[0]

class SideBar extends React.Component {
    constructor(props) {
        super(props);
		if(this.props.arrows) {
			this.state = {
				arrow_up: false,
				arrow_down: true
			}
		}
    }

    componentDidMount() {
        this.sideBarOpenId = this.props.rootState.subscribe({
            hideSideBar: this.handleSideBarOpenChange,
            pinSideBar: this.handleSideBarPinChange
        });

        body.classList.add('sidebar-open')

        const {history} = this.props;
        const nav = this.nav;

        //Are the next three lines TRULY required? Yes.
        this.toggleBtn = document.getElementsByClassName('toggle-sidebar-btn')[0];
        this.toggleBtn.addEventListener('click', () => body.classList.toggle('sidebar-open'));
        if(this.props.closeOnInit || this.props.rootState.isLockedDown) {this.toggleBtn.click();}

        const links = nav.getElementsByTagName('a');

        const highlightActiveLink = (pathname) => {
            const path = `${pathname}`;

            let activeLinks = [];

            for (const a of links) {
                const li = a.parentElement;
                li.classList.remove('active'); //removing all active tags at the start

                const href = a.getAttribute('href');
                if (path === '/' && href === '/dashboard') activeLinks.push(a);
                if (path.indexOf(href) === 0 && path.length === href.length) activeLinks.push(a)
            }

            activeLinks.forEach((alink) => {
                const li = alink.parentElement;

                li.classList.toggle('active')
            })
        }

        highlightActiveLink(history.location.pathname);
        history.listen((location) => highlightActiveLink(location.pathname));
    }

    componentWillUnmount() {
        this.props.rootState.unsubscribe(this.sideBarOpenId);
        this.props.rootState.unsubscribe(this.widthId);
    }

    widthId = 0;
    sideBarOpenId = 0;

    handleSideBarOpenChange = () => {
        let {rootState} = this.props;
        if (rootState.hideSideBar && !rootState.pinSideBar) {
            this.widthId = rootState.subscribe({
                width: this.handleClickOutside
            });
        }
    }

    handleSideBarPinChange = () => {
        let {rootState} = this.props;
        if (rootState.pinSideBar) this.props.rootState.unsubscribe(this.widthId);
        else {
            this.handleSideBarOpenChange();
        }
    }

    handleScroll = (e) => {
		if(this.props.arrows) {
			const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
			//debug purposes
			// console.log("scrolling: ", bottom, e.target.clientHeight, e.target.scrollTop, e.target.scrollHeight);
			if (bottom) {
				this.setState({arrow_down: false});
			} else {
				this.setState({arrow_down: true});
			}
			if (e.target.scrollTop === 0) {
				this.setState({arrow_up: false});
			} else {
				this.setState({arrow_up: true});
			}
		}
    }

    handleRef = (ref) => this.nav = ref;

    render() {
        return (
            <nav className={'app-sidebar no-print theme-bg-color'}>
                {this.state?.arrow_up && this.props.arrows &&
                    <section className={'sidebar-content-arrow-up'}/>
                }
                <section className={'sidebar-content'} onScroll={this.handleScroll}>
                    <ul className={'nav'} onClick={this.handleClickOutside} ref={this.handleRef}>
                        {this.props.children}
                    </ul>
                </section>
                {this.state?.arrow_down && this.props.arrows &&
                    <section className={'sidebar-content-arrow-down'}/>
                }

                <section className={'sidebar-footer'}>
                    <ul className={'nav'}>
                        <li>
                            <a target={"_blank"} href={this.props.footerLink}>
                                <i className={'nav-icon material-icons'}>help</i>
                                <span className={'nav-text'}>
									<span>Help & Support</span>
								</span>
                            </a>
                        </li>
                    </ul>
                </section>
            </nav>
        );
    }
}

class SideBarOutside extends React.Component {
    handleClickOutside = (e) => {
        let {rootState} = this.props;
        let parentClassList = e.target?.parentElement?.classList;
        let stayOpen = this.props.clickOutside ?? true;

        if (parentClassList?.contains('toggle-sidebar-btn') || parentClassList?.contains('pin-sidebar-btn')) {
            console.log('passed to sidebar toggle handler...')
        } else {
            if (!rootState.hideSideBar && !rootState.pinSideBar && stayOpen) {//only hide sidebar onClickOutside if rootState.pinSideBar is false
                rootState.set({hideSideBar: true});
                body.classList.remove('sidebar-open');
            }
        }
    }

    render() {
        let {rootState, history, ...rest} = this.props;
        return (
            <SideBarReal
				/*handleClickOutside={this.handleClickOutside}*/
                rootState={rootState}
                history={history}
                {...rest}
            >
                {this.props.children}
            </SideBarReal>
        );
    }
}

export default SideBar/* = onClickOutside(SideBar)*/;
