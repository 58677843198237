export const getFullID = (apiObject, prefix = '') => {
	let binaryIdKey;
	let pkeyKey
	if( prefix !== '' ) {
		binaryIdKey = `${prefix}_binary_id`;
		pkeyKey = `${prefix}`;
	} else {
		binaryIdKey = `binary_id`;
		pkeyKey = `pkey`;
	}
	let binaryId = apiObject[binaryIdKey];
	let	pkey = apiObject[pkeyKey];
	if( binaryId ) {
		return `${binaryId}-${pkey}`;
	} else {
		return `${pkey}`;
	}
}

export const isValidPkey = (pkey) => {
	return pkey != null && (
		(typeof pkey === 'string' && pkey.length) || (typeof pkey === 'number' && pkey > -1)
	);
}

export const getDisplayName = (Component) => {
	return Component.displayName || Component.name || "Component";
};

export const hasParentWithClass = (child, className) => {
	let node = child.parentNode;

	while (node != null && node.classList) {
		if (Array.from(node.classList).includes(className)) {
			return true;
		}

		node = node.parentNode;
	}

	return false;
};
