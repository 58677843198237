import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import{Card, CardContent} from "@material-ui/core";
import moment from "moment";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

import {confirmAlert} from "react-confirm-alert";

class Sessions extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Checkouts._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
		this.resumeCart = this.resumeCart.bind(this)
	}
	newCart = () => {
		// noinspection JSIgnoredPromiseFromCall
		confirmAlert({
			title: 'Confirm New Sale',
			message: typeof this.delete_message !== 'undefined' ? this.delete_message : 'This will clear your cart.  Do you wish to continue?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						this.props.rootState.set({
							session_items_count: 0
						})
						this.model.submit(null, API.RequestType.CREATE);

					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});
	};

	async resumeCart (id) {
		this.model.object.pkey = id;
		this.request = 'POST_RESUME';
		await this.model.submit(null,this.request);
		this.props.history.push('/checkout')
	};

	//
	deleteCart = (id) => () => {

		confirmAlert({
			title: 'Delete Paused Sale',
			message: typeof this.delete_message !== 'undefined' ? this.delete_message : 'This will delete the selected paused sale.  Continue?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						this.model.object.pkey = id;
						// noinspection JSIgnoredPromiseFromCall
						this.model.submit(null, API.RequestType.DELETE)
						this.setState({key: Math.random()});
						this.props.reloadParent();					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});
	};

	render() {
		const {data, pages, loading} = this.state;
		let columns = [

			//Todo: column does not sort correctly with how the accessor is configured - Might need a backend fix
			{
				//Todo: Still can't sort this column
				Header: "Items",
				id: "checkout_itemss",
				filterable: false,
				sortable: false,
				width: 70,
				/** @var r.checkout_itemss */
				/** @var r.checkout_productss */
				/** @var r.checkout_consignmentss */

				accessor: r => r.this,
				Cell: props => {

					let allItems = 0;

					//Collect item entry quantities and map to new array
						const items = props.original.checkout_itemss.map((item) => {
							return item.quantity;
						})

						//Increment allItems with new array values
						items.forEach((item) => {
							isNaN(item) ? allItems += 0 : allItems += item;
						})

						//Products
						let products = props?.original?.checkout_productss?.map((product) => {
							return product;
						})

						products.forEach((product) => {
							allItems += 1;
						})

						//Consignments
						let consignments = props.original.checkout_consignmentss.map((consignment) => {
							return consignment.quantity;
						})

						consignments.forEach((consignment) => {
							isNaN(consignment) ? allItems += 0 : allItems += consignment;

						});

						//Layaways - always add one
						let layaways = props?.original?.checkout_layawayss?.map((layaway) => {
							return layaway;
						})

						layaways.forEach(() => {
							allItems += 1;
						})

					return allItems;


				}
			},
			// {
			// 	Header: "Location",
			// 	id: "location",
			// 	accessor: r => r.locations != null ? r.locations.name : null,
			// 	Cell: props => props.original != null ? <Link to={"/locations/"+props.original.locations.pkey}>{props.value}</Link> : ""
			// },
			{
				Header: "User",
				id: "user",
				filterable: false,
				/** @var r.users */
				accessor: r => r.creator_name,
				Cell: props => props.original.users != null ?
					<Link to={"/users/" + props.original.users.pkey}>{props.value}</Link> :
					<Link to={"/settings"}>{props.value}</Link>
			},
			{
				Header: "Created",
				id: "created",
				filterable: false,
				accessor: d => {
					return moment(d.created)
						.local()
						.format("MM/DD/YYYY hh:mm A")
				}
			},
			{
				Header: "Description",
				id: "description",
				filterable: false,
				accessor: d => d.description ? d.description : "Description not Recorded"
			},
			{
				Header: "Client",
				id: "client",
				filterable: false,
				accessor: d => d.client ? d.client : "Client not Recorded"
			},
			{
				Header: "Resume",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 100,
				Cell: props => {
					//Check if items in a session is zero
					let noItems = props.original.checkout_consignmentss.length === 0 && props.original.checkout_itemss.length === 0 && props.original.checkout_productss.length === 0 && props.original.checkout_layawayss.length === 0 ? true : false
					return (
						<span>
							<Button color="primary pull-right"
							        onClick={()=> this.resumeCart(props.original.pkey)}
							        disabled={noItems}
							        size={"sm"} block={true}><i
								className={"fa fa-shopping-cart"}/>&nbsp;&nbsp;Resume</Button>
						</span>
					);
				}
			},
			{
				Header: "Delete",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 100,
				Cell: props => {

					return (
						<span>
							<Button color="danger pull-right"
							        onClick={this.deleteCart(props.original.pkey)}
							        size={"sm"} block={true}><i
								className={"fa fa-trash"}/>&nbsp;&nbsp;Delete</Button>
						</span>
					);
				}
			},

		];
		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Paused Sales')}
							{/*Search errors out*/}
							{this.renderHeaderRight("Paused Sales",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						filterable
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination={true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={this.model.limit}
						className="-striped -highlight"
					/>
				</CardContent>
				{APPCONFIG.settings.isDev() ? null :
					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				}
			</Card>

		)
	}
}

export default Framework.Components.withRootState(Sessions);
