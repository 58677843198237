import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";

import SalesTaxReport from './components/SalesTaxReport';
import SalesReport from "./components/SalesReport";
import YearEndReport from "./components/YearEndReport";

export default ({ match, locations}) => (
	<div>
		<Switch>

			<ErrorBoundaryRoute exact path={`${match.url}/sales_tax`}>
				<SalesTaxReport locations={locations}/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute exact path={`${match.url}/sales_report`}>
				<SalesReport locations={locations}/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute exact path={`${match.url}/year_end_report`}>
				<YearEndReport locations={locations}/>
			</ErrorBoundaryRoute>

		</Switch>
	</div>
);
