import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from '@beardeddevops/react.framework';
import ErrorBoundaryRoute from '../../../containers/ErrorBoundaryRoute';

import Header from 'components/Header';
import Sidenav from 'components/Sidenav';
import Footer from 'components/Footer';

import Dashboard from '../routes/dashboard/';
import Products from '../routes/products';
import Inventories from '../routes/inventories';
import TradeIns from '../routes/tradeins';
import Checkout from '../routes/checkout';
import Consignments from "../routes/consignments"
import ReportingModule from "../routes/reports";
import GrossRevenueReport from "../routes/gross-revnue";
import NetMarginsReport from "../routes/net-margins";
import NetProfitsReport from "../routes/net-profits"
import SalesTaxReport from "../routes/salestax";
import YearEndReport from "../routes/reports/components/YearEndReport"
import SessionsPage from '../routes/sessions';
import Transactions from '../routes/transactions';
import Certificates from '../routes/certificates';
import Layaways from '../routes/layaways'
import Skus from '../routes/skus';
import Users from '../routes/users/';
import Registers from '../routes/registers/';
import Locations from '../routes/locations/';
import Settings from '../routes/settings/';

import UserRoles from 'constants/UserRoles';
import NetMargins from "../routes/net-margins";
import NetProfits from "../routes/net-profits";

export default class MainApp extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			name: "",
			account: "",
			location: "",
			location_count: null,
			sessions_count: 0,
			session_items_count: 0,
			ffl_boss_linked: true
		};
	}

	reloadParent = () => {
		//this.props.rootState.triggerAction('getUserInformation', null);
	}

	render() {
		const {match, rootState, show_consignments, show_layaways} = this.props;
		let {
			name,
			roles,
			account,
			locations_count,
			sessions_count,
			session_items_count,
			ffl_boss_linked
		} = rootState;

		if (!account) {
			// Show loading spinner while session is undetermined - component unmounts if not authenticated
			// Prevents dashboard from flashing when unauthenticated users navigate to /
			return (
				<div className={classNames("tabled", "loader", 'active')}>
					<ClipLoader color={"#4A4A4A"} loading={true}/>
				</div>
			);
		}
		return (
			<div className="main-app-container">
				<Sidenav
					roles={roles}
					locations={locations_count}
					history={this.props.history}
					sessions={sessions_count}
					items={session_items_count}
					ffl_boss_linked={ffl_boss_linked}
					show_consignments={!!show_consignments}
					show_layaways={!!show_layaways}
				/>

				<section id="page-container" className="app-page-container">
					<Header
						roles={roles}
						name={name}
						items={session_items_count}
						history={this.props.history}
						reloadShell={this.reloadShell}
					/>

					<div className="app-content-wrapper">
						<div className="app-content">
							<div className="full-height">
								<Switch>
									{/*region Dashboard*/}
									<ErrorBoundaryRoute path={`${match.url}dashboard`}>
									<Dashboard
										account={account}
										// locations={locations}
										// states={states}
										reloadShell={this.reloadShell}
										scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}
										roles={roles}
									/>
								</ErrorBoundaryRoute>

									{/*region Sales Tax Report*/}
									{/*{roles <= UserRoles.Manager &&*/}
									{/*<ErrorBoundaryRoute path={`${match.url}sales_tax_report`}>*/}

									{/*<SalesTaxReport*/}
									{/*	account={account}*/}
									{/*	// locations={locations}*/}
									{/*	// states={states}*/}
									{/*	reloadShell={this.reloadShell}*/}
									{/*	scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}*/}
									{/*/>*/}
									{/*</ErrorBoundaryRoute>}*/}

									{/*region Products*/}

									<ErrorBoundaryRoute path={`${match.url}products`}>
										<Products
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}inventory`}>
										<Inventories
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}trade-ins`}>
										<TradeIns
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									{/*endregion Products*/}

									{/*region Sales*/}

									<ErrorBoundaryRoute path={`${match.url}checkout`}>
										<Checkout
											account={account}
											reloadParent={this.reloadParent}
											shouldReloadCart={this.props.shouldReloadCart}
										/>
									</ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}sessions`}>
										<SessionsPage
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									<ErrorBoundaryRoute path={`${match.url}transactions`}>
										<Transactions
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									{roles < UserRoles.Supervisor &&
									<ErrorBoundaryRoute path={`${match.url}certificates`}>
										<Certificates
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>}
									<ErrorBoundaryRoute path={`${match.url}consignments`}>
										<Consignments
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>
									<ErrorBoundaryRoute path={`${match.url}layaways`}>
										<Layaways
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									{/*endregion Sales*/}

									{/*region Inventory Control*/}

									<ErrorBoundaryRoute path={`${match.url}skus`}>
										<Skus
											account={account}
											locations={locations_count}
											reloadParent={this.reloadParent}
											shouldReloadCart={this.props.shouldReloadCart}
											scannedCode={this.props.scannedCode}
										/>
									</ErrorBoundaryRoute>

									{/*endregion Inventory Control*/}

									{/*region Account*/}

									{(roles < UserRoles.Supervisor || roles === undefined) &&
									<ErrorBoundaryRoute path={`${match.url}users`}>
										<Users
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>}

									{roles < UserRoles.Supervisor &&

									<ErrorBoundaryRoute path={`${match.url}registers`}>
										<Registers
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>}
									{roles < UserRoles.Supervisor &&

									<ErrorBoundaryRoute path={`${match.url}locations`}>
										<Locations
											account={account}
											locations={locations_count}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>}

									{(roles < UserRoles.Supervisor || roles === undefined) &&
									<ErrorBoundaryRoute path={`${match.url}settings`}>
										<Settings
											account={account}
											reloadParent={this.reloadParent}
										/>
									</ErrorBoundaryRoute>

									}

									{/*endregion Account*/}

									{/*Reporting Module*/}

									{roles < UserRoles.Supervisor &&
										<ErrorBoundaryRoute path={`${match.url}reports`}>
											<ReportingModule
												account={account}
												reloadParent={this.reloadParent}
											/>
										</ErrorBoundaryRoute>

									}
									{roles <= UserRoles.Manager &&
										<ErrorBoundaryRoute path={`${match.url}sales_tax_report`}>

											<SalesTaxReport
												account={account}
												// locations={locations}
												// states={states}
												reloadShell={this.reloadShell}
												scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}
											/>
										</ErrorBoundaryRoute>}


										<ErrorBoundaryRoute path={`${match.url}year_end_report`}>

											<YearEndReport
												account={account}
												reloadShell={this.reloadShell}
											/>
										</ErrorBoundaryRoute>

									{roles <= UserRoles.Manager &&
										<ErrorBoundaryRoute path={`${match.url}gross_revenue`}>

											<GrossRevenueReport
												account={account}
												// locations={locations}
												// states={states}
												reloadShell={this.reloadShell}
												scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}
											/>
										</ErrorBoundaryRoute>}

									{roles <= UserRoles.Manager &&
										<ErrorBoundaryRoute path={`${match.url}net_margins`}>

											<NetMarginsReport
												account={account}
												// locations={locations}
												// states={states}
												reloadShell={this.reloadShell}
												scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}
											/>
										</ErrorBoundaryRoute>}

									{roles <= UserRoles.Manager &&
										<ErrorBoundaryRoute path={`${match.url}net_profits`}>

											<NetProfitsReport
												account={account}
												// locations={locations}
												// states={states}
												reloadShell={this.reloadShell}
												scannedCode={!this.hasModalOpen ? this.props.scannedCode : null}
											/>
										</ErrorBoundaryRoute>}
									{/*endregion Reporting Module*/}
									<Redirect to="dashboard"/>
								</Switch>
							</div>
						</div>

						<Footer/>
					</div>
				</section>

			</div>
		);
	}
}

MainApp = Framework.Components.withRootState(MainApp);
