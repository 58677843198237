import React from 'react';
import APPCONFIG from "../../../../../constants/Config";
import Framework from '@beardeddevops/react.framework';
import { Collections, Objects } from 'components';
import { Button } from 'reactstrap';
const { UniversalModal } = Framework.Elements;


const breadcrumbsStatic = [
	{
		name: 'Checkout',
		url: '/',
		icon: 'calculator',
	},
	{
		name: 'Trade-Ins',
		url: '/trade-ins',
		icon: 'exchange',
	}
];

export default class TradeIns extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-tradein" onClick={this.toggle('modalTradein')} size={'sm'} color="primary">
						<i className="fa fa-exchange"/>&nbsp;&nbsp;Add Trade-In
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.TradeIns
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Trade-In"}
					object={Objects.TradeIn}
					history={this.props.history}
					toggle={this.state['modalTradein']}
					reportClose={this.reportClose('modalTradein')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>
			</div>
		);
	}
}
