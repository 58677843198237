import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import {Button, Alert, ModalHeader, ModalBody, ModalFooter, Modal} from 'reactstrap';

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';
import moment from "moment";

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Card, CardActions, TextField} from "@material-ui/core";
import Print from "../../containers/Print";
import {Objects} from "../index";
import ReactToPrint from "react-to-print";

class TransactionObject extends Framework.Components.Object {
	constructor(props) {
		/**
		 *  @typedef {Transactions_Object} this.state
		 */
		super(props, Types.Transactions._name);

		this.voidTransaction = this.voidTransaction.bind(this);
		this.refundTransaction = this.refundTransaction.bind(this);
		this.toggleRefund = this.toggleRefund.bind(this);
		this.saveModelRefund = this.saveModelRefund.bind(this);
		this.sendReceipt = this.sendReceipt.bind(this);

		this.state.showModalRefund = false;
	}

	toggleRefund() {
		this.setState({showModalRefund: !this.state.showModalRefund});
	}

	// noinspection JSUnusedGlobalSymbols
	loadSelfAfter(data) {
		this.setState({"locations": data.locations});
		// this.loadItems();
		// this.loadProducts();
		this.loadRefunds();
		this.loadEntries();
	}

	async loadEntries() {
		let items = new API.BuildClass(Types.Transactions_Entries._name, API.RequestType.READ_ALL);
		items.parent = this.state.pkey;
		// noinspection JSIgnoredPromiseFromCall
		let data = await items.submit(null, API.RequestType.READ_ALL)
		this.setState({entries: data.items});
	}

	async loadRefunds() {
		let refunds = new API.BuildClass(Types.Transactions_Refunds._name, API.RequestType.READ_ALL);
		refunds.parent = this.state.pkey;
		// noinspection JSIgnoredPromiseFromCall
		let data = await refunds.submit(null, API.RequestType.READ_ALL)
		this.setState({refunds: data.items});
	}

	voidTransaction() {
		if (!this.state.pkey || !this.state.pkey) return;
		this.state.status = "void";
		this.state.method = "terminal";
		this.setState({loading: true});
		this.model.submit(this, API.RequestType.DELETE, () => {
			this.setState({loading: false});
			this.props.attemptedSave(true);
			this.state.status = null;
			this.loadSelf();
		}).then();
	};

	refundTransaction() {
		if (!this.state.pkey || !this.state.pkey) return;
		this.state.status = "refund";
		this.state.method = "terminal";
		this.setState({loading: true});
		this.model.submit(this, API.RequestType.DELETE, () => {
			this.setState({loading: false});
			this.props.attemptedSave(true);
			this.state.status = null;
			this.loadSelf();
		}).then();
	};

	saveModelRefund() {
		if (!this.state.pkey || !this.state.pkey) return;
		this.state.status = "refund";
		//this.state.method = "terminal";
		this.setState({loading: true});
		this.model.submit(this, API.RequestType.DELETE, () => {
			this.setState({loading: false});
			this.props.attemptedSave(true);
			this.state.status = null;
			this.toggleRefund();
			this.loadSelf();
		}).then();
	}

	sendReceipt = async () => {
		if (!this.state.receipt_email) {
			this.setState({updateEmailModal: true})
			return;
		}
		if (!this.state.pkey || !this.state.pkey) return;
		//this.state.method = "terminal";
		this.setState({loading: true});
		await this.model.submit(this, 'POST_EMAIL', false).then(() => {
			this.setState({loading: false});
			if (typeof(this.props.attemptedSave) !== "undefined") {
				this.props.attemptedSave(true)
			}
		})

		this.setState({loading: false});
	}

	render() {
		if (!this.state.pkey || !this.state.entries || !this.state.refunds) {
			return <div/>;
		}
		/**
		 *  @typedef {Transactions_Object} object
		 */
		let object = this.state;

		/**
		 *  @typedef {Transactions_Properties} properties
		 */
		let {properties} = this.model
		let exists = object.pkey && object.pkey;

		let total = parseFloat(object.amount);
		let taxAmount = parseFloat(object.tax_amount).toFixed(2);
		let refundAmount = 0;
		let refundArray = object.refunds;

		//Configure refunded amount
		if (refundArray.length != 0) {
			refundArray.map((item, i) => {
				refundAmount += (parseFloat(item.amount));
			});
		}


		let remaining = object.amount;
		if (!isNaN(object.cash_amount) && object.cash_amount) remaining -= parseFloat(object.cash_amount).toFixed(2)
		if (!isNaN(object.check_amount) && object.check_amount) remaining -= parseFloat(object.check_amount).toFixed(2)
		if (!isNaN(object.other_amount) && object.other_amount) remaining -= parseFloat(object.other_amount).toFixed(2)
		if (!isNaN(object.invoice_amount) && object.invoice_amount) remaining -= parseFloat(object.invoice_amount).toFixed(2)
		remaining = parseFloat(remaining).toFixed(2);
		let change = (remaining < 0) ? remaining * -1 : 0;

		let status = null;
		if (object.status) {
			switch (object.status.status) {
				case "settled":
					status =
						<Alert className={"no-print"} color={"success"}>Settled <Button className={"pull-right"}
						                                                                color="danger"
						                                                                onClick={this.refundTransaction}>Refund
							Credit Card Transaction</Button></Alert>;
					break;
				case "pending_settlement":
					status = <Alert className={"no-print"} color={"warning"}>Pending Settlement <Button
						className={"pull-right"} color="danger" onClick={this.voidTransaction}>Void Credit Card
						Transaction</Button></Alert>;
					break;
				case "refunded":
					status = <Alert className={"no-print"} color={"dark"}>Refunded</Alert>;
					break;
				case "voided":
					status = <Alert className={"no-print"} color={"dark"}>Voided</Alert>;
					break;
			}
		} else {
			if (object.refunded) {
				status = <Alert className={"no-print"} color={"primary"}>All items for this transaction have been
					refunded.</Alert>;
			}
		}

		//**New subtotal & discount_amount configuration
		let subtotal = 0;
		let subtotal_tradeins = 0;
		let entries = this.state.entries;

		//**Configure Discount Amount**
		let discountTotalAmount = 0;
		let singularDiscountAmount = 0;

		if (entries?.length > 0)
		{
			entries.forEach(sumSubTotals);
			entries.forEach(sumSingularDiscounts);
		}

		function sumSingularDiscounts(item) {
			let castedItem = parseFloat(item.discount_amount);
			singularDiscountAmount += castedItem;
		}

		function sumSubTotals(item) {
			//set default to 1 since fflboss products can't have more than 1 quantity
			if(item.transaction_type === 'transaction_trade'){
				subtotal_tradeins += ((parseFloat(item.price)).toFixed(2) * (item.quantity ? item.quantity : 1));
			} else {
				subtotal += ((parseFloat(item.price)).toFixed(2) * (item.quantity ? item.quantity : 1));
			}
		}

		//Discount Amount
		let discountAmount = this.state.discount_amount
		discountAmount = parseFloat(discountAmount).toFixed(2);

		//Discount Percent
		let discountPercentage = this.state.discount_percentage;
		let discountPercentAmount = (parseFloat((discountPercentage / 100)) * (parseFloat(subtotal) - parseFloat(singularDiscountAmount))).toFixed(2);

		//Normal Discount
		if (discountAmount != 0.00) {
			discountTotalAmount += parseFloat(discountAmount);
		}
		//Percentage Discount
		if (discountPercentAmount != 0.00) {
			discountTotalAmount += parseFloat(discountPercentAmount);
		}

		return (
			<div>
				{status}
				<div className="row">
					<div className={"col-10"}>
						<Card>
							<div className="row">
								<div className="col-4">
									<div className={'row'}>
										<div className={"col-6"}>
											<div className={"form-group"}>
												<Framework.Elements.TextField
													name={"client"}
													label="Client Name"
													value={object.client}
													error={object.client_error}
													property={properties.client}
													update={this.handleTextFieldPropertyUpdate}
												/>
											</div>
										</div>
										<div className={"col-6"}>
											<div className={"form-group"}>
												<Framework.Elements.TextField
													name={"order_po_number"}
													label="PO Number"
													value={object.order_po_number}
													error={object.order_po_number_error}
													property={properties.order_po_number}
													update={this.handleTextFieldPropertyUpdate}
												/>
											</div>
										</div>
									</div>
									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"description"}
											label="Transaction Description"
											value={object.description}
											error={object.description_error}
											property={properties.description}
											update={this.handleTextFieldPropertyUpdate}
											multiline={3}
										/>
									</div>
									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"receipt_email"}
											label="Email Receipt To"
											value={object.receipt_email}
											error={object.receipt_email_error}
											property={properties.receipt_email}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"salesman"}
											label="Salesman"
											value={object.salesman}
											error={object.salesman_error}
											property={properties.salesman}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
								<div className="col-4">
									<div className={"form-group"}>
										<Framework.Elements.TextField
											name={"notes"}
											multiline={8}
											label={"Notes"}
											property={properties.notes}
											value={object.notes}
											error={object.notes_error}
											update={this.handleTextFieldPropertyUpdate}
										/>
									</div>
								</div>
								{this.state.payment_method !== "cash" &&
									<>
										<div className="col-4">
											<div className={"form-group"}>
												<Framework.Elements.TextField
													name={"terminal_cardholder_name"}
													label="Card Holder Name"
													value={object.terminal_cardholder_name}
													error={object.terminal_cardholder_name_error}
													property={properties.terminal_cardholder_name}
													update={this.handleTextFieldPropertyUpdate}
													disabled={true}
												/>
											</div>
											<div className={'row'}>
												<div className={"col-6"}>
													<div className={"form-group"}>
														<Framework.Elements.TextField
															name={"terminal_card_type"}
															label="Card Type"
															value={object.terminal_card_type.toUpperCase()}
															error={object.terminal_card_type_error}
															property={properties.terminal_card_type}
															update={this.handleTextFieldPropertyUpdate}
															disabled={true}
														/>
													</div>
												</div>
												<div className={"col-6"}>
													<div className={"form-group"}>
														<Framework.Elements.TextField
															name={"terminal_last_four"}
															label="Last 4"
															value={object.terminal_last_four}
															error={object.terminal_last_four_error}
															property={properties.terminal_last_four}
															update={this.handleTextFieldPropertyUpdate}
															disabled={true}
														/>
													</div>
												</div>
												<div className={"col-6"}>
													<div className={"form-group"}>
														<Framework.Elements.TextField
															name={"processor_user_id"}
															label="Processor ID"
															value={object.processor_user_id}
															error={object.processor_user_id_error}
															property={properties.processor_user_id}
															update={this.handleTextFieldPropertyUpdate}
															disabled={true}
														/>
													</div>
													<div className={"form-group"}>
														<Framework.Elements.TextField
															name={"terminal_id"}
															label="Terminal ID"
															value={object.terminal_id}
															error={object.terminal_id_error}
															property={properties.terminal_id}
															update={this.handleTextFieldPropertyUpdate}
															disabled={true}
														/>
													</div>
												</div>
												<div className={"col-6"}>
													<div className={"form-group"}>
														<Framework.Elements.TextField
															name={"transaction_id"}
															label="Transaction ID"
															value={object.transaction_id}
															error={object.transaction_id_error}
															property={properties.transaction_id}
															update={this.handleTextFieldPropertyUpdate}
															disabled={true}
														/>
													</div>
												</div>
											</div>
										</div>
									</>
								}
							</div>
							<CardActions style={{justifyContent: 'flex-end', padding: '14px'}}>
								<Button color="primary" onClick={this.handleSubmit}>Save</Button>
							</CardActions>
						</Card>
					</div>
					<div className="col-2 invoice-sum text-right">
						<ul className="list-unstyled">
							<li><b>Sub Total: $ {(parseFloat(subtotal).toFixed(2))}</b></li>
							{/*Todo: Question, Do we need to display the sum of discounts on singular items as well as the Discount applied to the whole order?. */}

							{singularDiscountAmount !== 0 &&
								<li>Singular Discounts: <span style={{color: "orangered"}}> ${parseFloat(singularDiscountAmount).toFixed(2)}</span>
								</li>
							}
							{discountTotalAmount !== 0 &&
								<li>Transaction Discount: <span style={{color: "orangered"}}> ${parseFloat(discountTotalAmount).toFixed(2)} {this.state.discount_percentage !== 0 ? '(' + this.state.discount_percentage +'%)' : ''}</span>
								</li>
							}
							{subtotal_tradeins !== 0 &&
								<li>Trade-In Subtotal: <span style={{color: "orangered"}}> ${parseFloat(subtotal_tradeins).toFixed(2)} {this.state.discount_percentage !== 0 ? '(' + this.state.discount_percentage +'%)' : ''}</span>
								</li>
							}
							{discountTotalAmount !== 0 || singularDiscountAmount !== 0 || subtotal_tradeins !== 0 ? <li><strong>Discounted Sub Total : $
								{(parseFloat(total) - (parseFloat(taxAmount))).toFixed(2)}</strong></li> : null
							}
							<li>Tax : $ {taxAmount}</li>
							{object.cc_fee_amount > 0 &&
								<li>CC Fee : $ {object.cc_fee_amount}</li>
							}
							<li><strong>Total : $ {(parseFloat(total)).toFixed(2)}</strong></li>
							{refundAmount > 0 ?
								<li><strong style={{color: "red"}}>Refunded: $ {refundAmount.toFixed(2)}</strong></li> : null}
							{/*{refund_total ?*/}
							{/*	<li><strong style={{color: "#d60000"}}>Refunded: $ {refund_total}</strong></li> : null}*/}
							<hr/>
							<li><strong>Cash : $ {this.state.cash_amount}</strong></li>
							<li><strong>Check : $ {this.state.check_amount}</strong></li>
							<li><strong>Gift Cert. : $ {this.state.other_amount}</strong></li>
							<li><strong>To Invoice: ${this.state.invoice_amount}</strong></li>
							<li><strong>Card : $ {this.state.processor_amount}</strong></li>
							{change > 0 ? <hr/> : null}
							{change > 0 ? <li><strong>Change : $ {change.toFixed(2)}</strong></li> : null}
						</ul>

					</div>
				</div>

				{exists ?
					<div className={"no-print"}>
						<Typography color="textSecondary" gutterBottom>
							Created by {object.users !== "" ? (object.users.name_first + " " + object.users.name_last) : (this.props.rootState.account_name)} {moment(object.created).local().format("MM/DD/YYYY hh:mm A")}
							{object.modified ?
								"| Modified " + moment(object.modified).local().format("MM/DD/YYYY hh:mm A")
								: null}
						</Typography>
					</div> : null}
				<hr className={"no-print"}/>

				<Modal
					isOpen={this.state.updateEmailModal}
					toggle={() => {this.setState({updateEmailModal: false})}}
					size={"md"}
					backdrop={true}
				>
					<ModalBody>
						<div className={'d-flex flex-column'}>
							<b style={{justifySelf: "center", paddingBottom: '1rem'}}>This transaction is missing an email to send the receipt to. Please fill that out below.</b>
							<TextField
								value={this.state.receipt_email}
								onChange={(event) => {this.setState({receipt_email: event.currentTarget.value})}}
								fullWidth={true}
								size={"small"}
								label={"Email"}
								variant={'outlined'}
							/>
							<div className={'justify-content-between full-width d-flex py-1'}>
								<Button
									color={'danger'}
									size={"sm"} block={false}
									onClick={() => {this.setState({updateEmailModal: false})}}
								>
									Cancel
								</Button>
								<Button
									color={'success'}
									size={"sm"} block={false}
									onClick={async () => {
										let transaction = new API.BuildClass(Types.Transactions._name, API.RequestType.UPDATE);
										transaction.object.pkey = this.state.pkey;
										transaction.object.receipt_email = this.state.receipt_email;
										// noinspection JSIgnoredPromiseFromCall
										await transaction.submit(null, 'POST_UPDATE_EMAIL', true)
										await this.sendReceipt();
										this.setState({updateEmailModal: false});
									}}
								>
									Update and Send
								</Button>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<div className={classNames("loader", {'active': object.loading})}>
					<ClipLoader color={"#ffffff"} loading={object.loading}/>
				</div>
			</div>
		)
	}
}

export default Framework.Components.withRootState(TransactionObject);
