import React from 'react';
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";
import {Collections, Objects} from "components"
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "../../constants/Config";
import {confirmAlert} from "react-confirm-alert";
import Print, {PrintReceiptModal} from "../../containers/Print";
import ReceiptObject from "../Objects/Receipt"
import API from "@beardeddevops/react.api";
import Types from "../../constants/Types";
import {size} from "lodash";
import {toast} from "react-toastify";

const breadcrumbsStatic = [
	{
		name: 'Checkout',
		url: '/',
		icon: 'calculator',
	},
	{
		name: 'Transactions',
		url: '/transactions',
		icon: 'shopping-cart',
	}
];

class TransactionsView extends React.Component {

	constructor(props) {
		super(props);

		//Create a reference variable
		this.transactionRef = React.createRef();
		this.transactionEntriesRef = React.createRef();
		this.state = {
			Tkey: Math.random(),
			Rkey: Math.random(),
			modalRefund: false,
			refundItems: []
		}
	}

	handleSendReceipt = async () => {
		let {current} = this.transactionRef;
		current.sendReceipt.call({current}, null);
	};

	handleRefund = async () => {
		await this.setState({Rkey: Math.random(), Tkey: Math.random()})
	}
	handleDelete = () => {
		let model = new API.BuildClass(Types.Transactions._name, API.RequestType.DELETE);
		model.object.pkey = this.props.transaction
		confirmAlert({
			title: 'Confirm Delete',
			message: `This will delete anything tied to this transaction ( Products, Refunds, and Certificates). Are you sure you want to delete this?`,
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						try {
							await model.submit(null, API.RequestType.DELETE);
							this.props.history.push('/transactions');

						} catch (error) {

						}
					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});
	}

	handlePrintReceipt = async () => {
		//load receiptData
		this.state.rModel = [];
		let rModel = new API.BuildClass(Types.Accounts_Customizations._name, API.RequestType.READ_ALL);
		rModel.parent = this.state.pkey;
		let rData = await rModel.submit(null, API.RequestType.READ_ALL)
		this.setState({rModel: rData.rModel});

		//load transactionData
		let data = this.transactionEntriesRef.current.state.data;
		let receiptData = this.transactionRef.current.state;
		let items = this.state.refundItems;

		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<PrintReceiptModal
						onClose={onClose}
						account={this.props.rootState.account}
						data={data}
						rData={rData}
						receiptData={receiptData}
						items={items}
					/>
				);
			},
		});
	};

	toggleRefund = () => {
		if(this.state.modalRefund === false){
			let items = this.state.refundItems.filter((item) => {
				//if item is already refunded, filter it out
				if(item.refunded !== 1){
					return item;
				}
			});

			//if items is zero, don't open it and pop a toast that says there are no items available to refund
			if(size(items) === 0){
				API.ToastId = toast.error("There are no items available to refund", {
					position: toast.POSITION.TOP_CENTER
				});
				return;
			}
		}
		this.setState({modalRefund: !this.state.modalRefund});
	}

	handleRefundItems = async (refundItems) => {
		//get the transaction id, so we can pull all items to pass to refunds
		let pkey = refundItems[0].transaction
		let model = new API.BuildClass(Types.Transactions_Entries._name, API.RequestType.READ_ALL);
		model.parent = pkey;
		model.limit = 50;
		let items = await model.submit(null, 'GET_ALL')
		this.setState({refundItems: items.items});
	}

	allItemsAreFree() {
		const { refundItems } = this.state;
		if (refundItems.length === 0) return false;

		const transactionValue = refundItems[0].transactions.amount;
		return transactionValue === "0.00";
	}

	render() {
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.props.transaction,
				url: `/transactions/${this.props.transaction}`,
				icon: 'edit',
			}
		]);

		const shouldHideButton = this.allItemsAreFree();

		return (
			<>
				<div className="container-fluid">
					<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}>
						<Button size={'sm'} color="danger" onClick={this.handleDelete}>Delete</Button>
						<Button size={'sm'} color="secondary" onClick={this.handleSendReceipt}>Email Receipt</Button>
						<Button size={'sm'} color="primary" onClick={this.handlePrintReceipt} block={false}>Print
							Receipt</Button>
						{!shouldHideButton && <Button size={'sm'} color="primary" onClick={this.toggleRefund} block={false}>Refund</Button>}
					</Framework.Components.PageBar>
					<Collections.TransactionEntries
						refund={this.handleRefund}
						ref={this.transactionEntriesRef}
						parent={this.props.transaction}
						history={this.props.history}
						account={this.props.account}
						refundItems={this.handleRefundItems}
					/>
					<Card>
						<CardContent>
							<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
								<Objects.Transaction
									key={this.state.Tkey}
									ref={this.transactionRef}
									history={this.props.history}
									base={this.props.base}
									account={this.props.account}
									id={this.props.transaction}
									// shouldSave={this.state["saveModelTransaction"]}
									// attemptedSave={this.resetModal("Transaction")}
								/>
							</Framework.Errors.General>
						</CardContent>
					</Card>
					<Collections.TransactionRefunds
						key={this.state.Rkey}
						parent={this.props.transaction}
						history={this.props.history}
						account={this.props.account}
					/>
					<Framework.Elements.UniversalModal
						isDev={APPCONFIG.settings.isDev()}
						title={"Refund"}
						name={"Refunds"}
						submitLabel={"Refund"}
						parent={this.props.transaction}
						object={Objects.TransactionRefund}
						objectProps={{
							items: structuredClone(this.state.refundItems)
						}}
						history={this.props.history}
						toggle={this.state.modalRefund}
						reportClose={this.toggleRefund}
						afterSave={() => window.location.reload()}
						size={'lg'}
						hideGoTo={true}
					/>
				</div>
			</>
		)
	}
}

export default Framework.Components.withRootState(TransactionsView);
