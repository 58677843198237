import React from "react";
import {Button} from 'reactstrap';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import _Base from "./_Base";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarToday from "@material-ui/icons/CalendarToday";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

export default class _DateTime extends _Base {

	hasSetDefault = false;

	componentDidUpdate(prevProps, prevState, snapshot) {
		let defaultValue = typeof this.props.defaultValue !== "undefined";
		if (defaultValue && !this.state.value && !this.hasSetDefault) {
			this.hasSetDefault = true;
			this.handleDateChange(this.props.defaultValue);
		}
	}

	render() {
		let property = this.state;
		if (property.value === '') property.value = null;
		let defaultValue = typeof this.props.defaultValue !== "undefined" ? this.props.defaultValue : null;
		let disabled = typeof this.props.disabled !== 'undefined' ? this.props.disabled : false;
		return (
			<MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
				<div className="picker">
					<FormControl fullWidth={true} error={property.error !== ""} className={property.error !== "" ? 'error' : ''}>
						<KeyboardDateTimePicker
							disabled={disabled}
							autoOk
							hideTabs
							ampm={true}
							clearable={!property.required}
							value={property.value}
							onChange={this.handleDateChange}
							onError={console.log}
							format={"MM/DD/YYYY hh:mm A"}
							mask={'__/__/____ __:__ _'}
							label={this.props.label ? this.props.label : property.label}
							initialFocusedDate={defaultValue} //property.required ? moment().format('YYYY-MM-DD HH:mm:ss') : defaultValue}
							inputProps={{name: property.name}}
							margin={this.props.margin ? this.props.margin : "dense"}
							inputVariant={this.props.variant ? this.props.variant : "outlined"}
							fullWidth={true}
							invalidDateMessage={'Valid date is required'}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Button size={'sm'}>
											<CalendarToday/>
										</Button>
									</InputAdornment>
								)
							}}
						/>
						{this.state.error && <FormHelperText>{this.state.error}</FormHelperText>}
					</FormControl>
				</div>
			</MuiPickersUtilsProvider>
		)
	}
}