import React from "react";
import {Link} from "react-router-dom";
import {Objects, Types} from "components";

import Framework from "@beardeddevops/react.framework";
import API from "@beardeddevops/react.api";
import {Button, ButtonGroup} from "reactstrap";

// noinspection JSUnresolvedVariable
/**
 * @param {Transactions_Entries_Object} entry
 * @param {Products_Object} product
 * @param {function} onDelete
 * @param {function} onUpdate
 * @param {function} openModal
 * @param {function} reportClose
 * @param {bool} modalUpdate
 * @param {object} history
 * @param {array} locations
 * @param {array} states
 * @param {function} updatePawn
 * @param {function} updateMagazine
 */
export default ({
	                entry,
	                product,
	                onDelete,
	                onUpdate,
	                modalUpdate,
	                openModal,
	                reportClose,
	                history,
	                handleTaxableChange
                }) => {
	const displayName = entry?.name ?? ((product.manufacturers ? product.manufacturers.name : product.manufacturer_other) + ' | '  + product.model + ' | ' + (product.calibers ? product.calibers.name : product.caliber_other));
	const taxable = ((entry?.taxable !== undefined && entry?.taxable !== null) ? entry?.taxable : entry.information?.taxable);
	return (
		<div key={Math.random()} className="portlet box blue-hoki">
			<div className="portlet-title">
				<div className="caption">
					<i className="fa fa-barcode"/>&nbsp;&nbsp;
					{entry?.serial_number ?? product.serial_number}
				</div>
				{onDelete && <div className="tools">
					<a href='#'
					   onClick={() => onDelete()}
					   className="remove"
					   style={{color: 'white'}}><i className={"fa fa-remove"}/></a>
				</div>}
			</div>

			<div className="portlet-body" style={{display: 'flex', flexDirection: 'column'}}>
				{/*<h5 style={{marginTop: 0}}>{product.type}</h5>*/}
				{/*need to fix this later*/}
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<h5 style={{marginTop: 0, alignSelf: 'flex-start'}}>{displayName}</h5>
						<h5 style={{marginTop: 0, alignSelf: 'flex-start'}}>${entry.cost ?? entry.price ?? product.price_list ?? 0} {(parseFloat(entry?.discount_amount) > 0 || parseFloat(product?.discount_amount) > 0) && <b style={{color: 'red'}}>(-${entry.discount_amount ?? product.discount_amount})</b>}</h5>
						{(entry.sku ?? product?.skus?.sku ?? '') !== '' &&
							<h5 style={{marginTop: 0, alignSelf: 'flex-start'}}>SKU: {entry.sku ?? product.skus?.sku ?? ''}</h5>
						}
					</div>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'flex-end'}}>
						<ButtonGroup
							style={{marginRight: '10px'}}
						>
							<Button
								id={'true'}
								color="primary"
								style={{backgroundColor: taxable ? '#0062CC' : 'white', color: taxable ? 'white' : 'black'}}
								active={taxable}
								outline={!taxable}
								onClick={() => handleTaxableChange(true)}
							>
								Taxable
							</Button>
							<Button
								id={'false'}
								color="secondary"
								style={{backgroundColor: !taxable ? '#0062CC' : 'white', color: !taxable ? 'white' : 'black'}}
								active={!taxable}
								outline={taxable}
								onClick={() => handleTaxableChange(false)}
							>
								Non-Taxable
							</Button>
						</ButtonGroup>
						<a onClick={openModal} className="btn btn-sm blue-hoki">Edit</a>
					</div>
				</div>
			</div>
		</div>
	)
};
