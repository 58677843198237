import React from 'react';
import PropTypes from "prop-types";

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

export default class StatelessModal extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		name: PropTypes.string,
		submitLabel: PropTypes.string,
		isOpen: PropTypes.bool.isRequired,
		size: PropTypes.string,
		onToggle: PropTypes.func.isRequired,
		onSubmit: PropTypes.func,
	};

	static defaultProps = {
		title: '',
		submitLabel: '',
		size: 'lg',
		onSubmit: null,
	}

	render() {
		let {title, name, submitLabel, isOpen, onToggle, size, onSubmit, children} = this.props;

		return (
			<Modal
				isOpen={isOpen}
				toggle={onToggle}
				size={size}
				backdrop={true}>
				<ModalHeader toggle={onToggle}>
					{title}
				</ModalHeader>
				<ModalBody>
					{children}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={onToggle}>Cancel</Button>
					{submitLabel ?
						<Button
							data-id={"update-add-" + name.toLowerCase().replace(/ /gi, '-')}
							color="primary" onClick={onSubmit}>
							{submitLabel}
						</Button> : null}{' '}
				</ModalFooter>
			</Modal>
		);
	}
}
