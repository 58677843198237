import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Objects} from "components";

import {Button} from "reactstrap";

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Registers',
		url: '/registers',
		icon: 'usd',
	}
];

export default class Registers extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-certificate" onClick={this.toggle('modalRegister')} size={"sm"}
					        color="primary">
						<i className="fa fa-usd"/>&nbsp;&nbsp;Add Register
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Registers
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Register"}
					object={Objects.Register}
					history={this.props.history}
					toggle={this.state['modalRegister']}
					reportClose={this.reportClose('modalRegister')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>


			</div>
		);
	}
}

