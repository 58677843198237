import React from 'react';
import JsBarcode from 'jsbarcode';
import {v4 as UUID} from 'uuid';

export default class Label extends React.Component {

	constructor(props) {
		super(props);
		// noinspection JSUnresolvedFunction
		this.state = {
			id: this.convert(UUID().replace(/-/g, ""))
		};
	};

	componentDidMount() {

		JsBarcode("#i"+this.state.id, this.props.prefix+"-"+this.props.code, {
			format: "CODE128A",
			lineColor: "#303030",
			width: 1.5,
			height: 40,
			//displayValue: false,
			font: 'Arial',
			fontSize: 10
		});
	}

	convert(num) {
		return num
			.toString()    // convert number to string
			.split('')     // convert string to array of characters
			.map(i => Number(i) || i)   // parse characters as numbers
			.map(n => isNaN(n) ? n : (n + 65))
			.map(c => (isNaN(c) ? c : String.fromCharCode(c)).toUpperCase())   // convert char codes to strings
			.join('');     // join values together
	}

	render() {
		let label = this.props.label;
		return (
			<div className="no-screen" style={{backgroundColor: 'white'}}>
				<div style={{border: '0 inset black', width: '336px', height: '104px', textAlign: 'center'}}>
					<div style={{paddingTop: '10px'}}>{ label }</div>
					<svg id={"i" + this.state.id}/>
				</div>
			</div>
		)
	}
};
