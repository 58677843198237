import React from 'react';
import Framework from "@beardeddevops/react.framework";

import APPCONFIG from "constants/Config";
import {Objects} from "components";
import InventoryLedgersCollection from "../../../../../components/Collections/InventoryLedgers";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Inventory',
		url: '/skus',
		icon: 'list-alt',
	}
];

export default class Sku extends Framework.Components.View {

	handleInventoryPkey = (inventory) => {
		this.setState({invoPkey: inventory})
	}

	render() {
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/skus/${this.props.id}`,
				icon: 'edit',
			}
		]);
		const { parent, ...rest } = this.props;
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}/>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.Sku
						id={this.props.match.params.id}
						locations={this.props.locations}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={'/skus'}
						updateInventory={this.handleInventoryPkey}
					/>
				</Framework.Errors.General>

				{this.state.invoPkey !== undefined && this.state.invoPkey !== null &&
					<div>
						<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
							<Objects.Inventory
								parent={this.state.invoPkey}
								history={this.props.history}
								baseUrl={this.props.baseUrl}
								id={this.state.invoPkey}
								// noDelete = {false}
								shouldSave={this.state["saveModelInventory"]}
								attemptedSave={() => this.resetModal("Inventory")}
							/>
						</Framework.Errors.General>

						<InventoryLedgersCollection parent={this.state.invoPkey} {...rest} />
					</div>
				}
			</div>
			// <div className="container-fluid">
			// 	 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
			// 		<Card>
			// 			<CardContent style={{paddingBottom: 0}}>
			// 				<SkuObject
			// 					history={this.props.history}
			// 					base={this.props.base}
			// 					id={this.props.match.params.id}
			// 					shouldSave={this.state["saveModelSku"]}
			// 					shouldDelete={this.state["deleteModelSku"]}
			// 					attemptedSave={this.resetModal("Sku")}
			// 				/>
			// 				<hr style={{marginBottom: 0}}/>
			// 			</CardContent>
			// 			<CardActions style={{justifyContent: 'flex-end', padding: '16px'}}>
			// 				<Button color="danger" onClick={() => this.deleteModel("Sku")()}>Delete</Button>
			// 				<Button color="primary" onClick={() => this.saveModel("Sku")()}>Save Inventory</Button>
			// 			</CardActions>
			// 		</Card>
			// 	</Framework.Errors.General>
			// </div>
		)
	}
};
