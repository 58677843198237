import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router'

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from "react-select";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, Card} from "reactstrap";
import {CardElement, injectStripe, Elements, StripeProvider} from 'react-stripe-elements';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import APPCONFIG from 'constants/Config';
import API from '@beardeddevops/react.api';
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Objects, Parts} from "../../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardActions from "@material-ui/core/CardActions";
import {confirmAlert} from "react-confirm-alert";
import {CardContent, makeStyles} from "@material-ui/core";

export default class Register extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Accounts_Create._name);
		this.model.requestType = API.RequestType.CREATE;

		this.state.step = 0;
		this.state.states = [];
		this.state.processors = [];
		this.state.quantity = 0;
		this.state.loading = false;
		this.brand = APPCONFIG.brand;
		this.redirect = "/locations"
	}

	async componentDidMount() {
		super.componentDidMount();
		this.state.tos = false;
		let processors = new API.BuildClass(Types.Processors._name, API.RequestType.READ_ALL);
		processors = await processors.submit(null, 'GET_ALL');
		processors = processors.items;
		this.setState({processors})
	}

	handleNext = () => {
		let shouldContinue = API.Validator.process(this, this.requestType, true, null, this.state.step + 1);
		if (shouldContinue) {
			this.setState({"step": this.state.step + 1});
		}
	};

	handlePrevious = () => {
		this.setState({"step": this.state.step - 1});
	};

	handleSubmit = async event => {

		if (typeof event !== 'undefined') event.preventDefault();
		this.setState({loading: true});
		if (await this.handleProcess(null, null)) {
			try {
				await this.model.submit(this, API.RequestType.CREATE);
			} catch (e) {
				this.setState({loading: false});
				return false;
			}
			this.props.history.push('/settings#billing');
		} else {
			this.setState({loading: false});
		}
		return false;
	};


	render() {
		let price = this.state.quantity > 0 ? (((this.state.quantity - 1) * 50) + 75) : 50;

		let brand = this.brand;
		let step = this.state.step;
		let steps = ['Account Setup', 'Store Information', 'Plan Details'];

		/** @type {Accounts_Create_Properties} properties */
		let properties = this.model.properties;
		/** @type {Accounts_Create_Object} object */
		let object = this.state;

		const classes = makeStyles((theme) => ({
			root: {
				width: '100%',
			},
			button: {
				marginRight: theme.spacing(1),
			},
			instructions: {
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
			},
		}));


		return (
			<div className="page-signup">
				<div className="main-body" style={{maxWidth: '800px'}}>
					<div className="body-inner">
						<Card>
							<CardContent>
								<section className="logo text-center">
									<h1><a href="/">{brand}</a></h1>
								</section>


								<Stepper activeStep={step}>
									{steps.map((label, index) => {
										const stepProps = {};
										const labelProps = {};
										return (
											<Step key={label} {...stepProps}>
												<StepLabel {...labelProps}>{label}</StepLabel>
											</Step>
										);
									})}
								</Stepper>

								{step === 0 ?
									<fieldset>
										<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Account Setup</h4>
										<div className="form-group">
											<Framework.Elements.TextField name={"name_first"}
											                              label={"First Name"}
											                              property={properties.name_first}
											                              value={object.name_first}
											                              error={object.name_first_error}
											                              update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="form-group">
											<Framework.Elements.TextField name={"name_last"}
											                              label={"Last Name"}
											                              property={properties.name_last}
											                              value={object.name_last}
											                              error={object.name_last_error}
											                              update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="form-group">
											<Framework.Elements.TextField name={"email"}
											                              label={"Email"}
											                              property={properties.email}
											                              value={object.email}
											                              error={object.email_error}
											                              update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="form-group">
											<Framework.Elements.TextField name={"password"}
											                              label={"Password"}
											                              property={properties.password}
											                              value={object.password}
											                              error={object.password_error}
											                              update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="form-group">
											<Framework.Elements.TextField name={"password_re"}
											                              label={"Retype Password"}
											                              property={properties.password_re}
											                              value={object.password_re}
											                              error={object.password_re_error}
											                              update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
									</fieldset>
									: null}

								{step === 1 ?
									<fieldset>
										<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Store
											Information</h4>
										<div className={"row"}>
											<div className={"col-md"}>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"name"}
														label={"Name"}
														property={properties.name}
														value={object.name}
														error={object.name_error}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"store_number"}
														label={"Store Number"}
														value={object.store_number}
														error={object.store_number_error}
														property={properties.store_number}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"description"}
														label={"Description"}
														property={properties.description}
														value={object.description}
														error={object.description_error}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
												<div className="form-group">
													<Framework.Elements.PhoneNumber
														label={"Phone"}
														property={properties.phone}
														value={object.phone}
														error={object.phone_error}
														update={this.handleTextFieldPropertyUpdate}
													/>
												</div>
											</div>
											<div className={"col-md"}>
												<Parts.Address
													object={object}
													properties={properties}
													onTextUpdate={this.handleTextFieldPropertyUpdate}
													onSelectUpdate={this.handleSelectPropertyUpdate}
													hasCounty={false}
												/>
											</div>
										</div>
										<hr/>
										<div className={"row"}>
											<div className={"col-md"}>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"tax_rate_state"}
														label={"State Tax Rate"}
														property={properties.tax_rate_state}
														value={object.tax_rate_state}
														error={object.tax_rate_state_error}
														type={'string'}
														update={this.handleTextFieldPropertyUpdate}
														InputProps={{
															endAdornment: (
																<InputAdornment position={"end"}> <i
																	className="fa fa-percent"/></InputAdornment>
															),
															shrink: false
														}}
													/>
												</div>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"tax_rate_special"}
														label={"Other/Special Tax Rate"}
														property={properties.tax_rate_special}
														value={object.tax_rate_special}
														error={object.tax_rate_state_error}
														update={this.handleTextFieldPropertyUpdate}
														type={"string"}
														InputProps={{
															endAdornment: (
																<InputAdornment position={"end"}> <i
																	className="fa fa-percent"/></InputAdornment>
															),
															shrink: false
														}}
													/>
												</div>
											</div>
											<div className={"col-md"}>
												<div className="form-group">
													<Framework.Elements.TextField
														name={"tax_rate_county"}
														label={"County Tax Rate"}
														property={properties.tax_rate_county}
														value={object.tax_rate_county}
														error={object.tax_rate_county_error}
														update={this.handleTextFieldPropertyUpdate}
														type={"string"}
														InputProps={{
															endAdornment: (
																<InputAdornment position={"end"}> <i
																	className="fa fa-percent"/></InputAdornment>
															),
															shrink: false
														}}
													/>
												</div>
												<div className="form-group">
													<Framework.Elements.TextField

														label={"City Tax Rate"}
														property={properties.tax_rate_city}
														value={object.tax_rate_city}
														error={object.tax_rate_city_error}
														update={this.handleTextFieldPropertyUpdate}
														type={"string"}
														InputProps={{
															endAdornment: (
																<InputAdornment position={"end"}> <i
																	className="fa fa-percent"/></InputAdornment>
															),
															shrink: false
														}}

													/>
												</div>
											</div>
										</div>
									</fieldset>

									: null}

								{step === 2 ?

									<fieldset>
										<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Plan Details</h4>
										{/*Commenting this all out for now, it's easier if we have them set this up after account creation.*/}
										{/*<div className={"row"}>*/}
										{/*	<div className={"col-md-4"}>*/}
										{/*		<div className="form-group">*/}
										{/*			<Select*/}
										{/*				key={this.state.processors}*/}
										{/*				value={this.state.processors.map((item) => {*/}
										{/*					if (item.pkey === this.state.processor) {*/}
										{/*						return ({value: item.pkey, label: item.name});*/}
										{/*					} else {*/}
										{/*						return null;*/}
										{/*					}*/}
										{/*				})}*/}
										{/*				onChange={(item) => {*/}
										{/*					this.setState({processor: item.value})*/}
										{/*				}}*/}
										{/*				options={this.state.processors.map((item) => {*/}
										{/*					return ({value: item.pkey, label: item.name});*/}
										{/*				})}*/}
										{/*				isClearable*/}
										{/*				placeholder={'Processor'}*/}
										{/*				fullWidth={true}*/}
										{/*			/>*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*	<div className={"col-md-6"}>*/}
										{/*		<div className="form-group">*/}
										{/*			<Framework.Elements.TextField*/}
										{/*				name={"reference_id"}*/}
										{/*				label={"API Key"}*/}
										{/*				property={properties.reference_id}*/}
										{/*				value={object.reference_id}*/}
										{/*				error={object.reference_id_error}*/}
										{/*				update={this.handleTextFieldPropertyUpdate}*/}
										{/*			/>*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*	<div className={"col-md-2"}>*/}
										{/*		<div className="form-group">*/}
										{/*			<Framework.Elements.TextField*/}
										{/*				name={"quantity"}*/}
										{/*				label={"Terminals"}*/}
										{/*				property={properties.quantity}*/}
										{/*				value={object.quantity}*/}
										{/*				error={object.quantity_error}*/}
										{/*				update={this.handleTextFieldPropertyUpdate}*/}
										{/*			/>*/}
										{/*		</div>*/}
										{/*	</div>*/}
										{/*</div>*/}
										<hr/>
										<p style={{textAlign: "center", fontSize: "18px"}}>
											<b>$50/mo</b> for <i style={{fontSize: "20px", fontStyle: 'italic'}}>Cash Only</i>
											<br/>
											<b>$75/mo</b> for 1 Terminal connection<br/>
											<b>$50/mo</b> for each additional Terminal connection
										</p>
										<b style={{justifyContent: 'center', display: 'flex'}}>You will be prompted to set up a Stripe billing subscription upon account creation.</b>
										<b style={{textAlign: 'center', display: 'flex'}}>If you plan to use terminals, you will be able to add your processor API key after account creation.</b>

									</fieldset>
									: null}

							</CardContent>
							<CardActions>
								<div style={{width: '100%'}}>
									<div>
										{step === 0 ?
											<Button onClick={() => this.props.history.push('/login')}
											        className={classes.button}>
												Back
											</Button>
											:
											<Button onClick={this.handlePrevious} className={classes.button}>
												Back
											</Button>}
										<Button
											variant="contained"
											color="primary"
											onClick={step === steps.length - 1 ? () => {
												this.handleSubmit().then(() => {

												}).catch(() => {

												})
											} : this.handleNext}
											className={classes.button}
											style={{'float': 'right'}}
										>
											{step === steps.length - 1 ? 'Finish' : 'Next'}
										</Button>
									</div>
								</div>
							</CardActions>
							<Framework.Elements.LoadingSpinner isLoading={object.loading}/>
						</Card>
					</div>
				</div>
			</div>
		);
	}
}
