import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Objects} from "components";
import {Button} from 'reactstrap';

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Inventory',
		url: '/skus',
		icon: 'list-alt',
	}
];

export default class Skus extends Framework.Components.View {

	constructor(props) {
		super(props);
		this.state.key = Math.random();
	}

	componentDidUpdate(prevProps, nextProps, snapshot) {
		if (prevProps.shouldReloadCart !== this.props.shouldReloadCart) {
			this.toggleModal("Restock")();
		}
	}

	render() {


		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-sku" onClick={this.toggle('modalRestock')} size={"sm"} color="primary">
						<i className="fa fa-list-alt"/>&nbsp;&nbsp;Restock Inventory
					</Button>
					<Button data-id="add-sku" onClick={this.toggle('modalAdd')} size={"sm"} color="primary">
						<i className="fa fa-list-alt"/>&nbsp;&nbsp;Add Inventory
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Skus
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>


				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					location={this.props.locations}
					title={"Add Inventory"}
					name={"Skus"}
					object={Objects.Sku}
					history={this.props.history}
					toggle={this.state.modalAdd}
					reportClose={this.reportClose('modalAdd')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					title={"Restock Inventory"}
					name={"Inventory"}
					object={Objects.Inventory}
					history={this.props.history}
					toggle={this.state.modalRestock}
					reportClose={this.reportClose('modalRestock')}
					afterSave={this.handleAfterSave}
					size={'lg'}
					submitLabel={"Restock"}
					account={this.props.account}
				/>


				{/*<Modal isOpen={this.state["showModalRestock"]} toggle={() => this.toggleModal("Restock")()} size={"lg"}*/}
				{/*       backdrop={false}>*/}
				{/*	<ModalHeader toggle={() => this.toggleModal("Restock")()}>Restock Inventory</ModalHeader>*/}
				{/*	<ModalBody>*/}
				{/*		 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>*/}
				{/*			<InventoryObject*/}
				{/*				history={this.props.history}*/}
				{/*				shouldSave={this.state["saveModelRestock"]}*/}
				{/*				attemptedSave={this.resetModal("Restock")}*/}
				{/*				scannedCode={!this.state["showModalRestock"]?"":this.props.scannedCode}*/}
				{/*			/>*/}
				{/*		</Framework.Errors.General>*/}
				{/*	</ModalBody>*/}
				{/*	<ModalFooter>*/}
				{/*		<Button color="primary" onClick={() => this.saveModel("Restock")()}>Restock</Button>{' '}*/}
				{/*		<Button color="secondary" onClick={() => this.toggleModal("Restock")()}>Cancel</Button>*/}
				{/*	</ModalFooter>*/}
				{/*</Modal>*/}

			</div>
		);
	}
}
