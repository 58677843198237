import Certificates from "components/Collections/Certificates";
import CertificateTransactions from "components/Collections/CertificateTransactions";
import Consignments from "components/Collections/Consignments";
import GrossRevenueReport from "components/Collections/GrossRevenueReport";
import Inventories from "components/Collections/Inventories";
import InventoryLedgers from "components/Collections/InventoryLedgers";
import Layaways from "components/Collections/Layaways";
import LayawayEntries from "components/Collections/LayawayEntries";
import LayawayItems from "components/Collections/LayawayItems";
import LayawayPayments from "components/Collections/LayawayPayments";
import LayawayProducts from "components/Collections/LayawayProducts";
import Locations from "components/Collections/Locations";
import NetMarginsReport from "components/Collections/NetMarginsReport"
import NetProfitsReport from "components/Collections/NetProfitsReport";
import Products from "components/Collections/Products";
import SalesReport from "components/Collections/SalesReport";
import RegisterLedgers from "components/Collections/RegisterLedgers";
import Registers from "components/Collections/Registers";
import Reports from "components/Collections/Reports";
import SalesTaxReports from "components/Collections/SalesTaxReports"
import Sessions from "components/Collections/Sessions";
import SkuClasses from "components/Collections/SkuClasses";
import Skus from "components/Collections/Skus";
import TradeIns from "components/Collections/TradeIns";
import TransactionEntries from "components/Collections/TransactionEntries"
import TransactionRefunds from "components/Collections/TransactionRefunds";
import Transactions from "components/Collections/Transactions";
import Users from "components/Collections/Users";
import YearEndReport from "components/Collections/YearEndReports";

const Collections = {
	Certificates: Certificates,
	CertificateTransactions: CertificateTransactions,
	Consignments: Consignments,
	GrossRevenueReports: GrossRevenueReport,
	Inventories: Inventories,
	InventoryLedgers: InventoryLedgers,
	Layaways:Layaways,
	LayawayEntries:LayawayEntries,
	LayawayItems:LayawayItems,
	LayawayPayments: LayawayPayments,
	LayawayProducts: LayawayProducts,
	Locations: Locations,
	NetMarginsReport: NetMarginsReport,
	NetProfitsReport: NetProfitsReport,
	Products: Products,
	SalesReport: SalesReport,
	Reports: Reports,
	RegisterLedgers: RegisterLedgers,
	Registers: Registers,
	SalesTaxReports: SalesTaxReports,
	Sessions: Sessions,
	SkuClasses: SkuClasses,
	Skus: Skus,
	TradeIns: TradeIns,
	TransactionEntries: TransactionEntries,
	TransactionRefunds:TransactionRefunds,
	Transactions: Transactions,
	Users: Users,
	YearEndReport: YearEndReport

};

export default Collections;
