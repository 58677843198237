import React from "react";
import APPCONFIG from "../../constants/Config";
import moment from "moment";
// Wrapper around ReactToPrint that works around its pattern of passing anonymous functions in the render method

export default (data) => {

	let sub_total = 0;
	let tax_total = 0;
	let refund_total = 0;
	// let newTotal = 0;
	let singularItemDiscounts = 0;
	let otherFieldsObject = data?.receiptData;
	let tradeSubtotal = 0;

	let items = data.items.map((item, i) => {
		let trade = false;

		//if trade-in
		if(item.transaction_type === 'transaction_trade'){
			tradeSubtotal += item.price * item.quantity;
			trade = true;
		}

		//Fix for Firearms quantity
		let quantity = item.refund_quantity != null && trade === false ? item.refund_quantity : (item.quantity !== null ? item.quantity : 1);

		let price = parseFloat(item.price);
		let item_sub_total = price * quantity;
		if(!trade) {
			sub_total += price * quantity;
		}
		tax_total = parseFloat(item.transactions.tax_amount);
		if(item.refunded) {
			refund_total += parseFloat(item.refund_amount);
			console.log("refund total here", refund_total, parseFloat(item_sub_total), parseFloat(tax_total))
		}
		// newTotal =  (sub_total - refund_total + tax_total);

		//Getting singular item discounts from entries
		singularItemDiscounts += (parseFloat(item.discount_amount));

		return (
			<div>
				<br></br>
				<div key={'i' + i} style={{ borderBottom: "1px dashed #d6d6d6"}} id={"printItems"}>
					<div className={"row"}>
					     <span className={"col-6"}>
						     <span><strong>{item.name}{trade ? " - Trade" : ''}</strong></span>
					     </span>
					<span className={"col-6 text-right"}>
							<span id={"printPrice"}>$ {price.toFixed(2)}</span>
						</span>
				</div>

					<div className={"row"}>
						<span className={"col-6"}>
							{
								item.serial_number ?
									<span><strong>{item.serial_number}</strong></span> :
									<span>-</span>
							}
						</span>
						<span className={"col-6 text-right"}>
							<span id={"printQuantity"}>x{quantity}</span>
						</span>
					</div>

					{item?.discount_amount != "0.00" ?
						<><div className={"row"}>
							<span className={"col-6"}>
								<span><strong > Sub Total: </strong></span>
							</span>
							<span className={"col-6 text-right"}>
								<span >$ {item.price *  quantity}</span>
							</span>
						</div>
						<div className={"row"}>
						<span className={"col-6"}>
							<span><strong > Discount: </strong></span>
						</span>
						<span className={"col-6 text-right"}>
							<span style={{color: "red"}} >$ {item.discount_amount}</span>
						</span>
					</div></> : null
					}

					<div className={"row"}>
						<span className={"col-6"}>
							{item.sku}
						</span>
						<span className={"col-6 text-right"}>
							<span id={"printRefunded"}>
								<strong>{trade ? "- " : ''}{!item.refunded ? "$ " + (parseFloat((price * quantity) - (item.discount_amount)).toFixed(2)): "Refunded"}</strong>
							</span>
						</span>
					</div>
					<br></br>
				</div>
			</div>
		)
	})

	let location = data.data[0].transactions.locations;
	let transaction = data.data[0].transactions;
	let customReceipt = data.rData.items[0];
	let receiptDate = moment(data.data[0].transactions.created).format('MM-DD-YYYY');

	//Grab discount field from response object
	let discountAmount = data.data[0].transactions.discount_amount;
	let discountPercent = data.data[0].transactions.discount_percentage;

	//amount
	let amount = data.data[0].transactions.amount;

	//Set up discount fields for receipt
	let discountField = null;
	let discountedTotal = null;

	if(discountAmount > 0) {
		discountField = "$ " + discountAmount;
		discountedTotal = (amount - tax_total);
		discountedTotal.toFixed(2);
	}
	if(discountPercent > 0 ) {
		discountField = "$ " + (parseFloat((discountPercent / 100)) * (parseFloat(sub_total) - parseFloat(tradeSubtotal) - parseFloat(singularItemDiscounts))).toFixed(2) + " ("+discountPercent+"%)";
		discountedTotal = (amount) - tax_total;
		discountedTotal.toFixed(2);
	}

	return (
		<>
				<div>
				{location ?
					<div  style={{marginTop: "1rem"}}>
						<span style={{textAlign: "center", fontSize: "1.2rem"}}>
							{customReceipt.logo &&
								<div>
									<img
										id="logo"
										src={APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/accounts/customizations/image'}
										alt={""}
										style={{marginBottom: "1rem", height:"115px"}}
									/>
								</div>
							}
							<div>
								{location.name? <div><b>{location.name}</b></div> : null}
								{location.store_number ? <div>{location.store_number}</div> : null}
								{location.phone ? <div>{location.phone}</div> : null}
								{location.email ? <div>{location.email}</div> : null}
								{location.street_1 ? <div>{location.street_1}</div> : null}
								{location.street_1 ?
									<div>{location.city} {location.state} {location.zip}</div> : null}
								{receiptDate}
								<br/><br/>
							</div>
						</span>

					</div>
					: null}
				<div style={{fontSize: '20px', borderBottom: "1px solid #d6d6d6", marginTop:"1rem", paddingBottom: "10px"}}>
					<b>Transaction {data.data[0].transaction}</b>
				</div>
				<div>
					{items}
				</div>
			<div className={"row"}  style={{color: "rgba(0, 0, 0, 0.5)", paddingTop: "14px"}}>
				<div className={"col"} id={"poInfo"}>
					{/*Conditionally show transaction information*/}
					{otherFieldsObject.order_po_number ?
					<div>
						<span>PO Number</span><span style={{float: "right"}}>{otherFieldsObject.order_po_number}</span><br/>
					</div> : null
					}
					{transaction.transaction_id ?
						<div>
							<span>ID</span><span style={{float: "right"}}>{transaction.transaction_id}</span><br/>
						</div> : null
					}
					{transaction.terminal_last_four ?
						<div>
							<span>Last 4 of CC</span><span style={{float: "right"}}>{transaction.terminal_last_four}</span><br/>
						</div> : null
					}
				</div>
			</div>
					{!transaction.order_po_number && !transaction.transaction_id && !transaction.terminal_last_four ? null : <hr></hr>}
			<div className="invoice-sum text-right" >
				<ul className="list-unstyled">
					<li>Sub Total: $ {sub_total.toFixed(2)}</li>
					<li>{singularItemDiscounts ? "Singular Discounts: $" + parseFloat(singularItemDiscounts).toFixed(2): null}</li>
					<li>{tradeSubtotal ? "Trade-In Subtotal: -$" + parseFloat(tradeSubtotal).toFixed(2): null}</li>
					<li>{discountField ? "Transaction Discount: " + discountField : null}</li>
					<li>{discountField ? "Discounted Sub Total: $ " + discountedTotal.toFixed(2) : null}</li>
					<li>Tax : $ {parseFloat(tax_total).toFixed(2)}</li>
					{transaction.cc_fee_amount > 0 &&
						<li>CC Fee : $ {parseFloat(transaction.cc_fee_amount).toFixed(2)}</li>
					}
					<li>{"Total: $ " + amount}</li>


					{ refund_total > 0 ?
						<li id={"refunded"}><strong style={{color: "red"}}>Refunded: $ {(parseFloat(refund_total)).toFixed(2)}</strong></li>: null}
					{refund_total > 0 ? <li><strong >New Total: $ {(parseFloat(amount) - parseFloat(refund_total)).toFixed(2)}</strong></li> : null}
					<hr id={"hrPrint"}/>
					<li><strong>Cash : $ {transaction.cash_amount}</strong></li>
					<li><strong>Check : $ {transaction.check_amount}</strong></li>
					<li><strong>Gift Cert. : $ {transaction.other_amount}</strong></li>
					<li><strong>To Invoice: $ {transaction.invoice_amount}</strong></li>
					<li><strong>Card : $ {transaction.processor_amount}</strong></li>

					{/*{change > 0 ? <hr/> : null}*/}
					{/*{change > 0 ? <li><strong>Change : $ {change}</strong></li> : null}*/}
				</ul>
			</div>


					{/*Don't show line if there is no transaction info*/}
					{!customReceipt.message && !customReceipt.warranty && !customReceipt.return_policy ? null : <hr></hr>}
			<div id="customMessageContainer">
				<div style={{textAlign: "center"}}>
					<h4 id="customMessage">
						{customReceipt.message ?? ""}
					</h4>
					{customReceipt.warranty &&
						<div style={{textAlign: "center"}}>
							<span><b>Warranty Information</b></span>
							<div>
								{customReceipt.warranty}
							</div>
							<br/>
							<br/>
						</div>
					}
					{customReceipt.return_policy &&
						<div style={{textAlign: "center"}}>
							<span><b>Returns Information</b></span>
							<div>
								{customReceipt.return_policy}
							</div>
							<br/>
							<br/>
						</div>
					}
				</div>
			</div>

					{/*Section for Other Transaction information regarding the client*/}
					{(otherFieldsObject.client != null || otherFieldsObject.receipt_email != null || otherFieldsObject.description != null) &&
					<div>
						<hr />
					<div style={{textAlign: "center"}}>
						<div style={{fontSize: "1.2rem"}}><b>Transaction Information</b></div>
						<br/>
					</div>
							{otherFieldsObject.client ?
								<div style={{textAlign: "center"}}>
									<span><b>Customer Name</b></span>
									<div>
										{otherFieldsObject.client}
									</div>
									<br/>
								</div>
								: null}

					{otherFieldsObject.receipt_email ?
						<div style={{textAlign: "center"}}>
							<span><b>Customer Email</b></span>
							<div>
								{otherFieldsObject.receipt_email}
							</div>
							<br/>
						</div>
						: null}
					{otherFieldsObject.description ?
						<div style={{textAlign: "center"}}>
							<span><b>Description</b></span>
							<div>
								{otherFieldsObject.description}
							</div>
							<br/>

						</div>

						: null}

					{otherFieldsObject.notes ?
						<div style={{textAlign: "center"}}>
							<span><b>Notes</b></span>
							<div>
								{otherFieldsObject.notes}
							</div>
							<br/>
						</div>
						: null }
					</div>
						}
				</div>
		</>
	)
}
