import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
	prefix: '',
	suffix: '',
	includeThousandsSeparator: false,
	allowDecimal: true,
	integerLimit: 12,
	allowNegative: true
});

const Currency = (props) => {
	const { inputRef, disabled, ...other } = props;
	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={numberMask}
			placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
			disabled={disabled}
			style={{textAlign: 'right'}}
		/>
	);
};

Currency.propTypes = {
	inputRef: PropTypes.func.isRequired,
};

export default Currency;