import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";
import {Button} from "reactstrap";
import {Card, CardContent, CardActions} from '@material-ui/core'
const {UniversalModal} = Framework.Elements;

import API from "@beardeddevops/react.api";
import ReactToPrint from 'react-to-print';
import APPCONFIG from "../../constants/Config";
import {Objects} from "../index";

export default class SkuObject extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Skus._name);
		// this.state.sku_auto = "";
		//
		// this.sku_request_complete = false;
		this.state.taxable = true;
	}

	loadSelfAfter(data) {
		if (typeof this.props.updateInventory === 'function') {
			if (typeof data.inventoriess[0].pkey === 'number') {
				this.props.updateInventory(data.inventoriess[0].pkey);
			}
		}
	}


		// handleProcess = event => {
	// 	if (event == null || event.target.name === "sku") {
	// 		if (this.state.sku === "" && this.state.sku_auto !== "") {
	// 			this.state.sku = this.state.sku_auto;
	// 			this.state.sku_auto = "";
	// 		}
	// 	}
	// 	return API.Validator.process(this, this.requestType, true, event ? event.target.name : null);
	// };

	handleAfterSave = (event) => {
		this.setState({class: event.pkey});
	}

	form = () => {
		// let exists = this.state.pkey && this.state.pkey;
		/**
		 *  @param {Skus_Properties} data
		 */
		let {properties} = this.model;
		/**
		 *  @type {Skus_Object} object
		 */
		let object = this.state;
		let {loading} = this.state

		//TODO: Select needs a "Add New" option that can be thrown by attribute
		// if (object.class === '' && this.sku_request_complete) {
		// 	if (object.sku_classes.length > 0) {
		// 		object.class = object.sku_classes[0].pkey;
		// 		object.class.alt = object.sku_classes[0].name;
		// 	} else {
		// 		object.class = 0;
		// 		object.class.alt = "... Add New";
		// 		object.class_other = "Default Group";
		// 	}
		// }
		// if (object.sku === "" && object.name !== "") {
		// 	let prefix = object.name.substr(0, 3);
		// 	if (prefix.length > 2) {
		// 		object.sku_auto = prefix + "1000";
		// 	} else {
		// 		object.sku_auto = "";
		// 	}
		// } else {
		// 	object.sku_auto = "";
		// }
		// let locations = object.locations.map((location, i) => {
		// 	return <MenuItem key={"location_" + (i + 1)} value={location.pkey}>{location.name}</MenuItem>
		// });

		// if (object.location && object.location === '' && object.locations.length > 0) {
		// 	object.location = object.locations[0].pkey;
		// }
		//
		// //This is a hack to disable Serialized inventory for now per Matt's request
		// object.serialized = object.pkey && object.pkey ? object.serialized : false;

		return (
			<div>
				<h4 className="section-title">Inventory</h4>

				<div className={"row"}>
					<div className={"col-md"}>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"name"}
								label={"Name"}
								value={object.name}
								error={object.name_error}
								property={properties.name}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"sku"}
								label={"SKU"}
								value={object.sku}
								error={object.sku_error}
								property={properties.sku}
								update={this.handleTextFieldPropertyUpdate}
							/>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col d-flex">
									<Button data-id="add-sku" onClick={this.toggle('modalAdd')} size={"sm"} outline
									        color="success" className={"select-add"}>
										<i className={"fa fa-plus"}/>
									</Button>
									<div style={{width: "100%"}}>
										<Framework.Elements.Select
											shouldLoad={true}
											update={this.handleSelectPropertyUpdate}
											property={properties.class}
											value={object.class}
											error={object.class_error}
											model={Types.Skus_Classes._name}
											nullable={false}
											limit={null}
										/>

										<UniversalModal
											isDev={APPCONFIG.settings.isDev()}
											locations={this.props.locations}
											name={"Inventory Group"}
											object={Objects.SkuClass}
											history={this.props.history}
											toggle={this.state['modalAdd']}
											reportClose={this.reportClose('modalAdd')}
											afterSave={this.handleAfterSave}
											size={'lg'}
										/>

									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<Framework.Elements.Select
								shouldLoad={true}
								update={this.handleSelectPropertyUpdate}
								property={properties.location}
								value={object.location}
								error={object.location_error}
								model={Types.Locations._name}
								nullable={false}
								limit={null}
							/>
						</div>
						<div className={'form-group'}>
							<Framework.Elements.ButtonSwitch
								value={!!object.taxable}
								error={object.taxable_error}
								property={properties.taxable}
								update={this.handleTextFieldPropertyUpdate}
								labelTrue={"Taxable"}
								labelFalse={"Non-Taxable"}
							/>
						</div>
						{object.class.value !== 0 ? null :
							<div className="form-group">
								<Framework.Elements.TextField
									name={"class_other"}
									label={"New Group"}
									value={object.class_other}
									error={object.class_other_error}
									property={properties.class_other}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						}
					</div>
					<div className={"col-md"}>
						<div className={"row"}>
							<div className="col-md">
								<div className="form-group">
									<Framework.Elements.DollarAmount
										size={"small"}
										name={"price_retail"}
										label={"Retail Price"}
										value={object.price_retail}
										error={object.price_retail_error}
										property={properties.price_retail}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>
							<div className="col-md">
								<div className="form-group">
									<Framework.Elements.DollarAmount
										size={"small"}
										name={"price_minimum"}
										label={"Min Sale Price"}
										value={object.price_minimum}
										error={object.price_minimum_error}
										property={properties.price_minimum}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>
							<div className="col-md">
								<div className="form-group">
									<Framework.Elements.DollarAmount
										size={"small"}
										name={"price_purchase"}
										label={"Purchase Price"}
										value={object.price_purchase}
										error={object.price_purchase_error}
										property={properties.price_purchase}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<Framework.Elements.TextField
								name={"description"}
								label={"Description"}
								value={object.description}
								error={object.description_error}
								property={properties.description}
								update={this.handleTextFieldPropertyUpdate}
								multiline={6}
							/>
						</div>
					</div>
				</div>
				<hr/>
				<div className={"row"}>
					{!object.pkey ?
						<div className="col-md">
							<div className={"form-group"}>
								<strong>Stocking Levels</strong>
							</div>

							<div className="form-group">
								<Framework.Elements.NumberField
									name={"quantity_current"}
									label={"Current Stock *"}
									value={object.quantity_current}
									error={object.quantity_current_error}
									property={properties.quantity_current}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div> : null}
					<div className="col-md">
						<div className="col-md">
							<div className={"form-group"}>
								<strong>For Inventory Reporting (To Keep In Stock)</strong>
							</div>
						</div>

						<div className={'row'}>
							<div className={'col-md'}>
								<div className="form-group">
									<Framework.Elements.NumberField
										name={"quantity_min"}
										label={"Minimum"}
										value={object.quantity_min}
										error={object.quantity_min_error}
										property={properties.quantity_min}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>

							<div className={'col-md'}>
								<div className="form-group">
									<Framework.Elements.NumberField
										name={"quantity_max"}
										label={"Maximum"}
										value={object.quantity_max}
										error={object.quantity_max_error}
										property={properties.quantity_max}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>


				{object.pkey && object.pkey ?
					<Framework.Printable.Label ref={el => (this['printable'] = el)} prefix="I" code={object.pkey}
					                           label={object.sku + "\n $" + object.price_retail}/> : null}

				<div className={classNames("loader", {'active': loading})}>
					<ClipLoader color={"#4A4A4A"} loading={loading}/>
				</div>
			</div>
		)
	};

	render() {
		let hasModal = typeof this.props.hasModal !== 'undefined' ? this.props.hasModal : false;
		if (hasModal) {
			return this.form();
		}
		return (
			<Card style={{overflow: 'visible'}}>
				<CardContent style={{paddingBottom: 0}}>
					{this.form()}
					<hr style={{marginBottom: 0}}/>
				</CardContent>
				<CardActions style={{justifyContent: 'flex-end', padding: '14px'}}>
					<ReactToPrint
						trigger={() =>
							<Button color="secondary" style={{marginRight: 'auto'}}>Print Label</Button>
						}
						content={() => this['printable']}
						copyStyles={false}
						pageStyle={'@media print { body { -webkit-print-color-adjust: exact; margin: 0 } @page { margin: 0 }}'}
						bodyClass={""}
					/>

					<Button color="danger" onClick={this.handleDelete}>Delete</Button>
					<Button color="primary" onClick={this.handleSubmit}>Save</Button>
				</CardActions>
			</Card>
		)
	}
}
