import {
	Collection,
	Object,
	ObjectState,
	View,
	SideBar,
	RootState,
	withRootState,
	TableHeaderRight,
	PageBar,
} from "./Components/index";
import {
	Select,
	TextField,
	NumberField,
	DollarAmount,
	Checkbox,
	DateTime,
	Date,
	DropZone,
	ButtonSwitch,
	NavGroup,
	Breadcrumb,
	LoadingSpinner,
	Header,
	UniversalModal,
	StatelessModal,
	ZipCode,
	PhoneNumber,
	EmailAddress
} from "./Elements/index";
import {General, MissingPropertyError, MissingAPIRouteError} from "./Errors/index";
import {Currency, FFL, Zip, Phone, PhoneExtension} from "./Masks/index";
import {Label} from "./Printable/index";
import {HtmlTooltip} from "./Tooltips/index";
import {TitledNotice} from "./Toast/index";
import * as Utilities from './Utilities';

import "./Prototypes";
import "./Styles";

const Framework = {
	Components: {
		Collection: Collection,
		Object: Object,
		ObjectState: ObjectState,
		View: View,
		SideBar,
		RootState,
		withRootState,
		TableHeaderRight,
		PageBar,
	},
	Elements: {
		Select: Select,
		TextField: TextField,
		NumberField,
		DollarAmount: DollarAmount,
		Checkbox: Checkbox,
		DateTime: DateTime,
		Date: Date,
		DropZone: DropZone,
		ButtonSwitch: ButtonSwitch,
		Breadcrumb,
		NavGroup,
		LoadingSpinner,
		UniversalModal,
		StatelessModal,
		ZipCode,
		PhoneNumber,
		EmailAddress,
		Header
	},
	Errors: {
		General,
		MissingPropertyError,
		MissingAPIRouteError,
	},
	Masks: {
		Currency,
		FFL,
		Zip,
		Phone,
		PhoneExtension,
	},
	Printable: {
		Label: Label
	},
	Tooltips: {
		HtmlTooltip: HtmlTooltip
	},
	Toast: {
		TitledNotice: TitledNotice
	},
	Utilities,
};

/**
 * @typedef {Object} ScannedCode
 * @property {string} prefix
 * @property {string} barcode
 */

// noinspection JSUnusedGlobalSymbols
export default Framework;
