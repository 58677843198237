import React from 'react';
import Framework from '@beardeddevops/react.framework';
import Types from 'constants/Types';
import classNames from "classnames";
import { ClipLoader } from "react-spinners";
import API from "@beardeddevops/react.api";

export default class TradeIn extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Trades._name);
	}

	async handleSubmit(event, suppressToast = false, suppressProcess = false) {
		if (this.props.forCheckout) {
			'PUT_CHECKOUT'
			let model = new API.BuildClass(Types.Trades._name, API.RequestType.READ_ALL);
			await model.submit(this, "PUT_CHECKOUT");
			this.props.afterSave();
		} else {
			super.handleSubmit(event, suppressToast, suppressProcess)
		}
	}

	form = () => {
		let { properties } = this.model;
		let object = this.state;
		return (
			<div>
				<fieldset>
					<h4 className="section-title">Trade-In</h4>
					<div className="row">
						<div className="col-md">
							<div className={"form-group"}>
								<Framework.Elements.TextField
									label={"Trade-In Name"}
									property={properties.name}
									value={object.name}
									error={object.name_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className={"form-group"}>
								<Framework.Elements.DollarAmount
									label={"Price"}
									property={properties.price}
									value={object.price}
									error={object.price_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className={"form-group"}>
								<Framework.Elements.NumberField
									name={"quantity"}
									label={"Quantity"}
									value={object.quantity}
									error={object.quantity_error}
									property={properties.quantity}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
						<div className="col-md">
							<div className={"form-group"}>
								<Framework.Elements.TextField
									label={"Notes"}
									property={properties.notes}
									value={object.notes}
									error={object.notes_error}
									multiline={6}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
					</div>
				</fieldset>
				<div className={classNames("loader", {'active': this.state.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				</div>
			</div>
		);
	}
}
