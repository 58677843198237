import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import {Button} from 'reactstrap';

import {ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';

import HelpCenterIcon from '@material-ui/icons/ContactSupport';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/PersonOutline';

import API from "@beardeddevops/react.api";
import Types from "../../constants/Types";
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "constants/Config";
import UserRoles from 'constants/UserRoles';

class NavRightList extends Framework.Components.View {

	constructor(props) {
		super(props);
		this.state.anchorEl = null;
		this.model = new API.BuildClass(Types.Accounts._name, API.RequestType.READ);
	}

	handleRoute = (value) => () => {
		this.props.history.push(value);
		this.setState({
			anchorEl: null,
		});
	};

	handleAPIChange = (value) => () => {
		APPCONFIG.settings.apiURLOverride = value;
		localStorage.setItem('apiURLOverride', value);
		API.URL = APPCONFIG.settings.apiURL();
		this.handleClose();
	};

	handleOpen = (e) => {
		const el = e.currentTarget.name;

		this.setState({
			[el]: e.currentTarget,
		});
	};

	handleClose = () => {
		this.setState({
			anchorEl: null,
		});
	};

	handleLogout = async (event) => {
		if (typeof event !== 'undefined') event.preventDefault();
		if (this.props.rootState.isAdmin) {
			let model = new API.BuildClass(Types.Admins_Locations._name, API.RequestType.READ);
			await model.submit(null, API.RequestType.DELETE);
			let OtherDomains = API.Domains.filter(domain => domain !== API.URL);
			for (let index in OtherDomains) {
				try {
					model.submit(null, API.RequestType.DELETE, false, false, false, OtherDomains[index]).then();
				} catch (e) {
				}
			}
			this.props.rootState.triggerAction('logout_admin');
		} else {
			await this.model.submit(null, API.RequestType.DELETE);
			let OtherDomains = API.Domains.filter(domain => domain !== API.URL);
			for (let index in OtherDomains) {
				try {
					this.model.submit(null, API.RequestType.DELETE, false, false, false, OtherDomains[index]).then();
				} catch (e) {
				}
			}
			this.props.rootState.triggerAction('logout');
		}
		return false;
	};


	// handleCloseSession = async (event) => {
	// 	if (typeof event !== 'undefined') event.preventDefault();
	// 	// noinspection JSIgnoredPromiseFromCall
	// 	await this.model.submit(null, 'DELETE_CLOSE');
	// 	//this.props.rootState.triggerAction('logout');
	// 	return false;
	// };

	render() {
		const {anchorEl} = this.state;
		let {rootState} = this.props;
		let {name, locations, userAccess, user, roles} = rootState

		/** @type {Locations_Object} location */
		return (
			<ul className="list-unstyled float-right">
				<li style={{marginRight: '10px'}}>
					{roles <= UserRoles.Manager || roles === undefined ?
						<Button
							name="anchorEl"
							aria-owns={anchorEl ? 'user-menu' : null}
							aria-haspopup="true"
							onClick={this.handleOpen}
							className="md-button header-btn menu-button"
							color={'none'}
						>
							<PersonIcon/>
							<span style={{margin: '0 6px'}}>{name}</span>
							<i className={'fa fa-angle-down'}/>
						</Button> :
						<span>
							<PersonIcon/>
							<span style={{margin: '0 6px'}}>{name}</span>
						</span>}

					<Button onClick={this.handleLogout} data-id={'log-out-btn'} color={'none'}
					        className="md-button header-btn">
						<LogOutIcon/>
					</Button>

					<Menu
						id="user-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={this.handleClose}
						getContentAnchorEl={null}
						anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
						transformOrigin={{horizontal: 'right', vertical: 'top'}}
					>
						{/*Restricts Employees and Supervisors*/}
						{(roles <= UserRoles.Manager || roles === undefined) &&
							<div>
								{(roles === "10" || roles === 4) && (
									<>
										<MenuItem onClick={this.handleRoute('/registers')}>
											<ListItemIcon><i className={"fa fa-user fa-lg"}/></ListItemIcon>
											<ListItemText inset primary="Registers"/>
										</MenuItem>
										<MenuItem onClick={this.handleRoute('/settings')}>
											<ListItemIcon><i className={"fa fa-address-card-o fa-lg"}/></ListItemIcon>
											<ListItemText inset primary="Account Settings"/>
										</MenuItem>
										<MenuItem onClick={this.handleRoute('/settings#billing')}>
											<ListItemIcon><i className={"fa fa-usd fa-lg"}/></ListItemIcon>
											<ListItemText inset primary="Billing"/>
										</MenuItem>
									</>
								)}
								<MenuItem onClick={async () => {
									await API.clearCache();
									this.setState({anchorEl: null});
								}}>
									<ListItemText inset primary="Clear API Cache"/>
								</MenuItem>
								<MenuItem onClick={() => window.open("https://fflsoftwarepro.freshdesk.com/support/tickets/new")}>
									<ListItemIcon><HelpCenterIcon/></ListItemIcon>
									<ListItemText inset primary="Help Center"/>
								</MenuItem>
							</div>}
						{/*<MenuItem onClick={this.handleCloseSession}>*/}
						{/*	<ListItemIcon>*/}
						{/*		<LogOutIcon/>*/}
						{/*	</ListItemIcon>*/}
						{/*	<ListItemText inset primary="Logout w/o Redirect"/>*/}
						{/*</MenuItem>*/}
						<MenuItem onClick={this.handleLogout}>
							<ListItemIcon>
								<LogOutIcon/>
							</ListItemIcon>
							<ListItemText inset primary="Logout"/>
						</MenuItem>
					</Menu>
				</li>
			</ul>
		);
	}
}

export default Framework.Components.withRootState(withRouter(NavRightList));
