import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";



export default class
NetMarginReports extends Framework.Components.Collection {

	//todo: hook up to correct model
	constructor(props) {
		let model = new API.BuildClass(Types.Locations_Sales._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
	}
	render() {

		//todo: Fix Columns
		const {data, pages, loading,limit} = this.state;
		let columns = [
			{
				Header: "Register Name",
				id: "name",
				accessor: r => (r.name.charAt(0).toUpperCase() + r.name.slice(1)) ,
				sortable: false
			},
			{
				Header: "Total Sales",
				id: "total_sales",
				accessor: r => ("$" + r.total_sales),
				sortable: false
			},
			{
				Header: "Non Taxable Sales",
				id: "non_taxable",
				accessor: r => ("$" + r.non_taxable),
				sortable: false
			},	{
				Header: "Taxable Sales",
				id: "taxable_sales",
				accessor: r => ("$" +r.taxable_sales),
				sortable: false
			},	{
				Header: "Tax Rate",
				id: "tax_rate",
				accessor: r => (parseFloat(r.tax_rate) + "%"),
				sortable: false
			},	{
				Header: "Sales Tax Payable",
				id: "total_tax",
				accessor: r => ("$" + r.total_tax),
				sortable: false
			},
		]
		return (
			<Card>

				<CardContent>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change

						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'ascending'
							}
						]}
						className="-striped -highlight"
						showPaginationTop= {false}
						showPaginationBottom= {false}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
					/>

					<div className={classNames("tabled", "loader", { 'active': loading })}>
						<ClipLoader color={"#4A4A4A"} loading={loading} />
					</div>
				</CardContent>
			</Card>
		)
	}
}
