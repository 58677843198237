import React from 'react';
import {withRouter} from "react-router";
import APPCONFIG from 'constants/Config';
import TextField from '@material-ui/core/TextField';
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import API from "@beardeddevops/react.api";
import {toast} from "react-toastify";
class ForgotPassword extends Framework.Components.Object {
	constructor(props) {
		super(props,Types.Accounts._name);
		this.model.requestType = 'DELETE_FORGOT';
		this.brand = APPCONFIG.brand;
		this.shouldAutoLoad = false;
	}
	handleSubmit = async event => {
		if (typeof event !== 'undefined') event.preventDefault();

		this.setState({loading: true});
		if (this.handleProcess(null, 'DELETE_FORGOT')) {
			await this.model.submit(this, 'DELETE_FORGOT', true);
			toast.info(Framework.Toast.TitledNotice("Password Reset", "An email with your temporary password has been sent."), {
				position: toast.POSITION.TOP_RIGHT
			});
			this.props.history.push('/login');
		} else {
			this.setState({loading: false});
		}
		return false;
	};
	render() {
		let brand = this.brand;
		/** @type {Accounts_Properties} properties */
		let properties = this.model.properties;
		/** @type {Accounts_Object} object */
		let object = this.state;
		return (
			<div className="body-inner">
				<div className="card bg-white">
					<div className="card-content">
						<section className="logo text-center">
							<h1><a href="#">{brand}</a></h1>
						</section>
						<form>
							<fieldset>
								<div className="form-group">
									<Framework.Elements.TextField
										value={object.email}
										error={object.email_error}
										property={properties.email}
										update={this.handleTextFieldPropertyUpdate}
										variant={"standard"}
									/>
									<div className="additional-info text-center text-small">
										Enter your email address that you used to register. We'll send you an email with
										your username and a link to reset your password.
									</div>
								</div>
							</fieldset>
						</form>
					</div>
					<div className="card-action no-border text-right">
						<Link to={"/login"}>
							<Button color="secondary" size={"md"} block={false}>Back</Button>
						</Link>
						<a href="#" className="color-primary">
							<Button onClick={this.handleSubmit} color="primary" size={"md"} block={false}>Reset</Button>
						</a>
					</div>
				</div>
			</div>
		);
	}
}
const ForgotPasswordRoute = withRouter(ForgotPassword);
const Page = () => (
	<div className="page-forgot">
		<div className="main-body">
				<div key="1">
					<ForgotPasswordRoute/>
				</div>
		</div>
	</div>
);

export default Page;
