import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";

import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";

export default class SkuClasses extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Skus_Classes._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		let columns =[
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <Link
					to={"/skus/classes/" + props.original.pkey}>{props.value}</Link>
			},

			//Todo: Can't sort or search for this since it's computed on the front end.
			{
				Header: "Inventory Items",
				id: "quantity",
				filterable: false,
				accessor: r => {
					/** @property r.skuses */
					let cnt = 0;
					for (let sku of r.skuses) {
						/** @property sku.inventoriess */
						for (let inventory of sku.inventoriess) {
							cnt += inventory.quantity;
						}
					}
					return cnt;
				}
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`classes/`+ props.original.pkey}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (

			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Inventory Group')}
							{this.renderHeaderRight("Inventory Skus", columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination={true}
						showPaginationTop={false}
						showPaginationBottom={true}
						showPageSizeOptions={true}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
