import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Objects} from "components";

import {Button} from "reactstrap";

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Checkout',
		url: '/',
		icon: 'calculator',
	},
	{
		name: 'Consignments',
		url: '/consignments',
		icon: 'gift',
	}
];

export default class Consignments extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-consignment" onClick={this.toggle('modalConsignment')} size={"sm"}
					        color="primary">
						<i className="fa fa-handshake-o"/>&nbsp;&nbsp;Add Consignment
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Consignments
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Consignment"}
					object={Objects.Consignment}
					history={this.props.history}
					toggle={this.state['modalConsignment']}
					reportClose={this.reportClose('modalConsignment')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		);
	}
}
