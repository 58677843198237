import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";


/**
 *  @property {Consignments_Object} state
 *  @property {object} model
 *  @property {Consignments_Properties} model.properties
 *  @property {Consignments_Is} model.is
 *  @property {Consignments_Has} model.has
 */

export default class ConsignmentObject extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Consignments._name);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		/** @type {Consignments_Object} object */
		let object = this.state;
		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name);
		}
	}

	form = () => {
		let {properties} = this.model;

		/**
		 *  @type {Consignments_Object} object
		 */
		let object = this.state;

		return (
			<div>

				<fieldset>
					<h4 className="section-title">Consignment</h4>
					<div className="row">
						<div className="col-md">
								<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Consignment Name"}
										property={properties.name}
										value={object.name}
										error={object.name_error}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
							<div className="form-group">
								<Framework.Elements.TextField
									label={"Point of Contact"}
									property={properties.poc}
									value={object.poc}
									error={object.poc_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.TextField
									label={"Terms"}
									property={properties.terms}
									value={object.terms}
									error={object.terms_error}
									update={this.handleTextFieldPropertyUpdate}
									multiline={6}
								/>
							</div>
							<div className={'form-group'}>
								<Framework.Elements.ButtonSwitch
									value={!!object.taxable}
									error={object.taxable_error}
									property={properties.taxable}
									update={this.handleTextFieldPropertyUpdate}
									labelTrue={"Taxable"}
									labelFalse={"Non-Taxable"}
								/>
							</div>
						</div>
						<div className="col-md">
							<div className="form-group">
								<Framework.Elements.DollarAmount
									label={"Price"}
									property={properties.price}
									value={object.price}
									error={object.price_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.NumberField
									name={"quantity"}
									label={"Quantity"}
									value={object.quantity}
									error={object.quantity_error}
									property={properties.quantity}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
								<div className="form-group">
									<Framework.Elements.TextField
										label={"Notes"}
										property={properties.notes}
										value={object.notes}
										error={object.notes_error}
										multiline={6}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
						</div>
					</div>
				</fieldset>


				<div className={classNames("loader", {'active': this.state.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				</div>
			</div>
		)
	}
}
