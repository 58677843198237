import React from "react";
import PropTypes from "prop-types";
import {isEqual, uniq} from "lodash";
import {getDisplayName} from '../Utilities';

export const StateContext = React.createContext("state");

export const withRootState = (Component) => {
	const StateComponent = React.forwardRef((props, ref) => {
		return (
			<StateContext.Consumer>
				{(state) => <Component ref={ref} {...props} rootState={state} />}
			</StateContext.Consumer>
		);
	});

	StateComponent.displayName = `withRootState(${getDisplayName(Component)})`;

	return StateComponent;
};

export class RootState extends React.Component {
	static propTypes = {
		defaultState: PropTypes.object,
	};

	static defaultProps = {
		defaultState: {},
	};

	constructor(props) {
		super(props);

		let {defaultState} = props;

		this.subscriptions = [];

		this.idIncrement = -1;

		this.state = {
			...defaultState,
			height: window.innerHeight,
			width: window.innerWidth,

			set: (obj, cb) => {
				const keys = Object.keys(obj);
				let changed = false;

				for (let i = 0, len = keys.length; i < len; i++) {
					let key = keys[i];
					let callbacks;

					if (!this.renderKeys.includes(key) || isEqual(obj[key], this.state[key])) {
						continue;
					}

					changed = true;

					callbacks = this.subscriptions
						.filter((item) => item.key === key)
						.map((item) => item.fn);

					for (let i = 0, len = callbacks.length; i < len; i++) {
						if (callbacks[i]) callbacks[i](obj);
					}
				}

				if (changed) {
					window.DEBUG_STATE && console.log('RootState change: ', obj);
					this.setState(obj, cb);
				} else {
					window.DEBUG_STATE && console.log('RootState unchanged: ', obj)
				}
			},

			triggerAction: (key, ...args) => {
				const subscription = this.subscriptions
					.find((item) => item.key === key);

				if (subscription) {
					return subscription.fn(...args);
				} else {
					console.warn(`Couldn\'t find action trigger for ${key}`);
				}
			},

			subscribe: (obj) => {
				let id = this.idIncrement++;
				let keys = Object.keys(obj);

				for (let i = 0, len = keys.length; i < len; i++) {
					let key = keys[i];
					let fn = obj[key];
					let index = -1;

					index = this.subscriptions.findIndex((item) => item.key === key && item.fn === fn);

					if (index < 0) {
						this.subscriptions.push({
							key,
							fn,
							id,
						})
					}
				}

				return id;
			},

			unsubscribe: (id) => {
				this.subscriptions = this.subscriptions.filter((item) => item.id !== id);
			},
		};

		window.rootState = () => this.state;
	}

	get renderKeys() {
		return uniq(this.subscriptions.map((item) => item.key));
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.handleResize);
	};

	handleResize = () => {
		this.state.set({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	render() {
		return (
			<StateContext.Provider value={this.state}>
				{this.props.children}
			</StateContext.Provider>
		);
	}
}
