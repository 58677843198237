import React from "react";
import {Button} from "reactstrap";
import {Card, CardContent, Tooltip} from "@material-ui/core";
import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Elements} from "components";

class LayawayEntries extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Layaways_Entries._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 5;
		this.limit = 5;
		this.state.sorted = [];
		console.log('YOU SHOULD NOT BE IN HERE A;LSDJF;ALKSDJF;ALKSDJF;ALSKDFJA;LSDKFJ')
	};

	//DEPRECIATED USE LAYAWAYITEMS INSTEAD

	componentDidMount() {
		super.componentDidMount();
		//manually fetch data cause this is a "collection"
		this.fetchData(this.state).then();
	}

	handleToggleItemModel = (forItem) => {
		if (typeof this.state[forItem] === 'undefined') {
			this.state[forItem] = false;
		}
		return () => {
			this.setState({[forItem]: true});
		};
	}

	handleReportModalClose = (forItem) => () => {
		this.setState({ [forItem]: false });
	}

	render() {
		let object = this.state;
		return (<>
					<div className={'section-title'}>
						<h4 className="section-title">Layaway Items</h4>
						<div className={"button-container"}>
							<Tooltip title={"Add Best POS Products "}>
								<Button color={'primary'} size={'sm'}
								        onClick={this.toggle('modalLayawayItems')}>
									<i className={"fa fa-plus"}/>
									<span className="hidden-xs">&nbsp;Add Item</span>
								</Button>
							</Tooltip>
							<Tooltip style={{marginLeft: '0px'}} title={"Add FFL Boss Products "}>
								<Button color={'primary'} size={'sm'}
								        onClick={this.toggle('modalLayawayProducts')}>
									<i className={"fa fa-plus"}/>
									<span className="hidden-xs">&nbsp;Add Product</span>
								</Button>
							</Tooltip>
						</div>
						{/*<BarcodeReader*/}
						{/*	onError={this.handleError}*/}
						{/*	onScan={this.handleScan}*/}
						{/*/>*/}
					</div>
					<div style={{
						overflowY: "auto",
						paddingRight: "4px",
						maxHeight: "362px"
					}}>
						{object.data && object.data.map((entry, i) =>
							{
								console.log("loggin data in this map", entry, i);
								// return true;
								if(entry.inventory !== null) {
									return (
										<Elements.Item
											key={i}
											entry={entry}
											product={entry}
											onDelete={this.deleteItem}
											onUpdate={this.updateItem}
											openModal={this.handleToggleItemModel('itemUpdate' + i)}
											reportClose={this.handleReportModalClose('itemUpdate' + i)}
											modalUpdate={this.state['itemUpdate' + i]}
											history={this.props.history}
										/>
									)
								} else if(entry.product !== null) {
									return (
										<Elements.Product
											key={i}
											entry={entry}
											product={entry}
											onDelete={this.deleteProduct}
											onUpdate={this.updateProduct}
											openModal={this.handleToggleItemModel('itemUpdate' + i)}
											reportClose={this.handleReportModalClose('itemUpdate' + i)}
											modalUpdate={this.state['itemUpdate' + i]}
											history={this.props.history}
										/>
									)
								}
								})
						}
					</div>
				<p style={{textAlign: 'center', color: 'grey'}}><i>{object.data.length} Total Items</i></p>
		</>

		)
	}
}

export default Framework.Components.withRootState(LayawayEntries);
