import React from 'react';
import {Link, Switch, withRouter} from 'react-router-dom';
import 'jquery-slimscroll/jquery.slimscroll.min';
import PropTypes from "prop-types";
import FFLBossLogo from '../../assets/images/FFL-logo-box.png';
import { Badge } from 'reactstrap';
import UserRoles from 'constants/UserRoles';
import Framework from "@beardeddevops/react.framework"

class SidebarContent extends React.Component {
	static propTypes = {
		locations: PropTypes.number,
		sessions: PropTypes.number,
		items: PropTypes.number,
		ffl_boss_linked: PropTypes.bool,
		roles: PropTypes.number
	};

	render() {
		const {items, sessions, ffl_boss_linked, roles, history, show_consignments, show_layaways} = this.props;
		//year end reports show bool
		const YEI = this.props.rootState.account === 183 ||this.props.rootState.account === 10;

		return (
			<>
				<li>
					<Link data-id={'dashboard'} variant={"text"} to="/dashboard">
						<i className="fa fa-tachometer"/>
						<span className="nav-text">Dashboard</span>
					</Link>
				</li>
				{/*<li>*/}
				{/*	{roles <= UserRoles.Manager &&*/}
				{/*	<Link data-id={'dashboard'} variant={"text"} to="sales_tax_report">*/}
				{/*		<i className="fa fa-file"/>*/}
				{/*		<span className="nav-text">Sales Tax Report</span>*/}
				{/*	</Link>}*/}
				{/*</li>*/}
				<Framework.Elements.NavGroup
					parentTextVarient={null}
					parentTargetPath={'/products'}
					parentId={'products'}
					parentLinkText={'Products'}
					history={history}
					path={history.location.pathname}
					default={'open'}
				>
					{ffl_boss_linked &&
						<li>
							<Link variant={"text"} to="/products"><img
								style={{width: "20%", marginLeft: ".5rem", paddingRight: "10px"}} src={FFLBossLogo}/>
								<span className="nav-text">Firearms</span>
							</Link>
						</li>}

					{/*{!ffl_boss_linked ?*/}
					<li>
						<Link variant={"text"} to="/inventory">
							<i className="fa fa-barcode"/>
							<span className="nav-text"> Inventory</span>
						</Link>
					</li>
					{/*: null}*/}

					{show_consignments && <li>
						<Link variant={"text"} to="/consignments"><i className="fa fa-handshake-o"/>
							<span className="nav-text">Consignments</span>
						</Link>
					</li>}
					<li>
						<Link variant={"text"} to={"/trade-ins"}><i className="fa fa-exchange"/>
							<span className="nav-text">Trade-Ins</span>
						</Link>
					</li>
				</Framework.Elements.NavGroup>

				<Framework.Elements.NavGroup
					parentTextVarient={null}
					parentTargetPath={'/sales'}
					parentId={'sales'}
					parentLinkText={'Sales'}
					history={history}
					path={history.location.pathname}
					default={'open'}
				>

					<li>
						<Link variant={"text"} to="/checkout">
							<span className="nav-text"><i className="fa fa-calculator"/>Checkout <Badge
								style={{float: "right", position: "relative", top: "5px"}} color={"primary"}
								pill>{parseInt(items) || 0}</Badge></span>
						</Link>
					</li>
					<li>
						<Link variant={"text"} to="/sessions">

							<span className="nav-text"><i className="fa fa-pause-circle"/>Paused Sales <Badge
								style={{float: "right", position: "relative", top: "5px"}} color={"primary"}
								pill>{parseInt(sessions) || 0}</Badge></span>
						</Link>
					</li>
					<li>
						<Link variant={"text"} to="/transactions">
							<i className="fa fa-shopping-cart"/>
							<span className="nav-text">Transactions</span>
						</Link>
					</li>
					{(roles <= UserRoles.Manager || roles === undefined) &&
						<li>
							<Link variant={"text"} to="/certificates"><i className="fa fa-gift"/>
								<span className="nav-text">Gift Certificates</span>
							</Link>
						</li>}
					{show_layaways && <li>
						<Link variant={"text"} to="/layaways"><i className="fa fa-hourglass"/>
							<span className="nav-text">Layaways</span>
						</Link>
					</li>}
					{/*{this.props.ffl_boss_linked ? <li><Link variant={"text"} to="/dispositions"><i className="fa fa-upload"/><span className="nav-text">Dispositions</span></Link></li> : null }*/}
				</Framework.Elements.NavGroup>


				<Framework.Elements.NavGroup
					parentTextVarient={null}
					parentTargetPath={'/inventory-control'}
					parentId={'inventory-control'}
					parentLinkText={'Inventory Control'}
					history={history}
					path={history.location.pathname}
					default={'open'}
				>

					{/*<li className="nav-header">*/}
					{/*	<span>Inventory Control</span>*/}
					{/*</li>*/}
					<li>
						<Link variant={"text"} to="/skus"><i className="fa fa-list-alt"/>
							<span className="nav-text">Inventory</span>
						</Link>
					</li>
					<li>
						<Link variant={"text"} to="/skus/classes"><i className="fa fa-folder"/>
							<span className="nav-text">Inventory Groups</span>
						</Link>
					</li>
				</Framework.Elements.NavGroup>

				{/*Hide reports except from Account Owner and Managers*/}
				{(roles === "10" || roles === 4) &&
					<Framework.Elements.NavGroup
						parentTextVarient={null}
						parentTargetPath={'/reports'}
						parentId={'reports'}
						parentLinkText={'Reports'}
						history={history}
						path={history.location.pathname}
						default={'open'}
					>
						<li>
							<Link variant={"text"} to="/reports/inventory_report"><i className="fa fa-list-alt"/>
								<span className="nav-text">Inventory Report</span>
							</Link>
						</li>
						<li>
							<Link variant={"text"} to="/reports/sales_tax"><i className="fa fa-list-alt"/>
								<span className="nav-text">Sales Tax Report</span>
							</Link>
						</li>
						<li>
							<Link variant={"text"} to="/reports/sales_report"><i className="fa fa-list-alt"/>
								<span className="nav-text">Sales Report</span>
							</Link>
						</li>
						{YEI &&
							<li>
								<Link variant={"text"} to="/reports/year_end_report"><i className="fa fa-list-alt"/>
									<span className="nav-text">Year End Report</span>
								</Link>
							</li>
						}
					</Framework.Elements.NavGroup>}

				{/*<li><Link variant={"text"} to="/reports"><i className="nav-icon material-icons">pie_chart_outlined</i><span className="nav-text">Reports</span></Link></li>*/}

				{/*Hide Account options except from Account Owner and Managers*/}
				{(roles === "10" || roles === 4) &&
					<Framework.Elements.NavGroup
						parentTextVarient={null}
						parentTargetPath={'/account'}
						parentId={'account'}
						parentLinkText={'Account'}
						history={history}
						path={history.location.pathname}
						default={'open'}
					>

						<li>
							<Link id="link-users" variant={"text"} to="/users">
								<i className="fa fa-users"/>
								<span className="nav-text">Users</span>
							</Link>
						</li>
						<li>
							<Link variant={"text"} to="/registers">
								<i className="fa fa-usd"/>
								<span className="nav-text">Registers</span>
							</Link>
						</li>
						<li>
							<Link variant={"text"} to={"/locations/" + this.props.rootState.location.Pkey}>
								<i className="fa fa-building"/>
								<span className="nav-text">Locations</span>
							</Link>
						</li>
						<li>
							<Link id="link-users" variant={"text"} to="/settings">
								<i className="fa fa-users"/>
								<span className="nav-text">Settings</span>
							</Link>
						</li>
					</Framework.Elements.NavGroup>}
			</>
		);
	}
}
export default Framework.Components.withRootState(SidebarContent);
