import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import API from "@beardeddevops/react.api";
import {toast} from "react-toastify";


export default class RefundObject extends Framework.Components.Object {
	constructor(props) {
		/** @typedef {Transactions_Object} this.state */
		super(props, Types.Transactions_Refunds._name);
		this.state.quantity = this.state.restock = this.props.quantity - this.props.quantity_refunded;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);

	}

	handleSubmit = (event) => {
		this.state.transaction = this.props.transaction
			// //Check if refund amount is larger than item total
			// if(parseFloat(this.state.amount).toFixed(2) > parseFloat(this.props.total).toFixed(2))
			// {
			// 	console.log("this.state.amount",this.state.amount);
			// 	console.log("props",this.props?.total);
			// 	API.ToastId = toast.error('Refund amount is larger than Refunded Amount', {
			// 		position: toast.POSITION.TOP_RIGHT
			// 	});
			// }
			// else {
				// if (this.props.product) {
				// 	this.state.product = this.props.entry
				// } else {
				// 	this.state.item = this.props.entry
				// }
		this.state.item = this.props.entry

		super.handleSubmit(event)
	}
	form = () => {
		/**	 @param {Transactions_Refunds_Properties} data */

		let {properties} = this.model;
		/** @type {Transactions_Refunds_Object} object */
		let object = this.state;

		return (
			<fieldset>
				<div className="form-group">
					<Framework.Elements.DollarAmount
						error={object.amount_error}
						property={properties.amount}
						placeholder={parseFloat(this.props?.total - this.props.refund_amount).toFixed(2)}
						value={object.amount}
						label={"Refund Amount"}
						update={this.handleTextFieldPropertyUpdate}
						allowNegative={false}
					/>
				</div>
				<div className={"form-group"}>
					<Framework.Elements.Select
						shouldLoad={true}
						update={this.handleSelectPropertyUpdate}
						property={properties.method}
						error={object.method_error}
						value={object.method}
						objects={[
							{pkey: 'cash', name: "Cash"},
							{pkey: 'card', name: "Card"},
							{pkey: 'other', name: "Other"},
						]}
						text={'name'}
					/>
				</div>
				<div className={"form-group"}>
					<Framework.Elements.NumberField
						label={"Quantity To Refund"}
						allowNegative={false}
						update={this.handleTextFieldPropertyUpdate}
						error={object.quantity_error}
						property={properties.quantity}
						value={object.quantity}
					/>
				</div>
				<div className={"form-group"}>
					<Framework.Elements.NumberField
						label={"Quantity To Restock"}
						allowNegative={false}
						update={this.handleTextFieldPropertyUpdate}
						error={object.restock_error}
						property={properties.restock}
						value={object.restock}
					/>
				</div>
				<div className={"form-group"}>
					<Framework.Elements.TextField
						name={"note"}
						label={"Note"}
						multiline={"3"}
						update={this.handleTextFieldPropertyUpdate}
						error={object.note_error}
						property={properties.note}
						value={object.note}
					/>
				</div>

				<div className={classNames("loader", {'active': properties.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={properties.loading}/>
				</div>
			</fieldset>
		)
	}
}
