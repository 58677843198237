import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import Framework from "@beardeddevops/react.framework";
import Types from "constants/Types";
import moment from "moment";


/**
 *  @property {Certificates_Object} state
 *  @property {object} model
 *  @property {Certificates_Properties} model.properties
 *  @property {Certificates_Is} model.is
 *  @property {Certificates_Has} model.has
 */

export default class CertificateObject extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Certificates._name);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		/** @type {Certificates_Object} object */
		let object = this.state;
		if (typeof this.props.updateName === 'function') {
			this.props.updateName(object.name);
		}
	}

	form = () => {
		let {properties} = this.model;

		/**
		 *  @type {Certificates_Object} object
		 */
		let object = this.state;
		return (
			<div>

				<fieldset>
					<h4 className="section-title">Certificate</h4>
					<div className="row">
						<div className="col-md">
							{object.pkey ?
								<div className={'form-group'}>
									<Framework.Elements.TextField
										label={"Certificate Code"}
										property={properties.code}
										value={object.code}
										error={object.code_error}
										update={this.handleTextFieldPropertyUpdate}
									/>
								</div>
								: null}
							<div className="form-group">
								<Framework.Elements.TextField
									label={"Recipient Name"}
									property={properties.name}
									value={object.name}
									error={object.name_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
							<div className="form-group">
								<Framework.Elements.DollarAmount
									label={"Certificate Value"}
									property={properties.value}
									value={object.value}
									error={object.value_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>
						</div>
						<div className="col-md">

							<div className="form-group">
								<Framework.Elements.PhoneNumber
									property={properties.phone}
									value={object.phone}
									error={object.phone_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>

							<div className="form-group">
								<Framework.Elements.TextField
									label={"Email"}
									property={properties.email}
									value={object.email}
									error={object.email_error}
									update={this.handleTextFieldPropertyUpdate}
								/>
							</div>

							{object.pkey ?

								<div className="form-group">
									<Framework.Elements.TextField
										label={"Status"}
										property={properties.used}
										value={(object.used ? "Used" : " ")}
										error={object.used_error}
										update={this.handleTextFieldPropertyUpdate}
										disabled={"true"}
									/>
								</div>
								: null}
						</div>
					</div>
				</fieldset>


				<div className={classNames("loader", {'active': this.state.loading})}>
					<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
				</div>
			</div>
		)
	}
}
