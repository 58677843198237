import React from 'react';
import InputMask from 'react-input-mask';

const Zip = (props) => {
	const {inputRef, value, ...other} = props;

	const handleRef = (ref) => {
		inputRef(ref ? ref.inputElement : null);

		if (!ref) return;

		if (value.length === 6) {
			ref.setCursorToEnd();
		}
	}

	return (
		<InputMask
			{...other}
			ref={handleRef}
			value={value}
			mask={value.length <= 5 ? '999999' : '99999-9999'}
			maskChar={null}
			alwaysShowMask={false}
		/>
	);
};

export default Zip;
