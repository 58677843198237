const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
	brand: 'FFL Boss POS',
	year,
	copyright: '2018',
	productLink: 'https://pos.karlstechnology.com',
	settings: {
		isDev: () => {
			return window.location.hostname === 'dev.pos.karlstechnology.com' || window.location.hostname === 'beta.pos.karlstechnology.com';
		},
		apiURL: () => {
			return window.location.hostname === 'beta.pos.karlstechnology.com' ? "https://beta.api.pos.karlstechnology.com" : (
				window.location.hostname === 'dev.pos.karlstechnology.com' ?
					'https://dev.api.pos.karlstechnology.com' :
					'https://api.pos.karlstechnology.com')
				;
		},
		fflBossURL: () => {
			return window.location.hostname === 'beta.pos.karlstechnology.com' ?
				"https://beta.fflboss.com" :
				( window.location.hostname === 'pos.karlstechnology.com' ?
					'https://app.fflboss.com' :
					'https://dev.fflboss.com' );
		},
		apiVersion: 'v354'
	}
};

export default APPCONFIG;
