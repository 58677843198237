import React from "react";

import API from "@beardeddevops/react.api";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default class CertificateTransactions extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Certificates._name, API.RequestType.READ);
		super(props, model);
		this.request = 'GET_HISTORY';
		this.limit = 20;
	};

	render() {
		const { data, pages, limit, loading } = this.state;
		const columns = [
			{
				Header: "Date",
				id: "date",
				accessor: obj => {
					return moment(obj.transaction_date).local().format("MM/DD/YYYY")
				}
			},
			{
				Header: "Amount",
				id: "amount",
				accessor: obj => "$ " + (parseFloat(obj.transaction_amount)).toFixed(2),
			},
			{
				Header: "Transaction",
				id: "transaction",
				accessor: obj => obj.transaction_pkey,
				Cell: obj => (
					<Link to={`/transactions/${obj.original.transaction_pkey}`}>
						{obj.original.transaction_pkey}
					</Link>
				)
			}
		];

		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('History', "")}
							{this.renderHeaderRight("History", columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						onPageSizeChange={this.onPageSizeChange}
						onSortedChange={this.onSortedChange}
						page={this.state.page}
						onPageChange={this.onPageChange}
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						showPagination={true}
						showPaginationTop={false}
						showPaginationBottom={true}
						showPageSizeOptions={true}
						defaultPageSize={limit}
						pageSize={limit}
						pageSizeOptions={[5, 20, 50, 100, 500, 1000]}
						className="-striped -highlight"
					/>

					<Framework.Elements.LoadingSpinner isLoading={loading}/>
				</CardContent>
			</Card>
		);
	};
}
