import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import Collection from './components/Users'
import Object from './components/User'

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";

export default ({ match, history,locations}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute exact path={`${match.url}`}>
				<Collection
					history={history}
					base={`${match.url}`}
				/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute path={`${match.url}/:id`} >
				<Object
					history={history}
					locations={locations}
					baseUrl={`${match.url}`}
				/>
			</ErrorBoundaryRoute>
			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
