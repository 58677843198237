if (!String.prototype.capitalize) {
	String.prototype.capitalize = function () {
		return this.replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		});
	};
}

if (!String.prototype.isNumeric) {
	String.prototype.isNumeric = function () {
		return !isNaN(this);
	};
}

if (!String.prototype.titleCase) {
	String.prototype.titleCase = function() {
		return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
	}
}

if (!String.prototype.padUntil) {
	String.prototype.padUntil = function(size, char = '0') {
		let s = String(this);
		while (s.length < (size || 2)) {s = char + s;}
		return s;
	}
}

if (!String.prototype.pad) {
	String.prototype.pad = function(size, char = '0') {
		let s = String(this);
		let i = 0;
		while (i < size) {s = char + s; i++;}
		return s;
	}
}

if(!String.prototype.alphaToMonth) {
	String.prototype.alphaToMonth = function() {
		let expirations = {
			"A": 'Jan',
			"B": 'Feb',
			"C": 'Mar',
			"D": 'Apr',
			"E": 'May',
			"F": 'June',
			"G": 'July',
			"H": 'Aug',
			"J": 'Sept',
			"K": 'Oct',
			"L": 'Nov',
			"M": 'Dec',
		};
		try {
			if( expirations.hasOwnProperty(this) ) {
				return expirations[this];
			}
		} catch(e) {}

		return '';
	}
}
if(!String.prototype.numToYear) {
	String.prototype.numToYear = function(forYear) {
		try {
			let yearFirstPart = Number.parseInt(forYear.substring(0, 2));
			let yearPartThree = Number.parseInt(forYear.substring(2, 3));
			let yearPartFour = Number.parseInt(forYear.substring(3, 4));

			if( Number.parseInt(this) < yearPartFour ) {
				yearPartThree += 1;
			}

			return yearFirstPart.toString() + yearPartThree.toString() + this.toString();
		} catch(e) {}

		return '';
	}
}