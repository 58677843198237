if (!Number.prototype.padUntil) {
	Number.prototype.padUntil = function(size, char = '0') {
		let s = String(this);
		while (s.length < (size || 2)) {s = char + s;}
		return s;
	}
}

if (!Number.prototype.pad) {
	Number.prototype.pad = function(size, char = '0') {
		let s = String(this);
		let i = 0;
		while (i < size) {s = char + s; i++;}
		return s;
	}
}

if (!Number.prototype.isEven) {
	Number.prototype.isEven = function() {
		return (this % 2) === 1;
	}
}