import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingSpinner = ({
	isLoading = false,
	className = 'tabled loader',
	color = '#4A4A4A',
}) => {
	return (
		<div className={`${className}${isLoading ? ' active' : ''}`}>
			<ClipLoader color={color} loading={isLoading}/>
		</div>
	);
}

export default LoadingSpinner;
