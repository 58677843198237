import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Objects} from "components";

const breadcrumbsStatic = [
	{
		name: 'Checkout',
		url: '/',
		icon: 'calculator',
	},
	{
		name: 'Consignment',
		url: '/consignments',
		icon: 'gift',
	}
];

export default class Consignment extends Framework.Components.View {

	constructor(props) {
		super(props);
	}

	render() {
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/consignments/${this.props.id}`,
				icon: 'edit',
			}
		]);
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}/>
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.Consignment
						id={this.props.match.params.id}
						locations={this.props.locations}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={this.props.baseUrl}
					/>

				</Framework.Errors.General>
			</div>
		)
	}
}
