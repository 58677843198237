import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent, Paper} from "@material-ui/core";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import moment from "moment/moment";


export default class YearEndReport extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Year_End_Reports._name, API.RequestType.READ_ALL);
		super(props, model);
		// this.state.limit = 16;
	}

	handleToggleModal = (modal) => {
		this.setState({[modal]: !this.state[modal]})
	}


	handleDateChange = async (name, date) => {
		this.state.filterDate = moment(date).format();
		await this.setState({filterDate: moment(date).format()});
		this.handleToggleModal('showDate');
		await this.getReport();
	}


	getReport = async () => {
		let yModel = new API.BuildClass(Types.Year_End_Reports._name, 'PUT_YEAR_END_REPORT');
		// yModel.object.end_year = this.state.end_date;

		await this.setState({loading: true});
		await yModel.submit(null, 'PUT_YEAR_END_REPORT').then()

		this.data = await this.model.submit(null, API.RequestType.READ_ALL).then()
		// this.data = this.data.items;
		// this.setState({data: this.data, loading: false})
		this.reloadTable();
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		const {properties} = this.model;
		let columns = [

			{
				Header: "Account",
				id:"account",
				accessor: "account",
			},
			{
				Header: "Year",
				id: "year",
				accessor: r => r.start_year.substring(0,4),
			},
			{
				Header: "Quantity Start",
				accessor: "quantity_start",
			},
			{
				Header: "Quantity End",
				accessor: "quantity_end",
			},
			{
				Header: "Purchase Price Start",
				id: "purchase_price_start",
				accessor: r => r.purchase_price_start,
				Cell: props => (
					<span>
						{new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(props.value)}
					</span>
				)
			},
			{
				Header: "Purchase Price End",
				id: "purchase_price_end",
				accessor: r => r.purchase_price_end,
				Cell: props => (
					<span>
						{new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(props.value)}
					</span>
				)
			},
			{
				Header: "Retail Price Start",
				id: "retail_price_start",
				accessor: r => r.retail_price_start,
				Cell: props => (
					<span>
						{new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(props.value)}
					</span>
				)
			},
			{
				Header: "Retail Price End",
				id: "retail_price_end",
				accessor: r => r.retail_price_end,
				Cell: props => (
					<span>
						{new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(props.value)}
					</span>
				)
			}


		];
		return (

			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{this.renderHeaderRight("Year End Reports",columns)}
						</div>
						<Button color="secondary" onClick={this.getReport}>Get Report</Button>

					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages}
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'ascending'
							}
						]}
						className="-striped -highlight"
						showPaginationTop={false}
						showPaginationBottom={false}
						showPageSizeOptions={true}
						defaultPageSize={limit}
					/>

					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				</CardContent>
			</Card>
		)
	}
}
