import React from 'react';
import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections} from "components";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Inventory Report',
		url: '/reports/inventory_report',
		icon: 'file',
	}
];

export default class InventoryReport extends Framework.Components.View {

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>
				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>

					<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
						<Collections.InventoryReport
							history={this.props.history}
							reloadShell={this.props.reloadShell}
							reloadPage={this.refreshPage}
							reloadSelf={this.state.refresh}
						/>
					</Framework.Errors.General>
				</Framework.Errors.General>
			</div>
		)
	}
}
