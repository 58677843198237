import React, {createRef} from "react";

import Dropzone from 'react-dropzone'
import PropTypes from "prop-types";
import MissingPropertyError from "../Errors/MissingProperty";
import { Button } from "reactstrap";

const DropZoneChild = (label) => ({getRootProps, getInputProps}) => (
	<section className="container">
		<div {...getRootProps({className: 'dropzone'})}>
			<input {...getInputProps()} />
			<p>{label?label:'Drag \'n\' drop a file here, or click to select file'}</p>
		</div>
	</section>
);

const DropZoneChildButton = (label) => ({ getRootProps, getInputProps }) => (
	<section className="container" style={{ paddingBottom: 10 }}>
		<Button color="primary" {...getRootProps({ className: 'dropzone' })}>
			<input {...getInputProps()} />
			<p>{ label ? label: 'Click to select file' }</p>
		</Button>
	</section>
);

export default class _DropZone extends React.Component {
	static getDerivedStateFromProps = (props, state) => {
		const nextState = {};

		if (props.value !== state.value) {
			nextState.value = props.value;
			nextState.file = null;
		}

		if (props.error !== state.error) {
			nextState.error = props.error;
		}

		return nextState;
	};

	static propTypes = {
		property: PropTypes.object.isRequired,
		update: PropTypes.func.isRequired,

		label: PropTypes.string,

		accept: PropTypes.array,
		maxSize: PropTypes.number,

		button: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		if( !this.props.property ) {
			throw new MissingPropertyError('Missing API property for DropZone. Please "Clear API Cache" from the drop down menu in the top left.');
		}
		this.state = this.props.property;
		this.state.value = this.props.value; //Name of File
		this.state.file = null;
		this.state.error = this.props.error;

		this.accept = this.props.accept ? this.props.accept : ['.png', '.jpeg', '.jpg'];
		this.maxSize = this.props.maxSize ? this.props.maxSize : 4e+6; //4MB
	}

	handleFiles = files => {
		if( files && files[0] ) {
			files.length > 1 ?
				this.props.update(this.state.name, files)
				: this.props.update(this.state.name, files[0]);
		}
	};

	dropzoneRef = createRef();
	openDialog = () => {
		// Note that the ref is set async,
		// so it might be null at some point
		if (this.dropzoneRef.current) {
			this.dropzoneRef.current.open()
		}
	};

	onDropRejected = (files, event) => {
		files.forEach(file => {
			let errors="";
			file.errors.forEach(error=>{
				if(errors !== "") errors += "|" + error.message;
				else errors = error.message;
			})
			alert('There was an error(s) uploading your file: \n'+errors);
		})
	};

	render() {
		return (
			<div>
				{ this.props.button ?
					<Dropzone
						onDrop={this.handleFiles}
						accept={this.accept}
						maxSize={this.maxSize}
						onDropRejected={this.onDropRejected}
					>
						{DropZoneChildButton()}
					</Dropzone> : null
				}
				<Dropzone
					onDrop={this.handleFiles}
					accept={this.accept}
					maxSize={this.maxSize}
					onDropRejected={this.onDropRejected}
					label={this.props.label}
				>
					{DropZoneChild(this.props.label)}
				</Dropzone>
			</div>
		)
	}
}
