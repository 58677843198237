import React from 'react';
import {Link, Router} from 'react-router-dom';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'


export default class NavGroup extends React.Component {
	constructor(props) {
		super(props);
		let childrenCount = React.Children.count(this.props.children)
		this.state = {showSublist: this.props.default === 'open', childLinks: childrenCount > 1 ? this.props.children.map((child) => {
			if(child !== false) {
				return child.props.children.props.to
			}
		})
				: childrenCount > 0 ? [this.props.children.props.to]
				: []}; //this gets all of the child links passed into the NavGroup
	}

	toggleSubnav = () => this.setState({showSublist: !this.state.showSublist})

	render() {
		const {key, parentTextVariant, parentTargetPath, parentId, parentIcon, parentLinkText} = this.props;
		const hasSublist = this.props.children;

		let isActive = this.props.path === this.props.parentTargetPath || (this.state.childLinks?.includes(this.props.path) && !this.state.showSublist)

		return (
			<Router history={this.props.history}>
				{hasSublist ?
				<>
					<li key={`nav-group ${key ?? Math.random()}`} className={isActive ? "link-container d-flex active" : "link-container d-flex"} >
						<Link variant={parentTextVariant ?? 'text'} to={parentTargetPath} data-id={parentId}>
							{parentIcon && <i className={`fa fa-${parentIcon}`}/>}
							<span className={'nav-text'}>{parentLinkText}</span>
						</Link>
						{this.state.showSublist ? <KeyboardArrowDown className={'sublist-indicator'} onClick={this.toggleSubnav} style={{alignSelf: 'center'}}/>
							: <KeyboardArrowRight className={'sublist-indicator'} onClick={this.toggleSubnav} style={{alignSelf: 'center'}}/>}
					</li>
					<ul className={`sub${this.state.showSublist ? " show" : ""}`}>
						{this.props.children}
					</ul>
				</>
				:
				<li key={`nav-group ${key ?? Math.random()}`} className={isActive ? "link-container active" : "link-container"} >
					<Link variant={parentTextVariant ?? 'text'} to={parentTargetPath} data-id={parentId}>
						{parentIcon && <i className={`fa fa-${parentIcon}`}/>}
						<span className={"nav-text"}>{parentLinkText}</span>
					</Link>
				</li>
				}
			</Router>
		);
	}
}
