import React from 'react';
import PropTypes from 'prop-types';

import {Breadcrumb} from '../Elements';

class PageBar extends React.PureComponent {
	static propTypes = {
		breadcrumbs: PropTypes.array.isRequired,
	}

	static defaultProps = {
		breadcrumbs: [],
	};

	render() {
		let {breadcrumbs, children, history} = this.props;

		return (
			<div className="page-bar">
				<ul className="page-breadcrumb">
					{breadcrumbs.map((breadcrumb) => {
						let {name, url, icon} = breadcrumb;

						return (
							<Breadcrumb
								key={name}
								name={name}
								url={url}
								icon={icon}
								history={history}
							/>
						);
					})}
				</ul>
				<div className="page-toolbar">
					{children}
				</div>
			</div>
		);
	}
}

export default PageBar;
