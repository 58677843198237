import React from 'react';
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";
import {Collections, Elements, Objects} from "components"
import Framework from "@beardeddevops/react.framework";
import APPCONFIG from "../../constants/Config";
import API from "@beardeddevops/react.api";
import Types from "../../constants/Types";


export default class LayawayView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			key: Math.random()
		}
	}

	render() {
		return <>
			<Card>
				<CardContent>
					<div className={'row'}>
						<div className={'col-lg-7'}>
							<Objects.Layaway
								ref={this.props.refTransaction}
								key={this.state.key}
								id={this.props.id}
								history={this.props.history}
								afterSave={() => this.setState({key: Math.random()})}
								setRemaining={this.props.setRemaining}
							/>
						</div>
						<div className={'col-lg-5'}>
							<Elements.LayawayItems
								ref={this.props.refTransactionItems}
								key={this.state.key}
								layaway={this.props.id}
								updateEntries={() => this.setState({key: Math.random()})}
								history={this.props.history}
							/>
						</div>
					</div>
				</CardContent>
			</Card>

		</>

	}
}

