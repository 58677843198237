import React from 'react';
import { Link } from 'react-router-dom';
import APPCONFIG from 'constants/Config';
import Framework from "@beardeddevops/react.framework";
import NavLeftList from './NavLeftList';
import NavRightList from './NavRightList';
import PropTypes from "prop-types";

const FrameworkHeader = Framework.Components.withRootState(Framework.Elements.Header)

export default class Header extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		items: PropTypes.number
	};

	sidebarBtn;

	componentDidMount() {
	}

	render() {

		const navLeft = <NavLeftList locations={this.props.locations} history={this.props.history} reloadShell={this.props.reloadShell} items={this.props.items} />

		const navRight = <NavRightList name={this.props.name} locations={this.props.locations} history={this.props.history} reloadShell={this.props.reloadShell}/>

		return (
			<FrameworkHeader
				shouldShowBrand={true}
				brandLink={<Link to="/" className="brand">{APPCONFIG.brand}</Link>}
				navLeft={navLeft}
				navRight={navRight}
				pin={false}
			/>
			// <section className="app-header no-print">
			// 	<div
			// 		className='app-header-inner bg-color-dark'>
			// 		{/*app-header-inner*/}
			// 		<div className="d-lg-none d-xl-none float-left">
			// 			<a href="javascript:" className="md-button header-icon toggle-sidebar-btn" ref={(c) => { this.sidebarBtn = c; }}>
			// 				<i className="material-icons">menu</i>
			// 			</a>
			// 		</div>
			//
			// 		<div className="brand d-none d-lg-inline-block d-xl-inline-block">
			// 			<h2><Link to="/">{APPCONFIG.brand}</Link></h2>
			// 		</div>
			//
			// 		<div className="top-nav-left d-none d-lg-inline-block d-xl-inline-block">
			// 			<NavLeftList
			// 				locations={this.props.locations}
			// 				history={this.props.history}
			// 				reloadShell={this.props.reloadShell}
			// 				items={this.props.items} />
			// 		</div>
			//
			// 		<div className="top-nav-right">
			// 			<NavRightList name={this.props.name} locations={this.props.locations} history={this.props.history} reloadShell={this.props.reloadShell}/>
			// 		</div>
			// 	</div>
			// </section>
		);
	}
}
