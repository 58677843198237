import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import ClipLoader from 'react-spinners/ClipLoader';
import moment from "moment";
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

export default class InventoryLedgers extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Inventories_Ledgers._name, API.RequestType.READ_ALL);
		model.parent = props.parent;
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, loading,limit} = this.state;
		/**
		 * @type {Object} r
		 * @property {object} sale
		 */
		let columns =[
			{
				Header: "Quantity",
				id: "quantity",
				accessor: r => r.quantity
			},

			//Todo: Maybe figure out the accessor logic on the backend and receive it as a field to sort by
			{
				Header: "Source",
				id: "source",
				accessor: r => r.source = r.sale ? 'Sale' : (r.quantity > 0 ? 'Restocking' : 'Manual Adjustment')
			},

			//Todo: Not possible to sort this column
			{
				Header: "User",
				id: "user",
				/** @var r.users */
				accessor: r => r.users != null ? `${r.users.name_first} ${r.users.name_last} (${r.users.email})` : this.props.account,
				Cell: (props) => <Link to={"/users/" + props.original?.users?.pkey}>{props.value}</Link>
			},
			{
				Header: "Created",
				id: "created",
				accessor: d => {
					return moment(d.created)
						.local()
						.format("MM/DD/YYYY hh:mm A")
				}
			},
		];

		return (
			<Card>
				<CardContent>
					<div className="box-body">
						<h3>
							Inventory Ledger

						</h3>
						<div className="table-bar">
							<div className="d-flex">
								{this.renderSearch("Inventory Ledgers")}
								{this.renderHeaderRight("Inventory Ledger",columns)}
							</div>
						</div>

						<ReactTable
							key={this.state.key}
							columns={columns}
							manual // Forces table not to paginate or sort automatically, so we can handle it server-side
							data={data}
							pages={pages} // Display the total number of pages
							loading={loading} // Display the loading overlay when we need it
							loadingText="Requesting Data"
							onFetchData={this.fetchData} // Request new data when things change
							defaultSorted={[
								{
									id: this.model.sort,
									desc: this.model.order === 'desc'
								}
							]}
							expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
							}}
							onPageSizeChange={this.onPageSizeChange}
							showPagination= {true}
							showPaginationTop= {false}
							showPaginationBottom= {true}
							showPageSizeOptions= {true}
							defaultPageSize={limit}
							className="-striped -highlight"
						/>
					</div>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
