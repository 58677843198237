import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import API from "@beardeddevops/react.api";

export default class _Base extends React.Component {
	static getDerivedStateFromProps = (props, state) => {
		const nextState = {};

		if (props.value !== state.value) {
			nextState.value = props.value;
		}

		if (props.error !== state.error) {
			nextState.error = props.error;
		}

		return nextState;
	};

	static propTypes = {
		property: PropTypes.object,
		update: PropTypes.func.isRequired,
		label: PropTypes.string,
		handleFocus: PropTypes.func,
		multiline: PropTypes.number,
		margin: PropTypes.string,
		defaultValue: PropTypes.string,
		disabled: PropTypes.bool,
		appendTime: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = this.props.property || {};
		this.state.value = this.props.value;
		this.state.error = this.props.error;
		const {property, value, error, label, update, handleFocus, multiline, margin, ...rest} = props;
		this.passThroughProps = rest;
		this.state.validation_type = null;
	}

	passThroughProps;

	handleDataChange = event => {
		this.props.update(this.state.name, event.target.value);
	};
	handleCheckedChangeFor = async event => {
		this.props.update(this.state.name, event.target.value);
	};
	/**
	 * @param momentDate moment
	 * @param value string
	 */
	handleDateChange = (momentDate, value) => {
		if( typeof momentDate === 'string' ) {
			value = momentDate;
			momentDate = null;
		}
		if( momentDate ) {
			if( this.props.appendTime ) {
				let date = this.passThroughProps.defaultTimezone ? momentDate.tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD") : momentDate.local().format("YYYY-MM-DD");
				let time = this.passThroughProps.defaultTimezone ? moment.tz(this.passThroughProps.defaultTimezone).format('HH:mm:ss'): momentDate.local().format('HH:mm:ss');
				this.props.update(this.state.name, date ? date+' '+time : null);
			}
			else {
				this.props.update(this.state.name, momentDate ? this.passThroughProps.defaultTimezone ? momentDate.tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD HH:mm:ss") : momentDate.local().format("YYYY-MM-DD HH:mm:ss") : null);
			}
		}
		else if( typeof value === 'string' && ((this.props.appendTime && value.match(/\d\d\/\d\d\/\d\d\d\d/)) || value.match(/(\d\d\/\d\d\/\d\d\d\d \d\d:\d\d [PApa]$)/) ) ) {
			if( this.props.appendTime ) {
				let date = this.passThroughProps.defaultTimezone ? moment(value, 'MM/DD/YYYY').tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD") : moment(value, 'MM/DD/YYYY').local().format("YYYY-MM-DD");
				let time = this.passThroughProps.defaultTimezone ? moment().tz(this.passThroughProps.defaultTimezone).format('HH:mm:ss') : moment().local().format('HH:mm:ss');
				this.props.update(this.state.name, date ? date+' '+time : null);
			}
			else {
				this.props.update(this.state.name, value ? this.passThroughProps.defaultTimezone ? moment(value).tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD HH:mm:ss") : moment(value).local().format("YYYY-MM-DD HH:mm:ss") : null);
			}
		}
		else if( typeof value === 'string' && ((this.props.appendTime && value.match(/\d\d\d\d-\d\d-\d\d/)) || value.match(/\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d/) ) ) {
			if( this.props.appendTime ) {
				let date = this.passThroughProps.defaultTimezone ? moment(value, 'YYYY-MM-DD').tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD") : moment(value, 'YYYY-MM-DD').local().format("YYYY-MM-DD");
				let time = this.passThroughProps.defaultTimezone ? moment().tz(this.passThroughProps.defaultTimezone).format('HH:mm:ss') : moment().local().format('HH:mm:ss');
				this.props.update(this.state.name, date ? date+' '+time : null);
			}
			else {
				this.props.update(this.state.name, value ? this.passThroughProps.defaultTimezone ? moment(value).tz(this.passThroughProps.defaultTimezone).format("YYYY-MM-DD HH:mm:ss") : moment(value).local().format("YYYY-MM-DD HH:mm:ss") : null);
			}
		}
		else {
			this.props.update(this.state.name, value);
		}
	};

	handleProcess = event => {
		// Clear Error first
		let name = event.target.name;
		API.Validator.constructor.process_error(this, name, '');

		// Then validate
		API.Validator.processItem(this, name);
	};

}
