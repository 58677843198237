import React from 'react';
import ErrorBoundary from '../Errors/General';
import {isValidPkey} from "../Utilities";
import {TrackJS} from "trackjs";

export default class ObjectState extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			refresh: false,
		};

		let originalRender = this.render;

		// this.render = () => {
		// 	try {
		// 		return originalRender.call(this);
		// 	} catch (e) {
		// 		if (process.env.production) {
		// 			TrackJS.track(e);
		// 		}
		// 		console.log(e);
		//
		// 		class Wrapper extends React.Component {
		// 			render() {
		// 				throw new Error(e);
		// 			}
		// 		}
		//
		// 		return (
		// 			<ErrorBoundary isDev={!process.env.production}>
		// 				<Wrapper/>
		// 			</ErrorBoundary>
		// 		)
		// 	}
		// }
	}

	loadIDs = () => {
		let props = this.props;
		let pkeyValid = isValidPkey(props.id);

		this.state.pkey = pkeyValid ? props.id : "";
		this.state.binary_id = pkeyValid && props.binary_id ? props.binary_id : "";
		if( !this.state.binary_id && this.state.pkey && this.state.pkey.toString().indexOf('-') !== -1 ) {
			let splitParts = this.state.pkey.toString().split('-');
			this.state.pkey = splitParts[1];
			this.state.binary_id = splitParts[0];
		}

		this.model.parent = props.parent ? props.parent : null;
		this.model.parent_binary_id = props.parent_binary_id ? props.parent_binary_id : null;
		if( !this.model.parent_binary_id && this.model.parent && this.model.parent.toString().indexOf('-') !== -1 ) {
			let splitParts = this.model.parent.toString().split('-');
			this.model.parent = splitParts[1];
			this.model.parent_binary_id = splitParts[0];
		}

		this.model.grandparent = props.grandparent ? props.grandparent : null;
		this.model.grandparent_binary_id = props.grandparent_binary_id ? props.grandparent_binary_id : null;
		if( !this.model.grandparent_binary_id && this.model.grandparent && this.model.grandparent.toString().indexOf('-') !== -1 ) {
			let splitParts = this.model.grandparent.toString().split('-');
			this.model.grandparent = splitParts[1];
			this.model.grandparent_binary_id = splitParts[0];
		}
	}

	/**
	 *
	 * @param prevProps
	 * @param type
	 * @param force
	 * @returns {boolean} True if entity has changed.
	 */
	reloadIDs = (prevProps, type, force = false) => {
		if (prevProps.id !== this.props.id ||
			prevProps.binary_id !== this.props.binary_id ||
			prevProps.parent !== this.props.parent ||
			prevProps.parent_binary_id !== this.props.parent_binary_id ||
			prevProps.grandparent !== this.props.grandparent ||
			prevProps.grandparent_binary_id !== this.props.grandparent_binary_id ||
			prevProps.reloadSelf !== this.props.reloadSelf ||
			force
		) {
			this.setState(this.model.object, () => {
				let props = this.props;
				let pkeyValid = isValidPkey(props.id);

				this.state.pkey = pkeyValid ? props.id : "";
				this.state.binary_id = pkeyValid && props.binary_id ? props.binary_id : "";
				if (!this.state.binary_id && this.state.pkey && this.state.pkey.toString().indexOf('-') > -1) {
					let splitParts = this.state.pkey.toString().split('-');
					this.state.pkey = splitParts[1];
					this.state.binary_id = splitParts[0];
				}

				this.model.parent = props.parent ? props.parent : null;
				this.model.parent_binary_id = props.parent_binary_id ? props.parent_binary_id : null;
				if( !this.model.parent_binary_id && this.model.parent && this.model.parent.toString().indexOf('-') !== -1 ) {
					let splitParts = this.model.parent.toString().split('-');
					this.model.parent = splitParts[1];
					this.model.parent_binary_id = splitParts[0];
				}

				this.model.grandparent = props.grandparent ? props.grandparent : null;
				this.model.grandparent_binary_id = props.grandparent_binary_id ? props.grandparent_binary_id : null;
				if( !this.model.grandparent_binary_id && this.model.grandparent && this.model.grandparent.toString().indexOf('-') !== -1 ) {
					let splitParts = this.model.grandparent.toString().split('-');
					this.model.grandparent = splitParts[1];
					this.model.grandparent_binary_id = splitParts[0];
				}

				if( prevProps.params !== this.props.params ) {
					if( props.params ) {
						this.setState({...this.state, ...props.params});
					}
				}

				//this.read = API.RequestType.READ;
				//this.update = typeof this.state.pkey !== 'undefined' && this.state.pkey ? API.RequestType.UPDATE : API.RequestType.CREATE;
				//this.delete = API.RequestType.DELETE;

				if(type === 'object') {
					this.loadSelf().then(() => {
					});
				}
				//TODO: Verify the collection has this covered now.
				// if(type === 'collection') {
				// 	this.reloadTable();
				// }
			});
			return true;
		}
		return false;
	}

	toggle = (name) => {
		if( typeof this.state[name] === 'undefined' ) {
			this.state[name] = false;
		}
		return () => {
			this.setState({[name]: true});
		};
	}
	reportClose = (name) => () => {
		this.setState({[name]: false});
	}

	refreshPage = () => {
		this.setState({refresh: !this.state.refresh});
	};

	handleAfterSave = () => {
		this.setState({refresh: !this.state.refresh});
	}

}
