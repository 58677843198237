import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from "constants/Types";

import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@material-ui/core";
import {Button} from "reactstrap";


export default class Registers extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Registers._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, loading,limit} = this.state;
		let columns = [
			{
				Header: "Name",
				accessor: "name",
				Cell: props =><span>{props.value}</span>
			},
			{
				Header: "Current Amount",
				accessor: "amount_current",
				Cell: props => <span>${props.value}</span>
			},
			{
				Header: "Location",
				id: "location",
				accessor: r => r.locations.name,
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`/registers/`+ props.original.pkey+"#details"}>
								<Button color="green-meadow" size={"sm"} block={true}>
									<i className={"fa fa-pencil"}/></Button>
							</Link>
						</span>
					);
				}
			},
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<span>
							<Link to={`/registers/`+ props.original.pkey+"#ledger"}>
								<Button color="secondary" size={"sm"} block={true}>
									<i className={"fa fa-file-text-o"}/></Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (
			<Card>
				<CardContent>

					<div className="table-bar">
						<div className="d-flex">
							{this.renderHeaderRight("Registers",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change

						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						className="-striped -highlight"
						onPageSizeChange={this.onPageSizeChange}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
					/>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
