import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Elements} from "components";

import {quantity} from "echarts/lib/util/number";

export default class LayawayItems extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Inventories._name, API.RequestType.READ_ALL);
		super(props, model);
		if (this.props.inStock) {
			this.request = "GET_IN_STOCK";
		}
		this.state.limit = 16;
		this.limit= 16;
		this.state.items=[]
		this.state.items_error=[];
		this.afterSubmit = this.afterSubmit.bind(this);
	}
	addItem = (e) => {
		const {data, items} = this.state;
		const pkey = parseInt(e.currentTarget.id);
		let found = items.find((entry) => entry.pkey === pkey);

		if (!found) {
			const item = data.find((entry) => entry.pkey === pkey);
			let Litem ={...item}
			if (Litem) {
				Litem.quantity = 1
				items.push(Litem);


			}
		} else {
			if (found) {
				let index = items.findIndex((entry)=>entry.pkey === pkey)
				items[index].quantity +=1
			}
		}
		this.setState({
			items,
			items_error: ""
		});
	};
	removeItem = (e) => {
		const {items} = this.state;
		const pkey = parseInt(e.currentTarget.id);
		const existingItemIndex = items.findIndex((entry) => entry.pkey === pkey);
		if (existingItemIndex > -1) {
			items.splice(existingItemIndex, 1);
		}

		this.setState({
			items,
			items_error: '',
		});
	};
	async afterSubmit(data, request, suppressProcess = false) {
		if (typeof this.props.afterSave !== 'undefined') {
			this.props.afterSave(this.state.items);
		}
	};
	table =()=>{
		const {data, pages, loading,limit} = this.state;
		let columns =[
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<Button
							id={props.original.pkey}
							color="blue-hoki"
							size={"sm"}
							block={true}
							onClick={this.addItem}
							>
							<i className={"fa fa-plus"}/>
						</Button>
					);
				}
			},
			{
				Header: "Name",
				id: "name",
				accessor: r => r.skus.name,
				Cell: props => <Link to={{pathname: "/inventory/" + props.original.pkey, inventoryProps: {parent: props.original.pkey}}}>{props.value}</Link>
			},
			{
				Header: "SKU",
				id: "sku",
				accessor: r => r.skus.sku,
				Cell: props => <Link to={"/skus/" + props.original.skus.pkey}>{props.value}</Link>
			},
			{
				Header: "Inventory Group",
				id: "sku_class",
				accessor: r => r.skus.sku_classes.name
			},
			{
				Header: "Price",
				id: "price",
				width: 100,
				accessor: r => r.skus.price_retail,
				Cell: props => <span>${props.value}</span>
			},
			{
				Header: "Quantity",
				id: "quantity",
				width: 100,
				filterable: false,
				accessor: r => r.skus.serialized === false ? r.quantity : r.inventory_serializeds.length
			},

		]
		return(
			<>
				<div className={'row'}>
					<div className={'col-lg-8'}>
						<div className="table-bar">
							<div className="d-flex">
								{this.renderSearch('Products')}
								{this.renderHeaderRight("Products",columns)}
							</div>
						</div>
						<ReactTable
							key={this.state.key}
							columns={columns}
							manual // Forces table not to paginate or sort automatically, so we can handle it server-side
							data={data}
							pages={pages} // Display the total number of pages
							loading={loading} // Display the loading overlay when we need it
							loadingText="Requesting Data"
							onFetchData={this.fetchData} // Request new data when things change
							onPageSizeChange={this.onPageSizeChange}
							onSortedChange={this.onSortedChange}
							page={this.state.page}
							onPageChange={this.onPageChange}
							defaultSorted={[
								{
									id: this.model.sort,
									desc: this.model.order === 'desc'
								}
							]}
							expanderDefaults={{
								sortable: true,
								resizable: true,
								filterable: false,
								width: undefined
							}}
							showPagination={true}
							showPaginationTop={false}
							showPaginationBottom={true}
							showPageSizeOptions={true}
							defaultPageSize={limit}
							className="-striped -highlight"
						/>
					</div>
					<div className={'col-lg-4'}>
						<h4 className="section-title">Selected Items</h4>
						{this.state.items_error &&
						<div style={{
							"padding": "0px 4px 4px",
							"fontStyle": "italic",
							"fontSize": "12px",
							"color": "#ef534f",
							"textAlign": "center"
						}}>
							{this.state.items_error}
						</div>
						}
						{this.state.items.map((item, i) => {
							return (
								<Elements.ItemLookup
									key={i}
									product={item}
									remove={this.removeItem}/>
							);
						})}
					</div>
					{APPCONFIG.settings.isDev() ? null :
						<Framework.Elements.LoadingSpinner isLoading={loading}/>
					}
				</div>

			</>
		)

	}
	render() {
		let hasModal = typeof this.props.hasModal !== 'undefined' ? this.props.hasModal : false;
		if (hasModal) {
			return this.table();
		}
		return (
			<Card>
				<CardContent>
					{this.table()}
				</CardContent>
			</Card>
		)
	}
}
