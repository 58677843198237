import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';
import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import moment from "moment";
import {Card, CardContent} from "@material-ui/core";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export default class SalesTaxReports extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Locations_Sales._name, API.RequestType.READ_ALL);
		super(props, model);
		this.limit = 1;
		this.state.limit = 1;

		try {
			if (localStorage.getItem('dateSalesTax')) {
				this.state.filterDate = moment((localStorage.getItem('dateSalesTax'))).format();
			} else {
				this.state.filterDate = moment(Date.now()).format();
			}
		} catch (e) {
			console.log(e, 'error')
		}
		this.state.showDate = false;
		this.state.showStartDate = false;
		this.state.showEndDate = false;
		this.state.showDateRange = false;

		this.state.end_date = moment(Date.now()).format('YYYY-MM-DD');
		this.state.start_date = moment(Date.now()).subtract(30, 'days').format('YYYY-MM-DD');
		this.state.dateRangeFormatted = moment(this.state.start_date).format('dddd MMM D') + ' - ' + moment(this.state.end_date).format('dddd MMM D');

	}

	async componentDidMount() {
		await this.getReport();
	}

	handleToggleModal = (modal) => {
		this.setState({[modal]: !this.state[modal]})
	}

	handleDateChange = async (name, date) => {
		this.state.filterDate = moment(date).format();
		await this.setState({filterDate: moment(date).format()});
		this.handleToggleModal('showDate');
		await this.getReport();
	}

	getReport = async () => {
		let model = new API.BuildClass(Types.Locations_Sales._name, API.RequestType.READ);
		this.read = 'GET_ALL';
		model.filter = {
			'day': this.state.filterDate
		}
		this.state.filter = model.filter;
		model.filter.start_date = this.state.start_date;
		model.filter.end_date = this.state.end_date;
		this.state.dateRangeFormatted = moment(this.state.start_date).format('dddd MMM D') + ' - ' + moment(this.state.end_date).format('dddd MMM D')
		model.search = this.state.search
		this.data = await model.submit(null, this.read);
		// this.limit = this.data.total;
		this.data = this.data.items;
		//TODO whenever we allow for more than 1 location, this limit will need to change
		await this.setState({data: this.data, key: Math.random()});
		localStorage.setItem("dateSalesTax", this.state.filterDate);
	}

	render() {
		const {data, pages, loading, limit} = this.state;
		let columns = [
			{
				Header: "Location Name",
				id: "name",
				accessor: r => (r.name?.charAt(0).toUpperCase() + r.name?.slice(1)),
				sortable: false
			},
			{
				Header: "Total Sales",
				id: "total_sales",
				accessor: r => ("$" + r?.total_sales),
				sortable: false
			},
			{
				Header: "Non Taxable Sales",
				id: "non_taxable",
				accessor: r => ("$" + r?.non_taxable),
				sortable: false
			}, {
				Header: "Taxable Sales",
				id: "taxable_sales",
				accessor: r => ("$" + r?.taxable_sales),
				sortable: false
			}, {
				Header: "Tax Rate",
				id: "tax_rate",
				accessor: r => (parseFloat(r?.tax_rate) + "%"),
				sortable: false
			}, {
				Header: "Total Sales Tax",
				id: "total_tax",
				accessor: r => ("$" + r?.total_tax),
				sortable: false
			}, {
				Header: "Total CC Fee",
				id: "cc_fees",
				accessor: r => ("$" + r?.cc_fees),
				sortable: false
			},
		];

		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<Modal
							isOpen={this.state.showDate}
							toggle={() => this.handleToggleModal('showDate')}
							size={"sm"}
							backdrop={true}
							style={{display: "none"}}
						>
							<ModalBody>
								<Framework.Elements.Date
									label={"Custom Date"}
									value={this.state.filterDate}
									update={this.handleDateChange}
									open={this.state.showDate}
									clearable={false}
									cancelLabel={''}
								/>
							</ModalBody>
						</Modal>
						<Modal
							isOpen={this.state.showDateRange}
							toggle={() => this.handleToggleModal('showDateRange')}
							size={"lg"}
							backdrop={true}
						>
							<ModalHeader>
								Select Start and End Date
							</ModalHeader>
							<ModalBody>
								<div className={'row'}>
									<div className={'col-6'}>
										<div>
											Start Date &nbsp;&nbsp;
											<Button onClick={() => {
												this.handleToggleModal('showStartDate')
											}}>
												{moment(this.state.start_date).format('dddd MMM D')}
											</Button>
										</div>
									</div>
									<div className={'col-6'}>
										<div>
											End Date &nbsp;&nbsp;
											<Button onClick={() => {
												this.handleToggleModal('showEndDate')
											}}>
												{moment(this.state.end_date).format('dddd MMM D')}
											</Button>
										</div>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button color={'primary'} onClick={() => {
									this.handleToggleModal('showDateRange')
									this.getReport().then()
								}}>
									OK
								</Button>
								<Button color={'secondary'} onClick={() => {
									const today = moment(Date.now()).format('YYYY-MM-DD');
									this.setState({start_date: today, end_date: today})
									this.handleToggleModal('showDateRange')
								}}>
									Cancel
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							isOpen={this.state.showStartDate}
							toggle={this.toggleClose}
							size={"sm"}
							backdrop={true}
							style={{display: "none"}}
						>
							<ModalBody>
								<Framework.Elements.Date
									label={"Custom Date"}
									value={this.state.start_date}
									update={(name, date) => {
										if (moment(date).isAfter(moment(this.state.end_date))) {
											alert('Start date may not be after the end date.')
											this.handleToggleModal('showStartDate');
											return;
										}
										this.state.start_date = moment(date).format('YYYY-MM-DD');
										this.handleToggleModal('showStartDate');
									}}
									open={this.state.showStartDate}
									clearable={false}
									cancelLabel={''}
								/>
							</ModalBody>
						</Modal>
						<Modal
							isOpen={this.state.showEndDate}
							toggle={this.toggleClose}
							size={"sm"}
							backdrop={true}
							style={{display: "none"}}
						>
							<ModalBody>
								<Framework.Elements.Date
									label={"Custom Date"}
									value={this.state.end_date}
									update={(name, date) => {
										if (moment(date).isBefore(moment(this.state.start_date))) {
											alert('End date may not be before the start date.')
											this.handleToggleModal('showEndDate');
											return;
										}
										this.state.end_date = moment(date).format('YYYY-MM-DD');
										this.handleToggleModal('showEndDate');
									}}
									open={this.state.showEndDate}
									clearable={false}
									cancelLabel={''}
								/>
							</ModalBody>
						</Modal>
						<div className={"row rbc-toolbar"}
						     style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
							<div className={'col-2'}>
								<Button onClick={() => {
									this.setState({showDateRange: true})
								}}>
									Select Date Range
								</Button>
							</div>
							<div className={"rbc-toolbar-label"}>
								<label onClick={() => {
									this.handleToggleModal('showDate')
								}}>
									{this.state.dateRangeFormatted ? this.state.dateRangeFormatted : moment(this.state.filterDate).format('dddd MMM D')}
								</label>
							</div>
						</div>
					</div>
					{data === 'undefined' || data[0]?.pkey === null ?
						<div>
							There is no data for the dates selected.
						</div>
						:
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages}
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'ascending'
							}
						]}
						className="-striped -highlight"
						showPaginationTop={false}
						showPaginationBottom={false}
						showPageSizeOptions={true}
						defaultPageSize={limit}
					/>
					}
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}
