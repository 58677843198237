import React from "react";
import TextField from "@material-ui/core/TextField";
import _Base from "./_Base";
import {v4 as uuid} from 'uuid';

export default class _EmailAddress extends _Base {

	static propTypes = {
		..._Base.propTypes,
	}

	static defaultProps = {
		margin: 'dense',
		variant: 'outlined',
	}

	constructor(props) {
		super(props);

		// Generate a new autoComplete ID for every TextField instance to prevent browsers from autofilling form fields
		this.autoCompleteToken = uuid();
		this.state.validation_type = this.props.type || 'email';
	}

	render() {
		let {type} = this.props;
		let property = this.state;
		let multiline = this.props.multiline;
		let passThroughProps = this.passThroughProps;
		let additionalProps = {};
		if (multiline) {
			additionalProps.rows = multiline;
			additionalProps.style = {marginTop: '20px'};
		}
		if (property.value == null) {
			property.value = "";
		}

		type = type || 'email';

		return (
			<TextField
				{...additionalProps}
				{...passThroughProps}
				autoComplete={this.autoCompleteToken}
				type={type}
				validation_type={this.validation_type}
				value={property.value}
				error={property.error !== ""}
				helperText={property.error}
				onChange={this.handleDataChange}
				onBlur={this.handleProcess}
				inputProps={{name: property.name}}
				label={this.props.label||property.label}
				fullWidth={true}
				margin={this.props.margin}
				variant={this.props.variant}
				multiline={!!multiline}
			/>
		)
	}
}