/**
 @typedef {Object} ScannedCode
 @property {string} prefix
 @property {string} barcode
 */
/**
 @typedef {Object.<any>} Property_Generic
 @property {string} value
 @property {string} error
 @property {string} type
 @property {bool} required
 @property {bool} update
 @property {string} label
 @property {string} helper
 @property {array} set
 @property {bool} searchable
 @property {bool} queryable
 @property {bool} visible
 @property {?string} override
 @property {?string} reference
 @property {Object} range
 @property {name} pkey
 */
/**
 @typedef {Object.<any>} Accounts
 @property {Accounts_Properties} properties
 @property {Accounts_Has} has
 @property {Accounts_Is} is
 @property {Accounts_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {email} email
 @property {number|int} external_id
 @property {number|int} external_service
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name_first
 @property {text|string} name_last
 @property {string} password
 @property {string} password_temp
 @property {number|int} pkey
 @property {string} password_new
 @property {string} password_re
 @property {text|string} reference_id
 */
/**
 @typedef {Object.<any>} Accounts_Has
 */
/**
 @typedef {Object.<any>} Accounts_Is
 @property {External_Services_Object} external_services
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?email} email
 @property {string} email_error
 @property {?number|int} external_id
 @property {string} external_id_error
 @property {?number|int} external_service
 @property {string} external_service_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name_first
 @property {string} name_first_error
 @property {?text|string} name_last
 @property {string} name_last_error
 @property {?string} password
 @property {string} password_error
 @property {?string} password_temp
 @property {string} password_temp_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?string} password_new
 @property {string} password_new_error
 @property {?string} password_re
 @property {string} password_re_error
 @property {?text|string} reference_id
 @property {string} reference_id_error
 @property {External_Services_Object} external_services
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Billing
 @property {Accounts_Billing_Properties} properties
 @property {Accounts_Billing_Has} has
 @property {Accounts_Billing_Is} is
 @property {Accounts_Billing_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Billing_Properties
 @property {text|string} token
 */
/**
 @typedef {Object.<any>} Accounts_Billing_Has
 */
/**
 @typedef {Object.<any>} Accounts_Billing_Is
 */
/**
 @typedef {Object.<any>} Accounts_Billing_Object
 @property {?text|string} token
 @property {string} token_error
 */
/**
 @typedef {Object.<any>} Accounts_Create
 @property {Accounts_Create_Properties} properties
 @property {Accounts_Create_Has} has
 @property {Accounts_Create_Is} is
 @property {Accounts_Create_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Create_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} city
 @property {text|string} description
 @property {email} email
 @property {text|string} name
 @property {text|string} name_first
 @property {text|string} name_last
 @property {string} password
 @property {string} password_re
 @property {text|string} phone
 @property {number|int} processor
 @property {number|int} quantity
 @property {text|string} reference_id
 @property {text|string} state
 @property {text|string} store_number
 @property {text|string} street_1
 @property {text|string} street_2
 @property {number|int|float} tax_rate_city
 @property {number|int|float} tax_rate_county
 @property {number|int|float} tax_rate_special
 @property {number|int|float} tax_rate_state
 @property {boolean} tos
 @property {text|string} zip
 */
/**
 @typedef {Object.<any>} Accounts_Create_Has
 */
/**
 @typedef {Object.<any>} Accounts_Create_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Create_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} city
 @property {string} city_error
 @property {?text|string} description
 @property {string} description_error
 @property {?email} email
 @property {string} email_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} name_first
 @property {string} name_first_error
 @property {?text|string} name_last
 @property {string} name_last_error
 @property {?string} password
 @property {string} password_error
 @property {?string} password_re
 @property {string} password_re_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {?number|int} processor
 @property {string} processor_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} reference_id
 @property {string} reference_id_error
 @property {?text|string} state
 @property {string} state_error
 @property {?text|string} store_number
 @property {string} store_number_error
 @property {?text|string} street_1
 @property {string} street_1_error
 @property {?text|string} street_2
 @property {string} street_2_error
 @property {?number|int|float} tax_rate_city
 @property {string} tax_rate_city_error
 @property {?number|int|float} tax_rate_county
 @property {string} tax_rate_county_error
 @property {?number|int|float} tax_rate_special
 @property {string} tax_rate_special_error
 @property {?number|int|float} tax_rate_state
 @property {string} tax_rate_state_error
 @property {?boolean} tos
 @property {string} tos_error
 @property {?text|string} zip
 @property {string} zip_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Customizations
 @property {Accounts_Customizations_Properties} properties
 @property {Accounts_Customizations_Has} has
 @property {Accounts_Customizations_Is} is
 @property {Accounts_Customizations_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Customizations_Properties
 @property {number|int} account
 @property {date|string} created
 @property {number|int} creator
 @property {string} logo
 @property {text|string} max_quantity
 @property {text|string} message
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} return_policy
 @property {boolean} show_consignments
 @property {boolean} show_layaways
 @property {text|string} warranty
 */
/**
 @typedef {Object.<any>} Accounts_Customizations_Has
 */
/**
 @typedef {Object.<any>} Accounts_Customizations_Is
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Customizations_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?string} logo
 @property {string} logo_error
 @property {?text|string} max_quantity
 @property {string} max_quantity_error
 @property {?text|string} message
 @property {string} message_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} return_policy
 @property {string} return_policy_error
 @property {?boolean} show_consignments
 @property {string} show_consignments_error
 @property {?boolean} show_layaways
 @property {string} show_layaways_error
 @property {?text|string} warranty
 @property {string} warranty_error
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Processors
 @property {Accounts_Processors_Properties} properties
 @property {Accounts_Processors_Has} has
 @property {Accounts_Processors_Is} is
 @property {Accounts_Processors_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Processors_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} reference_id
 */
/**
 @typedef {Object.<any>} Accounts_Processors_Has
 */
/**
 @typedef {Object.<any>} Accounts_Processors_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Processors_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} reference_id
 @property {string} reference_id_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Accounts_Subscriptions
 @property {Accounts_Subscriptions_Properties} properties
 @property {Accounts_Subscriptions_Has} has
 @property {Accounts_Subscriptions_Is} is
 @property {Accounts_Subscriptions_Object} object
 */
/**
 @typedef {Object.<any>} Accounts_Subscriptions_Properties
 @property {number|int} plan
 @property {number|int} quantity
 */
/**
 @typedef {Object.<any>} Accounts_Subscriptions_Has
 */
/**
 @typedef {Object.<any>} Accounts_Subscriptions_Is
 @property {Stripe_Plans_Object} stripe_plans
 */
/**
 @typedef {Object.<any>} Accounts_Subscriptions_Object
 @property {?number|int} plan
 @property {string} plan_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {Stripe_Plans_Object} stripe_plans
 */
/**
 @typedef {Object.<any>} Certificates
 @property {Certificates_Properties} properties
 @property {Certificates_Has} has
 @property {Certificates_Is} is
 @property {Certificates_Object} object
 */
/**
 @typedef {Object.<any>} Certificates_Properties
 @property {text|string} code
 @property {date|string} created
 @property {number|int} creator
 @property {email} email
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int|float} original_amount
 @property {text|string} phone
 @property {number|int} pkey
 @property {boolean} used
 @property {number|int|float} value
 */
/**
 @typedef {Object.<any>} Certificates_Has
 */
/**
 @typedef {Object.<any>} Certificates_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Certificates_Object
 @property {?text|string} code
 @property {string} code_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?email} email
 @property {string} email_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int|float} original_amount
 @property {string} original_amount_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?boolean} used
 @property {string} used_error
 @property {?number|int|float} value
 @property {string} value_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts
 @property {Checkouts_Properties} properties
 @property {Checkouts_Has} has
 @property {Checkouts_Is} is
 @property {Checkouts_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Properties
 @property {number|int|float} amount
 @property {number|int|float} cash_amount
 @property {number|int|float} cc_fee_amount
 @property {number|int} certificate
 @property {number|int|float} check_amount
 @property {text|string} client
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} description
 @property {number|int|float} discount_amount
 @property {number|int|float} discount_percentage
 @property {number|int|float} invoice_amount
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} notes
 @property {text|string} order_po_number
 @property {number|int|float} other_amount
 @property {number|int} pkey
 @property {number|int|float} processor_amount
 @property {text|string} receipt_email
 @property {number|int} register
 @property {text|string} salesman
 @property {text|string} alignment
 @property {number|int|float} card_amount
 @property {number|int|float} cc_fee_amount
 @property {array} changing_items
 @property {text|string} credit_card_cvc
 @property {text|string} credit_card_expiry
 @property {text|string} credit_card_name
 @property {text|string} credit_card_number
 @property {boolean} layaway
 @property {number|int} layawayPkey
 @property {array} product_information
 @property {number|int|float} remaining
 @property {text|string} sudo_card
 @property {number|int|float} tax_amount
 @property {boolean} taxable
 @property {text|string} terminal
 */
/**
 @typedef {Object.<any>} Checkouts_Has
 @property {Checkouts_Items_Object[]} 0
 @property {Checkouts_Products_Object[]} 1
 @property {Checkouts_Consignments_Object[]} 2
 @property {Checkouts_Trades_Object[]} 3
 @property {Checkouts_Layaways_Object[]} 4
 */
/**
 @typedef {Object.<any>} Checkouts_Is
 @property {Certificates_Object} certificates
 @property {Locations_Object} locations
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Object
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?number|int|float} cash_amount
 @property {string} cash_amount_error
 @property {?number|int|float} cc_fee_amount
 @property {string} cc_fee_amount_error
 @property {?number|int} certificate
 @property {string} certificate_error
 @property {?number|int|float} check_amount
 @property {string} check_amount_error
 @property {?text|string} client
 @property {string} client_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} description
 @property {string} description_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int|float} discount_percentage
 @property {string} discount_percentage_error
 @property {?number|int|float} invoice_amount
 @property {string} invoice_amount_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} notes
 @property {string} notes_error
 @property {?text|string} order_po_number
 @property {string} order_po_number_error
 @property {?number|int|float} other_amount
 @property {string} other_amount_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} processor_amount
 @property {string} processor_amount_error
 @property {?text|string} receipt_email
 @property {string} receipt_email_error
 @property {?number|int} register
 @property {string} register_error
 @property {?text|string} salesman
 @property {string} salesman_error
 @property {?text|string} alignment
 @property {string} alignment_error
 @property {?number|int|float} card_amount
 @property {string} card_amount_error
 @property {?number|int|float} cc_fee_amount
 @property {string} cc_fee_amount_error
 @property {?array} changing_items
 @property {string} changing_items_error
 @property {?text|string} credit_card_cvc
 @property {string} credit_card_cvc_error
 @property {?text|string} credit_card_expiry
 @property {string} credit_card_expiry_error
 @property {?text|string} credit_card_name
 @property {string} credit_card_name_error
 @property {?text|string} credit_card_number
 @property {string} credit_card_number_error
 @property {?boolean} layaway
 @property {string} layaway_error
 @property {?number|int} layawayPkey
 @property {string} layawayPkey_error
 @property {?array} product_information
 @property {string} product_information_error
 @property {?number|int|float} remaining
 @property {string} remaining_error
 @property {?text|string} sudo_card
 @property {string} sudo_card_error
 @property {?number|int|float} tax_amount
 @property {string} tax_amount_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?text|string} terminal
 @property {string} terminal_error
 @property {Checkouts_Items_Object[]} 0
 @property {Checkouts_Products_Object[]} 1
 @property {Checkouts_Consignments_Object[]} 2
 @property {Checkouts_Trades_Object[]} 3
 @property {Checkouts_Layaways_Object[]} 4
 @property {Certificates_Object} certificates
 @property {Locations_Object} locations
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Consignments
 @property {Checkouts_Consignments_Properties} properties
 @property {Checkouts_Consignments_Has} has
 @property {Checkouts_Consignments_Is} is
 @property {Checkouts_Consignments_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Consignments_Properties
 @property {number|int} checkout
 @property {number|int} consignment
 @property {text|string} cost
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} discount_amount
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int} quantity
 @property {text|string} serial
 @property {text|string} sku
 @property {boolean} taxable
 */
/**
 @typedef {Object.<any>} Checkouts_Consignments_Has
 */
/**
 @typedef {Object.<any>} Checkouts_Consignments_Is
 @property {Checkouts_Object} checkouts
 @property {Consignments_Object} consignments
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Consignments_Object
 @property {?number|int} checkout
 @property {string} checkout_error
 @property {?number|int} consignment
 @property {string} consignment_error
 @property {?text|string} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} discount_amount
 @property {string} discount_amount_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {Checkouts_Object} checkouts
 @property {Consignments_Object} consignments
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Items
 @property {Checkouts_Items_Properties} properties
 @property {Checkouts_Items_Has} has
 @property {Checkouts_Items_Is} is
 @property {Checkouts_Items_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Items_Properties
 @property {number|int} checkout
 @property {text|string} cost
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} discount_amount
 @property {number|int} inventory
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int} quantity
 @property {text|string} serial
 @property {text|string} sku
 @property {boolean} taxable
 */
/**
 @typedef {Object.<any>} Checkouts_Items_Has
 */
/**
 @typedef {Object.<any>} Checkouts_Items_Is
 @property {Checkouts_Object} checkouts
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Items_Object
 @property {?number|int} checkout
 @property {string} checkout_error
 @property {?text|string} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {Checkouts_Object} checkouts
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Layaways
 @property {Checkouts_Layaways_Properties} properties
 @property {Checkouts_Layaways_Has} has
 @property {Checkouts_Layaways_Is} is
 @property {Checkouts_Layaways_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Layaways_Properties
 @property {number|int} checkout
 @property {text|string} cost
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} discount_amount
 @property {number|int} layaway
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int} quantity
 @property {text|string} serial
 @property {text|string} sku
 @property {boolean} taxable
 */
/**
 @typedef {Object.<any>} Checkouts_Layaways_Has
 */
/**
 @typedef {Object.<any>} Checkouts_Layaways_Is
 @property {Checkouts_Object} checkouts
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Layaways_Object
 @property {?number|int} checkout
 @property {string} checkout_error
 @property {?text|string} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} layaway
 @property {string} layaway_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {Checkouts_Object} checkouts
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Products
 @property {Checkouts_Products_Properties} properties
 @property {Checkouts_Products_Has} has
 @property {Checkouts_Products_Is} is
 @property {Checkouts_Products_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Products_Properties
 @property {number|int} checkout
 @property {text|string} cost
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} discount_amount
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int} product
 @property {text|string} serial
 @property {text|string} sku
 @property {boolean} taxable
 */
/**
 @typedef {Object.<any>} Checkouts_Products_Has
 */
/**
 @typedef {Object.<any>} Checkouts_Products_Is
 @property {Checkouts_Object} checkouts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Products_Object
 @property {?number|int} checkout
 @property {string} checkout_error
 @property {?text|string} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} discount_amount
 @property {string} discount_amount_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} product
 @property {string} product_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {Checkouts_Object} checkouts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Trades
 @property {Checkouts_Trades_Properties} properties
 @property {Checkouts_Trades_Has} has
 @property {Checkouts_Trades_Is} is
 @property {Checkouts_Trades_Object} object
 */
/**
 @typedef {Object.<any>} Checkouts_Trades_Properties
 @property {number|int} checkout
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {number|int} quantity
 @property {number|int} trade
 */
/**
 @typedef {Object.<any>} Checkouts_Trades_Has
 */
/**
 @typedef {Object.<any>} Checkouts_Trades_Is
 @property {Checkouts_Object} checkouts
 @property {Trades_Object} trades
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Checkouts_Trades_Object
 @property {?number|int} checkout
 @property {string} checkout_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?number|int} trade
 @property {string} trade_error
 @property {Checkouts_Object} checkouts
 @property {Trades_Object} trades
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Consignments
 @property {Consignments_Properties} properties
 @property {Consignments_Has} has
 @property {Consignments_Is} is
 @property {Consignments_Object} object
 */
/**
 @typedef {Object.<any>} Consignments_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {text|string} notes
 @property {number|int} pkey
 @property {text|string} poc
 @property {number|int|float} price
 @property {number|int|float} quantity
 @property {boolean} taxable
 @property {text|string} terms
 */
/**
 @typedef {Object.<any>} Consignments_Has
 */
/**
 @typedef {Object.<any>} Consignments_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Consignments_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} notes
 @property {string} notes_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} poc
 @property {string} poc_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int|float} quantity
 @property {string} quantity_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?text|string} terms
 @property {string} terms_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Dashboard
 @property {Dashboard_Properties} properties
 @property {Dashboard_Has} has
 @property {Dashboard_Is} is
 @property {Dashboard_Object} object
 */
/**
 @typedef {Object.<any>} Dashboard_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {array} totals
 */
/**
 @typedef {Object.<any>} Dashboard_Has
 */
/**
 @typedef {Object.<any>} Dashboard_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Dashboard_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?array} totals
 @property {string} totals_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories
 @property {Inventories_Properties} properties
 @property {Inventories_Has} has
 @property {Inventories_Is} is
 @property {Inventories_Object} object
 */
/**
 @typedef {Object.<any>} Inventories_Properties
 @property {number|int} account
 @property {date|string} created
 @property {number|int} creator
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {number|int|float} quantity
 @property {number|int} sku
 @property {boolean} taxable
 @property {boolean} used
 */
/**
 @typedef {Object.<any>} Inventories_Has
 */
/**
 @typedef {Object.<any>} Inventories_Is
 @property {Accounts_Object} accounts
 @property {Locations_Object} locations
 @property {Skus_Object} skus
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} quantity
 @property {string} quantity_error
 @property {?number|int} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?boolean} used
 @property {string} used_error
 @property {Accounts_Object} accounts
 @property {Locations_Object} locations
 @property {Skus_Object} skus
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Connected
 @property {Inventories_Connected_Properties} properties
 @property {Inventories_Connected_Has} has
 @property {Inventories_Connected_Is} is
 @property {Inventories_Connected_Object} object
 */
/**
 @typedef {Object.<any>} Inventories_Connected_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {number|int|float} ffl_cost
 @property {number|int|float} ffl_price
 @property {text|string} sku
 */
/**
 @typedef {Object.<any>} Inventories_Connected_Has
 */
/**
 @typedef {Object.<any>} Inventories_Connected_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Connected_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} ffl_cost
 @property {string} ffl_cost_error
 @property {?number|int|float} ffl_price
 @property {string} ffl_price_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Ledgers
 @property {Inventories_Ledgers_Properties} properties
 @property {Inventories_Ledgers_Has} has
 @property {Inventories_Ledgers_Is} is
 @property {Inventories_Ledgers_Object} object
 */
/**
 @typedef {Object.<any>} Inventories_Ledgers_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {number|int} inventory
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {number|int|float} quantity
 */
/**
 @typedef {Object.<any>} Inventories_Ledgers_Has
 */
/**
 @typedef {Object.<any>} Inventories_Ledgers_Is
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Ledgers_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} quantity
 @property {string} quantity_error
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Serialized
 @property {Inventories_Serialized_Properties} properties
 @property {Inventories_Serialized_Has} has
 @property {Inventories_Serialized_Is} is
 @property {Inventories_Serialized_Object} object
 */
/**
 @typedef {Object.<any>} Inventories_Serialized_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {number|int} inventory
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} notes
 @property {number|int} pkey
 @property {text|string} serial
 @property {boolean} sold
 */
/**
 @typedef {Object.<any>} Inventories_Serialized_Has
 */
/**
 @typedef {Object.<any>} Inventories_Serialized_Is
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Inventories_Serialized_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} notes
 @property {string} notes_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?boolean} sold
 @property {string} sold_error
 @property {Inventories_Object} inventories
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways
 @property {Layaways_Properties} properties
 @property {Layaways_Has} has
 @property {Layaways_Is} is
 @property {Layaways_Object} object
 */
/**
 @typedef {Object.<any>} Layaways_Properties
 @property {number|int} account
 @property {number|int|float} amount
 @property {number|int|float} cc_fees
 @property {date|string} completed_date
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} customer
 @property {text|string} customer_notes
 @property {text|string} description
 @property {date|string} estimated_date
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} po_number
 @property {text|string} receipt_email
 @property {number|int|float} remaining
 @property {text|string} salesman
 @property {number|int|float} tax_amount
 @property {number|int|float} tax_rate
 @property {number|int|float} cash_amount
 @property {number|int|float} check_amount
 @property {text|string} credit_card_cvc
 @property {text|string} credit_card_expiry
 @property {text|string} credit_card_name
 @property {text|string} credit_card_number
 @property {text|string} description
 @property {array} entries
 @property {number|int|float} invoice_amount
 @property {number|int|float} other_amount
 @property {number|int|float} processor_amount
 @property {number|int} register
 @property {number|int|float} remaining
 @property {number|int|float} sub_total_amount
 @property {number|int|float} tax_amount
 @property {text|string} terminal
 */
/**
 @typedef {Object.<any>} Layaways_Has
 */
/**
 @typedef {Object.<any>} Layaways_Is
 @property {Accounts_Object} accounts
 @property {Locations_Object} locations
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?number|int|float} cc_fees
 @property {string} cc_fees_error
 @property {?date|string} completed_date
 @property {string} completed_date_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} customer
 @property {string} customer_error
 @property {?text|string} customer_notes
 @property {string} customer_notes_error
 @property {?text|string} description
 @property {string} description_error
 @property {?date|string} estimated_date
 @property {string} estimated_date_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} po_number
 @property {string} po_number_error
 @property {?text|string} receipt_email
 @property {string} receipt_email_error
 @property {?number|int|float} remaining
 @property {string} remaining_error
 @property {?text|string} salesman
 @property {string} salesman_error
 @property {?number|int|float} tax_amount
 @property {string} tax_amount_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?number|int|float} cash_amount
 @property {string} cash_amount_error
 @property {?number|int|float} check_amount
 @property {string} check_amount_error
 @property {?text|string} credit_card_cvc
 @property {string} credit_card_cvc_error
 @property {?text|string} credit_card_expiry
 @property {string} credit_card_expiry_error
 @property {?text|string} credit_card_name
 @property {string} credit_card_name_error
 @property {?text|string} credit_card_number
 @property {string} credit_card_number_error
 @property {?text|string} description
 @property {string} description_error
 @property {?array} entries
 @property {string} entries_error
 @property {?number|int|float} invoice_amount
 @property {string} invoice_amount_error
 @property {?number|int|float} other_amount
 @property {string} other_amount_error
 @property {?number|int|float} processor_amount
 @property {string} processor_amount_error
 @property {?number|int} register
 @property {string} register_error
 @property {?number|int|float} remaining
 @property {string} remaining_error
 @property {?number|int|float} sub_total_amount
 @property {string} sub_total_amount_error
 @property {?number|int|float} tax_amount
 @property {string} tax_amount_error
 @property {?text|string} terminal
 @property {string} terminal_error
 @property {Accounts_Object} accounts
 @property {Locations_Object} locations
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Entries
 @property {Layaways_Entries_Properties} properties
 @property {Layaways_Entries_Has} has
 @property {Layaways_Entries_Is} is
 @property {Layaways_Entries_Object} object
 */
/**
 @typedef {Object.<any>} Layaways_Entries_Properties
 @property {number|int|float} cost
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} discount_amount
 @property {number|int} inventory
 @property {number|int} layaway
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} product
 @property {number|int} quantity
 @property {text|string} serial_number
 @property {text|string} sku
 @property {number|int|float} tax_rate
 @property {boolean} taxable
 @property {text|string} type
 */
/**
 @typedef {Object.<any>} Layaways_Entries_Has
 */
/**
 @typedef {Object.<any>} Layaways_Entries_Is
 @property {Inventories_Object} inventories
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Entries_Object
 @property {?number|int|float} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?number|int} layaway
 @property {string} layaway_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} product
 @property {string} product_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} serial_number
 @property {string} serial_number_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?text|string} type
 @property {string} type_error
 @property {Inventories_Object} inventories
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Items
 @property {Layaways_Items_Properties} properties
 @property {Layaways_Items_Has} has
 @property {Layaways_Items_Is} is
 @property {Layaways_Items_Object} object
 */
/**
 @typedef {Object.<any>} Layaways_Items_Properties
 @property {number|int|float} cost
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} discount_amount
 @property {number|int} item_fkey
 @property {text|string} item_type
 @property {number|int} layaway
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} quantity
 @property {text|string} serial_number
 @property {text|string} sku
 @property {number|int|float} tax_rate
 @property {boolean} taxable
 @property {array} entries
 */
/**
 @typedef {Object.<any>} Layaways_Items_Has
 */
/**
 @typedef {Object.<any>} Layaways_Items_Is
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Items_Object
 @property {?number|int|float} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} item_fkey
 @property {string} item_fkey_error
 @property {?text|string} item_type
 @property {string} item_type_error
 @property {?number|int} layaway
 @property {string} layaway_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?text|string} serial_number
 @property {string} serial_number_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?array} entries
 @property {string} entries_error
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Payments
 @property {Layaways_Payments_Properties} properties
 @property {Layaways_Payments_Has} has
 @property {Layaways_Payments_Is} is
 @property {Layaways_Payments_Object} object
 */
/**
 @typedef {Object.<any>} Layaways_Payments_Properties
 @property {number|int|float} amount
 @property {number|int|float} cash_amount
 @property {boolean} cc_fee
 @property {number|int|float} check_amount
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} description
 @property {number|int|float} invoice_amount
 @property {number|int} layaway
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int|float} other_amount
 @property {number|int} pkey
 @property {number|int|float} processor_amount
 @property {number|int} register
 @property {text|string} credit_card_cvc
 @property {text|string} credit_card_expiry
 @property {text|string} credit_card_name
 @property {text|string} credit_card_number
 @property {number|int|float} remaining
 */
/**
 @typedef {Object.<any>} Layaways_Payments_Has
 */
/**
 @typedef {Object.<any>} Layaways_Payments_Is
 @property {Layaways_Object} layaways
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Payments_Object
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?number|int|float} cash_amount
 @property {string} cash_amount_error
 @property {?boolean} cc_fee
 @property {string} cc_fee_error
 @property {?number|int|float} check_amount
 @property {string} check_amount_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} description
 @property {string} description_error
 @property {?number|int|float} invoice_amount
 @property {string} invoice_amount_error
 @property {?number|int} layaway
 @property {string} layaway_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int|float} other_amount
 @property {string} other_amount_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} processor_amount
 @property {string} processor_amount_error
 @property {?number|int} register
 @property {string} register_error
 @property {?text|string} credit_card_cvc
 @property {string} credit_card_cvc_error
 @property {?text|string} credit_card_expiry
 @property {string} credit_card_expiry_error
 @property {?text|string} credit_card_name
 @property {string} credit_card_name_error
 @property {?text|string} credit_card_number
 @property {string} credit_card_number_error
 @property {?number|int|float} remaining
 @property {string} remaining_error
 @property {Layaways_Object} layaways
 @property {Registers_Object} registers
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Products
 @property {Layaways_Products_Properties} properties
 @property {Layaways_Products_Has} has
 @property {Layaways_Products_Is} is
 @property {Layaways_Products_Object} object
 */
/**
 @typedef {Object.<any>} Layaways_Products_Properties
 @property {text|string} cost
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} discount_amount
 @property {number|int} layaway
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} product
 @property {text|string} serial
 @property {text|string} sku
 */
/**
 @typedef {Object.<any>} Layaways_Products_Has
 */
/**
 @typedef {Object.<any>} Layaways_Products_Is
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Layaways_Products_Object
 @property {?text|string} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} layaway
 @property {string} layaway_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} product
 @property {string} product_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {Layaways_Object} layaways
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Locations
 @property {Locations_Properties} properties
 @property {Locations_Has} has
 @property {Locations_Is} is
 @property {Locations_Object} object
 */
/**
 @typedef {Object.<any>} Locations_Properties
 @property {text|string} city
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} credit_card_fee
 @property {text|string} description
 @property {email} email
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {text|string} phone
 @property {number|int} pkey
 @property {text|string} state
 @property {text|string} store_number
 @property {text|string} street_1
 @property {text|string} street_2
 @property {number|int|float} tax_rate_city
 @property {number|int|float} tax_rate_county
 @property {number|int|float} tax_rate_special
 @property {number|int|float} tax_rate_state
 @property {text|string} zip
 */
/**
 @typedef {Object.<any>} Locations_Has
 */
/**
 @typedef {Object.<any>} Locations_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Locations_Object
 @property {?text|string} city
 @property {string} city_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} credit_card_fee
 @property {string} credit_card_fee_error
 @property {?text|string} description
 @property {string} description_error
 @property {?email} email
 @property {string} email_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} phone
 @property {string} phone_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} state
 @property {string} state_error
 @property {?text|string} store_number
 @property {string} store_number_error
 @property {?text|string} street_1
 @property {string} street_1_error
 @property {?text|string} street_2
 @property {string} street_2_error
 @property {?number|int|float} tax_rate_city
 @property {string} tax_rate_city_error
 @property {?number|int|float} tax_rate_county
 @property {string} tax_rate_county_error
 @property {?number|int|float} tax_rate_special
 @property {string} tax_rate_special_error
 @property {?number|int|float} tax_rate_state
 @property {string} tax_rate_state_error
 @property {?text|string} zip
 @property {string} zip_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Locations_Sales
 @property {Locations_Sales_Properties} properties
 @property {Locations_Sales_Has} has
 @property {Locations_Sales_Is} is
 @property {Locations_Sales_Object} object
 */
/**
 @typedef {Object.<any>} Locations_Sales_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} date
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int|float} non_taxable
 @property {number|int} pkey
 @property {number|int|float} tax_rate
 @property {number|int|float} taxable_sales
 @property {number|int|float} total_sales
 @property {number|int|float} total_tax
 */
/**
 @typedef {Object.<any>} Locations_Sales_Has
 */
/**
 @typedef {Object.<any>} Locations_Sales_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Locations_Sales_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} date
 @property {string} date_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int|float} non_taxable
 @property {string} non_taxable_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?number|int|float} taxable_sales
 @property {string} taxable_sales_error
 @property {?number|int|float} total_sales
 @property {string} total_sales_error
 @property {?number|int|float} total_tax
 @property {string} total_tax_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Processors
 @property {Processors_Properties} properties
 @property {Processors_Has} has
 @property {Processors_Is} is
 @property {Processors_Object} object
 */
/**
 @typedef {Object.<any>} Processors_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Processors_Has
 */
/**
 @typedef {Object.<any>} Processors_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Processors_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Registers
 @property {Registers_Properties} properties
 @property {Registers_Has} has
 @property {Registers_Is} is
 @property {Registers_Object} object
 */
/**
 @typedef {Object.<any>} Registers_Properties
 @property {number|int|float} amount_current
 @property {date|string} created
 @property {number|int} creator
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Registers_Has
 */
/**
 @typedef {Object.<any>} Registers_Is
 @property {Locations_Object} locations
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Registers_Object
 @property {?number|int|float} amount_current
 @property {string} amount_current_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Locations_Object} locations
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Registers_Ledgers
 @property {Registers_Ledgers_Properties} properties
 @property {Registers_Ledgers_Has} has
 @property {Registers_Ledgers_Is} is
 @property {Registers_Ledgers_Object} object
 */
/**
 @typedef {Object.<any>} Registers_Ledgers_Properties
 @property {number|int|float} amount
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} event
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} note
 @property {number|int} pkey
 @property {number|int} register
 @property {number|int} transaction
 @property {boolean} type
 */
/**
 @typedef {Object.<any>} Registers_Ledgers_Has
 */
/**
 @typedef {Object.<any>} Registers_Ledgers_Is
 @property {Registers_Object} registers
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Registers_Ledgers_Object
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} event
 @property {string} event_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} note
 @property {string} note_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} register
 @property {string} register_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {?boolean} type
 @property {string} type_error
 @property {Registers_Object} registers
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Sessions
 @property {Sessions_Properties} properties
 @property {Sessions_Has} has
 @property {Sessions_Is} is
 @property {Sessions_Object} object
 */
/**
 @typedef {Object.<any>} Sessions_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Sessions_Has
 */
/**
 @typedef {Object.<any>} Sessions_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Sessions_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Skus
 @property {Skus_Properties} properties
 @property {Skus_Has} has
 @property {Skus_Is} is
 @property {Skus_Object} object
 */
/**
 @typedef {Object.<any>} Skus_Properties
 @property {number|int} class
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} description
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int} price_minimum
 @property {number|int} price_purchase
 @property {number|int} price_retail
 @property {number|int} quantity_max
 @property {number|int} quantity_min
 @property {text|string} sku
 @property {boolean} taxable
 @property {text|string} class_other
 @property {number|int} quantity_current
 */
/**
 @typedef {Object.<any>} Skus_Has
 */
/**
 @typedef {Object.<any>} Skus_Is
 @property {Locations_Object} locations
 @property {Skus_Classes_Object} sku_classes
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Skus_Object
 @property {?number|int} class
 @property {string} class_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} description
 @property {string} description_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} price_minimum
 @property {string} price_minimum_error
 @property {?number|int} price_purchase
 @property {string} price_purchase_error
 @property {?number|int} price_retail
 @property {string} price_retail_error
 @property {?number|int} quantity_max
 @property {string} quantity_max_error
 @property {?number|int} quantity_min
 @property {string} quantity_min_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?text|string} class_other
 @property {string} class_other_error
 @property {?number|int} quantity_current
 @property {string} quantity_current_error
 @property {Locations_Object} locations
 @property {Skus_Classes_Object} sku_classes
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Skus_Classes
 @property {Skus_Classes_Properties} properties
 @property {Skus_Classes_Has} has
 @property {Skus_Classes_Is} is
 @property {Skus_Classes_Object} object
 */
/**
 @typedef {Object.<any>} Skus_Classes_Properties
 @property {number|int} account
 @property {text|string} color
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Skus_Classes_Has
 */
/**
 @typedef {Object.<any>} Skus_Classes_Is
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Skus_Classes_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?text|string} color
 @property {string} color_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} States
 @property {States_Properties} properties
 @property {States_Has} has
 @property {States_Is} is
 @property {States_Object} object
 */
/**
 @typedef {Object.<any>} States_Properties
 @property {text|string} abbreviation
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} States_Has
 */
/**
 @typedef {Object.<any>} States_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} States_Object
 @property {?text|string} abbreviation
 @property {string} abbreviation_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Terminals
 @property {Terminals_Properties} properties
 @property {Terminals_Has} has
 @property {Terminals_Is} is
 @property {Terminals_Object} object
 */
/**
 @typedef {Object.<any>} Terminals_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Terminals_Has
 */
/**
 @typedef {Object.<any>} Terminals_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Terminals_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Trades
 @property {Trades_Properties} properties
 @property {Trades_Has} has
 @property {Trades_Is} is
 @property {Trades_Object} object
 */
/**
 @typedef {Object.<any>} Trades_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {text|string} notes
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int|float} quantity
 */
/**
 @typedef {Object.<any>} Trades_Has
 */
/**
 @typedef {Object.<any>} Trades_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Trades_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?text|string} notes
 @property {string} notes_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int|float} quantity
 @property {string} quantity_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions
 @property {Transactions_Properties} properties
 @property {Transactions_Has} has
 @property {Transactions_Is} is
 @property {Transactions_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Properties
 @property {number|int|float} amount
 @property {number|int|float} cash_amount
 @property {number|int|float} cc_fee_amount
 @property {number|int} certificate
 @property {number|int|float} check_amount
 @property {text|string} client
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} date
 @property {text|string} description
 @property {number|int|float} discount_amount
 @property {number|int} discount_percentage
 @property {number|int|float} invoice_amount
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} notes
 @property {text|string} order_id
 @property {text|string} order_ip_address
 @property {text|string} order_po_number
 @property {number|int|float} other_amount
 @property {text|string} payment_method
 @property {number|int} pkey
 @property {number|int|float} processor_amount
 @property {text|string} processor_user_id
 @property {text|string} receipt_email
 @property {boolean} refunded
 @property {text|string} salesman
 @property {text|string} signature_data
 @property {number|int|float} tax_amount
 @property {text|string} terminal_card_type
 @property {text|string} terminal_cardholder_name
 @property {text|string} terminal_entry_type
 @property {text|string} terminal_id
 @property {number|int} terminal_last_four
 @property {text|string} terminal_payment_type
 @property {text|string} transaction_id
 @property {number|int} user
 */
/**
 @typedef {Object.<any>} Transactions_Has
 @property {Transactions_Refunds_Object[]} 0
 @property {Transactions_Items_Object[]} 1
 @property {Transactions_Products_Object[]} 2
 @property {Transactions_Consignments_Object[]} 3
 */
/**
 @typedef {Object.<any>} Transactions_Is
 @property {Certificates_Object} certificates
 @property {Locations_Object} locations
 @property {Users_Object} users
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Object
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?number|int|float} cash_amount
 @property {string} cash_amount_error
 @property {?number|int|float} cc_fee_amount
 @property {string} cc_fee_amount_error
 @property {?number|int} certificate
 @property {string} certificate_error
 @property {?number|int|float} check_amount
 @property {string} check_amount_error
 @property {?text|string} client
 @property {string} client_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} date
 @property {string} date_error
 @property {?text|string} description
 @property {string} description_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} discount_percentage
 @property {string} discount_percentage_error
 @property {?number|int|float} invoice_amount
 @property {string} invoice_amount_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} notes
 @property {string} notes_error
 @property {?text|string} order_id
 @property {string} order_id_error
 @property {?text|string} order_ip_address
 @property {string} order_ip_address_error
 @property {?text|string} order_po_number
 @property {string} order_po_number_error
 @property {?number|int|float} other_amount
 @property {string} other_amount_error
 @property {?text|string} payment_method
 @property {string} payment_method_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} processor_amount
 @property {string} processor_amount_error
 @property {?text|string} processor_user_id
 @property {string} processor_user_id_error
 @property {?text|string} receipt_email
 @property {string} receipt_email_error
 @property {?boolean} refunded
 @property {string} refunded_error
 @property {?text|string} salesman
 @property {string} salesman_error
 @property {?text|string} signature_data
 @property {string} signature_data_error
 @property {?number|int|float} tax_amount
 @property {string} tax_amount_error
 @property {?text|string} terminal_card_type
 @property {string} terminal_card_type_error
 @property {?text|string} terminal_cardholder_name
 @property {string} terminal_cardholder_name_error
 @property {?text|string} terminal_entry_type
 @property {string} terminal_entry_type_error
 @property {?text|string} terminal_id
 @property {string} terminal_id_error
 @property {?number|int} terminal_last_four
 @property {string} terminal_last_four_error
 @property {?text|string} terminal_payment_type
 @property {string} terminal_payment_type_error
 @property {?text|string} transaction_id
 @property {string} transaction_id_error
 @property {?number|int} user
 @property {string} user_error
 @property {Transactions_Refunds_Object[]} 0
 @property {Transactions_Items_Object[]} 1
 @property {Transactions_Products_Object[]} 2
 @property {Transactions_Consignments_Object[]} 3
 @property {Certificates_Object} certificates
 @property {Locations_Object} locations
 @property {Users_Object} users
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Entries
 @property {Transactions_Entries_Properties} properties
 @property {Transactions_Entries_Has} has
 @property {Transactions_Entries_Is} is
 @property {Transactions_Entries_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Entries_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} decimal
 @property {number|int|float} discount_amount
 @property {number|int} inventory
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} product
 @property {number|int} quantity
 @property {number|int|float} refund_amount
 @property {number|int} refund_quantity
 @property {boolean} refunded
 @property {text|string} serial_number
 @property {number|int} sku
 @property {number|int|float} tax_rate
 @property {boolean} taxable
 @property {number|int} transaction
 @property {text|string} transaction_type
 @property {number|int} true_pkey
 */
/**
 @typedef {Object.<any>} Transactions_Entries_Has
 */
/**
 @typedef {Object.<any>} Transactions_Entries_Is
 @property {Inventories_Object} inventories
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Entries_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} decimal
 @property {string} decimal_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} product
 @property {string} product_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?number|int|float} refund_amount
 @property {string} refund_amount_error
 @property {?number|int} refund_quantity
 @property {string} refund_quantity_error
 @property {?boolean} refunded
 @property {string} refunded_error
 @property {?text|string} serial_number
 @property {string} serial_number_error
 @property {?number|int} sku
 @property {string} sku_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {?text|string} transaction_type
 @property {string} transaction_type_error
 @property {?number|int} true_pkey
 @property {string} true_pkey_error
 @property {Inventories_Object} inventories
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Items
 @property {Transactions_Items_Properties} properties
 @property {Transactions_Items_Has} has
 @property {Transactions_Items_Is} is
 @property {Transactions_Items_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Items_Properties
 @property {number|int|float} cost
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} discount_amount
 @property {number|int} inventory
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} quantity
 @property {boolean} refunded
 @property {text|string} serial_number
 @property {text|string} sku
 @property {number|int|float} tax_rate
 @property {boolean} taxable
 @property {number|int} transaction
 */
/**
 @typedef {Object.<any>} Transactions_Items_Has
 */
/**
 @typedef {Object.<any>} Transactions_Items_Is
 @property {Inventories_Object} inventories
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Items_Object
 @property {?number|int|float} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?number|int} inventory
 @property {string} inventory_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?boolean} refunded
 @property {string} refunded_error
 @property {?text|string} serial_number
 @property {string} serial_number_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {Inventories_Object} inventories
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Printable_Customization
 @property {Transactions_Printable_Customization_Properties} properties
 @property {Transactions_Printable_Customization_Has} has
 @property {Transactions_Printable_Customization_Is} is
 @property {Transactions_Printable_Customization_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Printable_Customization_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {string} logo
 @property {text|string} message
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} return_policy
 @property {text|string} warranty
 */
/**
 @typedef {Object.<any>} Transactions_Printable_Customization_Has
 */
/**
 @typedef {Object.<any>} Transactions_Printable_Customization_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Printable_Customization_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?string} logo
 @property {string} logo_error
 @property {?text|string} message
 @property {string} message_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} return_policy
 @property {string} return_policy_error
 @property {?text|string} warranty
 @property {string} warranty_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Products
 @property {Transactions_Products_Properties} properties
 @property {Transactions_Products_Has} has
 @property {Transactions_Products_Is} is
 @property {Transactions_Products_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Products_Properties
 @property {number|int|float} cost
 @property {date|string} created
 @property {number|int} creator
 @property {number|int|float} discount_amount
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} product
 @property {boolean} refunded
 @property {text|string} serial
 @property {text|string} sku
 @property {number|int|float} tax_rate
 @property {boolean} taxable
 @property {number|int} transaction
 */
/**
 @typedef {Object.<any>} Transactions_Products_Has
 */
/**
 @typedef {Object.<any>} Transactions_Products_Is
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Products_Object
 @property {?number|int|float} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int|float} discount_amount
 @property {string} discount_amount_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} product
 @property {string} product_error
 @property {?boolean} refunded
 @property {string} refunded_error
 @property {?text|string} serial
 @property {string} serial_error
 @property {?text|string} sku
 @property {string} sku_error
 @property {?number|int|float} tax_rate
 @property {string} tax_rate_error
 @property {?boolean} taxable
 @property {string} taxable_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Refunds
 @property {Transactions_Refunds_Properties} properties
 @property {Transactions_Refunds_Has} has
 @property {Transactions_Refunds_Is} is
 @property {Transactions_Refunds_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Refunds_Properties
 @property {number|int|float} amount
 @property {number|int} consignment
 @property {date|string} created
 @property {number|int} creator
 @property {number|int} item
 @property {text|string} method
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} note
 @property {number|int} pkey
 @property {number|int} product
 @property {number|int} quantity
 @property {number|int} register
 @property {number|int} transaction
 @property {array} consignments
 @property {array} items
 @property {array} products
 @property {boolean} restock
 */
/**
 @typedef {Object.<any>} Transactions_Refunds_Has
 */
/**
 @typedef {Object.<any>} Transactions_Refunds_Is
 @property {Registers_Object} registers
 @property {Transactions_Consignments_Object} transaction_consignments
 @property {Transactions_Items_Object} transaction_items
 @property {Transactions_Products_Object} transaction_products
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Refunds_Object
 @property {?number|int|float} amount
 @property {string} amount_error
 @property {?number|int} consignment
 @property {string} consignment_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?number|int} item
 @property {string} item_error
 @property {?text|string} method
 @property {string} method_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} note
 @property {string} note_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int} product
 @property {string} product_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?number|int} register
 @property {string} register_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {?array} consignments
 @property {string} consignments_error
 @property {?array} items
 @property {string} items_error
 @property {?array} products
 @property {string} products_error
 @property {?boolean} restock
 @property {string} restock_error
 @property {Registers_Object} registers
 @property {Transactions_Consignments_Object} transaction_consignments
 @property {Transactions_Items_Object} transaction_items
 @property {Transactions_Products_Object} transaction_products
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Trades
 @property {Transactions_Trades_Properties} properties
 @property {Transactions_Trades_Has} has
 @property {Transactions_Trades_Is} is
 @property {Transactions_Trades_Object} object
 */
/**
 @typedef {Object.<any>} Transactions_Trades_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int} quantity
 @property {number|int} trade
 @property {number|int} transaction
 */
/**
 @typedef {Object.<any>} Transactions_Trades_Has
 */
/**
 @typedef {Object.<any>} Transactions_Trades_Is
 @property {Trades_Object} trades
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Transactions_Trades_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int} quantity
 @property {string} quantity_error
 @property {?number|int} trade
 @property {string} trade_error
 @property {?number|int} transaction
 @property {string} transaction_error
 @property {Trades_Object} trades
 @property {Transactions_Object} transactions
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Unexpected_Errors
 @property {Unexpected_Errors_Properties} properties
 @property {Unexpected_Errors_Has} has
 @property {Unexpected_Errors_Is} is
 @property {Unexpected_Errors_Object} object
 */
/**
 @typedef {Object.<any>} Unexpected_Errors_Properties
 @property {number|int} account
 @property {date|string} created
 @property {number|int} creator
 @property {text|string} message
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} path
 @property {number|int} pkey
 @property {text|string} stack
 @property {number|int} user
 */
/**
 @typedef {Object.<any>} Unexpected_Errors_Has
 */
/**
 @typedef {Object.<any>} Unexpected_Errors_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Unexpected_Errors_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?text|string} message
 @property {string} message_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} path
 @property {string} path_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} stack
 @property {string} stack_error
 @property {?number|int} user
 @property {string} user_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Users
 @property {Users_Properties} properties
 @property {Users_Has} has
 @property {Users_Is} is
 @property {Users_Object} object
 */
/**
 @typedef {Object.<any>} Users_Properties
 @property {number|int} access
 @property {date|string} created
 @property {number|int} creator
 @property {email} email
 @property {text|string} employee_id
 @property {number|int} location
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name_first
 @property {text|string} name_last
 @property {text|string} password
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Users_Has
 */
/**
 @typedef {Object.<any>} Users_Is
 @property {Locations_Object} locations
 @property {Users_Accesses_Object} user_accesses
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Users_Object
 @property {?number|int} access
 @property {string} access_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?email} email
 @property {string} email_error
 @property {?text|string} employee_id
 @property {string} employee_id_error
 @property {?number|int} location
 @property {string} location_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name_first
 @property {string} name_first_error
 @property {?text|string} name_last
 @property {string} name_last_error
 @property {?text|string} password
 @property {string} password_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Locations_Object} locations
 @property {Users_Accesses_Object} user_accesses
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Users_Accesses
 @property {Users_Accesses_Properties} properties
 @property {Users_Accesses_Has} has
 @property {Users_Accesses_Is} is
 @property {Users_Accesses_Object} object
 */
/**
 @typedef {Object.<any>} Users_Accesses_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {text|string} name
 @property {number|int} pkey
 */
/**
 @typedef {Object.<any>} Users_Accesses_Has
 */
/**
 @typedef {Object.<any>} Users_Accesses_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Users_Accesses_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?text|string} name
 @property {string} name_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Version
 @property {Version_Properties} properties
 @property {Version_Has} has
 @property {Version_Is} is
 @property {Version_Object} object
 */
/**
 @typedef {Object.<any>} Version_Properties
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {text|string} version_number
 */
/**
 @typedef {Object.<any>} Version_Has
 */
/**
 @typedef {Object.<any>} Version_Is
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Version_Object
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?text|string} version_number
 @property {string} version_number_error
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Year_End_Reports
 @property {Year_End_Reports_Properties} properties
 @property {Year_End_Reports_Has} has
 @property {Year_End_Reports_Is} is
 @property {Year_End_Reports_Object} object
 */
/**
 @typedef {Object.<any>} Year_End_Reports_Properties
 @property {number|int} account
 @property {number|int|float} cost
 @property {date|string} created
 @property {number|int} creator
 @property {date|string} end_year
 @property {date|string} modified
 @property {number|int} modifier
 @property {number|int} pkey
 @property {number|int|float} price
 @property {number|int|float} price_end
 @property {number|int|float} price_start
 @property {number|int} quantity_end
 @property {number|int} quantity_start
 @property {number|int} sku
 @property {number|int} sku_number
 @property {date|string} start_year
 @property {date|string} report_end_year
 @property {date|string} report_start_year
 */
/**
 @typedef {Object.<any>} Year_End_Reports_Has
 */
/**
 @typedef {Object.<any>} Year_End_Reports_Is
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
/**
 @typedef {Object.<any>} Year_End_Reports_Object
 @property {?number|int} account
 @property {string} account_error
 @property {?number|int|float} cost
 @property {string} cost_error
 @property {?date|string} created
 @property {string} created_error
 @property {?number|int} creator
 @property {string} creator_error
 @property {?date|string} end_year
 @property {string} end_year_error
 @property {?date|string} modified
 @property {string} modified_error
 @property {?number|int} modifier
 @property {string} modifier_error
 @property {?number|int} pkey
 @property {string} pkey_error
 @property {?number|int|float} price
 @property {string} price_error
 @property {?number|int|float} price_end
 @property {string} price_end_error
 @property {?number|int|float} price_start
 @property {string} price_start_error
 @property {?number|int} quantity_end
 @property {string} quantity_end_error
 @property {?number|int} quantity_start
 @property {string} quantity_start_error
 @property {?number|int} sku
 @property {string} sku_error
 @property {?number|int} sku_number
 @property {string} sku_number_error
 @property {?date|string} start_year
 @property {string} start_year_error
 @property {?date|string} report_end_year
 @property {string} report_end_year_error
 @property {?date|string} report_start_year
 @property {string} report_start_year_error
 @property {Accounts_Object} accounts
 @property {Users_Object} users_related_by_creator
 @property {Users_Object} users_related_by_modifier
 */
const Types = {
	Accounts : {
		_name : "Accounts",
	},
	Accounts_Billing : {
		_name : "Accounts_Billing",
	},
	Accounts_Create : {
		_name : "Accounts_Create",
	},
	Accounts_Customizations : {
		_name : "Accounts_Customizations",
	},
	Accounts_Processors : {
		_name : "Accounts_Processors",
	},
	Accounts_Subscriptions : {
		_name : "Accounts_Subscriptions",
	},
	Certificates : {
		_name : "Certificates",
	},
	Checkouts : {
		_name : "Checkouts",
	},
	Checkouts_Consignments : {
		_name : "Checkouts_Consignments",
	},
	Checkouts_Items : {
		_name : "Checkouts_Items",
	},
	Checkouts_Layaways : {
		_name : "Checkouts_Layaways",
	},
	Checkouts_Products : {
		_name : "Checkouts_Products",
	},
	Checkouts_Trades : {
		_name : "Checkouts_Trades",
	},
	Consignments : {
		_name : "Consignments",
	},
	Dashboard : {
		_name : "Dashboard",
	},
	Inventories : {
		_name : "Inventories",
	},
	Inventories_Connected : {
		_name : "Inventories_Connected",
	},
	Inventories_Ledgers : {
		_name : "Inventories_Ledgers",
	},
	Inventories_Serialized : {
		_name : "Inventories_Serialized",
	},
	Layaways : {
		_name : "Layaways",
	},
	Layaways_Entries : {
		_name : "Layaways_Entries",
	},
	Layaways_Items : {
		_name : "Layaways_Items",
	},
	Layaways_Payments : {
		_name : "Layaways_Payments",
	},
	Layaways_Products : {
		_name : "Layaways_Products",
	},
	Locations : {
		_name : "Locations",
	},
	Locations_Sales : {
		_name : "Locations_Sales",
	},
	Processors : {
		_name : "Processors",
	},
	Registers : {
		_name : "Registers",
	},
	Registers_Ledgers : {
		_name : "Registers_Ledgers",
	},
	Sessions : {
		_name : "Sessions",
	},
	Skus : {
		_name : "Skus",
	},
	Skus_Classes : {
		_name : "Skus_Classes",
	},
	States : {
		_name : "States",
	},
	Terminals : {
		_name : "Terminals",
	},
	Trades : {
		_name : "Trades",
	},
	Transactions : {
		_name : "Transactions",
	},
	Transactions_Entries : {
		_name : "Transactions_Entries",
	},
	Transactions_Items : {
		_name : "Transactions_Items",
	},
	Transactions_Printable_Customization : {
		_name : "Transactions_Printable_Customization",
	},
	Transactions_Products : {
		_name : "Transactions_Products",
	},
	Transactions_Refunds : {
		_name : "Transactions_Refunds",
	},
	Transactions_Trades : {
		_name : "Transactions_Trades",
	},
	Unexpected_Errors : {
		_name : "Unexpected_Errors",
	},
	Users : {
		_name : "Users",
	},
	Users_Accesses : {
		_name : "Users_Accesses",
	},
	Version : {
		_name : "Version",
	},
	Year_End_Reports : {
		_name : "Year_End_Reports",
	},
};
export default Types;
