import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Objects} from "components";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Users',
		url: '/users',
		icon: 'users',
	}
];
export default class User extends Framework.Components.View {

	render() {
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/users/${this.props.id}`,
				icon: 'edit',
			}
		]);
		return (

			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}/>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Objects.User
						id={this.props.match.params.id}
						locations={this.props.locations}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={this.props.baseUrl}
					/>
				</Framework.Errors.General>
			</div>

		)
	}
};
