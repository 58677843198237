import AddLayaway from 'components/Objects/AddLayaway'
import AddLayawayPayment from 'components/Objects/AddLayawayPayment'
import Billing from "components/Objects/Billing";
import Certificate from "components/Objects/Certificate";
import Checkout from "components/Objects/Checkout";
import Consignment from "components/Objects/Consignment"
import Dashboard from "components/Objects/Dashboard";
import Inventory from "components/Objects/Inventory";
import Layaway from "components/Objects/Layaway"
import Location from "components/Objects/Location";
import Payment from "components/Objects/Payment"
import Receipt from "components/Objects/Receipt";
import Register from "components/Objects/Register";
import RegisterLedger from "components/Objects/RegisterLedger";
import Account from "components/Objects/Account";
import Sku from "components/Objects/Sku";
import SkuClass from "components/Objects/SkuClass";
import Subscription from "components/Objects/Subscription";
import TradeIn from "components/Objects/TradeIn";
import Transaction from "components/Objects/Transaction";
import TransactionRefund from "components/Objects/TransactionRefund";
import User from "components/Objects/User";
import Refund from "components/Objects/Refund";

const Objects =  {
	Account: Account,
	AddLayaway:AddLayaway,
	AddLayawayPayment:AddLayawayPayment,
	Billing: Billing,
	Certificate: Certificate,
	Checkout: Checkout,
	Consignment: Consignment,
	Dashboard: Dashboard,
	Inventory: Inventory,
	Layaway: Layaway,
	Location: Location,
	Payment:Payment,
	Refund: Refund,
	Receipt: Receipt,
	Register: Register,
	RegisterLedger: RegisterLedger,
	Sku: Sku,
	SkuClass: SkuClass,
	Subscription: Subscription,
	TradeIn: TradeIn,
	Transaction: Transaction,
	TransactionRefund: TransactionRefund,
	User: User,

};

export default Objects;
