import React from 'react';
import InputMask from 'react-input-mask';

const PhoneExtension = (props) => {
	const {inputRef, value, hasExtension, ...other} = props;

	const handleRef = (ref) => {
		inputRef(ref ? ref.inputElement : null);

		if (!ref) return;

		if (value.length === 11) {
			ref.setCursorToEnd();
		}
	}

	return (
		<InputMask
			{...other}
			value={value}
			ref={handleRef}
			mask={'(999) 999-9999 ext. 9999'}
			maskChar={null}
			alwaysShowMask={false}
		/>
	);
};

export default PhoneExtension;
