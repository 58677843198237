import React from "react";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {TextField, Tooltip} from "@material-ui/core";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Collections, Objects, Elements} from "components";
import BarcodeReader from 'react-barcode-reader';
import APPCONFIG from "../../constants/Config";
import API from "@beardeddevops/react.api";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

class LayawayItems extends Framework.Components.Collection {
	constructor(props) {
		/**
		 *  @typedef {Layaways_Object} this.state
		 */
		super(props, Types.Layaways._name);
		this.state.items = [];
		this.state.products = [];
		this.state.sub_total_amount = 0.00;
		this.state.tax_amount = 0.00;
		this.state.amount = 0.00;
		this.state.deposit = 0.00;
		this.state.remaining = 0.00;
		this.state.itemIsChanging = false;

	}

	async componentDidMount() {
		super.componentDidMount();
		if (this.props.layaway) {
			await this.fetchEntries();
		}
	}

	fetchEntries = async () => {
		let model = new API.BuildClass(Types.Layaways_Entries._name, API.RequestType.READ);
		model.parent = this.props.layaway;
		model = await model.submit(null, 'GET');
		this.setState({entries: model.items})
		//console.log('ITEMS', model.items)
		return true;
	}

	handleItemUpdate = (event) => {
		this.state.itemToChange[event.target.id] = event.target.value;
		this.setState({itemToChange: this.state.itemToChange});
	}

	handleSavingRowModal = async () => {
		let entries = this.state.entries;
		let index = entries.findIndex((entry) => entry.pkey === this.state.itemToChange.pkey)
		//console.log('ENTRIES IN SAVE', entries)
		//console.log('INDEX', index)
		//console.log('CHANGE', this.state.itemToChange)
		entries[index] = this.state.itemToChange;
		//if this.props.layaway is passed it is an existing layaway and we want to change immediately, otherwise just overwrite the state and continue on
		if (this.props.layaway) {
			let rowModel = new API.BuildClass(Types.Layaways_Items._name, API.RequestType.UPDATE);
			//console.log(this.state.itemToChange)
			//send the item to the backend, so it can update the checkout on the backend so everything is tip-top
			rowModel.object = this.state.itemToChange

			try {
				await rowModel.submit(null, 'POST', true);
				API.Toast.success("Saved!", {
					position: API.Toast.POSITION.TOP_RIGHT,
					autoClose: 8000
				})
			} catch (e) {
			}
		}
		let total = 0;
		entries.forEach((item) => {
			//console.log('ITEM', item)
			if (item.information?.quantity || item.quantity) {
				total += parseFloat((item.quantity ? item.quantity : item.information.quantity) * (item.cost ? item.cost : item.price ? item.price : item.information.skus.price_retail))
			} else {
				total += parseFloat(item.cost ? item.cost : item.price ? item.price : item.information.price_list)
			}
		})
		this.props.updateEntries(entries)
		this.setState({entries, itemToChange: null, itemIsChanging: false})
	}

	addItems = async (items) => {
		//in here need to do an update if it exists or a create if it doesnt but only if layaway is passed in FML
		let total = parseFloat(this.state.sub_total_amount);
		let entries = this.state.entries ?? [];
		items.forEach(item => {
			let found = entries.find((entry) => entry.pkey === item.pkey || entry.pkey === item.sku || entry.inventory === item.sku || entry.inventory === item.pkey)
			if (!found) {
				entries.push({pkey: item.pkey, information: item, type: 'item'})
			} else {
				let index = entries.findIndex((entry) => entry.pkey === item.pkey || entry.pkey === item.sku || entry.inventory === item.sku || entry.inventory === item.pkey)
				console.log(item, entries[index])
				entries[index].quantity ? entries[index].quantity += item.quantity : entries[index].information.quantity += item.quantity
			}
			total += parseFloat(item.skus.price_retail * item.quantity)
		})
		if (this.props.layaway) {
			this.setState({loading: true});
			let item = new API.BuildClass(Types.Layaways_Items._name, API.RequestType.CREATE);
			item.object.entries = entries;
			item.parent = this.props.layaway
			await item.submit(null, API.RequestType.CREATE).then((data) => {
				//console.log(data)
				entries = data.items;
				this.setState({loading: false})
			})
		}
		this.setState({entries, sub_total_amount: total});
		//setTimeout(() => {
		this.props.updateEntries(entries)
		//}, 750)
	}

	addProducts = async (products) => {
		let total = parseFloat(this.state.sub_total_amount);
		let entries = this.state.entries ?? [];
		entries = [...entries, ...products.map(
			product => {
				total += parseFloat(product.price_list);
				return {
					pkey: product.pkey,
					information: product,
					type: 'product'
				}
			}
		)];
		if (this.props.layaway) {
			this.setState({loading: true});
			let item = new API.BuildClass(Types.Layaways_Items._name, API.RequestType.CREATE);
			item.object.entries = entries;
			item.parent = this.props.layaway
			await item.submit(null, API.RequestType.CREATE).then((data) => {
				//console.log(data)
				entries = data.items;
				this.setState({loading: false})
			})
		}
		this.setState({entries, sub_total_amount: total});
		this.props.updateEntries(entries)
	}

	deleteItem = async (index) => {
		let entries = JSON.parse(JSON.stringify(this.state.entries))
		const entry = entries[index];
		if (this.props.layaway) {
			confirmAlert({
				title: 'Confirm Delete',
				message: (
					<span>
						This will delete this item from the Layaway. Are you sure you want to delete this?
					</span>
				),
				buttons: [
					{
						label: 'Yes',
						onClick: async () => {
							try {
								this.setState({loading: true});
								let item = new API.BuildClass(Types.Layaways_Items._name, API.RequestType.DELETE);
								item.object.pkey = entry.pkey
								item.object.layaway = this.props.layaway
								await item.submit(null, API.RequestType.DELETE).then(() => {
									this.setState({loading: false})
								})
								entries.splice(index, 1)
								this.setState({entries})
								this.props.updateEntries(entries)
							} catch (error) {

							}
						}
					},
					{
						label: 'No',
						onClick: () => {
						}
					}
				]
			});
		} else {
			entries.splice(index, 1)
			this.setState({entries})
			this.props.updateEntries(entries)
		}

	}

	handleToggleItemModel = (index) => {
		const item = this.state.entries[index];
		if (!item) {
			alert("There was an issue editing this item. Please contact support.")
			return;
		}

		//the below JSOn nonsense is to make a shallow copy so it doesn't change the state
		this.setState({itemToChange: JSON.parse(JSON.stringify(item)), itemIsChanging: true})
	}

	handleTaxableChange = async (index, value) => {
		let entries = this.state.entries;
		let item = entries[index];
		if (!item) {
			alert("There was an issue editing this item. Please contact support.")
			return;
		}
		item = JSON.parse(JSON.stringify(item));
		item.taxable = value
		entries[index] = item;
		if (this.props.layaway) {
			let rowModel = new API.BuildClass(Types.Layaways_Items._name, API.RequestType.UPDATE);
			rowModel.object = item

			try {
				await rowModel.submit(null, 'POST', true);
			} catch (e) {
			}
		}
		this.setState({entries})
		//setTimeout(() => {
		this.props.updateEntries(this.state.entries)
		//}, 750)
	}

	handleReportModalClose = (forItem) => () => {
		this.setState({[forItem]: false});
	}

	handleAddPayment = (payment) => {
		this.state.cash_amount = payment.cash_amount;
		this.state.check_amount = payment.check_amount;
		let deposit = parseFloat(payment.cash_amount) + parseFloat(payment.check_amount);
		this.setState({deposit: parseFloat(deposit)});
	}

	handleScan = (data) => {
		this.fetchScanData(data).then((r) => r);
	}

	handleError = (err) => {
		console.log('Scan Error', err);
	}

	fetchScanData = async (data) => {
		let productType = data.split('-')[0];
		data = data.split('-')[1];
		// If scanned item is an Item
		if (productType === 'I') {
			$.ajax({
				type: "GET",
				url: APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/inventories/' + data,
				success: (data) => {
					API.ToastId = toast.info(data.response.skus.name + ' added to layaway.', {
						position: toast.POSITION.TOP_RIGHT
					});
					data.response.quantity = 1;
					this.addItems([data.response]).then((r) => r);
				},
				error: function (xhr, status, error) {
					//TODO: change this error msg
					let errorMessage = xhr.responseJSON.response === 'Product is already in cart' ? xhr.responseJSON.response : 'We were unable to find that product you requested on your account.'
					API.ToastId = toast.error(errorMessage, {
						position: toast.POSITION.TOP_CENTER
					});
				}
			});
			// If scanned item is a Product
		} else {
			$.ajax({
				type: "GET",
				url: APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/inventories/connected?search==pkey:' + data + ";",
				success: (data) => {
					API.ToastId = toast.info(data.response.items[0].manufacturers.name + ' ' + data.response.items[0].model + ' added to layaway.', {
						position: toast.POSITION.TOP_RIGHT
					});
					data.response.quantity = 1;
					this.addProducts([data.response.items[0]]).then((r) => r);
				},
				error: function (xhr, status, error) {
					//TODO: change this error msg
					let errorMessage = xhr.responseJSON.response === 'Product is already in cart' ? xhr.responseJSON.response : 'We were unable to find that product you requested on your account.'
					API.ToastId = toast.error(errorMessage, {
						position: toast.POSITION.TOP_CENTER
					});
				}
			});
		}
	}

	render = () => {
		/**
		 * @type {Layaways_Properties} properties
		 */
		let properties = this.model.properties;
		/**
		 *  @type {Layaways_Object} object
		 */
		let object = this.state;
		let {location} = this.props.rootState;
		const displayName = this.state.itemToChange ? this.state.itemToChange.name ? this.state.itemToChange.name : this.state.itemToChange.type === 'product' ? ((this.state.itemToChange.information.manufacturers ? this.state.itemToChange.information.manufacturers?.name : this.state.itemToChange.information.manufacturer_other) + ' | ' + this.state.itemToChange.information.model + ' | ' + (this.state.itemToChange.information.calibers ? this.state.itemToChange.information.calibers?.name : this.state.itemToChange.information.caliber_other)) : this.state.itemToChange.information.skus?.name  : null;
		object.tax_rate = parseFloat(location.TaxRateCity) + parseFloat(location.TaxRateCounty) + parseFloat(location.TaxRateSpecial) + parseFloat(location.TaxRateState);

		object.tax_amount = Math.ceil(parseFloat(object.sub_total_amount) * (object.tax_rate / 100) * 100) / 100;
		object.amount = (Math.ceil((object.sub_total_amount + object.tax_amount) * 100) / 100);

		object.remaining = (parseFloat(object.amount - (object.deposit))).toFixed(2);

		return (
			<>
				<fieldset>
					<div className={'row'}>
						<div className={'col-lg-12'}>
							<div className={'section-title'}>
								<h4 className="section-title">Layaway Items</h4>
								<div className={"button-container"}>
									<Tooltip title={"Add Best POS Products "}>
										<Button color={'primary'} size={'sm'}
										        onClick={this.toggle('modalLayawayItems')}>
											<i className={"fa fa-plus"}/>
											<span className="hidden-xs">&nbsp;Add Item</span>
										</Button>
									</Tooltip>
									<Tooltip style={{marginLeft: '0px'}} title={"Add FFL Boss Products "}>
										<Button color={'primary'} size={'sm'}
										        onClick={this.toggle('modalLayawayProducts')}>
											<i className={"fa fa-plus"}/>
											<span className="hidden-xs">&nbsp;Add Product</span>
										</Button>
									</Tooltip>
								</div>
								<BarcodeReader
									onError={this.handleError}
									onScan={this.handleScan}
								/>
							</div>
							{/*<div style={{*/}
							{/*	overflowY: "auto",*/}
							{/*	paddingRight: "4px",*/}
							{/*	maxHeight: "362px"*/}
							{/*}}>*/}
							{/*	{object.items && object.items.map((entry, i) =>*/}
							{/*		<Elements.Item*/}
							{/*			key={i}*/}
							{/*			entry={entry}*/}
							{/*			product={entry.items}*/}
							{/*			onDelete={this.deleteItem}*/}
							{/*			onUpdate={this.updateItem}*/}
							{/*			openModal={this.handleToggleItemModel('itemUpdate' + i)}*/}
							{/*			reportClose={this.handleReportModalClose('itemUpdate' + i)}*/}
							{/*			modalUpdate={this.state['itemUpdate' + i]}*/}
							{/*			history={this.props.history}*/}
							{/*		/>)}*/}
							{/*</div>*/}
							{/*<div style={{*/}
							{/*	overflowY: "auto",*/}
							{/*	paddingRight: "4px",*/}
							{/*	maxHeight: "362px"*/}
							{/*}}>*/}
							{/*	{object.products && object.products.map((entry, i) =>*/}
							{/*		<Elements.Product*/}
							{/*			key={i}*/}
							{/*			entry={entry}*/}
							{/*			product={entry.products}*/}
							{/*			onDelete={this.deleteProduct}*/}
							{/*			onUpdate={this.updateProduct}*/}
							{/*			openModal={this.handleToggleItemModel('itemUpdate' + i)}*/}
							{/*			reportClose={this.handleReportModalClose('itemUpdate' + i)}*/}
							{/*			modalUpdate={this.state['itemUpdate' + i]}*/}
							{/*			history={this.props.history}*/}
							{/*		/>)}*/}
							{/*	<p style={{textAlign: 'center', color: 'grey'}}><i>{object.items.length + object.products.length} Total Items</i></p>*/}
							{/*</div>*/}
							{this.state.entries?.length > 0 &&
								<div style={{
									overflowY: "auto",
									paddingRight: "4px",
									maxHeight: "362px"
								}}>

									{this.state.entries?.map((entry, i) => {
										return entry.type === 'product' ? <Elements.Product
											key={i}
											entry={entry}
											product={entry.information}
											onDelete={async () => {
												this.deleteItem(i).then()
											}}
											//onUpdate={this.updateProduct}
											openModal={() => {
												this.handleToggleItemModel(i)
											}}
											handleTaxableChange={async (value) => {
												this.handleTaxableChange(i, value).then()
											}}
											//reportClose={this.handleReportModalClose('itemUpdate' + i)}
											modalUpdate={this.state['itemUpdate' + i]}
											history={this.props.history}
										/> : <Elements.Item
											key={i}
											entry={entry}
											product={entry.information}
											onDelete={async () => {
												this.deleteItem(i).then()
											}}
											//onUpdate={this.updateItem}
											openModal={() => {
												this.handleToggleItemModel(i)
											}}
											handleTaxableChange={async (value) => {
												this.handleTaxableChange(i, value).then()
											}}
											//reportClose={this.handleReportModalClose('itemUpdate' + i)}
											modalUpdate={this.state['itemUpdate' + i]}
											history={this.props.history}
										/>
									})}
									<p style={{textAlign: 'center', color: 'grey'}}>
										<i>{object.entries?.length ?? 0} Total Items</i></p>
								</div>

							}
						</div>
					</div>
				</fieldset>

				{this.state.itemToChange && <Modal
					isOpen={this.state.itemIsChanging}
					toggle={() => this.setState({itemIsChanging: false})}
					size={'md'}
					backdrop={true}
					style={{opacity: 1}}
				>
					<ModalHeader toggle={() => this.setState({itemIsChanging: false})}>
						Update
						for: {displayName}
					</ModalHeader>
					<ModalBody>
						<div className={"row"}>
							<div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={displayName}
										id="name"
										label="Name"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={this.state.itemToChange.serial_number ?? this.state.itemToChange.information.skus?.serial_number ?? this.state.itemToChange.information.serial_number}
										id="serial_number"
										label="Serial Number"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={this.state.itemToChange.sku ?? this.state.itemToChange.information.skus?.sku}
										id="sku"
										label="Sku"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={this.state.itemToChange.cost ?? this.state.itemToChange.price ?? this.state.itemToChange.information.skus?.price_retail ?? this.state.itemToChange.information.price_list}
										id="cost"
										label="Cost"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={this.state.itemToChange.discount_amount}
										id="discount_amount"
										label="Discount Amount"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>
							{this.state.itemToChange.type !== 'product' && <div className="col-12">
								<div className={"form-group"} style={{display: 'grid'}}>
									<TextField
										value={this.state.itemToChange.quantity ?? this.state.itemToChange.information.quantity ?? 1}
										id="quantity"
										label="Quantity"
										variant="outlined"
										onChange={this.handleItemUpdate}
									/>
								</div>
							</div>}
						</div>
					</ModalBody>
					<ModalFooter style={{justifyContent: "space-between"}}>
						<Button type={"button"} className="btn btn-secondary"
						        onClick={() => this.setState({itemIsChanging: false})}>Close</Button>
						<Button type={"button"} className="btn btn-primary"
						        onClick={() => this.handleSavingRowModal()}>Save</Button>
					</ModalFooter>
				</Modal>}

				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Items"}
					object={Collections.LayawayItems}
					history={this.props.history}
					toggle={this.state['modalLayawayItems']}
					reportClose={this.reportClose('modalLayawayItems')}
					afterSave={this.addItems}
					size={'xl'}
					objectProps={{inStock: true}}
				/>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Products"}
					object={Collections.LayawayProducts}
					history={this.props.history}
					toggle={this.state['modalLayawayProducts']}
					reportClose={this.reportClose('modalLayawayProducts')}
					afterSave={this.addProducts}
					size={'xl'}
				/>
				<Framework.Elements.UniversalModal
					// isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Layaway Payment"}
					object={Objects.AddLayawayPayment}
					history={this.props.history}
					toggle={this.state['modalPayments']}
					reportClose={this.reportClose('modalPayments')}
					//afterSave={this.addProducts}
					size={'lg'}
					objectProps={{
						handleAddPayment: this.handleAddPayment,
						total: this.state.amount,
					}}
				/>
			</>
		)
	}
}

export default Framework.Components.withRootState(LayawayItems);
