import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";

import {Collections, Objects} from "components";
import {Button} from "reactstrap";

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Users',
		url: '/users',
		icon: 'users',
	}
];

export default class Users extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-certificate" onClick={this.toggle('modalUser')} size={"sm"} color="primary">
						<i className="fa fa-user"/>&nbsp;&nbsp;Add User
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Users
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					name={"User"}
					object={Objects.User}
					history={this.props.history}
					toggle={this.state['modalUser']}
					reportClose={this.reportClose('modalUser')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		);
	}
}


