import React from 'react';
import PropTypes from 'prop-types';
import {CSVLink} from 'react-csv';
import {Button} from 'reactstrap';
import Tooltip from "@material-ui/core/Tooltip";
import Download from '@material-ui/icons/GetApp'
import Delete from '@material-ui/icons/Delete'
import Replay from '@material-ui/icons/Replay'

class TableHeaderRight extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		showCSV: PropTypes.bool,
		csvData: PropTypes.array,
		onReload: PropTypes.func,
		onClear: PropTypes.func,
		children: PropTypes.any, //Element or Array of Elements

	};

	static defaultProps = {
		name: 'collection',
		showCSV: true,
		csvData: [],
		onReload: null,
		onClear: null,
		children: null,

	};

	componentDidMount() {
		let {name} = this.props;

		this.fileName = `${name}.csv`;
	}

	render() {
		const {children, csvData, showCSV, onReload, onClear} = this.props;

		return (
			<div className="TableHeaderRight">
				{children}
				{csvData.length > 0 && showCSV ?
					<div className={`${!children ? 'ml-auto ' : ' '}download`}>
						<Tooltip title={"Download"} placement={"top"}>
							<CSVLink
								data={csvData}
								filename={this.fileName}
								className="btn btn-secondary"
								target="_blank"
							>
								<Download fontSize={'small'}/>
							</CSVLink>
						</Tooltip>
					</div> : null}
				{onClear &&
				<div className="refresh">
					<Tooltip title={"Clear Filters"} placement={"top"}>
						<Button color="secondary" onClick={onClear}>
							<Delete fontSize={'small'}/>
						</Button>
					</Tooltip>
				</div>}
				{onReload &&
				<div className="refresh">
					<Tooltip title={"Refresh"} placement={"top"}>
						<Button color="secondary" onClick={onReload}>
							<Replay fontSize={'small'}/>
						</Button>
					</Tooltip>
				</div>}
			</div>
		);
	}
}

export default TableHeaderRight;
