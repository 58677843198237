import React from "react";
import classNames from "classnames";
import ClipLoader from 'react-spinners/ClipLoader';
import API from "@beardeddevops/react.api";
import APPCONFIG from "constants/Config";
import Types from "constants/Types";
import Framework from "@beardeddevops/react.framework";
import ReactTable from "react-table";
import {Card, CardContent} from "@material-ui/core";

class InventoryReport extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Skus._name, API.RequestType.READ_ALL);
		super(props, model);
		this.limit = 0;
		this.state.limit = 0;
	}

	formatData = async (res) => {

		let total_price = 0.00;
		let total_quantity = 0;
		let sales_price = 0.00;
		let total_cost = 0.00;
		res.rows.forEach((item) => {
			total_price += parseFloat(item.price_retail);
			total_quantity += item.inventoriess[0].quantity;
			sales_price += parseFloat(item.price_retail * item.inventoriess[0].quantity);
			total_cost += parseFloat((item.price_purchase ?? 0) * item.inventoriess[0].quantity);
		});

		//add empty row
		res.rows.push({
			total_key: true,
			name: "",
			sku: "",
			price_purchase: "",
			quantity: "",
			sale_price: "",
			total_cost: ""
		})
		//add the totals row
		res.rows.push({
			total_key: true,
			name: "",
			sku: "",
			price_purchase: "Sum of Columns:",
			price_retail: total_price.toFixed(2),
			quantity: total_quantity,
			sale_price: sales_price.toFixed(2),
			total_cost: total_cost.toFixed(2)
		})

		return res;
	}

	render() {
		const {data, pages, loading, limit} = this.state;
		let columns =
			[
				{
					Header: "Name",
					id: "name",
					accessor: r => r.name,
				},
				{
					Header: "SKU",
					id: "sku",
					accessor: r => r.sku,
				},
				{
					Header: "Cost",
					id: "price_purchase",
					accessor: r => typeof r.total_key !== 'undefined' ? r.price_purchase : "$ " + (parseFloat(r.price_purchase ?? 0)).toFixed(2),
				},
				{
					Header: "Price",
					id: "price_retail",
					accessor: r => typeof r.total_key !== 'undefined' ? r.price_retail : "$ " + (parseFloat(r.price_retail)).toFixed(2),

				},
				{
					Header: "Quantity",
					id: "quantity",
					accessor: r => typeof r.total_key !== 'undefined' ? r.quantity : r.inventoriess[0].quantity,

				},
				{
					Header: "Sale Price",
					id: "sale_price",
					accessor: r => typeof r.total_key !== 'undefined' ? r.sale_price : "$ " + (parseFloat(r.price_retail * r.inventoriess[0].quantity)).toFixed(2),

				},
				{
					Header: "Total Cost",
					id: "total_cost",
					accessor: r => typeof r.total_key !== 'undefined' ? r.total_cost : "$ " + (parseFloat((r.price_purchase ?? 0) * r.inventoriess[0].quantity)).toFixed(2),
				}
			];


		return (
			<Card>
				<CardContent>
					<div className="box-body">

						<div className="table-bar">
							<div className="d-flex">
								{this.renderHeaderRight("Transactions", columns)}
							</div>
						</div>

						<ReactTable
							key={this.state.key}
							columns={columns}
							manual // Forces table not to paginate or sort automatically, so we can handle it server-side
							data={data}
							pages={pages} // Display the total number of pages
							loading={loading} // Display the loading overlay when we need it
							loadingText="Requesting Data"
							onFetchData={this.fetchData} // Request new data when things change
							defaultSorted={[
								{
									id: this.model.sort,
									desc: this.model.order === 'desc'
								}
							]}
							expanderDefaults={{
								sortable: true,
								resizable: true,
								filterable: false,
								width: undefined
							}}
							showPagination={false}
							showPaginationTop={false}
							showPaginationBottom={false}
							showPageSizeOptions={false}
							defaultPageSize={limit}
							onPageSizeChange={this.onPageSizeChange}
							className="-striped -highlight"
						/>

					</div>
					{APPCONFIG.settings.isDev() ? null :
						<div className={classNames("tabled", "loader", {'active': loading})}>
							<ClipLoader color={"#4A4A4A"} loading={loading}/>
						</div>
					}
				</CardContent>
			</Card>
		)
	}
}

export default Framework.Components.withRootState(InventoryReport);


