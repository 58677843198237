import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Collections, Objects} from "components";

import {Button} from "reactstrap";

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Gift Certificates',
		url: '/certificates',
		icon: 'gift',
	},
];

export default class Certificates extends Framework.Components.View {

	render() {
		return (
			<div className="container-fluid">

				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}>
					<Button data-id="add-certificate" onClick={this.toggle('modalCertificate')} size={"sm"}
					        color="primary">
						<i className="fa fa-gift"/>&nbsp;&nbsp;Add Gift Certificate
					</Button>
				</Framework.Components.PageBar>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					<Collections.Certificates
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>
				</Framework.Errors.General>

				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Gift Certificate"}
					object={Objects.Certificate}
					history={this.props.history}
					toggle={this.state['modalCertificate']}
					reportClose={this.reportClose('modalCertificate')}
					afterSave={this.handleAfterSave}
					size={'lg'}
				/>

			</div>
		);
	}
}
