import React from 'react';
import ObjectState from './ObjectState';

export default class View extends ObjectState {

	constructor(props, defaultTab = '') {
		super(props);
		this.state.name = this.props.match ? (this.props.match.params.id || '') : '';
		this.state.key = Math.random();
		this.state.tab = this.getAnchor(defaultTab);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	updateName = (name) => {
		if (this.state.name !== name) {
			//this.state.name = name;
			this.setState({
				name: name
			});
		}
	};

	handleTabChange = (event, newValue) => {
		this.props.history.push('#'+newValue);
		this.setState({tab: newValue});
	};

	getAnchor(defaultTab) {
		let anchor = 0;
		let hash = this.props.history.location.hash;
		if( hash !== '' ) {
			anchor = hash.substr(1);
		} else if(defaultTab) {
			anchor = defaultTab;
		}
		return anchor;
	}

	refreshPage = () => {
		this.setState({
			key: Math.random(),
			refresh: !this.state.refresh
		});
	};
}
