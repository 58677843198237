import React from "react";
import {withRouter} from "react-router";
import classNames from "classnames";

import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';

import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";

import moment from "moment";
import Types from 'constants/Types';
import APPCONFIG from "../../constants/Config";
import {Objects} from "../index";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";


class SubscriptionObject extends Framework.Components.Object {

	constructor(props) {
		/**
		 *  @typedef {Accounts_Subscriptions_Object} this.state
		 */
		super(props, Types.Accounts_Subscriptions._name);
		this.state.card_error = false;
	}

	loadSelfAfter(data) {
		this.loadBilling().then();
		if (!this.props.rootState.no_subscription) {
			this.loadTerimals().then();
		}
	}

	loadTerimals = async () => {
		let terminals = new API.BuildClass(Types.Accounts_Subscriptions._name, API.RequestType.READ_ALL);
		terminals.url = "/api/terminals/check";
		let data = await terminals.submit(null, API.RequestType.READ);
		this.setState({quantity: data.quantity, plan_name: data.plan_name})
	}
	loadBilling = async () => {
		let billing = new API.BuildClass(Types.Accounts_Billing._name, API.RequestType.READ);
		let data;
		try {
			data = await billing.submit(null, API.RequestType.READ);
			let date = moment();
			date.set("year", data.exp_year)
			date.set("month", data.exp_month)
			this.setState({
				card_brand: data.brand,
				card_exp_date: data.exp_month + "/" + data.exp_year.toString().slice(2, 4),
				card_last4: data.last4,
				card_error: false
			})
			if (typeof(this.props.setCardError) === 'function') {
				this.props.setCardError(false);
			}
		} catch (e) {
			if (typeof(this.props.setCardError) === 'function') {
				this.setState({card_error: true})
				this.props.setCardError(true);
			}
		}
	}


	render() {
		let {properties} = this.model;
		let {id, current_period_end, quantity, loading, plan, plan_name, card_error} = this.state;
		let exists = !!(id || plan);
		let price = plan_name === 'Terminals' ? (((quantity - 1) * 50) + 75) : 50;

		return (
			<div>
				{exists ?
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<Typography variant="h5" component="h3">
							Subscription
						</Typography>
						<Typography variant="h5" component="h3">
							Current Plan: <b>{plan_name}</b>
						</Typography>
					</div> :
					<Typography variant="h5" component="h3">
						Subscription
					</Typography>}
				<br/>
				{this.state.card_brand && <div className={"text-center"}>
					<i>Current Card is a {this.state.card_brand} ending in {this.state.card_last4} Expires
						on {this.state.card_exp_date}</i>
				</div>}
				<hr/>
				{exists ? <>
						{plan_name === 'Terminals' &&
							<p style={{textAlign: "center", margin: "0 0 0 10px"}}>You have {this.props.rootState.api_key_exists ? quantity : 'no'} active
								terminal(s) *</p>}
						<h4 style={{textAlign: "center", margin: "0 0 10px"}}>Your cost is ${price}/mo</h4>
					</> :
					<h4 style={{textAlign: "center", margin: "0 0 10px"}}>You have no active subscriptions. Please
						subscribe below, contact support if you have questions
						or concerns.</h4>}
				{exists ? <div style={{textAlign: "center", fontSize: "12px"}}>Renews
					on {moment.unix(current_period_end).format("MM/DD/YYYY")}<br/><br/></div> : null}
				<p style={{textAlign: "center", fontSize: "12px"}}>
					<b>$50/mo</b> for <i style={{fontSize: "14px", fontStyle: 'italic'}}>Cash Only</i>
					<br/>
					<b>$75/mo</b> for 1 Terminal connection<br/>
					<b>$50/mo</b> for each additional Terminal connection
				</p>
				{(exists && plan_name === 'Terminals' && this.props.rootState.api_key_exists) ? <p style={{textAlign: "center", fontSize: "12px"}}>
					<strong>* Your Subscription will automatically adjust to the number of<br/>Dejavoo z11s that are
						active with your payment processor.</strong>
				</p> : null}
				{(!this.props.rootState.api_key_exists && plan_name === 'Terminals') && <p style={{textAlign: "center", fontSize: "14px"}}><strong>*To add terminals, update the API key under Account Settings*</strong></p>}
				{card_error && <p style={{textAlign: "center", fontSize: "18px"}}>
					<strong>Please update your card info to subscribe.</strong>
				</p>}

				<div className={classNames("loader", {'active': loading})}>
					<ClipLoader color={"#4A4A4A"} loading={loading}/>
				</div>

			</div>
		)
	}
}

export default Framework.Components.withRootState(SubscriptionObject);
