import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import ErrorBoundaryRoute from "../../../../containers/ErrorBoundaryRoute";
import Object from './components/Checkout';

export default ({match, locations, account,shouldReloadCart}) => (
	<div>
		<Switch>
			<ErrorBoundaryRoute path={`${match.url}`}>
				<Object locations={locations} account={account} baseUrl={`${match.url}`} shouldReloadCart={shouldReloadCart} />
			</ErrorBoundaryRoute>

			<ErrorBoundaryRoute>
				<Redirect to={`${match.url}`}/>
			</ErrorBoundaryRoute>
		</Switch>
	</div>
);
