import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import ClipLoader from 'react-spinners/ClipLoader';
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

import moment from "moment";


export default class Layaways extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Layaways._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 16;
		this.limit = 16;
	}

	render() {
		const {data, pages, limit, loading} = this.state;
		const columns = [
			{
				Header: "Layaway",
				id: "pkey",
				accessor: r => r.pkey
			},
			{
				Header: "Customer",
				id: "customer",
				accessor: obj => obj.customer
			},
			{
				Header: "Created Date",
				id: "created",
				accessor: obj => {
					return obj.created ? moment(obj.created).local().format("MM/DD/YYYY") : '-'
				}
			},
			{
				Header:"Price",
				id: "subtotal",
				accessor: r => r.subtotal =  "$" + parseFloat(r.amount - r.tax_amount).toFixed(2)
			},
			{
				Header: "Tax",
				id: "tax_amount",
				accessor: r => (parseFloat(r.tax_amount)) <= 0.00 ? "Non-Taxable" :
					"$" + r.tax_amount
			},
			{
				Header:"Total",
				id: "amount",
				accessor: r => "$" + r.amount
			},
			{
				Header: "Remaining Amount",
				id: "remaining",
				accessor: r => "$" + r.remaining
			},
			{
				Header: "Est. Payoff Date",
				id: "estimated_date",
				accessor: obj => {
					return obj.estimated_date ? moment(obj.estimated_date).local().format("MM/DD/YYYY") : '-'
				}
			},
			{
				Header: "Completed Date",
				id: "completed_date",
				accessor: obj => {
					return obj.completed_date ? moment(obj.completed_date).local().format("MM/DD/YYYY") : '-'
				}

			},

			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 60,
				Cell: props => {
					return (
						<span>
							<Link to={`/layaways/` + props.original.pkey}>
								<Button color="primary" size={"sm"} block={true}>View</Button>
							</Link>
						</span>
					);
				}
			},
		];
		return (
			<Card>
				<CardContent>
					<div className="table-bar">
						<div className="d-flex">
							{/*Todo: Suggest Search does not work (probably back end) --- Undefined index: properties"  */}
							{this.renderSearch("Layaways")}
							{this.renderHeaderRight("Layaways",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						expanderDefaults={{
							sortable: true,
							resizable: true,
							filterable: false,
							width: undefined
						}}
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						onPageSizeChange={this.onPageSizeChange}
						className="-striped -highlight"
					/>
				</CardContent>
				{APPCONFIG.settings.isDev() ? null :
					<div className={classNames("tabled", "loader", {'active': loading})}>
						<ClipLoader color={"#4A4A4A"} loading={loading}/>
					</div>
				}
			</Card>
		)
	}
}
