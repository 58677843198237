import React from 'react';
import InputMask from 'react-input-mask';


const FFL = (props) => {
	const { inputRef, ...other } = props;
	//9-92-280-01-9H-35237
	return (
		<InputMask
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask="9-99-999-99-9a-99999"
			maskChar=" "
		/>
	);
};

export default FFL;