import React from "react";
import classNames from "classnames";
import {Button, ButtonGroup} from 'reactstrap';
import {InputAdornment, TextField, Card, CardContent} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Cards from 'react-credit-cards';
import Cookies from 'js-cookie';
import ClipLoader from 'react-spinners/ClipLoader';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import API from "@beardeddevops/react.api";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import 'react-credit-cards/es/styles-compiled.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import APPCONFIG from "../../constants/Config";
import {Objects} from "../index";
import {isNumeric} from "echarts/lib/util/number";
const { UniversalModal } = Framework.Elements;


class CheckoutObject extends Framework.Components.Object {
	constructor(props) {
		super(props, Types.Checkouts._name);
		this.read = 'GET_ACTIVE';
		this.state.sub_total_tax = 0;
		this.state.sub_total_nontax = 0;
		this.state.locations = null;
		this.state.items = [];
		this.state.layaways = [];
		this.state.products = [];
		this.state.terminals = [];
		this.state.all_registers = [];
		this.state.manual = false;
		this.state.manual_card = false;
		this.state.optionalFields = false;
		this.state.card_percentage = false;
		this.state.card_expiry = "";
		this.state.sudo_card = "";

		this.state.focused = "";
		this.state.alignment = 'amount';
		this.state.taxable = true;
		this.state.showRegister = false;
		this.state.showOtherFields = false;
		this.state.itemsCount = 0;
		this.state.itemKey = Math.random();
		this.itemRef = React.createRef();
		this.state.itemToChange = {name: '', serial: '', sku: '', cost: 0.00};
		this.state.itemIsChanging = false;
		this.timeout = null;
		this.state.type = null;

		//layaway state vars
		this.state.layaway = false;
		this.state.product_information = [];

		this.pause = this.pause.bind(this)
		this.checkout = this.checkout.bind(this);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		super.componentDidUpdate(prevProps, prevState, snapshot);
		if (prevProps.shouldReloadCart !== this.props.shouldReloadCart) {
			this.loadItems().then();
			this.loadProducts().then();
		}
		//Is this even needed anymore? It's all set on the backend now.
		//this.findItemCount().then();

	}

	async findItemCount() {

		let itemsCount = this.state.itemsCount;

		//Get items
		let itemsContainer = this.state.items
		itemsContainer?.forEach((item) => {
			itemsCount += item.quantity;
		})

		//Add Products
		itemsCount += this?.state?.products?.length ?? 0;

		//Get consignments - Consignment's don't technically have items, so they will usually be a quantity of one.
		let consignmentsContainer = this.state?.consignments;
		consignmentsContainer?.forEach((consignment) => {
			itemsCount += consignment.quantity;
		})

		itemsCount += this?.state?.layaways?.length > 0 ? 1 : 0

		//Updates badge on side nav
		this.props.rootState.set({
			session_items_count: itemsCount
		})

	}

	loadSelfAfter(data) {
		if (data.pkey) {
			this.setState({"creator_name": data.creator_name});
			this.setState({"locations": data.locations});
			this.setState({"order_id": data.pkey});

			if (this.state.discount_percentage === 0) {
				this.setState({"discount_percentage": "0"});
			}

			this.loadItems().then();
			this.loadConsignments().then();
			this.loadProducts().then();
			this.loadTrades().then();
			this.loadLayaways().then();
		}
	}

	async loadItems() {
		this.state.items = [];
		let items = new API.BuildClass(Types.Checkouts_Items._name, API.RequestType.READ_ALL);
		items.parent = this.state.pkey
		let data = await items.submit(null, API.RequestType.READ_ALL)
		this.setState({items: data.items})
	}

	async loadLayaways() {
		this.state.layaways = [];
		let layaways = new API.BuildClass(Types.Checkouts_Layaways._name, API.RequestType.READ_ALL);
		layaways.parent = this.state.pkey
		let data = await layaways.submit(null, API.RequestType.READ_ALL)
		this.setState({layaways: data.items})
	}

	async loadTrades() {
		this.state.trades = [];
		let items = new API.BuildClass(Types.Checkouts_Trades._name, API.RequestType.READ_ALL);
		items.parent = this.state.pkey
		let data = await items.submit(null, API.RequestType.READ_ALL)
		this.setState({trades: data.items})
	}

	async loadConsignments() {
		this.state.consignments = [];
		let consignments = new API.BuildClass(Types.Checkouts_Consignments._name, API.RequestType.READ_ALL);
		consignments.parent = this.state.pkey
		let data = await consignments.submit(null, API.RequestType.READ_ALL)
		this.setState({consignments: data.items})
	}

	async loadProducts() {
		let products = new API.BuildClass(Types.Checkouts_Products._name, API.RequestType.READ_ALL);
		this.state.products = [];
		products.parent = this.state.pkey
		// noinspection JSIgnoredPromiseFromCall
		let data = await products.submit(null, API.RequestType.READ_ALL)
		if (!Array.isArray(data.items) || data.items.length <= 0) {
			this.state.products = [];
		} else {
			let pkeys = [];
			for (let item of data.items) {
				pkeys.push(item.product)
			}

			let product = new API.BuildClass(Types.Inventories_Connected._name, 'GET_ALL');
			product.search = '=' + pkeys.map(pkey => {
				return (`pkey:${pkey};`)
			});
			product.search = product.search.replaceAll(',', '')

			let theProduct = await product.submit(null, API.RequestType.READ_ALL);

			this.setState({product_information: theProduct.items})
			const result = [...theProduct.items.reduce((r, o) => {
				const id = o.product || o.pkey;
				r.has(id) || r.set(id, {});
				const item = r.get(id);
				delete item.taxable;
				Object.entries(o).forEach(([k, v]) => {
					if (k === 'pkey') {
						for (let dataitems of data.items) {
							if (dataitems.product === v) {
								item['checkout'] = dataitems.pkey;
								item['taxable'] = this.state.type !== null ? !this.state.type : dataitems.taxable;
								//these are the editable columns for products, this is to make it easier to manage in 1 column instead of multiple
								//item name is for changing the product name in-line and editing the checkout overall
								item['name'] = dataitems.name || ((o.manufacturers !== undefined ? o.manufacturers.name : o.manufacturer_other) + " " + o.model + " " + o.type);
								item['serial'] = dataitems.serial || o.serial_number;
								item['sku'] = dataitems.sku || o.sku;
								item['cost'] = dataitems.cost || o.price_list;
								//debugging purposes
								// console.log("logging the product in checkout", dataitems, item, o);
							}
						}
					}
					//put any column you set up there in this if, so it won't get overwritten
					//skip taxable, its null and will set it to null
					if (k === 'taxable' || k === 'name' || k === 'serial' || k === 'sku' || k === 'cost') {
						return;
					}
					item[k] = Array.isArray(item[k]) ?
						[...new Set([...item[k], ...v])] : v;
				});
				return r;
			}, new Map()).values()]
			this.setState({products: result, keys: Math.random()});
		}

	}

	handleAmountFocus = (event) => {
		if (this.state[event.currentTarget.name] === "0.00") {
			this.setState({[event.currentTarget.name]: this.state.remaining});
		}
	};

	//Removes a BPOS Item
	removeItemFromCart = async (pkey) => {
		this.setState({loading: true});
		setTimeout(async () => {
			let item = new API.BuildClass(Types.Checkouts_Items._name, API.RequestType.DELETE);
			item.object.pkey = pkey
			item.parent = this.state.pkey
			await item.submit(null, API.RequestType.DELETE).then(() => {
				this.loadItems()
				this.setState({loading: false})
			})
		}, 500)

	};

	removeTradeFromCart = async (pkey) => {
		this.setState({loading: true});
		setTimeout(async () => {
			let item = new API.BuildClass(Types.Checkouts_Trades._name, API.RequestType.DELETE);
			item.object.pkey = pkey
			item.parent = this.state.pkey
			await item.submit(null, API.RequestType.DELETE).then(() => {
				this.loadTrades()
				this.setState({loading: false})
			})
		}, 500)

	};

	removeConsignmentFromCart = async (pkey) => {
		// this.setState({loading:true});
		setTimeout(async () => {
			let item = new API.BuildClass(Types.Checkouts_Consignments._name, API.RequestType.DELETE);
			item.object.pkey = pkey
			item.parent = this.state.pkey
			// noinspection JSIgnoredPromiseFromCall
			await item.submit(null, API.RequestType.DELETE).then(() => {
				this.loadConsignments()
				this.setState({loading: false})
			})
		}, 500)
	};

	updateItemQuantityValue = (index, inventory, pkey) => event => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
		if (event.currentTarget.value === '' || (event.currentTarget.value !== '0' && re.test(event.currentTarget.value))) {
			let items = this.state.items;
			items[index].quantity = event.currentTarget.value !== '' ? parseFloat(event.currentTarget.value) : '';
			// this.setState({items: items});
			if (items[index].quantity !== "" && items[index].quantity !== 0) {
				this.updateItemQuantity(pkey, inventory, event.currentTarget.value).then();
			}
		}
	};

	updateSingularDiscount = (index, inventory, pkey, table) => event => {
		if (table === 'item') {
			let items = this.state.items;
			items[index].discount_amount = event.currentTarget.value;
			this.setState({items: items});
		}
		if (table === 'consignment') {
			let items = this.state.consignments;
			items[index].discount_amount = event.currentTarget.value;
			this.setState({consignments: items});
		}
		if (table === 'product') {
			let items = this.state.products;
			items[index].discount_amount = event.currentTarget.value;
			this.setState({products: items});
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			if (table === 'item') {
				this.updateItemQuantity(pkey, inventory, this.state.items[index].discount_amount, true).then();
			}
			if (table === 'consignment') {
				this.updateConsignmentQuantity(pkey, inventory, this.state.consignments[index].discount_amount, true).then();
			}
			if (table === 'product') {
				this.updateProductDiscount(pkey, this.state.products[index].discount_amount).then();
			}
		}, 800);
	};

	//check where it calls loaditems and see why state isnt undefined there

	async updateCheckoutTaxable(classname, pkey, value, preventLoading = false) {
		let item = new API.BuildClass(Types['Checkouts_' + classname]._name, API.RequestType.UPDATE)
		item.parent = this.state.pkey;
		item.object = {
			pkey: pkey,
			checkout: this.state.pkey,
			taxable: value
		}
		await item.submit(null, API.RequestType.UPDATE, true)
		if (preventLoading) {
			return;
		}
		this.loadItems().then();
		this.loadConsignments().then();
		this.loadProducts().then();

	}

	async updateProductDiscount(pkey, discount) {
		let item = new API.BuildClass(Types.Checkouts_Products._name, API.RequestType.UPDATE)
		item.parent = this.state.pkey;

		item.object = {
			pkey: pkey,
			checkout: this.state.pkey,
			discount_amount: discount
		}
		await item.submit(null, API.RequestType.UPDATE, true)
		await this.loadItems();
	};

	async updateItemQuantity(pkey, inventory, quantity, discounting_singular = false) {
		let item = new API.BuildClass(Types.Checkouts_Items._name, API.RequestType.UPDATE)
		item.parent = this.state.pkey;
		if (discounting_singular) {
			item.object = {
				pkey: pkey,
				inventory: inventory,
				checkout: this.state.pkey,
				discount_amount: quantity
			}
		} else {
			item.object = {
				pkey: pkey,
				inventory: inventory,
				checkout: this.state.pkey,
				quantity: quantity
			}
		}
		await item.submit(null, API.RequestType.UPDATE, true)
		await this.loadItems();
	};

	async updateConsignmentQuantity(pkey, inventory, quantity, discounting_singular = false) {
		let item = new API.BuildClass(Types.Checkouts_Consignments._name, API.RequestType.UPDATE)
		item.parent = this.state.pkey;
		if (discounting_singular) {
			item.object = {
				pkey: pkey,
				inventory: inventory,
				checkout: this.state.pkey,
				discount_amount: quantity
			}
		} else {
			item.object = {
				pkey: pkey,
				inventory: inventory,
				checkout: this.state.pkey,
				quantity: quantity
			}
		}
		await item.submit(null, API.RequestType.UPDATE, true)
		await this.loadItems();
	};

	removeProductFromCart = async (pkey) => {
		setTimeout(async () => {
			let item = new API.BuildClass(Types.Checkouts_Products._name, API.RequestType.DELETE);

			item.object.pkey = pkey
			item.parent = this.state.pkey
			await item.submit(null, API.RequestType.DELETE).then(() => {
				this.loadProducts()
				this.setState({loading: false})
			})
		}, 500)
	};

	removeLayawayFromCart = async (pkey) => {
		setTimeout(async () => {
			let item = new API.BuildClass(Types.Checkouts_Layaways._name, API.RequestType.DELETE);

			item.object.pkey = pkey
			item.parent = this.state.pkey
			await item.submit(null, API.RequestType.DELETE).then(() => {
				this.loadLayaways().then()
				this.setState({loading: false})
			})
		}, 500)
	};

	toggleManualCard = async () => {
		this.setState({manual_card: !this.state.manual_card});
	}

	toggleOtherPaymentsManual = async () => {
		this.setState({manual: !this.state.manual});

		//set things back to zero, so it can actually go back
		if (this.state.manual) {
			this.setState({
				other_amount: "0.00",
				check_amount: "0.00",
				cash_amount: "0.00",
				invoice_amount: "0.00"
			});
		}

		let showRegister = this.state.showRegister;
		(showRegister ? this.setState({showRegister: false}) : this.setState({showRegister: true}))

	}

	toggleOtherFields = async () => {
		this.setState({optionalFields: !this.state.optionalFields});

		let showOtherFields = this.state.showOtherFields;
		(showOtherFields ? this.setState({showOtherFields: false}) : this.setState({showOtherFields: true}))

	}

	async pause() {
		await this.model.submit(this, API.RequestType.UPDATE)
		this.props.history.push("/sessions")
		this.props.rootState.set({
			session_items_count: 0
		})
	}

	async checkout(request) {
		//if layaway, determine what route to call
		if (this.state.layaway && request !== 'POST_CARD') {
			if(isNumeric(this.state.card_amount) && this.state.card_amount > 0){
				request = 'POST_TERMINAL';
			} else {
				request = 'POST_CASH';
			}
		}
		//this.update = this.state.card_amount !== "" ? "POST_TERMINAL" : request;
		this.update = request;
		if (parseFloat(this.state.amount) <= 0) {
			confirmAlert({
				title: 'Confirm Transaction',
				message: 'Are you sure you want to checkout for $0?',
				buttons: [
					{
						label: 'Yes',
						onClick: async () => {
							await this.handleSubmit(null);

							//Work around for setting badge counter after checkout completion
							this.props.rootState.set({
								session_items_count: 0
							})
						}
					},
					{
						label: 'No',
						onClick: () => {
						}
					}
				]
			});
		} else {
			await this.handleSubmit(null);

			//Work around for setting badge counter after checkout completion
			this.props.rootState.set({
				session_items_count: 0
			})
		}
	};

	handleSubmit = async () => {
		if (this.state.layaway && !this.state.layawayPkey) {
			confirmAlert({
				title: 'Confirm Transaction',
				message: 'Are you sure you want to start a layaway?',
				buttons: [
					{
						label: 'Yes',
						onClick: async () => {
							await super.handleSubmit(null);
						}
					},
					{
						label: 'No',
						onClick: () => {
						}
					}
				]
			});
		} else {
			await super.handleSubmit(null);
		}
	}

	async afterSubmit(data) {
		if (this.state.layaway) {
			this.props.history.push("/layaways/" + data.pkey);
		} else {
			this.props.history.push("/transactions/" + data.pkey);
		}
	}

	handleAlignment = (event, alignment) => {
		if (alignment === "amount") {
			this.setState({discount_percentage: '0'});
		} else {
			this.setState({discount_amount: '0.00'});
		}
		this.setState({alignment});
	};

	// This function handles what value is input into the Gift Certificate Amount textfield
	handleCertificateChange = (property, value, linked, object) => {
		// Use a function inside setState to access the current state
		this.setState(prevState => {
			// Initialize an object to hold the new state values
			let newState = {};

			// First, ensure the remaining amount is updated or confirmed based on existing conditions.
			if (prevState.cash_amount === '0.00' && prevState.check_amount === '0.00' && prevState.invoice_amount === '0.00') {
				newState.remaining = prevState.amount;
			} else {
				newState.remaining = prevState.remaining;
			}

			/*
			If there is a certificate and the value of that certificate is a number than we proceed to figure what value to put into
			the Gift Certificate Amount textfield. If false, then the value '0.00' is put into that textfield.
			*/
			if (object && !isNaN(object.value)) {
				// Need to parse the strings into numbers for proper comparison.
				let certificateValue = parseFloat(object.value);
				let currentRemaining = parseFloat(prevState.amount) - (parseFloat(prevState.cash_amount) + parseFloat(prevState.check_amount) + parseFloat(prevState.invoice_amount));

				/*
				If the gift certificate value is greater than the remaining value of the transaction, than we will have the
				Gift Certificate Amount textfield display the remaining balance. If false, then the textfield will display
				the amount on the Gift Certificate.
				 */
				if (certificateValue > currentRemaining) {
					newState.other_amount = currentRemaining.toFixed(2).toString();
					newState.remaining = '0.00';
				} else {
					newState.other_amount = object.value;
					newState.remaining = (currentRemaining - certificateValue).toFixed(2).toString();
				}
			} else {
				newState.other_amount = '0.00';
			}

			// Return the new state object to update the component's state.
			return newState;
		}, () => {
			// Callback function to execute after state is updated.
			this.handleSelectPropertyUpdate(property, value, linked, object);
		});
	}

	handleAmountChange = (property, value) => {
		this.handleTextFieldPropertyUpdate(property, value)
	}

	handleDateChange = (property, value) => {
		value = moment(value).format("MM/YY")
		this.setState({card_expiry: value})
		this.handleTextFieldPropertyUpdate(property, value)
	}

	async handleTaxableStatus(object, type) {
		const promise = new Promise((resolve, reject) => {
			//add products back in later
			const checkouts = {'items': 'Items', 'consignments': 'Consignments'} // , 'products': 'Products'
			try {
				Object.keys(checkouts).map(async (key) => {
					//iterates through the keys of the checkouts obj, they point to the stateful items/consignments/products
					object[key].map(async (item) => {
						//iterates through each individual item/consignment/product
						//taxable is set to !type, didn't feel like ripping all of it out to fix
						await this.updateCheckoutTaxable(checkouts[key], item.pkey, !type, true);
						//calls the updateCheckoutTaxable fn to set the Checkouts_Items/Products/Consignments.taxable correctly, passes in the key ('items', 'consignments', or 'products') as well as the value ('Items', 'Consignments', etc)
						//the fn requires the value, but the stateful items are the key
					})
				})
				resolve(true)
			} catch (e) {
				reject(false)
			}
		})

		promise.then(async (result) => {
			if (result) {
				await this.loadItems().then();
				await this.loadConsignments().then();
				await this.loadProducts().then();
			}
		})
	}

	//Toggle between Taxable and non-taxable amounts
	toggleTax(object) {
		let taxable = object.taxable;
		if (taxable === false) {
			object.tax_amount = 0;
			object.amount = (Math.ceil((object.sub_total) * 100) / 100);
			object.remaining = object.amount
		}
		if (taxable === true) {
			const originalTax = object.tax_amount;
			object.tax = originalTax;
			object.amount = (Math.floor((object.sub_total + originalTax) * 100) / 100);
			object.remaining = object.amount;
		}
	}

	//Handles Taxable status of buttonGroup
	handleTypeChange = (event, type) => {
		type = false;
		let object = this.state;
		if (event.currentTarget.id === 'false') type = true;
		this.setState({type: type})
		this.handleTaxableStatus(object, type);
	}

	handleRowClick = (item = null, product = null, consignment = null) => {
		if (item === null && product === null && consignment === null) {
			return;
		}
		this.itemRef = item || product || consignment;
		let format = null;
		//format the different items you can put into checkout, so it is easier to display and change later
		let newItemFormat = {
			name: this.itemRef.name,
			serial: this.itemRef.serial,
			sku: this.itemRef.serial,
			cost: this.itemRef.cost
		};
		if (item !== null) {
			format = 'item';
		}
		if (product !== null) {
			format = 'product';
		}
		if (consignment !== null) {
			format = 'consignment';
		}
		this.setState({itemIsChanging: true, itemToChange: newItemFormat, itemFormat: format})
	}

	handleItemUpdate = (event) => {
		this.state.itemToChange[event.target.id] = event.target.value;
		this.setState({itemToChange: this.state.itemToChange});
	}

	handleSavingRowModal = async () => {
		this.itemRef.name = this.state.itemToChange.name;
		this.itemRef.serial = this.state.itemToChange.serial;
		this.itemRef.sku = this.state.itemToChange.sku;
		this.itemRef.cost = this.state.itemToChange.cost;
		this.setState({itemIsChanging: false})

		let rowModel = new API.BuildClass(Types.Checkouts._name, API.RequestType.UPDATE);

		//send the item to the backend, so it can update the checkout on the backend so everything is tip-top
		rowModel.object.changing_items = {...this.itemRef, 'format': this.state.itemFormat};

		try {
			await rowModel.submit(null, 'POST_ROW_EDIT', true);
			API.Toast.success("Saved!", {
				position: API.Toast.POSITION.TOP_RIGHT,
				autoClose: 8000
			})
		} catch (e) {
		}
	}

	clickableTD = (item, value, key, product = null, consignment = null) => {
		return <td className="text-center" onMouseEnter={() => this.setState({['hover' + key]: "lightgray"})}
		           onMouseLeave={() => this.setState({['hover' + key]: "white"})}
		           style={{backgroundColor: this.state['hover' + key] || "white", cursor: "pointer"}}
		           onClick={() => this.handleRowClick(item, product, consignment)}>{value}</td>
	}

	handleTransactionType = () => {
		this.setState({layaway: !this.state.layaway});
	}

	render() {
		/** @type {Checkouts_Object} object */
		let object = this.state;
		
		/** @type {Checkouts_Properties} properties */
		let {properties} = this.model;

		object.tax_amount = Number(object.amount);
		object.sub_total_tax = 0;
		object.sub_total_nontax = object.layaways.length > 0 ? object.layaways[0].total ?? 0 : 0;
		object.tradein_total = 0;
		let items = [];
		let consignments = [];
		let trades = [];

		//BPOS Items
		if (object.items) {
			items = object.items.map(
				/**
				 * @param {Checkouts_Items_Object} item
				 * @param i
				 * @returns {*}
				 */

				(item, i) => {
					item.quantity = item.quantity ?? 1;
					if (item.taxable) {
						object.sub_total_tax += (item.cost * item.quantity) - item.discount_amount;
					} else {
						object.sub_total_nontax += (item.cost * item.quantity) - item.discount_amount;
					}

					let total = ((parseFloat(item.cost) * parseFloat(item.quantity)) - parseFloat(item.discount_amount === '' ? 0 : item.discount_amount)).toFixed(2);
					return <tr key={i + this.state.itemKey}>
						<td className="text-center" style={{padding: '6px', width: '100px'}}>
							<Button color="danger default" onClick={() => {
								!this.state.loading && this.setState({loading: true}, () => {
									this.removeItemFromCart(item.pkey)
								})
							}}
							        size={"sm"}><i
								className={"fa fa-trash"}/></Button>
						</td>
						{this.clickableTD(item, item.name, 'itemName' + item.pkey)}
						{this.clickableTD(item, item.serial, 'itemSerial' + item.pkey)}
						{this.clickableTD(item, item.sku, 'itemSku' + item.pkey)}
						{this.clickableTD(item, item.cost, 'itemCost' + item.pkey)}
						<td className="text-center" style={{padding: '5px', textAlignLast: "center"}}>
							{/*buttons visibility is set to hidden in first conditional*/}
							{item.quantity === 1 ?
								<Button style={{marginRight: "10px", visibility: "hidden"}} color=" btn-default default"
								        onClick={() => this.updateItemQuantity(item.pkey, item.inventory, (item.quantity - 1))}
								        size={"sm"}><i
									className={"fa fa-caret-left"}/></Button> :
								<Button style={{marginRight: "10px"}} color=" btn-default default"
								        onClick={() => this.updateItemQuantity(item.pkey, item.inventory, (item.quantity - 1))}
								        size={"sm"}><i
									className={"fa fa-caret-left"}/></Button>}
							<TextField
								value={item.quantity}
								inventory={item.inventory}
								pkey={item.pkey}
								style={{
									width: "15px",
									textAlign: "center"
								}}
								onChange={(this.updateItemQuantityValue(i, item.inventory, item.pkey))}
								margin="none"
								fullWidth={false}
							/>
							<Button style={{marginLeft: "10px"}} color=" btn-default default"
							        onClick={() => this.updateItemQuantity(item.pkey, item.inventory, (item.quantity + 1))}
							        size={"sm"}><i
								className={"fa fa-caret-right"}/></Button>
						</td>
						<td className="text-center" style={{width: '235px', textAlignLast: "center", padding: '5px'}}>
							<ButtonGroup size={"md"}>
								<Button
									onClick={async () => {
										await this.updateCheckoutTaxable('Items', item.pkey, true)
									}}
									outline={!!item.taxable === false}
									active={!!item.taxable === true}
								>
									Taxable
								</Button>
								<Button
									onClick={async () => {
										await this.updateCheckoutTaxable('Items', item.pkey, false)
									}}
									outline={!!item.taxable === true}
									active={!!item.taxable === false}
								>
									Non-Taxable
								</Button>
							</ButtonGroup>
						</td>
						<td className="text-center" style={{width: "50px"}}>
							<TextField
								value={item.discount_amount}
								inventory={item.inventory}
								pkey={item.pkey}
								style={{
									width: "50px",
									textAlign: "center"
								}}
								onChange={this.updateSingularDiscount(i, item.inventory, item.pkey, 'item')}
								margin="none"
								fullWidth={false}
							/>
						</td>
						<td className="text-right">$ {total}</td>
					</tr>;
				});
		}

		if (object.consignments) {
			consignments = object.consignments.map(
				/**
				 * @param {Checkouts_Items_Object} item
				 * @param i
				 * @returns {*}
				 */
				(consignment, i) => {
					if (consignment.taxable) {
						object.sub_total_tax += (consignment.cost * consignment.quantity) - consignment.discount_amount;
					} else {
						object.sub_total_nontax += (consignment.cost * consignment.quantity) - consignment.discount_amount;
					}
					let subtotal = ((parseFloat(consignment.cost) * parseFloat(consignment.quantity)) - parseFloat(consignment.discount_amount === '' || consignment.discount_amount === null ? 0 : consignment.discount_amount)).toFixed(2);
					// let tax = subtotal * (itemTaxRate / 100);
					// let fixedTax = Math.floor(tax * 100) / 100;

					return <tr key={i}>
						<td className="text-center" style={{padding: '6px', width: '40px'}}>
							<Button color="danger default" onClick={() => {
								!this.state.loading && this.setState({loading: true}, () => {
									this.removeConsignmentFromCart(consignment.pkey)
								})
							}}
							        size={"sm"}><i
								className={"fa fa-trash"}/></Button>
						</td>
						{this.clickableTD(null, <span>{consignment.name + " - "}<em><Link to={{
							pathname: "/consignments/" + consignment.consignments.pkey,
							inventoryProps: {parent: consignment.consignments.pkey}
						}}>Consignment</Link></em>
						</span>, 'consignmentName' + consignment.consignments.pkey, null, consignment)}
						{this.clickableTD(null, consignment.serial, 'consignmentSerial' + consignment.consignments.pkey, null, consignment)}
						{this.clickableTD(null, consignment.sku, 'consignmentSku' + consignment.consignments.pkey, null, consignment)}
						{this.clickableTD(null, consignment.cost, 'consignmentPrice' + consignment.consignments.pkey, null, consignment)}
						<td className="text-center"> -</td>
						<td className="text-center" style={{width: '235px', textAlignLast: "center", padding: '5px'}}>
							<ButtonGroup size={"md"}>
								<Button
									onClick={async () => {
										await this.updateCheckoutTaxable('Consignments', consignment.pkey, true)
									}}
									outline={!!consignment.taxable === false}
									active={!!consignment.taxable === true}
								>
									Taxable
								</Button>
								<Button
									onClick={async () => {
										await this.updateCheckoutTaxable('Consignments', consignment.pkey, false)
									}}
									outline={!!consignment.taxable === true}
									active={!!consignment.taxable === false}
								>
									Non-Taxable
								</Button>
							</ButtonGroup>
						</td>
						<td className="text-center" style={{padding: '5px'}}>
							<TextField
								value={consignment.discount_amount}
								inventory={consignment.consignments}
								pkey={consignment.pkey}
								style={{
									width: "50px",
									textAlign: "center"
								}}
								onChange={this.updateSingularDiscount(i, consignment.inventory, consignment.pkey, 'consignment')}
								margin="none"
								fullWidth={false}
							/>

						</td>
						<td className="text-right">$ {subtotal}</td>
					</tr>;
				});
		}

		if (object.trades) {
			let tradein_total = 0;
			trades = object.trades.map((trade, i) => {
					let total = (parseFloat(trade.quantity) * parseFloat(trade.trades.price)).toFixed(2);
					tradein_total += parseFloat(total);
					return <tr key={'p' + i}>
						<td className="text-center" style={{padding: '6px', width: '40px'}}>
							<Button color="danger default" onClick={() => {
								!this.state.loading && this.setState({loading: true}, () => {
									//TODO: changed this for dev to function properly, was pkey, revert if messes up on live
									this.removeTradeFromCart(trade.pkey)
								})
							}} size={"sm"}><i
								className={"fa fa-trash"}/></Button>
						</td>
						<td className="text-center">{trade.trades.name}</td>
						<td className="text-center"></td>
						<td className="text-center"></td>
						<td className="text-center">{trade.trades.price}</td>
						<td className="text-center">{trade.quantity}</td>
						{/*leaving 2 blank cause trades aren't taxable or discountable*/}
						<td className="text-center"></td>
						<td className="text-center"></td>
						<td className="text-right">
							<span style={{color: "red"}}>${total}</span>
						</td>
					</tr>
				}
			);
			object.tradein_total = tradein_total;
		}

		//FFL Boss
		let products = [];
		products = object.products.map((item, i) => {
			/**
			 * @object item
			 * @property price_list
			 * @property manufacturer_name
			 * @property manufacturer_other
			 * @property serial_number
			 */
			item.cost = item.cost != null && !isNaN(item.cost) ? item.cost : (0).toFixed(2);
			if (item.taxable) {
				object.sub_total_tax += parseFloat(item.cost) - (parseFloat(item.discount_amount) || 0);
			} else {
				object.sub_total_nontax += parseFloat(item.cost) - (parseFloat(item.discount_amount) || 0);
			}
			let total = (parseFloat(item.cost) - (parseFloat(item.discount_amount) || 0)).toFixed(2);
			return <tr key={'p' + i}>
				<td className="text-center" style={{padding: '6px', width: '40px'}}>
					<Button color="danger default" onClick={() => {
						!this.state.loading && this.setState({loading: true}, () => {
							//TODO: changed this for dev to function properly, was pkey, revert if messes up on live
							this.removeProductFromCart(item.checkout)
						})
					}} size={"sm"}><i
						className={"fa fa-trash"}/></Button>
				</td>
				{this.clickableTD(null, item.name, 'productName' + item.checkout, item)}
				{this.clickableTD(null, <a href={"https://app.fflboss.com/products/" + item?._id}
				                           target="_blank">{item?.serial}</a>, 'productSerial' + item.checkout, item)}
				{this.clickableTD(null, item.sku, 'productSku' + item.checkout, item)}
				{this.clickableTD(null, item.cost, 'productPrice' + item.checkout, item)}
				<td className="text-center">1</td>
				<td className="text-center" style={{width: '235px', textAlignLast: "center", padding: '5px'}}>
					<ButtonGroup size={"md"}>
						<Button
							onClick={async () => {
								await this.updateCheckoutTaxable('Products', item.checkout, true)
							}}
							outline={!!item.taxable === false}
							active={!!item.taxable === true}
						>
							Taxable
						</Button>
						<Button
							onClick={async () => {
								await this.updateCheckoutTaxable('Products', item.checkout, false)
							}}
							outline={!!item.taxable === true}
							active={!!item.taxable === false}
						>
							Non-Taxable
						</Button>
					</ButtonGroup>
				</td>

				{/*Todo: get this working*/}
				<td className="text-center" style={{padding: '5px'}}>
					<TextField
						value={item.discount_amount}
						inventory={''}
						pkey={item.checkout}
						style={{
							width: "50px",
							textAlign: "center"
						}}
						onChange={this.updateSingularDiscount(i, item.inventory, item.checkout, 'product')}
						margin="none"
						fullWidth={false}
					/>
				</td>
				<td className="text-right">
					<span title="Click on the Serial Number to update price.">$ {total}</span>
				</td>
			</tr>;
		});

		//Layaways
		let layaways = [];
		layaways = object.layaways.map((item, i) => {
			/**
			 * @object item
			 * @property price_list
			 * @property manufacturer_name
			 * @property manufacturer_other
			 * @property serial_number
			 */
			item.total = item.total != null && !isNaN(item.total) ? item.total : (0).toFixed(2);
			object.layaway = true;
			object.layawayPkey = item.layaway;
			object.layawayMax = parseFloat(item.sku).toFixed(2);
			return <tr key={'con' + i}>
				<td className="text-center" style={{padding: '6px', width: '40px'}}>
					<Button color="danger" onClick={() => {
						!this.state.loading && this.setState({loading: true}, () => {
							this.removeLayawayFromCart(item.checkout).then()
						})
					}} size={"sm"}><i
						className={"fa fa-trash"}/></Button>
				</td>
				<td className="text-center" style={{width: '235px'}}>
					Layaway Payment
				</td>
				<td className="text-center">{item.serial}</td>
				<td className="text-center">{item.sku}</td>
				<td className="text-center">N/A</td>
				<td className="text-center">N/A</td>
				<td className="text-center">N/A</td>
				<td className="text-center">N/A</td>

				<td className="text-right">
					<input type={'number'} min={0.00} max={object.layawayMax} style={{width: '100%'}} value={item.total == 0.00 ? "" : item.total} onChange={(e) => {
						let newLayaways = JSON.parse(JSON.stringify(this.state.layaways));
						if(e.currentTarget.value !== "" && parseFloat(e.currentTarget.value) > parseFloat(item.sku)) {
							newLayaways[i].total = object.layawayMax;
						} else {
							newLayaways[i].total = e.currentTarget.value;
						}
						this.setState({layaways: newLayaways})
					}}/>
				</td>
			</tr>;
		});
		//TODO: change to local storage
		let register = Cookies.getJSON('register');
		if (register && object.register === '') {
			object.register = register;
		}
		let registers = [];
		if (object.register === '' && object.all_registers.length > 0) {
			object.register = object.all_registers[0];
		}

		Cookies.set('register', object.register);

		let terminals = [];
		if (object.terminal) {
			let terminal = Cookies.getJSON('terminal');
			if (terminal && object.terminal === '') {
				// noinspection JSPrimitiveTypeWrapperUsage
				object.terminal = terminal;
			}
			terminals = object.terminals.map(function (terminal) {
				return terminal;
			});
			if (object.terminal === '' && object.terminals.length > 0) {
				// noinspection JSPrimitiveTypeWrapperUsage
				object.terminal = object.terminals[0]
			}
			Cookies.set('terminal', terminal);
		}

		let discount_amt = parseFloat(object.discount_amount || 0.00);
		let discount_percent = (parseFloat(object.discount_percentage || 0) / 100);
		if (discount_percent !== 0) {
			object.sub_total_tax = object.sub_total_tax * (1 - discount_percent);
			object.sub_total_nontax = object.sub_total_nontax * (1 - discount_percent);
		} else if (discount_amt !== 0.00) {
			object.sub_total_tax = parseFloat(object.sub_total_tax - discount_amt).toFixed(2);
			if (object.sub_total_tax < 0.00) {
				object.sub_total_nontax = parseFloat(object.sub_total_nontax) + parseFloat(object.sub_total_tax);
				object.sub_total_tax = 0.00;
			}
		}

		let location = object.locations;
		let tax_rate = location == null ? 0 : parseFloat(location.tax_rate_city) + parseFloat(location.tax_rate_county) + parseFloat(location.tax_rate_special) + parseFloat(location.tax_rate_state);

		//subtotal here
		object.subtotal = (parseFloat(object.sub_total_tax) + parseFloat(object.sub_total_nontax)).toFixed(2);

		//show original values for tax and non-tax
		object.show_original_total_tax = object.sub_total_tax;
		object.show_original_total_nontax = object.sub_total_nontax;

		//subtract the trade-in total from the subtotal
		if(object.tradein_total > 0) {
			object.subtotal =  (parseFloat(object.subtotal) - parseFloat(object.tradein_total)).toFixed(2);
			if(object.sub_total_tax > 0) {
				object.sub_total_tax =  (parseFloat(object.sub_total_tax) - parseFloat(object.tradein_total)).toFixed(2);
			} else if (object.sub_total_nontax > 0){
				object.sub_total_nontax =  (parseFloat(object.sub_total_nontax) - parseFloat(object.tradein_total)).toFixed(2);
			}
		}

		//calculate taxes after everything else
		let tax_total = object.sub_total_tax * (tax_rate / 100);
		object.tax_amount = Math.ceil(tax_total * 100) / 100;
		//due to trade-ins potentially making tax amount less than 0, set it to 0
		if(object.tax_amount < 0){
			object.tax_amount = 0.00;
		}
		object.amount = (parseFloat(object.sub_total_tax) + parseFloat(object.sub_total_nontax) + object.tax_amount).toFixed(2);

		//process CC fee after the "amount" is first set
		object.card_fee = 0.00;
		if(parseFloat(object.locations?.credit_card_fee) > 0 && this.state.card_percentage){
			let cc_fee = object.card_fee = Math.ceil((parseFloat(object.locations.credit_card_fee / 100) * object.amount) * 100) / 100;
			//set it so it gets submitted
			object.cc_fee_amount = cc_fee;
			object.amount = (parseFloat(object.amount) + parseFloat(cc_fee)).toFixed(2);
		}


		//Sets up Transaction amount based on taxStatus
		//this.toggleTax(object)

		//Remaining Used for the amount that credit card will need to be charged for
		let remaining = object.amount;
		if (!isNaN(object.cash_amount) && object.cash_amount) remaining -= parseFloat(object.cash_amount).toFixed(2)
		if (!isNaN(object.check_amount) && object.check_amount) remaining -= parseFloat(object.check_amount).toFixed(2)
		if (!isNaN(object.other_amount) && object.other_amount) remaining -= parseFloat(object.other_amount).toFixed(2)
		if (!isNaN(object.invoice_amount) && object.invoice_amount) remaining -= parseFloat(object.invoice_amount).toFixed(2)
		if (!isNaN(object.card_amount) && object.card_amount) remaining -= parseFloat(object.card_amount).toFixed(2)
		// if(isNaN(remaining)) remaining = object.amount;
		// remaining = Math.ceil(remaining * 100) / 100;
		remaining = parseFloat(remaining).toFixed(2);

		let paidFor = remaining <= 0.00;

		// noinspection JSValidateTypes
		object.remaining = remaining;
		object.processor_amount = remaining;

		// if (parseFloat(object.discount_amount || 0) !== 0 ||
		// 	parseFloat(object.discount_percentage || 0) !== 0 ||
		// 	parseFloat(object.cash_amount || 0) !== 0 ||
		// 	parseFloat(object.check_amount || 0) !== 0 ||
		// 	parseFloat(object.invoice_amount || 0) !== 0 ||
		// 	parseFloat(object.other_amount || 0) !== 0  ) {
		if (items.length > 0 || products.length > 0 || consignments.length > 0 || layaways.length > 0) {
			object.manual = true;
		}
		// }
		if (parseFloat(object.discount_percentage || 0) !== 0) {
			object.alignment = 'percent';
		}

		let exists = object.pkey && object.pkey;

		return (
			<div className={'container-fluid'}>
				<UniversalModal
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={"Trade-In"}
					object={Objects.TradeIn}
					history={this.props.history}
					showModal={this.state.modalTradeIn}
					reportClose={this.reportClose('modalTradeIn')}
					size={'lg'}
					objectProps={{forCheckout: true, afterSave: async () => {
							this.setState({loading: true})
							this.state.modalTradeIn = false;
							this.reportClose('modalTradeIn');
							if(_.isEmpty(this.state.pkey)) {
								window.location.reload()
							} else {
								await this.loadTrades()
							}
							this.setState({loading: false, modalTradeIn: false})
						}}}
				/>

				<Card>
					{/*Modal for changing items on the checkout page directly*/}
					<Modal
						isOpen={this.state.itemIsChanging}
						toggle={() => this.setState({itemIsChanging: false})}
						size={"sm"}
						backdrop={true}
						style={{opacity: 1}}
					>
						<ModalHeader toggle={() => this.setState({itemIsChanging: false})}>
							Update for: {this.state.itemToChange.name}
						</ModalHeader>
						<ModalBody>
							<div className={"row"}>
								<div className={"col-md"}>
									<div className="row">
										<div className="col-12 align-content-center">
											<div className={"form-group"}>
												<TextField
													value={this.state.itemToChange.name}
													id="name"
													label="Name"
													variant="outlined"
													onChange={this.handleItemUpdate}
												/>
											</div>
											<div className={"form-group"}>
												<TextField
													value={this.state.itemToChange.serial}
													id="serial"
													label="Serial Number"
													variant="outlined"
													onChange={this.handleItemUpdate}
												/>
											</div>
											<div className={"form-group"}>
												<TextField
													value={this.state.itemToChange.sku}
													id="sku"
													label="Sku"
													variant="outlined"
													onChange={this.handleItemUpdate}
												/>
											</div>
											<div className={"form-group"}>
												<TextField
													value={this.state.itemToChange.cost}
													id="cost"
													label="Cost"
													variant="outlined"
													onChange={this.handleItemUpdate}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
						<ModalFooter style={{justifyContent: "space-between"}}>
							<Button type={"button"} class="btn btn-secondary" onClick={() => this.setState({itemIsChanging: false})}>Close</Button>
							<Button type={"button"} class="btn btn-primary" onClick={() => this.handleSavingRowModal()}>Save</Button>
						</ModalFooter>
					</Modal>

					<CardContent>
						<fieldset>
							<div className="row justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
								{/*Commenting this out for now*/}
								{/*{layaways.length === 0 && <div className={"col-2"}>*/}
								{/*	<div>*/}
								{/*		<ButtonGroup style={{marginRight: '10px'}}>*/}
								{/*			<Button*/}
								{/*				id={'true'}*/}
								{/*				color="primary"*/}
								{/*				active={this.state.layaway === false}*/}
								{/*				outline={this.state.layaway !== false}*/}
								{/*				onClick={this.handleTransactionType}*/}
								{/*			>*/}
								{/*				Transaction*/}
								{/*			</Button>*/}
								{/*			<Button*/}
								{/*				id={'false'}*/}
								{/*				color="primary"*/}
								{/*				active={this.state.layaway === true}*/}
								{/*				outline={this.state.layaway !== true}*/}
								{/*				onClick={this.handleTransactionType}*/}
								{/*			>*/}
								{/*				Layaway*/}
								{/*			</Button>*/}
								{/*		</ButtonGroup>*/}
								{/*	</div>*/}
								{/*</div>}*/}
								<div className="col-5" style={{ flex: '1 1 auto', minWidth: '300px', maxWidth: '50%' }}>
									<div className="row justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
										<div className="col-6" style={{ flex: '1 1 auto', minWidth: '150px', maxWidth: '50%' }}>
											{/*{this.state.showRegister === true &&*/}
											<Framework.Elements.Select
												shouldLoad={this.state.loaded}
												object={object.registers}
												value={object.register}
												error={object.register_error}
												model={Types.Registers._name}
												property={properties.register}
												label={'Registers'}
												update={this.handleSelectPropertyUpdate}
											/>
										</div>
										{/*// }*/}
										{this.props.rootState.location_plan === 1 &&
											<div className="col-6" style={{ flex: '1 1 auto', minWidth: '150px', maxWidth: '50%' }}>
												<Framework.Elements.Select
													shouldLoad={this.state.loaded}
													value={object.terminal}
													error={object.terminal_error}
													model={Types.Terminals._name}
													property={properties.terminal}
													label={'Terminal'}
													update={this.handleSelectPropertyUpdate}
													id={'id'}
													text={'description'}
													textHelper={'manufacturer&model'}
												/>
											</div>}
									</div>
								</div>
								<div className="col-1" style={{ flex: '0 1 auto', minWidth: '100px' }}>
									{(this.props.rootState.account === 10 || this.props.rootState.account === 168) &&
										<Button className="tradeInButton" block={true} size="md" color="primary" style={{ marginTop: '2px', width: '100%', height: '33px' }}
										        onClick={() => {
											        this.setState({modalTradeIn: true})
										        }}>Trade In</Button>}
								</div>
								<div className="col-1" style={{ flex: '0 1 auto', minWidth: '100px' }}>
									{(items.length > 0 || products.length > 0 || trades.length > 0) &&
										<Button block={true} size="md" color="secondary" style={{ marginTop: '2px', width: '100%', height: '33px' }}
										        onClick={this.pause}>Pause</Button>}
								</div>
							</div>
							<hr/>
							<div className="row">

								<div className="col">
									<table className="table table-bordered table-striped">
										<thead>
											<tr className="thead-dark">
												<th className="text-center"/>
												<th>Product</th>
												{layaways.length === 0 ? <>
														<th className="text-center">Serial Number</th>
														<th className="text-center">SKU</th>
														<th className="text-center">Unit Cost</th>
														<th className="text-center">Quantity</th>
														<th className="text-center">Taxable</th>
														<th width="50px" className="text-center">Discount</th>
													</> :
													<>
														<th className="text-center">Customer Name</th>
														<th className="text-center">Remaining</th>
														<th className="text-center">N/A</th>
														<th className="text-center">N/A</th>
														<th className="text-center">N/A</th>
														<th width="50px" className="text-center">N/A</th>
													</>}
												<th className="text-center">{layaways.length === 0 ? 'Total' : 'Payment Amount'}</th>
											</tr>
										</thead>
										<tbody>
											{items}
											{consignments}
											{products}
											{trades}
											{layaways}
										</tbody>
									</table>
									<div className="row">
										<div className="col-2"/>
										{object.manual_card && paidFor === false ?
											<div className="col-6">
												<h4 style={{textAlign: 'center'}}>Manual Credit Card Entry for
													$ <span
														id={"remaining"}>{!isNaN(remaining) ? remaining: '0.00'}</span>
												</h4>
												<div className="row checkoutCardRow">
													<div className="col-6 checkoutCardCol">
														<Cards
															number={object.credit_card_number}
															name={object.credit_card_name}
															expiry={this.state.card_expiry}
															cvc={object.credit_card_cvc}
															focused={object.focused}
														/>
													</div>
													<div className="col-6 checkoutCardTextFieldCol">
														<div className={"form-group"}>
															<Framework.Elements.TextField
																name={"credit_card_number"}
																label={"Card Number"}
																value={object.credit_card_number}
																error={object.credit_card_number_error}
																property={properties.credit_card_number}
																update={this.handleTextFieldPropertyUpdate}
															/>
														</div>
														<div className={"form-group"}>
															<Framework.Elements.TextField
																name={"credit_card_name"}
																label={"Card Holder Name"}
																property={properties.credit_card_name}
																value={object.credit_card_name}
																error={object.credit_card_name_error}
																update={this.handleTextFieldPropertyUpdate}
															/>
														</div>
														<div className="row thirdRowCreditCardTextFields">
															<div className="col creditCardExpiry">
																<div className={"form-group"}>
																	<Framework.Elements.Date
																		name={"credit_card_expiry"}
																		label={"Exp."}
																		format={"MM/YY"}
																		mask={"__/__"}
																		views={['month', 'year']}
																		// it's in a screwed up format because of the masking
																		value={object.credit_card_expiry ? moment(object.credit_card_expiry).format("MM/YY/DD") : null}
																		error={object.credit_card_expiry_error}
																		property={properties.credit_card_expiry}
																		update={this.handleDateChange}
																	/>
																</div>
															</div>
															<div className="col creditCardCvc">
																<div className={"form-group"}>
																	<Framework.Elements.TextField
																		name={"credit_card_cvc"}
																		label={"CVC"}
																		value={object.credit_card_cvc}
																		error={object.credit_card_cvc_error}
																		property={properties.credit_card_cvc}
																		update={this.handleTextFieldPropertyUpdate}
																	/>
																</div>
															</div>
															<div className="col creditCardProcessButton">
																<div className={"form-group"}>
																	<Button size="md" block={true} color="primary"
																	        onClick={() => this.checkout("POST_CARD")}>
																		Process
																	</Button>
																</div>
															</div>
														</div>
														<p style={{textAlign: 'center'}}>
															Click <strong>Process</strong> to run the credit card manually
															or
															click <strong>Charge Card</strong> to process the remaining
															balance
															on the Credit Card Terminal.
														</p>
													</div>
												</div>
											</div> :
											<div className="col-6"/>}
										<div className="col-4 invoice-sum text-right">
											<ul className="list-unstyled">

												{layaways.length === 0 && object.manual ?
													<li>
														<div style={{
															height: 56,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'flex-end',
														}}>
															<ToggleButtonGroup value={object.alignment}
															                   exclusive
															                   selected={true}
															                   onChange={this.handleAlignment}
															                   style={{
																                   marginRight: '10px',
																                   marginTop: '2px'
															                   }}>
																<ToggleButton value="amount"
																              selected={object.alignment === "amount"}
																              style={{
																	              padding: '16px 20px',
																	              height: 'auto',
																	              minWidth: 0,
																              }}>
																	<i className={"fa fa-usd"}/>
																</ToggleButton>
																<ToggleButton value="percent"
																              selected={object.alignment === "percent"}
																              style={{
																	              padding: '16px 18px',
																	              height: 'auto',
																	              minWidth: 0,
																              }}>
																	<i className={"fa fa-percent"}/>
																</ToggleButton>
															</ToggleButtonGroup>
															{object.alignment === "amount" ?
																<div style={{width: '86px'}}>
																	<Framework.Elements.DollarAmount
																		label={"Discount"}
																		property={properties.discount_amount}
																		value={object.discount_amount}
																		error={object.discount_amount_error}
																		update={this.handleTextFieldPropertyUpdate}
																		margin={"dense"}
																	/>
																</div> :
																<div style={{width: '86px'}}>
																	<Framework.Elements.TextField
																		label={"Discount"}
																		property={properties.discount_percentage}
																		value={object.discount_percentage}
																		error={object.discount_percentage_error}
																		update={this.handleTextFieldPropertyUpdate}
																		type={'string'}
																		placeholder={"0.00"}
																		InputProps={{
																			endAdornment: (
																				<InputAdornment position={"end"}> <i
																					className="fa fa-percent"/></InputAdornment>
																			)
																		}}
																	/>
																</div>}
														</div>
														<hr style={{
															width: '200px',
															display: 'inline-block',
															marginBottom: '5px',
														}}/>
													</li> : null}
												{layaways.length === 0 && <>
												<li>Taxable Total: $ {parseFloat(object.show_original_total_tax).toFixed(2)}</li>
												<li>Non-Taxable Total: $ {parseFloat(object.show_original_total_nontax).toFixed(2)}</li>
												{object.tradein_total !== 0 &&
													<li>Trade-In Total: <span style={{color: "red"}}>$ {parseFloat(object.tradein_total).toFixed(2)}</span></li>
												}
												<li>Sub Total: $ {object.subtotal}</li>
												<li>Tax ({tax_rate}%): $ <span>{object.tax_amount.toFixed(2)}</span></li>
												{object.card_percentage &&
													<li>CC Fee ({parseFloat(object.locations.credit_card_fee).toFixed(2)}%): $ <span>{parseFloat(object.card_fee).toFixed(2)}</span></li>
												}
												<li><strong>Total:
													$ <span>{isNaN(object.amount) ? "0.00" : parseFloat(object.amount).toFixed(2)}</span>
												</strong>
												</li>
											</>}

												{layaways.length !== 0 && object.card_percentage &&
													<>
													<li>CC Fee ({parseFloat(object.locations.credit_card_fee).toFixed(2)}%): $ <span>{parseFloat(object.card_fee).toFixed(2)}</span></li>
													<li><strong>Total:
													$ <span>{isNaN(object.amount) ? "0.00" : parseFloat(object.amount).toFixed(2)}</span>
														</strong>
													</li>
													</>
												}
												{object.manual ?
													<section>
														<div className={'row'}>
															<div className={'col-10 offset-md-2 order-md-2'}>
																<div className={'form-group'}>
																	<Framework.Elements.DollarAmount
																		name={"cash_amount"}
																		value={object.cash_amount}
																		error={object.cash_amount_error}
																		label={"Cash"}
																		property={properties.cash_amount}
																		update={this.handleAmountChange}
																		handleFocus={this.handleAmountFocus}
																		margin={"dense"}
																	/>
																</div>
															</div>
														</div>
														<div className={'row'}>
															<div className={'col-10 offset-md-2 order-md-2'}>
																<div className={'form-group'}>
																	<Framework.Elements.DollarAmount
																		name={"check_amount"}
																		label={"Check"}
																		property={properties.check_amount}
																		value={object.check_amount}
																		error={object.check_amount_error}
																		update={this.handleTextFieldPropertyUpdate}
																		handleFocus={this.handleAmountFocus}
																		margin={"dense"}
																	/>
																</div>
															</div>

														</div>
														<div className={'row giftCardRow'}>
															<div className={'col-6 offset-2 order-md-2 giftCardSelect'}>
																<div className={'form-group'}>
																	<Framework.Elements.Select
																		limit={null}
																		isSearchable={true}
																		shouldLoad={this.state.loaded}
																		object={object.certificates}
																		value={object.certificate}
																		error={object.certificate_error}
																		model={Types.Certificates._name}
																		requestOverride={'GET_UNUSED'}
																		property={properties.certificate}
																		label={'Gift Certificates'}
																		text={'name&value'}
																		nullable={true}
																		update={this.handleCertificateChange}
																	/>
																</div>
															</div>
															<div className={'col-4 order-md-3 giftCardDollarAmount'}>
																<div className={'form-group'}>
																	<Framework.Elements.DollarAmount
																		key={object.certificate}
																		name={"other_amount"}
																		label={"Gift Certificate Amount"}
																		disabled={!isNumeric(object.certificate)}
																		property={properties.other_amount}
																		value={object.other_amount}
																		error={object.other_amount_error}
																		update={this.handleTextFieldPropertyUpdate}
																		margin={"dense"}
																	/>
																</div>
															</div>
														</div>
														<div className={'row'}>
															<div className={'col-10 offset-2 order-md-2'}>
																<div className={'form-group'}>
																	<Framework.Elements.DollarAmount
																		name={"invoice_amount"}
																		label={this.state.sudo_card === "" ? "To Invoice" : "Card"}
																		property={properties.invoice_amount}
																		value={object.invoice_amount}
																		error={object.invoice_amount_error}
																		update={this.handleTextFieldPropertyUpdate}
																		handleFocus={this.handleAmountFocus}
																		margin={'dense'}
																	/>
																</div>
															</div>
														</div>
														{this.state.layaway &&
															<div className={'row'}>
																<div className={'col-10 offset-2 order-md-2'}>
																	<div className={'form-group'}>
																		<Framework.Elements.DollarAmount
																			name={"card_amount"}
																			label={"To Charge Card"}
																			property={properties.card_amount}
																			value={object.card_amount}
																			error={object.card_amount_error}
																			update={this.handleTextFieldPropertyUpdate}
																			handleFocus={this.handleAmountFocus}
																			margin={'dense'}
																		/>
																	</div>
																</div>
															</div>
														}
													</section> : null}
												<li>
													<hr style={{
														width: '200px',
														display: 'inline-block',
														marginBottom: '5px'
													}}/>
												</li>
												<li><strong>
													{object.remaining < 0 ? 'Amount to Give' : 'Amount to Collect'}:
													$
													<span
														id={"amount"}> {isNaN(object.remaining) ? "0.00" : object.remaining}</span>
												</strong></li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div style={{textAlign: 'right', marginTop: '0px', marginBottom: '20px', height: '33px'}}>

								{this.props.rootState.location_plan === 1 && (items.length > 0 || products.length > 0 || consignments.length > 0 || layaways.length > 0) &&
									<div>
										<div>
											{object.locations.credit_card_fee && parseFloat(object.locations?.credit_card_fee) > 0 &&
												<Button size="md" color="primary" style={{marginRight: '10px'}}
												        onClick={() => this.setState({card_percentage: !this.state.card_percentage})}>
													{this.state.card_percentage ? "Remove CC Fee" : "Add CC Fee"}
												</Button>
											}
											<Button size="md" color="primary" onClick={this.toggleManualCard}
											        style={{marginRight: '10px'}}>{!object.manual_card ? "Manual Card Entry" : "Go Back"}</Button>
											<Button size="md" color="primary"
											        onClick={() => this.checkout(paidFor ? 'POST_CASH' : 'POST_TERMINAL')}>
												{paidFor ? (isNumeric(this.state.card_amount) && this.state.card_amount > 0 && this.state.layaway ? "Charge" +
													" Card & Complete" : "Complete") : (this.state.layaway && !this.state.layawayPkey ? "Start Layaway" : "Charge Card")}
											</Button>
										</div>
										{/*<div style={{marginTop: '7px'}}>*/}
										{/*	<Button size="md" color="primary" onClick={this.toggleOtherPaymentsManual}>*/}
										{/*		{!object.manual ? "Split/Other Payments" : "Go Back"}*/}
										{/*	</Button>*/}
										{/*</div>*/}

									</div> }

								{this.props.rootState.location_plan === 2 && (items.length > 0 || products.length > 0 || consignments.length > 0  || layaways.length > 0) &&
									<div>
										<div>
											{(this.props.rootState.account === 10 || this.props.rootState.account === 183) && object.locations.credit_card_fee && parseFloat(object.locations?.credit_card_fee) > 0 &&
												<Button size="md" color="primary" style={{marginRight: '10px'}}
												        onClick={() => this.setState({
													        card_percentage: !this.state.card_percentage,
													        sudo_card: !this.state.sudo_card ? "card" : ""
														})}
												>
													{this.state.card_percentage ? "Remove CC Fee" : "Add CC Fee"}
												</Button>
											}
											<Button size="md" color="primary"
											        onClick={() => this.checkout('POST_CASH')}>
												Complete
											</Button>
										</div>

									</div> }

							</div>

							{exists ?
								<div>
									<Typography color="textSecondary" gutterBottom>
										Created by {this.state.creator_name ?? this.props.rootState.name} {moment(object.created.value).local().format("MM/DD/YYYY hh:mm A")}
										{object.modified.value ?
											"| Modified " + moment(object.modified.value).local().format("MM/DD/YYYY hh:mm A")
											: null}

									</Typography>
								</div> : null}

							<hr/>

							<h4 style={{display: "flex", justifyContent: 'space-between'}}>
								<div style={{textAlign: 'left'}}>
									Optional Fields
									<Button size="md" color="primary" style={{marginLeft: '10px'}} onClick={this.toggleOtherFields}>{this.state.optionalFields ? "Hide" : "Show"}</Button>
								</div>
								<div style={{textAlign: 'right'}}>
									Apply To Entire Checkout &nbsp;
									<ButtonGroup
										style={{marginRight: '10px'}}
									>
										<Button
											id={'true'}
											color="primary"
											active={this.state.type === false}
											outline={this.state.type !== false}
											onClick={this.handleTypeChange}
										>
											Taxable
										</Button>
										<Button
											id={'false'}
											color="secondary"
											active={this.state.type === true}
											outline={this.state.type !== true}
											onClick={this.handleTypeChange}
										>
											Non-Taxable
										</Button>
									</ButtonGroup>


								</div>
							</h4>


							{this.state.showOtherFields === true ?
								<div>
									<div className="row">

										<div className="col-3">
											<Framework.Elements.TextField
												name={"description"}
												label={"Transaction Description"}
												property={properties.description}
												value={object.description}
												error={object.description_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="col-3">
											<Framework.Elements.TextField
												name={"order_po_number"}
												label={"PO Number"}
												property={properties.order_po_number}
												value={object.order_po_number}
												error={object.order_po_number_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="col-3">
											<Framework.Elements.TextField
												name={"client"}
												label={"Client"}
												property={properties.client}
												value={object.client}
												error={object.client_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="col-3">
											<Framework.Elements.TextField
												name={"receipt_email"}
												label={"Email Receipt To"}
												property={properties.receipt_email}
												value={object.receipt_email}
												error={object.receipt_email_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>

									</div>
									<div className="row" style={{marginTop: 15}}>

										<div className="col-6">
											<Framework.Elements.TextField
												name={"description"}
												multiline={6}
												label={"Notes"}
												property={properties.notes}
												value={object.notes}
												error={object.notes_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
										<div className="col-3">
											<Framework.Elements.TextField
												name={"salesman"}
												label={"Salesman"}
												property={properties.salesman}
												value={object.salesman}
												error={object.salesman_error}
												update={this.handleTextFieldPropertyUpdate}
											/>
										</div>
									</div>
								</div>
								: null}


						</fieldset>
						<div className={classNames("loader", {'active': this.state.loading})}>
							<ClipLoader color={"#4A4A4A"} loading={this.state.loading}/>
						</div>
					</CardContent>
				</Card>
			</div>
		)
	}
}

export default Framework.Components.withRootState(CheckoutObject);
