
import React from "react";

import APPCONFIG from "../../../../../constants/Config";
import Framework from "@beardeddevops/react.framework";

import {Collections, Objects} from "components";
import {Tab,Tabs} from "@material-ui/core";
import {Button} from "reactstrap";

const {UniversalModal} = Framework.Elements;
const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Registers',
		url: '/registers',
		icon: 'usd',
	}
];

export default class Register extends Framework.Components.View {

	constructor(props) {
		super(props,'details');
		this.state.current_amount =0;
		this.state.method = '';
		this.state.ledgerName =''
		this.setCurrentAmount = this.setCurrentAmount.bind(this);

	}

	setCurrentAmount(amount) {
		this.setState({current_amount:amount})
	}

	toggleLedger=(event)=>{
		switch (event.currentTarget.id){
			case 'sod':
				this.state.method ='sod';
				this.state.ledgerName ='Start of Day'
				break;
			case 'adj':
				this.state.method ='adj';
				this.state.ledgerName ='Adjustment'
				break;
			case 'eod':
				this.state.method ='eod';
				this.state.ledgerName ='End of Day'
				break;
		}

		this.toggle("modalLedger")()


	}

	render() {
		const  {tab} = this.state;
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/registers/${this.props.id}`,
				icon: 'edit',
			}
		]);
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}>
					<Button id ='sod'color="primary" style={{marginRight:"5px"}} onClick={this.toggleLedger}>Start of Day</Button>
					<Button id ='adj' color="secondary" style={{marginRight:"5px"}} onClick={this.toggleLedger}>Adjust</Button>
					<Button id ='eod' color="primary"  onClick={this.toggleLedger}>End of Day</Button>
				</Framework.Components.PageBar>

				<Tabs value={tab} onChange={this.handleTabChange} component={'div'}>
					<Tab label="Register" value="details"/>
					<Tab label="Ledger" value="ledger"/>
				</Tabs>

				<div style={{display: tab === 'details' ? 'block' : 'none'}}>
				 <Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>

					<Objects.Register
						history={this.props.history}
						base={this.props.base}
						id={this.props.match.params.id}
						shouldSave={this.state["saveModelRegister"]}
						shouldDelete={this.state["deleteModelRegister"]}
						reloadSelf={this.state.refresh}
						setCurrentAmount={this.setCurrentAmount}
						baseUrl={this.props.baseUrl}
					/>
				</Framework.Errors.General>


				</div>

				<div style={{display: tab === 'ledger' ? 'block' : 'none'}}>
					<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
						<Collections.RegisterLedgers
							account={this.props.account}
							parent={this.props.match.params.id}
							history={this.props.history}
							reloadParent={this.props.reloadParent}
							reloadSelf={this.state.refresh}
						/>
					</Framework.Errors.General>
				</div>

				<UniversalModal
					parent={this.props.match.params.id}
					isDev={APPCONFIG.settings.isDev()}
					locations={this.props.locations}
					name={'Ledger'}
					object={Objects.RegisterLedger}
					history={this.props.history}
					toggle={this.state['modalLedger']}
					reportClose={this.reportClose('modalLedger')}
					afterSave={this.handleAfterSave}
					submitLabel={`Submit ${this.state.ledgerName}`}
					size={'lg'}
					objectProps={{currentAmount:this.state.current_amount,method:this.state.method}}
				/>


			</div>
		)
	}
};
