import React from "react";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Card, CardContent} from "@material-ui/core";
import { LineChart } from 'react-chartkick'
import "chart.js";
import  'chartjs-adapter-date-fns';

/**
 *  @property {Dashboard_Object} state
 *  @property {object} model
 *  @property {Dashboard_Properties} model.properties
 *  @property {Dashboard_Is} model.is
 *  @property {Dashboard_Has} model.has
 */

export default class Dashboard extends Framework.Components.Object {

	constructor(props) {
		super(props, Types.Dashboard._name);
		// this.state.totals = [];

	}

	render() {
		let {loading} = this.state;

		//optional summed sales for 30 days
		// let totalSales = 0;
		// let sales = object.totals;
		// for (const [key,value] of Object.entries(sales)) {
		// 	totalSales += value;
		// }
		// noinspection JSUnresolvedVariable
		return (
			<Card>
				<CardContent>
					<div>
						<div className={'row'}>
							<div className={'col-md-12'}>
								<h5>Transactions Over 30 Days</h5>
								<LineChart label="Sales"adapter={"chartjs"} data={this.state.totals} colors={["#007bff"]}  />
							</div>
						</div>
					</div>
					<Framework.Elements.LoadingSpinner isLoading={loading}/>
				</CardContent>
			</Card>
		)
	}
}
