import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import ClipLoader from 'react-spinners/ClipLoader';
import moment from "moment";
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';

class RegisterLedgers extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Registers_Ledgers._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 14;
		this.limit = 14;
	}

	render() {
		const {data, pages, loading,limit} = this.state;
		let columns =[
			{
				Header: "Date",
				id: "created",
				accessor: d => {
					return moment(d.created)
						.local()
						.format("MM/DD/YYYY hh:mm A")
				}
			},
			{
				Header: "Event",
				accessor: "event",
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: props => <span>$ {props.value}</span>
			},
			{
				Header: "User",
				id: "user",
				/** @var r.users */
				accessor: r => r.users != null ? (r.users.name_first + " " + r.users.name_last) : this.props.rootState.account_name,
				Cell: props => props.original.users != null ?
					<Link to={"/users/" + props.original.users.pkey}>{props.value}</Link> :
					<Link to={"/settings"}>{this.props.rootState.account_name}</Link>
			},
			{
				Header: "Note",
				accessor: "note",
			},
		]
		return (
				<Card>
					<CardContent>


					<div className="table-bar">
						<div className="d-flex">
							{this.renderSearch('Ledgers')}
							{this.renderDateRangeSearch()}
							{this.renderHeaderRight("Ledger",columns)}
						</div>
					</div>
					<ReactTable
						key={this.state.key}
						columns={columns}
						manual // Forces table not to paginate or sort automatically, so we can handle it server-side
						data={data}
						pages={pages} // Display the total number of pages
						loading={loading} // Display the loading overlay when we need it
						loadingText="Requesting Data"
						onFetchData={this.fetchData} // Request new data when things change
						defaultSorted={[
							{
								id: this.model.sort,
								desc: this.model.order === 'desc'
							}
						]}
						onPageSizeChange={this.onPageSizeChange}
						showPagination= {true}
						showPaginationTop= {false}
						showPaginationBottom= {true}
						showPageSizeOptions= {true}
						defaultPageSize={limit}
						className="-striped -highlight"
					/>
						{APPCONFIG.settings.isDev() ? null :
							<div className={classNames("tabled", "loader", {'active': loading})}>
								<ClipLoader color={"#4A4A4A"} loading={loading}/>
							</div>
						}
					</CardContent>
				</Card>

		)
	}
}
export default Framework.Components.withRootState(RegisterLedgers);

