import React from "react";
import PropTypes from "prop-types";

import {confirmAlert} from 'react-confirm-alert';
import ObjectState from './ObjectState';
import API from "@beardeddevops/react.api";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import {Button} from "reactstrap";
import {isValidPkey} from '../Utilities';

/**
 * @property delete_message
 */
export default class Object extends ObjectState {
	static propTypes = {
		id: PropTypes.string,
		binary_id: PropTypes.string,
		parent: PropTypes.string,
		parent_binary_id: PropTypes.string,
		grandparent: PropTypes.string,
		grandparent_binary_id: PropTypes.string,

		object: PropTypes.object, //IF Supplied the system will bypass the API call to load the object and load it from this property

		history: PropTypes.object.isRequired,

		reloadShell: PropTypes.func,
		reloadPage: PropTypes.func,
		reloadSelf: PropTypes.bool,

		// TODO: Replace remaining usage of these with refs so we don't re-render before submit
		saveSelf: PropTypes.any,
		deleteSelf: PropTypes.any,

		hasModal: PropTypes.bool, //TRUE skips Card wrapper and just returns the form object
		afterSave: PropTypes.func,
		afterDelete: PropTypes.func,

		updateName: PropTypes.func,

		baseUrl: PropTypes.string,

		autoLoad: PropTypes.bool,

		dataIdSave: PropTypes.string,
		dataIdDelete: PropTypes.string,

		noDelete: PropTypes.bool,
		deleteText : PropTypes.string,

		params: PropTypes.array, //If included will be merged onto the object state
		updateID: PropTypes.func, //Used to updated the ID in the UniversalModal if params caused a different item to load than the ID requested

		goBack: PropTypes.bool,//back button to redirect to whatever the baseUrl is : defaults to False

	};

	constructor(props, model) {
		super(props);
		this.model = new API.BuildClass(model, typeof props.id !== 'undefined' && props.id ? API.RequestType.READ : API.RequestType.CREATE);

		let pkeyValid = isValidPkey(props.id);

		this.state = this.model.object;
		this.state.loading = false;
		this.state.loaded = !pkeyValid;

		this.loadIDs();

		this.read = API.RequestType.READ;
		this.update = pkeyValid ? API.RequestType.UPDATE : API.RequestType.CREATE;
		this.delete = API.RequestType.DELETE;

		this.dataIdSave = typeof props.dataIdSave !== 'undefined' && props.dataIdSave ? props.dataIdSave : "";
		this.dataIdDelete = typeof props.dataIdDelete !== 'undefined' && props.dataIdDelete ? props.dataIdDelete : "";

		this.noDelete = typeof this.props.noDelete !== 'undefined' ? this.props.noDelete : true;
		this.goBack = typeof this.props.goBack !== 'undefined' ? this.props.goBack : false;
		this._ismounted = true;

		this.handleProcess = this.handleProcess.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.afterSubmit = this.afterSubmit.bind(this);

		this.loadSelfAfter = this.loadSelfAfter.bind(this);
		this.loadSelfAfterAlways = this.loadSelfAfterAlways.bind(this);

		this.redirect = null;
		this.shouldAutoLoad = typeof this.props.autoLoad !== 'undefined' ? this.props.autoLoad : true;

		if (props.params) {
			this.state = {...this.state, ...props.params};
		}
	}

	componentDidMount() {
		this.loadSelf().then();
		this._ismounted = true;
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		// Update the update request type in case a user submits a new object and state is updated with a pkey, or a form is reset.
		// But only if the inheriting component hasn't already specified a request method.
		if (this.state.loaded && (this.update === API.RequestType.UPDATE || this.update === API.RequestType.CREATE)) {
			this.update = isValidPkey(this.state.pkey) ? API.RequestType.UPDATE : API.RequestType.CREATE;
		}

		this.reloadIDs(prevProps, 'object');

		if (prevProps.saveSelf !== this.props.saveSelf) {
			this.handleSubmit().then();
		}
		if (prevProps.deleteSelf !== this.props.deleteSelf) {
			this.handleDelete();
		}
	}

	toggleLoading = (position) => {
		this.setState({
			loading: position
		})
	}

	// To be overridden by components inheriting this class if they want to preempt state setting.
	loadSelfBefore = async (data) => {
		return data;
	}

	loadSelf = async () => {
		let url = this.model.methods[this.read];
		let idLess = url ? url.indexOf("[ID]") === -1 && url.indexOf("[?ID]") === -1 : false;
		if ((!isValidPkey(this.state.pkey) && !idLess) || !this.shouldAutoLoad) {
			this.setState({"loaded": true});
			return;
		}
		this.toggleLoading(true);

		try {
			if (typeof this.props.object === 'undefined' || !this.props.object) {
				let data = await this.model.submit(this, this.read);

				//TODO: Should the last check only run if its NOT IDLess ??
				if (!data || (!idLess && !data.pkey)) {
					console.log('Item was NOT what we expected');
					this.props.history.push(this.props.baseUrl || '/');
				}

				data = await this.loadSelfBefore(data);

				if (data) this.loadSelfActual(data);
			} else {
				this.loadSelfActual(this.props.object);

			}
		} catch (e) {
			console.log('Couldn\'t find the requested object');
			this.props.history.push(this.props.baseUrl || '/');
		}

		this.loadSelfAfterAlways();
	};

	loadSelfActual(data) {
		if (this._ismounted) {
			let newState = {};
			newState['pkey'] = data['pkey'];
			if (data['binary_id']) {
				newState['binary_id'] = data['binary_id'];
			}
			let updateID = (data['pkey'] !== this.state.pkey || data['binary_id'] !== this.state.binary_id);

			this.model.properties.forEach((item, key) => {
				if (typeof item !== 'undefined' && item && typeof data[key] !== 'undefined') {
					newState[key] = data[key];
				}
			});

			this.model.has.forEach((item, key) => {
				if (typeof item !== 'undefined' && item && typeof data[key] !== 'undefined') {
					newState[key] = data[key];
				}
			});

			this.model.is.forEach((item, key) => {
				if (typeof item !== 'undefined' && item && typeof data[key] !== 'undefined') {
					newState[key] = data[key];
				}
			});

			newState['loading'] = false;
			newState['loaded'] = true;
			this.setState(newState, () => {
				if (updateID && typeof this.props.updateID !== 'undefined') {
					this.props.updateID(data['pkey'], data['binary_id']);
				}
				this.loadSelfAfter(data)
			});
		}
	}

	// noinspection JSUnusedLocalSymbols
	loadSelfAfter(data) {

	};

	loadSelfAfterAlways() {
	};

	handleFileChange = (property, file) => {
		this.model.files[property] = file;
		this.setState({
			[property]: file.name,
			[property + '_error']: '',
		});
	};

	handleMultipleFileChange = (property, files) => {
		this.model.files[property] = files;
		let file_names = '';
		if (files.length > 1) {
			files.forEach(file => {
				file_names += file.name + '|';
			})
			file_names = file_names.slice(0, -1);
		}
		else {
			file_names = files.name;
		}
		this.setState({
			[property]: file_names,
			[property + '_error']: '',
		});
	}

	handleTextFieldPropertyUpdate = (property, value) => {
		this.setState({
			[property]: value,
			[property + '_error']: '',
		});

		let object_property = this.model.properties[property] || null;
		// noinspection JSUnresolvedVariable
		let ux_validate = object_property ? object_property.ux_validation : false;
		if (ux_validate) {
			this.model.handleUXValidation(this, this.update, function (response) {
				console.log('WARNING : ' + response);
			});
		}

	};

	handleSelectPropertyUpdate = (property, value, linked, object) => {
		console.log(property, value, linked, object);
		if (linked) {
			if (object && object.hasOwnProperty('binary_id')) {
				this.setState({
					[linked]: object,
					[property]: value,
					[property + '_error']: '',
					[property + '_binary_id']: object.binary_id,
					[property + '_binary_id_error']: '',
				});
			} else {
				if( this.state.hasOwnProperty(property + '_binary_id')) {
					this.setState({
						[linked]: object,
						[property]: value,
						[property + '_error']: '',
						[property + '_binary_id']: null,
						[property + '_binary_id_error']: '',
					});
				} else {
					this.setState({
						[linked]: object,
						[property]: value,
						[property + '_error']: '',
					});
				}
			}
		} else {
			if( this.state.hasOwnProperty(property + '_binary_id')) {
				this.setState({
					[property]: value,
					[property + '_error']: '',
					[property + '_binary_id']: null,
					[property + '_binary_id_error']: '',
				});
			} else {
				this.setState({
					[property]: value,
					[property + '_error']: '',
				});
			}
		}
	};

	get requestType() {
		console.log('Request Type', this.update);
		if ([API.RequestType.CREATE, API.RequestType.UPDATE].indexOf(this.update) !== -1) {
			return this.update;
		}
		//this.model.requestType
		return this.state.pkey ? API.RequestType.UPDATE : API.RequestType.CREATE;
	}

	/**
	 * Override to add custom validations in Objects
	 * STEP: Add Function
	 * STEP: Bind to THIS in constructor
	 *
	 * @param event
	 * @param {string|null} requestOverride
	 * @returns {boolean|*}
	 */
	handleProcess(event, requestOverride) {
		let request = typeof requestOverride !== 'undefined' && requestOverride ? requestOverride : this.requestType;
		console.log('Processing Request', request);
		//process(component, requestType, submitted = false, focused = null, set = null) {
		return API.Validator.process(this, request, true, event ? event.target.name : null);
	}

	async handleSubmit(event, suppressToast = false, suppressProcess = false) {

		if (typeof event !== 'undefined' && event !== null) event.preventDefault();
		if( !suppressProcess ) {
			if( this.state.loading ) {
				return;
			}
			this.toggleLoading(true);
		}

		let url = this.model.methods[this.read];

		let idLess = url ? url.indexOf("[ID]") === -1 && url.indexOf("[?ID]") === -1 : false;
		let isUpdate = isValidPkey(this.state.pkey) || idLess;
		if (suppressProcess || this.handleProcess(null, null)) {
			try {
				let data = await this.model.submit(
					this,
					this.update,
					suppressToast,
					suppressProcess
				);
				if (this.redirect) {
					if (data.pkey && this.redirect.includes('[ID]')) {
						if (data.binary_id) {
							this.props.history.push(this.redirect.replace('[ID]', data.binary_id + '-' + data.pkey));
						} else {
							this.props.history.push(this.redirect.replace('[ID]', data.pkey));
						}
					} else {
						this.props.history.push(this.redirect);
					}
				} else {
					if (isUpdate) {
						for (let key in this.model.properties) {
							if (this.model.properties.hasOwnProperty(key)) {
								if (['file', 'image'].includes(this.model.properties[key].type) &&
									data.hasOwnProperty(key)
								) {
									this.setState({[key]: data[key]})
								}
							}
						}
					} else {
						if (typeof this.props.reloadPage === 'function') {
							this.props.reloadPage();
						}
					}
					let stopSubmitting = await this.afterSubmit(data, this.update, suppressProcess);
					if (!stopSubmitting && typeof this.props.afterSave !== 'undefined') {
						this.props.afterSave(data);
					}
				}
			} catch (error) {
			}
			if( !suppressProcess ) {
				this.toggleLoading(false);
			}
		} else {
			if( !suppressProcess ) {
				this.toggleLoading(false);
			}
		}
		return false;
	}

	// noinspection JSUnusedLocalSymbols
	/**
	 * Return TRUE to prevent handleSubmit from running afterSave(data) if already ran in the override here.
	 * @param data
	 * @param request
	 * @param suppressProcess
	 * @returns {Promise<void>}
	 */
	async afterSubmit(data, request, suppressProcess = false) {
	}

	handleDelete = () => {
		confirmAlert({
			title: 'Confirm Delete',
			message: typeof this.delete_message !== 'undefined' ? this.delete_message : 'Are you sure you want to delete this?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						try {
							await this.model.submit(this, this.delete);
							if (typeof this.props.afterDelete === 'function') {
								this.props.afterDelete(this.state.pkey, this.state.binary_id);
								return;
							}
							this.props.history.push(this.redirect || this.props.baseUrl || '/');
						} catch (error) {
						}
					}
				},
				{
					label: 'No',
					onClick: () => {
					}
				}
			]
		});

	};

	setNewObject = (property, pluralized) => (obj) => {
		if (typeof pluralized === 'undefined') {
			pluralized = property + 's';
		}

		this.setState({
			[property]: obj.pkey,
			[`${property}_binary_id`]: obj.binary_id,
			[pluralized]: obj
		});
	};

	form = () => {
		return (<span>&nbsp;</span>)
	};

	render() {
		let hasModal = typeof this.props.hasModal !== 'undefined' ? this.props.hasModal : false;
		if (hasModal) {
			return this.form();
		}
		return (
			<Card style={{overflow: 'visible'}}>
				<CardContent style={{paddingBottom: 0}}>
					{this.form()}
					<hr style={{marginBottom: 0}}/>
				</CardContent>
				<CardActions style={{justifyContent: 'flex-end', padding: '14px'}}>
					{this.goBack &&
						<Button color="secondary" onClick={() => (this.props.history.goBack())}>Back</Button>
					}
					{this.noDelete &&
						<Button color="danger" data-id={this.dataIdDelete} onClick={this.handleDelete}>{this.props.deleteText?this.props.deleteText:"Delete"}</Button>
					}
					{this.dataIdSave ?
						<Button color="primary" data-id={this.dataIdSave} onClick={this.handleSubmit}>Save</Button>
						:
						<Button color="primary" onClick={this.handleSubmit}>Save</Button>
					}
				</CardActions>
			</Card>
		)
	}

}
