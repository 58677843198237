import React from 'react';

import APPCONFIG from "constants/Config";
import Framework from "@beardeddevops/react.framework";
import {Objects, Collections} from "components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Gift Certificates',
		url: '/certificates',
		icon: 'gift',
	}
];

export default class Certificate extends Framework.Components.View {

	constructor(props) {
		super(props);
		this.state.tab = "details";
	}

	render() {
		let breadcrumbs = breadcrumbsStatic.slice().concat([
			{
				name: this.state.name,
				url: `/certificates/${this.props.id}`,
				icon: 'edit',
			}
		]);

		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbs} history={this.props.history}/>

				<Tabs value={this.state.tab} onChange={this.handleTabChange} component={'div'}>
					<Tab label="Certificate" value="details"/>
					<Tab label="Transactions" value="history"/>
				</Tabs>

				<Framework.Errors.General isDev={APPCONFIG.settings.isDev()}>
					{this.state.tab === "details" &&
					<Objects.Certificate
						id={this.props.match.params.id}
						locations={this.props.locations}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
						updateName={this.updateName}
						baseUrl={this.props.baseUrl}
					/>}

					{this.state.tab === "history" &&
					<Collections.CertificateTransactions
						id={this.props.match.params.id}
						history={this.props.history}
						reloadShell={this.props.reloadShell}
						reloadPage={this.refreshPage}
						reloadSelf={this.state.refresh}
					/>}

				</Framework.Errors.General>
			</div>
		)
	}
}
