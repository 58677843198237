import React from 'react';

import {Collections} from "components";
import Framework from "@beardeddevops/react.framework"
import APPCONFIG from "constants/Config";

const breadcrumbsStatic = [
	{
		name: 'Dashboard',
		url: '/dashboard',
		icon: 'dashboard',
	},
	{
		name: 'Products',
		url: '/inventory',
		icon: 'barcode',
	}
];

export default class Inventories extends Framework.Components.View {
	render()
	{
		return (
			<div className="container-fluid">
				<Framework.Components.PageBar breadcrumbs={breadcrumbsStatic} history={this.props.history}/>
				<Collections.Inventories
					history={this.props.history}
					reloadParent={this.props.reloadParent}
				/>

			</div>
		);
	}
}
