'use strict';

import React from "react";

import Validator from './Validator';

import RestfulObject from './RestfulObject';
import RequestType from "./RequestType";
import {TrackJS} from "trackjs";

export const API = {

	RequestType: RequestType,
	RestfulObject: RestfulObject,

	URL: '',
	Domains: [],
	Online: [],
	Sessions: {},
	Version: '',

	Location: '',
	LocationGeo: '',
	LocationLatitude: '',
	LocationLongitude: '',

	UrlsToSuppress : [],

	App: null,

	Validator: new Validator(),
	BrowserLocation: location, //React Location Tools
	Toast: {},
	ToastId: 0,
	NoLoggedInNotice: true,

	Options: {},

	useLocation: true,
	shouldRetry: false,

	loadOptions: async function (useCached, version) {
		let storage = window.localStorage;
		if (useCached) {
			try {
				let options = storage.getItem('options' + '_' + version);
				if (!options) {
					API.Options = await RestfulObject.options;
					storage.setItem('options' + '_' + version, JSON.stringify(API.Options));
				} else {
					API.Options = JSON.parse(options);
				}
			} catch (e) {
				storage.clear();
				API.Options = await RestfulObject.options;
				storage.setItem('options' + '_' + version, JSON.stringify(API.Options));
			}
		} else {
			API.Options = await RestfulObject.options;
			storage.setItem('options' + '_' + version, JSON.stringify(API.Options));
		}
		await this.garbageCollection(version);
	},

	garbageCollection: async function(version) {
		let storage = window.localStorage;
		version = parseInt(version.replace(/[^0-9]/g, '')) - 1;
		for(let i = version; i > 0; i--) {
			let options = storage.getItem('options' + '_v' + i);
			if (options) {
				storage.removeItem('options' + '_v' + i);
			}
		}
	},

	clearCache: async function () {
		let storage = window.localStorage;

		storage.clear();
		API.Options = await RestfulObject.options;
		storage.setItem('options' + '_' + API.Version, JSON.stringify(API.Options));

		let toast = API.Toast;
		if (API.ToastId === 0 || !toast.isActive(API.ToastId)) {
			API.ToastId = toast.info('API cache cleared', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	},

	/**
	 *
	 * @param className
	 * @param requestType
	 * @returns {RestfulObject}
	 * @constructor
	 */
	BuildClass: function (className, requestType) {
		let object = new RestfulObject(className, requestType);
		object.build();
		return object;
	},

	GetProperty: function (className, propertyName) {
		let object = new RestfulObject(className, RequestType.READ);
		object.build();
		return object.getProperty(propertyName);
	},

	requestLocation: function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.locationReceived, this.locationError);
		} else {
			console.log("Geolocation is not supported by this browser.");
		}
	},

	/**
	 * @param position
	 */
	locationReceived: function (position) {
		API.LocationGeo = position.coords.latitude + ',' + position.coords.longitude;
		API.LocationLatitude = position.coords.latitude.toString();
		API.LocationLongitude = position.coords.longitude.toString();

		let google = window.google;
		// noinspection JSUnresolvedVariable
		if (google && google.maps && google.maps.Geocoder) {
			let geocoder = new google.maps.Geocoder();
			// noinspection JSUnresolvedFunction
			let cords = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			// noinspection JSUnresolvedFunction
			geocoder.geocode({'location': cords}, function (results, status) {
				// noinspection JSUnresolvedVariable
				if (status === google.maps.GeocoderStatus.OK) {
					//console.log(results)
					if (results[1]) {
						//formatted address
						//console.log(results[0].formatted_address)
						let city = null;
						let state = null;
						//find country name
						// noinspection JSUnresolvedVariable
						for (let i = 0; i < results[0].address_components.length; i++) {
							// noinspection JSUnresolvedVariable
							for (let b = 0; b < results[0].address_components[i].types.length; b++) {

								// noinspection JSUnresolvedVariable
								if (results[0].address_components[i].types[b] === "locality") {
									//this is the object you are looking for
									// noinspection JSUnresolvedVariable
									city = results[0].address_components[i].long_name;
									break;
								}
								// noinspection JSUnresolvedVariable
								if (results[0].address_components[i].types[b] === "administrative_area_level_1") {
									//this is the object you are looking for
									// noinspection JSUnresolvedVariable
									state = results[0].address_components[i].short_name;
									break;
								}
							}
						}
						//city data
						console.log(city + " " + state)
						API.Location = city + ", " + state;

					} else {
						console.log("No results found");
					}
				} else {
					console.log("Geocoder failed due to: " + status);
				}
			});
		}
	},

	locationError: function (error) {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				console.log("User denied the request for Geolocation.");
				break;
			case error.POSITION_UNAVAILABLE:
				console.log("Location information is unavailable.");
				break;
			case error.TIMEOUT:
				console.log("The request to get user location timed out.");
				break;
			case error.UNKNOWN_ERR:
				console.log("An unknown error occurred.");
				break;
			default:
				console.log("An unknown error occurred.");
				break;
		}
	},
	removeTlD:function(url,striped = false) {
		let tlds = ['.com', '.app', '.net', '.org', '.cards', '.card'];
		let domain = '';
		for (let i = 0; i < tlds.length; i++) {
			if (url.includes(tlds[i])){
				if(striped)domain = url.split(tlds[i])[0];
				else domain = url.split(tlds[i])[0] + tlds[i];
			}
		}
		return domain;
	},
	/**
	 *
	 * @param app
	 * @param toast
	 * @param lockedLocations
	 * @param startingLocations
	 */
	setAJAXDefaults: function (app, toast, lockedLocations = ['/login', '/register', '/forgot-password'], startingLocations = ['/', '/login'],redirect = '/settings') {
		API.Toast = toast;
		API.App = app;

		if (API.useLocation) {
			API.requestLocation();
		}

		//Global AJAX Settings
		$.ajaxSetup({
			global: true,
			data: {
				requester: window.location.protocol + "//" + window.location.hostname,
			},
			dataType: 'json',
			xhrFields: {
				withCredentials: true
			},
			crossDomain: true,
			async: true,
			cache: false,
			// headers: {
			// 	'x_location': 'A Location!',
			// 	'x_location_geo': 'My Location'
			// },
			beforeSend: function (xhr, settings) {
				xhr.url = settings.url;
				if (API.useLocation) {
					xhr.setRequestHeader('X-Location', API.Location);
					xhr.setRequestHeader('X-Location-Geo', API.LocationGeo);
				}
				console.log(Object.keys(API.Sessions));
				xhr.setRequestHeader('X-Sessions', Object.keys(API.Sessions).map(key => {
						console.log(key+'|'+API.Sessions[key]);
						return key+'|'+API.Sessions[key];
					} ).toString()
				);
			},
			error: function (jqXHR, textStatus, errorThrown) {
				let failedAPIRequest = false;
				let suppressMessage = false;
				let domain = '';
				if (jqXHR != null && jqXHR.url != null) {
					let url = jqXHR.url.split('?')[0];
					domain = API.removeTlD(url);
					let stripedDomain = API.removeTlD(url,true)
					console.log(stripedDomain);

					for(url in API.UrlsToSuppress) {
						if( API.UrlsToSuppress.hasOwnProperty(url) ) {
							if (stripedDomain.endsWith(API.UrlsToSuppress[url])) {
								suppressMessage = true;
								console.log('Suppressing the warning');
							}
						}
					}
				}
				console.log(domain);

				let message = '';
				let type = 'danger';
				if (textStatus === 'timeout') {
					failedAPIRequest = true;
					if( ! suppressMessage ) {
						message = 'The server request timed out. Please check your internet connection then try again.';
						TrackJS.track(message)
					}
				}
				else if (textStatus === 'abort') {
				}
				else if (textStatus === "parsererror") {
					failedAPIRequest = true;
					if( ! suppressMessage ) {
						message = 'A communication error has occurred. Please check your internet connection then contact support for further assistance.';
						TrackJS.track(message)

					}
				}
				else if (jqXHR.responseText != null) {
					try {
						let data = $.parseJSON(jqXHR.responseText);
						if (data != null && data.type) {
							type = data.type;
						}

						if( domain === API.URL ) {
							if (data != null && data.refresh != null && data.refresh !== false && data.refresh !== '') {
								window.location.reload();
								return;
							}
						}

						if (data != null && data.api != null && data.api !== false && data.api !== '' && domain === API.URL) {
							API.Version = data.api;
							API.clearCache().then(() => {
								//Do Nothing Else. Let the Status Code ask for the window reload if necessary
							});
						}

						else if (data != null && data.location != null && data.location !== false && data.location !== '') {
							let app_location = app.props.location;
							if (data.location !== app_location.pathname && domain === API.URL) {
								app.props.history.push(data.location);
							}
							if (data.response != null && data.response !== false && data.response !== '') {
								message = data.response;
							}
						}
						else if (data != null && data.response != null && data.response !== false && data.response !== '') {
							/** @property data.suppress */
							if (typeof data.suppress === "undefined" || data.suppress == null || data.suppress !== true) {
								if( ! suppressMessage ) {
									message = data.response;
								}
							}
						} else {
							failedAPIRequest = true;
							if( ! suppressMessage ) {
								message = 'A communication error has occurred. Please check your internet connection then contact support for further assistance.';
								TrackJS.track(message)

							}
						}
					} catch (err) {
						failedAPIRequest = true;
						if( ! suppressMessage ) {
							message = 'A communication error has occurred. Please check your internet connection then contact support for further assistance.';
							TrackJS.track(message)

						}
					}
				}
				else {
					failedAPIRequest = true;
					if (textStatus !== 'abort') {
						if( ! suppressMessage ) {
							message = 'A communication error has occurred. Please check your internet connection then contact support for further assistance.';
							TrackJS.track(message)
						}
					}
				}


				if( failedAPIRequest ) {
					//If its part of the authorized domains
					if( API.Domains.find(dom => dom === domain) ) {
						if( API.Online.find(online => online === domain)) {
							API.Online = API.Online.filter(online => online !== domain);
						}
					}
					if(domain === API.URL) {
						//Remove from the list of available domains until window refresh has happened.
						let DomainsToTry = API.Domains.filter(domain => domain !== API.URL && API.Online.find(online => online === domain) && (API.Sessions.hasOwnProperty(domain) && typeof API.Sessions[domain] !== 'undefined') );
						if (DomainsToTry > 0) {
							//Reset API Url to one of the backup domains
							API.URL = DomainsToTry[0];
							//Clear the Message so we don't throw an error.
							message = '';
							//Flag retry
							API.shouldRetry = true;
							console.log('Rotating URLS with', API.URL, ' and ', API.shouldRetry);
						}
					}
				}

				if (message !== '' && domain === API.URL) {
					//DISPLAY A MESSAGE
					// message = message.replace(new RegExp('<br /><br />', 'g'), '. ');
					// message = message.replace(new RegExp('<br />', 'g'), ' ');
					// message = message.replace(new RegExp('<br/>', 'g'), ' ');
					// message = message.replace(new RegExp('<br>', 'g'), ' ');
					//message = message.replace('<br />', " ").replace('<br/>', " ").replace('<br>', " ");

					if (message === 'You are not currently logged in.' || message === 'Not currently logged in.') {
						if (API.NoLoggedInNotice === true) {
							API.NoLoggedInNotice = false;
							setTimeout(function () {
								API.NoLoggedInNotice = true;
							}, 10000)
						} else {
							//Skip the display of the notice
							API.NoLoggedInNotice = true;
							return;
						}
						//Don't display it on initial load. Just take them to the login page
						startingLocations = startingLocations.length > 0 ? startingLocations : ['/', '/login'];
						if (startingLocations.includes(app.props.location.pathname)) {
							return;
						}

						lockedLocations = lockedLocations.length > 0 ? lockedLocations : ['/login', '/register', '/forgot-password'];
						if (lockedLocations.includes(app.props.location.pathname)) {
							return;
						}
					}

					if( (API.ToastId === 0 || !toast.isActive(API.ToastId)) || message !== 'This request is not authorized from a Kiosk.') {
						if (message.substr(0, 6) === 'Error ') {
							if (type === 'warning') {
								API.ToastId = toast.warning(<div className={'error-notice'} dangerouslySetInnerHTML={{__html: message}}/>, {
									position: toast.POSITION.TOP_CENTER,
									style: {
										width: '300px !important',
										maxWidth: '100%'
									}
								});
							}
						} else {
							if (type === 'warning') {
								API.ToastId = toast.warning(message, {
									position: toast.POSITION.TOP_CENTER,
									style: {
										width: '300px !important',
										maxWidth: '100%'
									}
								});
							}
						}
					}
				}

				if (typeof this.onError === 'function') {
					this.onError(errorThrown);
				}
			},
			complete: function (jqXHR) {
				let domain = '';
				if (jqXHR != null && jqXHR.url != null) {
					let url = jqXHR.url.split('?')[0];
					domain = API.removeTlD(url);
					if( jqXHR.statusCode === 200 ) {
						//If its not in sessions and its in domains then add it to sessions
						if( API.Domains.find(session => session === domain) ) {
							if( !API.Online.find(online => online === domain)) {
								API.Online.push();
							}
						}
					}
				}
				//After running always reset to shouldRetry
				if (jqXHR.responseText != null) {
					API.Sessions[domain] = API.Sessions[domain] || '';
					try {
						let data = $.parseJSON(jqXHR.responseText);
						// noinspection JSUnresolvedVariable
						if (data && data.response && data.response.session_id ) {
							API.Sessions[domain] = data.response.session_id;
						}
						if( domain === API.URL ) {
							if (data != null && data.refresh != null && data.refresh !== false && data.refresh !== '') {
								window.location.reload();
							}
						}
					} catch (err) {

					}
				}
			},
			statusCode: {
				//401 = Not Logged In
				401: function (jqXHR) {
					let domain = '';

					if (jqXHR != null && jqXHR.url != null) {
						let url = jqXHR.url.split('?')[0]
						//This if else is here because bestPos is .app not .com lile KT3 and FFLboss
						domain = API.removeTlD(url);
					}
					//If it exists in Domains
					if( API.Domains.find(dom => dom === domain) ) {
						//And its in current session
						if( API.Sessions.hasOwnProperty(domain) && typeof API.Sessions[domain] !== 'undefined' && API.Sessions[domain] ) {
							//Remove it from active Sessions
							delete API.Sessions[domain];
						}
					}
					if( domain === API.URL) {
						let unlockedLocations = lockedLocations.length > 0 ? lockedLocations : ['/login', '/register', '/forgot-password'];
						//let unlockedLocations = ['/login', '/register', '/forgot-password'];
						let allowedLocations = ['/404', '/500'];
						if (!unlockedLocations.includes(app.props.location.pathname) &&
							!allowedLocations.includes(app.props.location.pathname)) {
							if (app.hasOwnProperty('handleLogout')) {
								console.log('Processing Background Logout');
								app.handleLogout();
							} else {
								console.log('Push to /login')
								app.props.history.push('/login');
							}
						}
					}
				},
				//402 = Payment Required
				402: function (jqXHR) {
					let domain = '';
					if (jqXHR != null && jqXHR.url != null) {
						let url = jqXHR.url.split('?')[0];
						domain = API.removeTlD(url);
					}
					if( domain === API.URL ) {
						let unlockedLocations = lockedLocations.length > 0 ? lockedLocations : ['/login', '/register', '/forgot-password'];
						//let unlockedLocations = ['/login', '/register', '/forgot-password', '/404', '/500'];
						if (![redirect].includes(app.props.location.pathname) &&
							!unlockedLocations.includes(app.props.location.pathname)) {
							app.props.history.push(redirect);
						}
					}
				},
				//403 = General Forbidden
				403: function () {
				},
				//404 = Unable to find Object Requested
				404: function () {
				},
				//405 = Method Not Allowed / Invalid API Routes
				405: function () {
				},
				//406 = Bad Request/Missing Data
				406: function () {
				},
				409: function () {
				},
				426: function () {
					//window.location.reload();
				}
			},
			timeout: 1000 * 60 * 10 //10 minutes
		});
	},



};

export default API;
