import React, {Component} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';

import MainApp from 'routes/app'
import PageRegister from 'routes/register/'
import PageInformation from 'routes/information/'
import Framework from '@beardeddevops/react.framework';

import PageForgotPassword from 'routes/forgot-password/'
import PageLogin from 'routes/login/'

import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/scss/font-awesome.scss';
import '@beardeddevops/react.framework/dist/index.css';

import lightTheme from './themes/lightTheme';
import 'constants/Styles.scss';

import API from "@beardeddevops/react.api";
import APPCONFIG from "constants/Config";
import Types from 'constants/Types';
import {defaultState} from 'constants/State';
import classNames from "classnames";
import {version} from '../../public/meta.json';
import BarcodeReader from 'react-barcode-reader';

export default class App extends Component {

	constructor(props) {
		super(props);

		API.URL = APPCONFIG.settings.apiURL();
		API.Version = APPCONFIG.settings.apiVersion;
		let lockedLocations = ['/login', '/register', '/forgot-password', '/information'];
		API.setAJAXDefaults(this, toast, lockedLocations);

		this.state = {
			shouldReloadCart: false,
			scannedCode: "",
			startLoading: false,
			hasLoaded: false,
		};

		this.sessionCheckingInitialized = false;
		// noinspection JSUnusedGlobalSymbols
		this.scanner_current_product = "";

		if (this.props.rootState.shouldCacheBust) {
			window.onbeforeunload = function () {
				if (caches || this.props.rootState.shouldCacheBust) {
					// Service worker cache should be cleared with caches.delete()
					caches.keys().then(function (names) {
						for (let name of names) caches.delete(name);
					});
					localStorage.clear();
				}
			}
		}
	}

	async componentDidMount() {
		let {rootState} = this.props;
		this.rootStateID = rootState.subscribe({
			name: null,
			account: null,
			account_name: null,
			locations: null,
			locations_count: null,
			sessions_count: null,
			session_items_count: null,
			ffl_boss_linked: null,
			getUserInformation: this.getUserInformation,
			logout: this.handleLogout,
			receiptCustomizationId: null,
			shouldCacheBust: false
		});

		await API.loadOptions(true, APPCONFIG.settings.apiVersion);

		await this.getUserInformation();

		this.setState({startLoading: true});

		// Initial routing is done, reload account info on route change
		this.props.history.listen(async () => {
			await this.getUserInformation();
		});
	}

	componentWillUnmount() {
		this.props.rootState.unsubscribe(this.rootStateID);
	}

	handleLogout = () => {
		this.props.rootState.set({...defaultState});
		this.props.history.push('/login');
	}

	getUserInformation = async () => {
		let {rootState} = this.props;

		let info = new API.BuildClass(Types.Accounts._name, API.RequestType.READ);
		let data;

		try {
			data = await info.submit(null, 'GET_DETAILS');
		} catch (e) {
			console.log("e", e);
			return;
		}
		if (!data) {
			return;
		}

		/**
		 * @object data
		 * @property name_first
		 * @property name_last
		 * @property account_first
		 * @property account_last
		 */

		rootState.set({
			account: data.account,
			account_name: data.account_first + " " + data.account_last,
			roles: data.user_access,
			name: data.name_first + ' ' + data.name_last,
			location: data.location,
			locations: data.locations,
			locations_count: data.locations_count,
			sessions_count: data.sessions_count,

			//This keeps resetting the item count to the number of checkout entries, when leaving checkout route
			session_items_count: data.session_items_count,

			ffl_boss_linked: data.ffl_boss_linked,
			receiptCustomizationId: data.recieptCustom,
			failedSubscription: data.failed_subscription,
			//Todo: its cause the api isnt returning the version number, thats why its failing
			// shouldCacheBust: parseInt(data.version.replaceAll('.', '')) > parseInt(version.replaceAll('.', ''))
			no_subscription: data.no_subscription,
			api_key_exists: data.api_key_exists,
			location_plan: data.location.StripePlan
			//just in case this is null
		});
		//setting in rootState doesn't trigger re-renders
		this.setState({show_consignments: !!data.show_consignments, show_layaways: !!data.show_layaways})

		if (this.props.rootState.shouldCacheBust) {
			if (!this.state.cacheInterval) {
				API.Toast.info('A new version is available, please refresh when you are able to.', {
					position: API.Toast.POSITION.TOP_RIGHT
				}); //Initial toast

				this.state.cacheInterval = setInterval(() => {
					API.Toast.info('A new version is available, please refresh when you are able to.', {
						position: API.Toast.POSITION.TOP_RIGHT
					});
				}, 300000)
			}
		}

		// if (location != null && data.locations_count === 0 && location.pathname !== '/locations') {
		// 	this.props.history.push('/locations');
		// }

		// Initialize services depending on an authenticated session
		await this.initSessionChecks();


		if (data.no_subscription && !window.location.href.includes('settings#billing')) {
			this.props.history.push('/settings#billing');
		}
	}

	handleSessionCheck = async () => {
		const lockedLocations = ['/login', '/forgot-password', '/information'];

		let check = new API.BuildClass(Types.Accounts._name, API.RequestType.READ);
		let data;
		try {
			//TODO: might use this later
			data = await check.submit(null, 'GET_CHECK');
		} catch (e) {
			console.log('handleSessionCheck error: ', e);
		}

		// If you're on a locked location but logged in, then redirect
		if (lockedLocations.includes(location.pathname)) {
			this.props.history.push('/');
		}
	}

	initSessionChecks = async () => {
		if (this.sessionCheckingInitialized) return;

		setInterval(async () => {
			await this.handleSessionCheck();
		}, 60000);

		await this.handleSessionCheck();

		this.sessionCheckingInitialized = true;
	}

	handleCheckoutIncrement = () => {
		this.props.rootState.set({
			session_items_count: this.props.rootState.session_items_count === null ? 1 : 1 + this.props.rootState.session_items_count
		});
	}

	processScanner = (product) => {
		let originalProduct = product;
		let productType = product.split('-')[0];
		product = product.split('-')[1];
		// let rootState = this;
		if (product === '0001' || product === '0002' || product === '0003' || product === '0004' || product === '4' || product === '0005') {
			product = originalProduct.split('-')[2];
		}
		if (this.props.history.location.pathname === "/skus") {
			this.props.history.push("/skus/" + product);
		} else if (this.props.location.pathname.includes("/layaways")) {
			//do nothing here, let the layaway page handle scanning on its own
		} else {
			if (productType === 'P' || productType === 'p') {
				$.ajax({
					type: "PUT",
					url: APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/checkouts/products',
					data: {
						object: JSON.stringify({
							"checkout": "",
							"sku": product,
							"product": product,
							"serial": null,
							"quantity": 1
						}),
					},
					success: (data) => {
						API.ToastId = toast.info(data.response.product + ' added to cart.', {
							position: toast.POSITION.TOP_RIGHT
						});
						this.handleCheckoutIncrement();
					},
					error: function (xhr, status, error) {
						let errorMessage = xhr.responseJSON.response === 'Product is already in cart' ? xhr.responseJSON.response : 'We were unable to find that product you requested on your account.'
						API.ToastId = toast.error(errorMessage, {
							position: toast.POSITION.TOP_CENTER
						});
					}
				});
			}
			//if(productType === 'I') {
			else {
				$.ajax({
					type: "PUT",
					url: APPCONFIG.settings.apiURL() + '/' + APPCONFIG.settings.apiVersion + '/checkouts/items',
					data: {
						object: JSON.stringify({
							"checkout": "",
							"sku": productType === 'I' ? product ?? originalProduct : originalProduct,
							"product": product ?? originalProduct,
							"serial": null,
							"quantity": 1
						}),
					},
					success: (data) => {
						API.ToastId = toast.info(data.response.inventories.skus.sku + ' added to cart.', {
							position: toast.POSITION.TOP_RIGHT
						});
						this.handleCheckoutIncrement();
					},
					//TODO: we dont need the product is already in cart special validation, need to get rid of in the future cause the backend wont even return it atm
					error: function (xhr, status, error) {
						let errorMessage = xhr.responseJSON.response === 'Product is already in cart' ? xhr.responseJSON.response : 'We were unable to find that product you requested on your account.'
						API.ToastId = toast.error(errorMessage, {
							position: toast.POSITION.TOP_CENTER
						});
					}
				});
			}
		}
		if (this.props.history.location.pathname === "/checkout") {
			this.props.history.push('/');
			this.props.history.push('/checkout');
		}
	}

	render() {
		let {match, location, rootState} = this.props;
		let {account} = rootState;
		let materialUITheme = lightTheme;
		let isRoot = location.pathname === '/';


		if (isRoot) {
			API.NoLoggedInNotice = false;
			let direct = '/dashboard';
			if (!account) {
				direct = '/login';
			}
			if (!window.location.href.includes('register')) {
				return (
					<Redirect to={direct}/>
				);
			}
		}
		let startLoading = this.state.startLoading;


		return (
			<MuiThemeProvider theme={createMuiTheme(materialUITheme)}>
				<div id="app-inner">
					<div className="preloaderbar hide">
						<span className="bar"/>
					</div>
					<div className='app-main full-height fixed-header sidebar-sm'>
						<BarcodeReader
							minLength={3}
							onScan={this.processScanner}
						/>
						{account ?
							<Switch>
								<Route path={`${match.url}`} render={
									(props) => <MainApp {...props} //Props From Route NOT from MainApp
									                    shouldReloadCart={this.state.shouldReloadCart}
									                    handleReloadCart={this.handleReloadCart}
									                    scannedCode={this.state.scannedCode}
									                    show_consignments={this.state.show_consignments}
									                    show_layaways={this.state.show_layaways}
									/>
								}/>
								<Redirect to="/"/>
							</Switch>
							: startLoading ?
								<Switch>
									<Route exact path="/login" component={PageLogin}/>
									<Route exact path="/register" component={PageRegister}/>
									<Route exact path="/forgot-password" component={PageForgotPassword}/>
									<Route exact path="/information" component={PageInformation}/>
								</Switch>
								: <div className={classNames("tabled", "loader", 'active')}>
									<ClipLoader color={"#4A4A4A"} loading={true}/>
								</div>}
						<ToastContainer autoClose={10000} style={{textAlign: "center"}}/>
					</div>
				</div>
			</MuiThemeProvider>
		);
	}
}

App = Framework.Components.withRootState(App);
