import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import ReactTable from "react-table";
import {Button} from "reactstrap";
import {Card, CardContent} from "@material-ui/core";

import API from "@beardeddevops/react.api";
import APPCONFIG from "../../constants/Config";
import Framework from "@beardeddevops/react.framework";
import Types from 'constants/Types';
import {Elements} from "components";

export default class LayawayProducts extends Framework.Components.Collection {
	constructor(props) {
		let model = new API.BuildClass(Types.Inventories_Connected._name, API.RequestType.READ_ALL);
		super(props, model);
		this.state.limit = 10;
		this.limit = 10
		this.state.products=[]
		this.state.products_error=[];
		this.afterSubmit = this.afterSubmit.bind(this);
	}
	addProduct = (e) => {
		const {data, products} = this.state;
		const pkey = parseInt(e.currentTarget.id);
		let found = products.find((entry) => entry.pkey === pkey);

		if (!found) {
			const product = data.find((entry) => entry.pkey === pkey);

			if (product) {
				products.push(product);

				this.setState({
					products,
					products_error: ""
				});
			}
		} else {
			this.setState({
				products_error: "Product already in list.",
			});
		}
	};
	removeProduct = (e) => {
		const {products} = this.state;
		const pkey = parseInt(e.currentTarget.id);
		const existingItemIndex = products.findIndex((entry) => entry.pkey === pkey);
		if (existingItemIndex > -1) {
			products.splice(existingItemIndex, 1);
		}

		this.setState({
			products,
			products_error: '',
		});
	};
	async afterSubmit(data, request, suppressProcess = false) {
		if (typeof this.props.afterSave !== 'undefined') {
			this.props.afterSave(this.state.products);
		}
	};
	table =()=>{
		const {data, pages, loading,limit} = this.state;
		let columns =[
			{
				Header: "",
				className: "button-cell",
				filterable: false,
				sortable: false,
				width: 40,
				Cell: props => {
					return (
						<Button
							id={props.original.pkey}
							color="blue-hoki"
							size={"sm"}
							block={true}
							onClick={this.addProduct}
						>
							<i className={"fa fa-plus"}/>
						</Button>
					);
				}
			},
			{
				Header: "Type",
				id: "type",
				accessor: r => r.type,
			},
			{
				Header: "Manufacturer",
				id: "manufacturer",
				accessor: r => r.manufacturers?.name || r.manufacturer_other
			},
			{
				Header: "Model",
				id: "model",
				accessor: r => r.model
			},
			{
				Header: "Caliber",
				id: "caliber",
				accessor: r => r.calibers?.name || r.caliber_other,
			},
			{
				Header: "Serial",
				id: "serial_number",
				//TODO: Make sure this lonks right
				accessor: r => <a href={"https://app.fflboss.com/products/"+r.binary_id+'-' + r.pkey} target={"_blank"}>
					{r.serial_number}
					<Framework.Printable.Label
						ref={el => (this['printable' + r.pkey] = el)}
						prefix="P" code={r.pkey} label={r.serial_number}/>
				</a>
			},
			{
				Header: "SKU",
				id: "sku",
				accessor: r => r.sku
			},
			{
				Header: "Price",
				id: "price_list",
				accessor: r => r.price_list,
				Cell: props => <span>${props.value}</span>
			},
			{
				Header: "Status",
				id: "status",
				accessor: r => r.status
			},
			{
				Header: "Location",
				id: "location",
				accessor: r => r.location
			},

		]
		return(
			<>
				<div className={'row'}>
					<div className={'col-lg-8'}>
						<div className="table-bar">
							<div className="d-flex">
								{this.renderSearch('Products')}
								{this.renderHeaderRight("Products",columns)}
							</div>
						</div>
						<ReactTable
							key={this.state.key}
							columns={columns}
							manual // Forces table not to paginate or sort automatically, so we can handle it server-side
							data={data}
							pages={pages} // Display the total number of pages
							loading={loading} // Display the loading overlay when we need it
							loadingText="Requesting Data"
							onFetchData={this.fetchData} // Request new data when things change
							onPageSizeChange={this.onPageSizeChange}
							onSortedChange={this.onSortedChange}
							page={this.state.page}
							onPageChange={this.onPageChange}
							defaultSorted={[
								{
									id: this.model.sort,
									desc: this.model.order === 'desc'
								}
							]}
							expanderDefaults={{
								sortable: true,
								resizable: true,
								filterable: false,
								width: undefined
							}}
							showPagination={true}
							showPaginationTop={false}
							showPaginationBottom={true}
							showPageSizeOptions={true}
							defaultPageSize={limit}
							pageSize={10}
							className="-striped -highlight"
						/>
					</div>
					<div className={'col-lg-4'}>
						<h4 className="section-title">Selected Products</h4>
						{this.state.products_error &&
						<div style={{
							"padding": "0px 4px 4px",
							"fontStyle": "italic",
							"fontSize": "12px",
							"color": "#ef534f",
							"textAlign": "center"
						}}>
							{this.state.products_error}
						</div>
						}
						{this.state.products.map((product, i) => {
							return (
								<Elements.ProductLookup
									key={i}
									product={product}
									remove={this.removeProduct}/>
							);
						})}
					</div>
					{APPCONFIG.settings.isDev() ? null :
						<Framework.Elements.LoadingSpinner isLoading={loading}/>
					}
				</div>

			</>
		)

	}
	render() {
		let hasModal = typeof this.props.hasModal !== 'undefined' ? this.props.hasModal : false;
		if (hasModal) {
			return this.table();
		}
		return (
			<Card>
				<CardContent>
					{this.table()}
				</CardContent>
			</Card>
		)
	}
}
