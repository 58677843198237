import React from "react";
import {Button, ButtonGroup} from "reactstrap";
import PropTypes from "prop-types";
import MissingPropertyError from "../Errors/MissingProperty";

export default class _ButtonSwitch extends React.Component {
	static getDerivedStateFromProps = (props, state) => {
		const nextState = {};

		if (props.value !== state.value) {
			nextState.value = props.value;
		}

		if (props.error !== state.error) {
			nextState.error = props.error;
		}

		return nextState;
	};

	static propTypes = {
		property: PropTypes.object.isRequired,
		update: PropTypes.func.isRequired,

		labelTrue: PropTypes.string,
		labelFalse: PropTypes.string,
		valueTrue: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
		]),
		valueFalse: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
		]),
		disabled: PropTypes.bool,
		autoSelect: PropTypes.bool,
		nullable: PropTypes.bool,
	};

	static defaultProps = {
		labelTrue: 'Yes',
		labelFalse: 'No',
		valueTrue: true,
		valueFalse: false,
		disabled: false,
		nullable: false,
		uncheck: false,
	}

	constructor(props) {
		super(props);
		if( !this.props.property ) {
			throw new MissingPropertyError('Missing API property for ButtonSwitch. Please "Clear API Cache" from the drop down menu in the top left.');
		}
		this.state = this.props.property;
		this.state.value = this.props.value;
		this.state.error = this.props.error;
		const {property, value, error, labelTrue, labelFalse, valueTrue, valueFalse, disabled, update, autoSelect, ...rest} = props;
		this.passThroughProps = rest;
		if (this.props.nullable) this.state.value = null;
	}

	passThroughProps;

	handleDataChange = (e) => {
		const value = JSON.parse(e.currentTarget.id);
		const {valueTrue, valueFalse} = this.props;
		let selected = value ? valueTrue : valueFalse;
		if (this.state.value === value && this.props.uncheck) {
			selected = null;
		}

		this.props.update(this.state.name, selected);
	};

	render() {
		const {labelTrue, labelFalse, valueTrue, valueFalse, disabled, autoSelect, nullable} = this.props;
		let property = this.state;
		let passThroughProps = this.passThroughProps;

		if ((property.value == null || property.value === '') && property.fill && !autoSelect && !nullable) {
			property.value = valueFalse;
		}

		return (
			<div>
				<ButtonGroup name={property.name}>
					<Button
						{...passThroughProps}
						id="true"
						color="primary"
						outline={property.value !== valueTrue}
						onClick={this.handleDataChange}
						disabled={disabled}
						active={property.value === valueTrue}>
							{labelTrue}
					</Button>
					<Button
						{...passThroughProps}
						id="false"
						color="primary"
						outline={property.value !== valueFalse}
						onClick={this.handleDataChange}
						disabled={disabled}
						active={property.value === valueFalse}>
							{labelFalse}
					</Button>
				</ButtonGroup>
				{property.error !== "" && <div className="errorColor"><i>{property.error}</i></div>}
			</div>
		)
	}
}
